import { validActionCheck } from 'store/actionUtils';

const initialState = {};

const SlotsUsageReducer = (state = initialState, action) => {
  if (validActionCheck(action)) {
    switch (action.type) {
      case 'SET_SLOTS_USAGE_PER_PROJECT':
        return {
          ...state,
          unitSlotUsageReportMap: action.payload
        };
      default: {
        return state;
      }
    }
  }
  return state;
};

export default SlotsUsageReducer;
