import axios from 'axios';
import to from 'await-to-js';
import env from 'environment';
import urlJoin from 'url-join';

/**
 * validate the code of an album
 * @param {string} code
 */
export const validateAlbumCode = code => {
  return new Promise((resolver, rejecter) => {
    axios({
      method: 'get',
      url: `${env().base360Url}/albums/code=${code}/published`,
      headers: {
        'X-Around-Api-Token': env().apiToken360
      }
    })
      .then(result => {
        resolver(result.status);
      })
      .catch(err => {
        rejecter(err);
      });
  });
};

/**
 * Get a 360 album by code
 * @param {String} albumCode - code of album
 */
export const getAlbumByCode = async albumCode => {
  if (!albumCode) {
    return Promise.reject(new Error('no album code given'));
  }
  const apiUrl = env().base360Url; //'https://api.around.media/v1';

  // first get api token
  const [tokenErr, result] = await to(
    axios.get(
      urlJoin(
        apiUrl,
        'albums',
        `code=${albumCode}/token`,
        `?_=${Date.now()}&apiToken=${env().apiToken360}`
      )
    )
  );

  if (tokenErr) {
    return {};
  }

  // then get album
  const [getAlbumErr, resultAlbum] = await to(
    axios.get(
      urlJoin(
        apiUrl,
        'albums',
        `code=${albumCode}`,
        `?_=${Date.now()}$token=${result.data?.token}&apiToken=${
          env().apiToken360
        }`
      )
    )
  );

  if (getAlbumErr) {
  }

  return resultAlbum.data;
};
