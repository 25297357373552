import { isMobileOnly } from 'react-device-detect';

export const modalStepTwo = {
  padding: 0,
  overflow: 'hidden',
  position: 'absolute',
  left: 0,
  top: 0,
  bottom: 0,
  right: 0,
  minWidth: isMobileOnly ? 200 : 500,
  borderRadius: isMobileOnly ? 0 : 3
};
