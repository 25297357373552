import { validActionCheck } from 'store/actionUtils';

const initialState = {
    counter: 0
};

const CounterReducers = (state = initialState, action) => {
    if (validActionCheck(action)) {
        switch (action.type) {
        case 'INCREMENT':
            return { counter: state.counter + 1 };

        case 'DECREMENT':
            return { counter: state.counter - 1 };

        default: {
            return state;
        }
        }
    }
    return state;
};

export default CounterReducers;
