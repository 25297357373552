import React, {
  useEffect,
  useCallback
} from 'react';

// Components
import PageSwiper from './contentViewer/PageSwiper';
import Navigation from './contentViewer/Navigation';
import Preview from './contentItems/Preview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//Helpers
import { useSwitcherState } from './SwitcherStore';
import { isMobile } from 'react-device-detect';
import { buildAssetURIWithOptions, downloadFilesFromUrl } from 'helpers/util';
import { Tracking } from '@prompto-api';
import env from 'environment';
import Cookies from 'universal-cookie';

// Styling
import styled from 'styled-components';
import { styledRespondTo } from 'styles/mixins';

const GridWrapper = styled.div`
  outline: transparent;
  max-width: 100vw;
  display: flex;
  grid-gap: 24px;
  height: fit-content;
  justify-content: center;
  flex-flow: row wrap;

  ${styledRespondTo.sm`
    grid-gap: 32px;
    justify-content: flex-start;
  `}
`;

const DownloadButton = styled.button`
  display: flex;
  color: ${({ theme }) => theme.whitePure};
  font-size: 0.75rem;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: ${({ theme }) => theme.showcaseBlack};
  cursor: pointer;
  box-sizing: border-box;
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 10;
  outline: transparent;
  border: none;
  transition: all 0.3s;
  opacity: 0;
`;
const StyledContextMenuIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.whitePure};
  font-size: 1.5rem;
`;

const FlexItemWrapper = styled.div`
  user-select: none;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  background-color: transparent;
  border-radius: 2px;
  transition: all 0.3s;
  padding: 0;

  &:hover {
    background-color: ${({ theme }) => theme.beigeBg10};
    & > ${DownloadButton} {
      opacity: 1;
    }
  }

  ${styledRespondTo.sm`
    padding: 16px;
    border-radius: 8px;
  `}
`;

const ContentItemHeader = styled.h3`
  width: 140px;
  font-family: ${({ theme }) => theme.fonts.DMSans};
  font-size: 0.75rem;
  font-weight: bold;
  color: ${({ theme }) => theme.showcaseBlack};
  word-wrap: break-word;
  margin-top: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  ${styledRespondTo.sm`
    width: 240px;
  `}
`;

const ResourcesGrid = ({
  contentItemList,
  previewContent,
  vaultId,
  visitorSessionId,
  fetchingContent
}) => {
  const cookies = new Cookies();
  const authorizedUserId = cookies.get(`prompto/${env().env}/ust`);
  const { SwitcherState, SwitcherStateDispatch } = useSwitcherState();
  const { activePage, showGrid, pages } = SwitcherState;

  useEffect(() => {
    if (previewContent?.length) {
      SwitcherStateDispatch({
        type: 'setPages',
        payload: previewContent
      });
    }
  }, [previewContent]);

  useEffect(() => {
    if (contentItemList && contentItemList.length > 0) {
      SwitcherStateDispatch({
        type: 'setPages',
        payload: contentItemList
          ?.filter((item) => item.contentItemState !== 'archived')
          ?.map((item, index) => ({
            ...item,
            pageIndex: index
          }))
      });
    }
  }, [contentItemList, SwitcherStateDispatch]);

  // Events
  const onPageClick = (e, index) => {
    document.body.style.overflow = 'hidden';
    SwitcherStateDispatch({ type: 'setGridItemClicked', payload: true });
    SwitcherStateDispatch({ type: 'setActivePage', payload: index });
    const timer = setTimeout(() => {
      SwitcherStateDispatch({ type: 'setShowGrid', payload: false });
      clearTimeout(timer);
    }, 60);
  };

  useEffect(() => {
    document.body.style.overflow = 'auto';
  }, []);

  const onNextPage = useCallback(() => {
    let newPageIndex = activePage + 1;
    if (newPageIndex >= pages?.length) {
      newPageIndex = 0;
    }

    SwitcherStateDispatch({ type: 'setActivePage', payload: newPageIndex });
  }, [SwitcherStateDispatch, activePage]);

  const onPreviousPage = useCallback(() => {
    let newPageIndex = activePage - 1;
    if (newPageIndex < 0) {
      newPageIndex = pages?.length - 1;
    }

    SwitcherStateDispatch({ type: 'setActivePage', payload: newPageIndex });
  }, [SwitcherStateDispatch, activePage]);

  const onCloseSwiper = useCallback(() => {
    if (!window.location.pathname.includes('unit')) {
      document.body.style.overflow = 'auto';
    }
    SwitcherStateDispatch({ type: 'setShowGrid', payload: true });
  }, [SwitcherStateDispatch]);

  const onPageLoaded = useCallback(() => {
    SwitcherStateDispatch({ type: 'setGridItemClicked', payload: false });
  }, [SwitcherStateDispatch]);

  // provide an extra padding for navigation if 360 item to avoid hint message overlapping with navigation
  const navigationExtraPadding = pages[activePage]?.contentItemType === 'image360';

  const pageSwiper = (
    <PageSwiper
      activePage={activePage}
      pages={pages}
      onNextPage={onNextPage}
      onPreviousPage={onPreviousPage}
      onClose={onCloseSwiper}
      videoFallbackThumbnail={''}
      CustomNavigation={<Navigation extraPadding={navigationExtraPadding} />}
      onPageLoaded={onPageLoaded}
      show={!showGrid}
      SwitcherStateDispatch={SwitcherStateDispatch}
      vaultId={vaultId}
      visitorSessionId={visitorSessionId}
    />
  );

  const renderCard = (page) => {
    if(!page.contentUri) return null;
    const handleDownload = (event) => {
      event.stopPropagation();
      downloadFilesFromUrl(
        [{
          url: ['image', 'image360'].includes(page.contentItemType) ?
            buildAssetURIWithOptions('o=true', page.contentUri)
            : page.contentUri,
          title: page.title.textMap.en,
          objectId: page.objectId,
        }],
        '',
        '',
        // onDownload callback - track download if it successfully happened
        () => {
          // skip tracking at content collection page for authorized users
          if(!authorizedUserId) {
            Tracking.trackSharedCollectionContentDownload(
              {
                vaultId,
                sessionId: visitorSessionId,
                action: 'sharedMediaDownloadSucceeded',
                contentItemIdList: [page.objectId]
              }
            )
              .catch(() => {});
          }
        }
      )
    }
    const content = (
      <Preview
        key={page.pageIndex}
        data={page}
        pageIndex={page.pageIndex}
        videoFallbackThumbnail={''}
        isVerticalLayout={true}
      />
    );
    const header = (
      <ContentItemHeader>
        {page?.title?.textMap?.en}
      </ContentItemHeader>
    )
    const download = <DownloadButton onClick={handleDownload}>
      <StyledContextMenuIcon icon={['far', 'arrow-to-bottom']} size='1x' />
    </DownloadButton>

    return (
        <FlexItemWrapper
          key={page.pageIndex}
          onClick={(event) => {
            event.stopPropagation();
            onPageClick(event, page.pageIndex)
          }}
        >
          {content}
          {header}
          {!isMobile ? download : null}
        </FlexItemWrapper>
    );
  };

  return (
    <GridWrapper>
      {pageSwiper}
      {pages?.map(renderCard).filter((x) => x)}
    </GridWrapper>
  );
};

export default ResourcesGrid;
