import React, { useRef, useEffect } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import {
  element,
  func,
  oneOfType,
  array,
  bool,
  object,
  string
} from 'prop-types';

// Components
import PromptoLoader from 'components/loader/PromptoLoader';
import { AnimatePresence, motion } from 'framer-motion';

// Helpers
import { debounce } from 'helpers/util';
import { styledRespondTo } from 'styles/mixins';

// Styling
import styled from 'styled-components';
import { MdWarning } from 'react-icons/md';

const Loader = styled(motion.div)`
  display: block;
  flex-grow: 1;
  height: 100px;
  padding: 20px;
`;

const ErrorMessage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding-bottom: 60px;
  margin: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  svg {
    color: ${({ theme }) => theme.warningColor};
    height: 80px;
    width: 80px;
  }
  p {
    color: ${({ theme }) => theme.primary400};
    font-size: 0.875rem;
    padding-top: 40px;
  }
`;

const List = styled.div`
  height: 100%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: ${({ fetching }) => (fetching ? 'hidden' : 'auto')};
  ${({ styles }) => styles}
  ${styledRespondTo.md`
    max-width: 1180px;
  `}
  ${styledRespondTo.extra_lg`
    max-width: 1440px;
  `}
`;

const ListScrollBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  overflow-y: ${({ disabled, fetching }) =>
    disabled || fetching ? 'hidden' : 'auto'};
  padding-right: 5px;
  ${({ styles }) => styles}
`;

const ListContent = styled.div`
  ${({ styles }) => styles}
`;

export const onScroll = debounce(
  (event, onBottomReached = () => {}, fetching = false) => {
    if (!event || (event && !event.target) || fetching) {
      return;
    }
    const { offsetHeight, scrollTop, scrollHeight } = event.target;
    if (offsetHeight + scrollTop >= scrollHeight * 0.8) {
      onBottomReached();
    }
  },
  100
);

const CardList = ({
  dataTestId,
  dataList,
  placeholderComponent,
  renderItem,
  renderLoader,
  toolbarItem,
  fetching,
  disabled,
  error,
  style,
  styles,
  listStyles,
  listScrollStyles,
  onBottomReached,
  enableAnimation
}) => {
  const cardContainer = useRef(null);
  const onNeedsUpdate = () => {
    // When deleting a project, we simulate a scroll event
    // to make the lazyloading check if it needs to load more
    onScroll({ target: cardContainer.current }, onBottomReached, fetching);
  };

  useEffect(() => {
    if (disabled && cardContainer.current) {
      cardContainer.current.scrollTop = 0;
    }
  }, [disabled]);

  const loader = renderLoader ? (
    renderLoader()
  ) : (
    <Loader
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <PromptoLoader dataTestId={'fetch-loader'} width={50} height={50} />
    </Loader>
  );

  const content = () => {
    if ((dataList && dataList.length > 0 && renderItem) || fetching) {
      return (
        <ListScrollBox
          ref={cardContainer}
          styles={listScrollStyles}
          disabled={disabled}
          fetching={fetching}
          onScroll={(event) => {
            /* istanbul ignore next */
            event.persist();
            onScroll(event, onBottomReached, fetching);
          }}
        >
          <ListContent
            key="cardList"
            data-testid="card-list-cards"
            styles={listStyles}
          >
            {enableAnimation ? (
              <AnimatePresence>
                {dataList.map((item, id) =>
                  renderItem({ item, id }, onNeedsUpdate)
                )}
              </AnimatePresence>
            ) : (
              dataList.map((item, id) =>
                renderItem({ item, id }, onNeedsUpdate)
              )
            )}
          </ListContent>

          <AnimatePresence>{fetching && loader}</AnimatePresence>
        </ListScrollBox>
      );
    }
    return (
      <ListScrollBox styles={listScrollStyles}>
        {placeholderComponent}
      </ListScrollBox>
    );
  };

  return (
    <List
      styles={styles}
      style={style}
      id="cardList"
      data-testid={dataTestId}
      fetching={fetching}
    >
      {toolbarItem}
      {error ? (
        <ErrorMessage data-testid={'errorMessage'}>
          <MdWarning />
          <p>
            {error?.error?.message ??
              'Something went wrong. Please, refresh the page.'}
          </p>
        </ErrorMessage>
      ) : (
        content()
      )}

      <ReactResizeDetector
        handleHeight
        handleWidth
        skipOnMount
        onResize={() => {
          // Simulating an onScroll event to make the lazy loading
          // check if it needs to load more, when resizing
          onScroll(
            { target: cardContainer.current },
            onBottomReached,
            fetching
          );
        }}
      />
    </List>
  );
};

CardList.propTypes = {
  dataTestId: string,
  dataList: array,
  placeholderComponent: oneOfType([element, func]),
  renderItem: oneOfType([element, func]),
  renderLoader: oneOfType([element, func]),
  toolbarItem: oneOfType([element, func]),
  fetching: bool,
  style: object,
  styles: string,
  listStyles: oneOfType([string, array]),
  listScrollStyles: string,
  onBottomReached: func
};

CardList.defaultTypes = {
  listClassName: null,
  dataTestId: 'cardlist',
  dataList: null,
  placeholderComponent: <span />,
  renderItem: null,
  toolbarItem: null,
  fetching: false,
  style: null,
  styles: null,
  listStyles: null,
  listScrollStyles: null,
  onBottomReached: null
};

export default CardList;
