import React from 'react';
import { bool, shape, func } from 'prop-types';

// Helpers
import localizer from 'localization/localizer';
import { buildAssetURIWithOptions } from 'helpers/util';

// Styling
import styled from 'styled-components';

const thumbnailOptions = 'mr=410,410';

const ListItem = styled.li`
  display: flex;
  border: 1px solid ${(props) => props.theme.gray300};
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 5px;
  user-select: none;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

const Thumbnail = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 3px;
  margin-right: 35px;
`;

const ItemBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 20px;
  width: 60%;
`;

const Label = styled.span`
  display: block;
  font-size: 0.75rem;
  color: ${(props) => props.theme.primary200};
  text-transform: capitalize;
`;

const ProjectName = styled.span`
  display: block;
  font-size: 0.75rem;
  font-weight: 600;
  color: ${(props) => props.theme.primary400};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row wrap;
`;

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;

  &:after {
    content: '';
    position: absolute;
    display: none;
  }
`;

const Container = styled.label`
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-bottom: 25px;
  cursor: pointer;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ ${Checkmark} {
      background-color: ${({ theme }) => theme.successColor};
    }
    &:checked ~ ${Checkmark}:after {
      display: block;
    }
  }

  ${Checkmark}:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid ${({ theme }) => theme.whitePure};
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const InviteUserProjectCard = ({ project, selected, disabled, onToggle }) => {
  const { title, thumbnailUri } = project;

  return (
    <ListItem
      disabled={disabled}
      onClick={() => {
        if (!disabled) {
          onToggle(project);
        }
      }}
    >
      <Thumbnail
        src={buildAssetURIWithOptions(thumbnailOptions, thumbnailUri)}
      />
      <ItemBody>
        <Label>{localizer.title}</Label>
        <ProjectName>{title}</ProjectName>
      </ItemBody>
      <CheckBoxWrapper>
        <Container>
          <input
            type="checkbox"
            disabled={true}
            checked={selected}
            readOnly={true}
          />
          <Checkmark />
        </Container>
      </CheckBoxWrapper>
    </ListItem>
  );
};

InviteUserProjectCard.propTypes = {
  project: shape({}).isRequired,
  onToggle: func.isRequired,
  selected: bool,
  disabled: bool
};

InviteUserProjectCard.defaultProps = {
  selected: false,
  disabled: false
};

export default InviteUserProjectCard;
