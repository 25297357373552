import { useState, useEffect, useCallback } from 'react';
import { usePrevious } from '@prompto-helpers';

const useKeyPress = (targetKey, isDown) => {
  const [keyPressed, setKeyPressed] = useState(false);
  const keyPressedPrevious = usePrevious(keyPressed);

  const downHandler = useCallback(
    ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(true);
      }
    },
    [targetKey]
  );

  const upHandler = useCallback(
    ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(false);
      }
    },
    [targetKey]
  );

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, [downHandler, upHandler]);

  if (isDown) {
    return keyPressed;
  } else {
    return !keyPressedPrevious && keyPressed;
  }
};

export default useKeyPress;
