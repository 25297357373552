// These are all the Vault related Actions

// Import API Calls here
import { Vault } from '@prompto-api';

// = ACTIONS =
export const vaultInfoAction = (stringParams, vaultObjectId, sessionToken) => {
  return (dispatch, getState) => {
    dispatch({ type: 'VAULT_INFO_REQUEST_PENDING' });
    return Vault.getInfo(vaultObjectId, sessionToken, stringParams)
      .then((result) => {
        const isAuthenticated = getState().store.AuthReducer?.authenticated;
        if (isAuthenticated) {
          dispatch({ type: 'VAULT_INFO_REQUEST_FULFILLED', payload: result });
        }
      })
      .catch((error) => {
        const isAuthenticated = getState().store.AuthReducer?.authenticated;
        if (isAuthenticated) {
          console.log(error);
          dispatch({
            type: `VAULT_INFO_REQUEST_REJECTED`,
            payload: { statusCode: error.response.status }
          });
        }
      });
  };
};

export const updateVaultInfoAction = (payload) => ({
  type: 'UPDATE_VAULT_INFO',
  payload
});

export const vaultUpdateAction = (callData, vaultObjectId, sessionToken) => ({
  type: 'VAULT_UPDATE_REQUEST',
  payload: Vault.update(vaultObjectId, callData, sessionToken)
});

export const vaultUpdateSubscriptionAction = (
  callData,
  vaultObjectId,
  sessionToken
) => ({
  type: 'VAULT_UPDATE_SUBSCRIPTION_REQUEST',
  payload: Vault.updateSubscription(vaultObjectId, callData, sessionToken)
});

export const addInvitationAction = (invitation) => ({
  type: 'ADD_INVITATION',
  payload: invitation
});

export const removeInvitationAction = (invitation) => ({
  type: 'REMOVE_INVITATION',
  payload: invitation
});

export const removeUserAction = (user) => ({
  type: 'REMOVE_USER',
  payload: user
});
