import React, { useState, useEffect } from 'react';
import { string, func, bool, number, shape } from 'prop-types';

// Components
import { DebounceInput } from 'react-debounce-input';
import { MdSearch, MdHighlightOff } from 'react-icons/md';
import { FaSearch } from 'react-icons/fa';

// Styling
import styled from 'styled-components';

const SearchIcon = styled.div`
    margin: auto 5px;
    min-width: 20px;
    width: 20px;
    min-height: 20px;
    height: 20px
    font-size: 0.875rem;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.gray400};
`;

const ClearButtonWrapper = styled.div`
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.primary900};
  margin-left: -40px;
`;

const ClearButton = styled(MdHighlightOff)`
  color: ${({ theme }) => theme.gray400};
  width: 20px;
  height: 20px;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.primary900} !important;
  }
`;

const StyledInput = styled.input`
  ${({ iconVisible, clearButtonEnabled }) => {
    let styles = '';
    if (iconVisible) {
      styles += `
        margin-left: -30px;
        padding-left: 35px;
        `;
    }
    if (clearButtonEnabled) {
      styles += `padding-right: 35px;`;
    }
    return styles;
  }}
`;

const StyledSearchField = styled.div`
  display: flex;
  flex-flow: row;
  position: relative;
  box-sizing: border-box;
  width: auto;
  height: 32px;
  background: ${({ theme }) => theme.whiteOff};
  text-transform: none;
  border: 1px solid transparent;
  border-radius: 3px;
  outline: none;
  transition: 0.2s border-color ease-in;
  &:hover,
  &:focus {
    border: 1px solid #eaedf0;
  }

  .searchFieldInput {
    flex-grow: 1;
    border: none;
    width: 100%;
    text-transform: none;
    color: ${({ theme }) => theme.mainTextColor1};
    font-size: 0.6875rem;
    background: transparent;
    padding-left: 10px;
    padding-right: 10px;
    outline: none;

    ${({ iconVisible, clearButtonEnabled }) => {
      let styles = '';
      if (iconVisible) {
        styles += `
          margin-left: -30px;
          padding-left: 35px;
          `;
      }
      if (clearButtonEnabled) {
        styles += `padding-right: 35px;`;
      }
      return styles;
    }}
  }

  ${({ styles }) => styles}
`;

const SearchField = ({
  className,
  fieldName,
  inputValue,
  placeholder,
  onFieldChange,
  onClear,
  hasDebounce,
  inputRef,
  disabled,
  iconVisible,
  clearButtonEnabled,
  childKey,
  dataTestId,
  debounceTimeout,
  minLength,
  styles
}) => {
  const [value, setValue] = useState(inputValue);

  useEffect(() => {
    setValue(inputValue);
  }, [inputValue]);

  const onValueChange = (event) => {
    if (value !== event.target.value) {
      setValue(event.target.value);
      onFieldChange(fieldName, event.target.value);
    }
  };

  const onClearInput = () => {
    setValue('');
    onClear();
    onFieldChange(fieldName, '');
  };

  // Icon
  let iconElement;
  if (iconVisible) {
    iconElement = (
      <SearchIcon>
        <FaSearch />
      </SearchIcon>
    );
  }

  // ClearButton
  let clearButtonElement;
  if (clearButtonEnabled) {
    clearButtonElement = (
      <ClearButtonWrapper>
        <ClearButton onClick={onClearInput} />
      </ClearButtonWrapper>
    );
  }

  // Inputfield
  const inputFieldProps = {
    id: fieldName,
    type: 'text',
    value,
    name: 'searchField',
    placeholder,
    onChange: onValueChange,
    inputRef,
    disabled,
    key: childKey,
    className: 'searchFieldInput'
  };

  let inputField = (
    <StyledInput
      data-testid={dataTestId}
      ref={inputRef}
      {...inputFieldProps}
      iconVisible={iconVisible}
      clearButtonEnabled={clearButtonEnabled}
    />
  );

  if (hasDebounce) {
    inputField = (
      <DebounceInput
        data-testid={dataTestId}
        {...inputFieldProps}
        debounceTimeout={debounceTimeout}
        minLength={minLength}
      />
    );
  }

  return (
    <StyledSearchField
      className={className}
      styles={styles}
      iconVisible={iconVisible}
      clearButtonEnabled={clearButtonEnabled}
    >
      {iconElement}
      {inputField}
      {clearButtonElement}
    </StyledSearchField>
  );
};

SearchField.propTypes = {
  /** Add a classname to further style this component externally */
  className: string,
  /** name of the field */
  fieldName: string.isRequired,
  /** input value of the input  */
  inputValue: string,
  /** text shown when input is empty */
  placeholder: string,
  /** function that triggers on input change */
  onFieldChange: func.isRequired,
  /** function that triggers when clear has happened*/
  onClear: func,
  /** enables/disabeles a 500ms debounce on the input */
  hasDebounce: bool,
  /** ref assigned to the inputfield */
  inputRef: shape({}),
  /** enables/disables the control */
  disabled: bool,
  /** shows/hides looking glass icon */
  iconVisible: bool,
  /** shows/hides clear button */
  clearButtonEnabled: bool,
  /** key to give the input field itself */
  childKey: string.isRequired,
  /** Data test id for automated testing */
  dataTestId: string,
  /** the debounce length if it is a debounced input */
  debounceTimeout: number,
  /** min length at which it starts sending debounced events*/
  minLength: number
};

SearchField.defaultProps = {
  className: '',
  inputValue: '',
  placeholder: 'Search',
  onClear: () => {},
  hasDebounce: true,
  inputRef: null,
  disabled: false,
  iconVisible: true,
  clearButtonEnabled: true,
  dataTestId: '',
  minLength: 0,
  debounceTimeout: 400
};

export default SearchField;
