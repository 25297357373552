import env from 'environment';
import qs from 'query-string';
import { pingPrompto, createCookie } from 'helpers/util';

/**
 * Launch a showCase from the portal
 * @param {Object} project
 * @param {Object} vault
 * @param {bool} useDeeplinks
 */
export const launchShowcase = (project, navItemId, vaultId, useDeeplinks) => {
  const params = {
    contentCollectionObjectId: project?.vmContentCollection?.objectId,
    navigationItemObjectId: navItemId,
    projectId: project?.objectId,
    vaultId
  };

  if (env().env !== 'production') {
    params.env = env().env;
  }

  // get track id
  let trackId = localStorage.getItem('ajs_user_id');
  if (trackId) {
    params.trackId = trackId.replace(/['"]+/g, '');
  }

  const link = `${env().showcaseUrl}?${qs.stringify(params)}`;

  if (useDeeplinks) {
    pingPrompto([{ showcaseParams: qs.stringify(params) }]);
  } else {
    window.open(link, '_blank');
  }
};

/**
 * Launch a showcase to the test signing flow
 * @param {Object} project
 * @param {Object} vault
 * @param {string} contractId
 * @param {string} name
 * @param {string} email
 * @param {bool} useDeeplinks
 */
export const launchTestSigningFlow = (
  project,
  vault,
  contractId,
  name,
  email,
  useDeeplinks
) => {
  // Set user name and email
  const testSigningUserNameCookieName = `prompto/${env().env}/testSignUserName`;
  const testSigningUserEmailCookieName = `prompto/${
    env().env
  }/testSignUserEmail`;

  createCookie(testSigningUserNameCookieName, name);
  createCookie(testSigningUserEmailCookieName, email);

  const params = {
    contentCollectionObjectId: project?.vmContentCollection?.objectId,
    projectId: project?.objectId,
    vaultId: vault?.objectId,
    contractId
  };

  if (env().env !== 'production') {
    params.env = env().env;
  }

  // get track id
  let trackId = localStorage.getItem('ajs_user_id');
  if (trackId) {
    params.trackId = trackId.replace(/['"]+/g, '');
  }

  const link = `${env().showcaseUrl}?${qs.stringify(params)}`;

  if (useDeeplinks) {
    pingPrompto([{ showcaseParams: qs.stringify(params) }]);
  } else {
    window.open(link, '_blank');
  }
};

/**
 * Launch the connect page
 * @param {*} useDeeplinks
 */
export const launchConnect = useDeeplinks => {
  if (useDeeplinks) {
    pingPrompto([{ openConnect: true }]);
    return;
  }
  const params = {};

  if (env().env !== 'production') {
    params.env = env().env;
  }

  const link = `${env().showcaseUrl}?${qs.stringify(params)}`;
  window.open(link, 'blank_');
};

/**
 * Launch the portfolio page
 * @param {*} useDeeplinks
 */
export const launchPortfolio = (useDeeplinks, vault) => {
  const params = {};

  if (env().env !== 'production') {
    params.env = env().env;
  }

  if (useDeeplinks) {
    params.openPortfolio = true;
    params.vaultId = vault?.objectId;

    pingPrompto([{ portfolioParams: qs.stringify(params) }]);
    return;
  }

  let subRoute = '/portfolio';
  const portfolioIsPublic = !!vault.settings?.portfolioIsPublic;
  if (portfolioIsPublic) {
    const encodedVaultId = window.btoa(vault.objectId);
    subRoute += `/${encodeURIComponent(encodedVaultId)}`;
  }

  const link = `${env().showcaseUrl}${subRoute}?${qs.stringify(params)}`;
  window.open(link, 'blank_');
};

/**
 * Launch the image tour showcase of a project
 * @param {*} project
 * @param {*} vault
 * @param {*} useDeeplinks
 */
export const launchImageTour = (navItemId, project, vault, useDeeplinks) => {
  const params = {
    navigationItemObjectId: navItemId,
    projectId: project?.objectId,
    vaultId: vault?.objectId
  };

  if (env().env !== 'production') {
    params.env = env().env;
  }

  if (useDeeplinks) {
    pingPrompto([{ portfolioParams: qs.stringify(params) }]);
    return;
  }

  const link = `${env().showcaseUrl}/?${qs.stringify(params)}`;
  window.open(link, 'blank_');
};

export const buildAddress = address =>
  `${address?.addressLine1 ?? ''} ${address?.city ?? ''} ${address?.country ??
    ''} ${address?.zipCode ?? ''}`;

export const transformAccentedLettersToNormalOnes = string =>
  string.normalize('NFD').replace(/\p{Diacritic}/gu, '');

export const searchByWordsCombination = (text, searchValue) => {
  if (!text || !searchValue) return false;

  const searchValueWords = searchValue.match(/\b(\w+)\b/g) ?? [];
  const textWords = text.match(/\b(\w+)\b/g) ?? [];

  // check if every word of a search value can be found in target text
  let allWordsFound = true;
  searchValueWords.forEach(word => {
    if (!textWords.some(x => x.includes(word))) {
      allWordsFound = false;
    }
  });

  return allWordsFound;
};

export const filterBySearchValue = (projects, searchValue) =>
  projects.filter(project => {
    const title = project.title?.toLowerCase();
    const description = project.description?.toLowerCase();
    const address = buildAddress(project.address)?.toLowerCase();

    return [title, description, address]
      .filter(Boolean)
      .map(x => transformAccentedLettersToNormalOnes(x))
      .some(x => searchByWordsCombination(x, searchValue.toLowerCase()));
  });

export const filterProjects = (projects, filter, userRole) => {
  const { offset, sortAsc, sortField, searchValue } = filter;

  // Filtering
  let filteredProjects = searchValue
    ? filterBySearchValue(projects, searchValue)
    : projects;

  if (userRole === 'redVaultAgent') {
    filteredProjects = filteredProjects.filter(
      project => project.state !== 'DRAFT'
    );
  }

  // Sorting
  const ordering = { DRAFT: 0, UPCOMING: 1, IN_PROGRESS: 2, COMPLETED: 3 };

  const sortOrder = sortAsc ? 1 : -1;
  const sortedProjects = filteredProjects.sort(
    (firstProject, secondProject) => {
      if (sortField === 'state') {
        const compareResult =
          ordering[firstProject.state] - ordering[secondProject.state] ||
          firstProject.createdAt - secondProject.createdAt;
        return compareResult * sortOrder;
      } else if (typeof firstProject[sortField] === 'string') {
        const compareResult = firstProject[sortField].localeCompare(
          secondProject[sortField]
        );
        return compareResult * sortOrder;
      } else {
        const compareResult =
          firstProject[sortField] - secondProject[sortField];
        return compareResult * sortOrder;
      }
    }
  );

  // Slicing
  const slicedProjects = sortedProjects.slice(0, offset);
  const endReached = sortedProjects.length < offset;

  return {
    projects: slicedProjects,
    endReached,
    totalProjects: sortedProjects.length
  };
};
