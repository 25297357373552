import React, { useState, useEffect } from 'react';
import { shape, func, array, bool, element } from 'prop-types';

//Components
import RoleSelectField from 'components/views/admin/pages/Users/RoleSelectField';
import Image from 'components/image/Image';

//Helpers
import {
  buildAssetURIWithOptions,
  capitalize,
  parseDateToDDMMYYYY
} from 'helpers/util';
import { motion } from 'framer-motion';
import localizer from 'localization/localizer';

// Styling
import styled from 'styled-components';
import { respondTo, tablet, desktop, desktopHd } from 'styles/mixins';

const Card = styled(motion.div)`
  width: 100%;
  padding: 20px;
  margin-bottom: 5px;
  border-radius: 3px;
  display: flex;
  flex-flow: row;
  background: ${props => props.theme.whitePure};
  border: 1px solid ${props => props.theme.gray300};

  ${respondTo([tablet, desktop, desktopHd])} {
    align-items: center;
    padding: 10px;
  }
`;

const ProfileImage = styled.div`
  display: none;
  ${respondTo([tablet, desktop, desktopHd])} {
    height: 70px;
    width: 70px;
    margin-right: 20px;
    border-radius: 3px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
  }
`;

const UserInfoWrapper = styled.div`
  width: 100%;
  position: relative;
  grid-area: form;
  display: grid;
  grid-template-areas:
    'fn fnv'
    'ln lnv'
    'e ev'
    'r rv'
    'la lav'
    's sv';
  grid-template-rows: repeat(auto-fill, 30px);
  grid-row-gap: 10px;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 20px;

  ${respondTo([tablet, desktop, desktopHd])} {
    grid-template-areas:
      'fn fnv r rv'
      'ln lnv la lav'
      'e ev s sv';
    grid-template-rows: auto auto auto;
    grid-row-gap: 7px;
    grid-template-columns: 1fr 2fr 1fr 2fr;
    grid-column-gap: 20px;
  }
`;

const BaseLabel = styled.div`
  display: flex;
  align-items: center;
  font-size: 11px;
  color: ${props => props.theme.primary200};
  overflow: hidden;

  p {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const Basevalue = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  color: ${props => props.theme.primary400};
  overflow: hidden;

  p {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const FirstNameLabel = styled(BaseLabel)`
  grid-area: fn;
`;

const FirstNameValue = styled(Basevalue)`
  grid-area: fnv;
`;

const LastNameLabel = styled(BaseLabel)`
  grid-area: ln;
`;

const LastNameValue = styled(Basevalue)`
  grid-area: lnv;
`;

const EmailLabel = styled(BaseLabel)`
  grid-area: e;
`;

const EmailValue = styled(Basevalue)`
  grid-area: ev;
`;
const LastActiveLabel = styled(BaseLabel)`
  grid-area: la;
`;
const LastActiveValue = styled(Basevalue)`
  grid-area: lav;
`;

const RoleLabel = styled(BaseLabel)`
  grid-area: r;
`;

const RoleValue = styled(Basevalue)`
  grid-area: rv;
  overflow: visible;
  ${respondTo([tablet, desktop, desktopHd])} {
    position: relative;
  }
`;

const RoleSelectFieldWrapper = styled(RoleSelectField)`
  width: 100%;
  height: 30px;
  position: relative;
  left: -15px;
`;

const StatusLabel = styled(BaseLabel)`
  grid-area: s;
`;
const StatusValue = styled(Basevalue)`
  grid-area: sv;
`;

const UpdateUserSuccess = styled(Basevalue)`
  grid-area: rv;
  display: flex;
  align-items: center;
  color: ${props => props.theme.successColor};
`;

const UpdateUserFailed = styled.div`
  grid-area: rv;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  overflow: visible;
  color: ${props => props.theme.errorColor};
`;

const ButtonWrapper = styled.div`
  height: 30px;
  display: flex;
  flex-shrink: 0;
  align-items: flex-start;
  justify-content: flex-end;

  ${respondTo([tablet, desktop, desktopHd])} {
    margin-left: 15px;
    min-width: 80px;
    justify-content: center;
    align-items: center;
  }
`;

const TryAgain = styled.span`
  grid-area: rv;
  color: ${props => props.theme.primary400};
  cursor: pointer;
`;

const UserCard = ({
  userData,
  isCurrentUser,
  roles,
  onUpdateUserRole,
  actionSection,
  readOnly,
  isRoleEditable
}) => {
  const profileImage = userData.metadata?.profilePictureUri;

  const [selectedRole, setSelectedRole] = useState(null);
  const [roleList, setRoleList] = useState([]);
  const [userUpdateSuccess, setUserUpdateSuccess] = useState(null);

  const [isUpdating, SetIsUpdating] = useState(false);

  useEffect(() => {
    let roleOptions = [];
    let currentRole = -1;

    roles.forEach((role, id) => {
      if (role === userData.role) {
        currentRole = id;
      }
      roleOptions.push({
        title: localizer[`${role}`],
        description: localizer[`${role}Description`],
        databaseEntry: role
      });
    });

    setRoleList(roleOptions);
    if (currentRole > -1 && currentRole < roleOptions.length) {
      setSelectedRole(roleOptions[`${currentRole}`]);
    } else {
      setSelectedRole(null);
    }
  }, [roles, userData]);

  useEffect(() => {
    if (userUpdateSuccess) {
      setTimeout(() => {
        setUserUpdateSuccess(null);
      }, 2000);
    }
  }, [userUpdateSuccess]);

  const onChangeUserRole = async role => {
    SetIsUpdating(true);
    setUserUpdateSuccess(null);
    const [error] = await onUpdateUserRole(userData, role);

    SetIsUpdating(false);
    if (error) {
      setUserUpdateSuccess(false);
      return;
    }

    setUserUpdateSuccess(true);
    setSelectedRole(role);
  };

  const isEditable = isRoleEditable && selectedRole !== null && !isCurrentUser;

  // Decide what to show in the place of the role info
  let roleSelection;
  if (isUpdating) {
    roleSelection = <RoleValue>{localizer.processing}</RoleValue>;
  } else if (userUpdateSuccess === true) {
    roleSelection = (
      <UpdateUserSuccess>{localizer.updateUserRoleSuccess}</UpdateUserSuccess>
    );
  } else if (userUpdateSuccess === false) {
    roleSelection = (
      <UpdateUserFailed>
        {localizer.updateUserRoleFailed}
        {', '}
        {localizer.please}{' '}
        <TryAgain onClick={() => onChangeUserRole(selectedRole)}>
          {localizer.tryAgain}
        </TryAgain>
      </UpdateUserFailed>
    );
  } else if (isEditable) {
    roleSelection = (
      <RoleValue>
        <RoleSelectFieldWrapper
          selectedRole={selectedRole}
          roleList={roleList}
          onSelectedRoleChange={onChangeUserRole}
          topOffset={36}
        />
      </RoleValue>
    );
  } else {
    roleSelection = <RoleValue>{localizer[userData?.role]}</RoleValue>;
  }

  return (
    <Card
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transitionEnd={{ display: 'none' }}
    >
      <ProfileImage>
        <Image
          imageUri={buildAssetURIWithOptions('q=100:mr=60,60', profileImage)}
          alt="profileImage"
          draggable={false}
        />
      </ProfileImage>
      <UserInfoWrapper>
        <FirstNameLabel>{capitalize(localizer.firstName)}</FirstNameLabel>
        <FirstNameValue>
          <p>{userData.firstName ? userData.firstName : '-'}</p>
        </FirstNameValue>

        <LastNameLabel>{capitalize(localizer.lastName)}</LastNameLabel>
        <LastNameValue>
          <p>{userData.lastName ? userData.lastName : '-'}</p>
        </LastNameValue>

        <EmailLabel>{capitalize(localizer.email)}</EmailLabel>
        <EmailValue>
          <p>{userData.email ? userData.email : '-'}</p>
        </EmailValue>

        <LastActiveLabel>{capitalize(localizer.lastActive)}</LastActiveLabel>
        <LastActiveValue>
          <p>
            {userData.lastModifiedAt
              ? parseDateToDDMMYYYY(userData.lastModifiedAt)
              : '-'}
          </p>
        </LastActiveValue>

        <RoleLabel>{capitalize(localizer.roleSelectLabel)}</RoleLabel>
        {roleSelection}
        <StatusLabel>{capitalize(localizer.status)}</StatusLabel>
        <StatusValue>
          <p>{userData.status ? userData.status : '-'}</p>
        </StatusValue>
      </UserInfoWrapper>
      {!readOnly && <ButtonWrapper>{actionSection}</ButtonWrapper>}
    </Card>
  );
};

UserCard.propTypes = {
  userData: shape({}).isRequired,
  isCurrentUser: bool.isRequired,
  roles: array,
  actionSection: element,
  onUpdateUserRole: func,
  readOnly: bool,
  isRoleEditable: bool
};

UserCard.defaultProps = {
  roles: [],
  onUpdateUserRole: () => {},
  onRemove: () => {},
  onResendInvitation: () => {},
  actionSection: null,
  readOnly: false,
  isRoleEditable: true
};

export default UserCard;
