import React from 'react';
// Components
import Album360Content from './types/album360/Album360Content';
import DocumentContent from './types/document/DocumentContent';
import ImageContent from './types/image/ImageContent';
import VideoContent from './types/video/VideoContent';
import ThreeSixtyImageContent from '../ThreeSixtyImageContent';

// Helpers
import fileCategories from 'config/SupportedFileCategories.json';
import { isMobileOnly } from 'react-device-detect';
import env from 'environment';

// Styles
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100%;
  position: ${({ isMobileOnly }) => (isMobileOnly ? 'absolute' : 'relative')};
  ${({ isMobileOnly }) => {
    if (isMobileOnly)
      return `
  top: 0;
  left: 0;
  z-index: 56;
  background-color: rgba(0, 0, 0, 1);
  border-radius: 12px;
  overflow: hidden;
`;
  }}

  .react-transform-component {
    width: 100% !important;
    height: 100% !important;
  }

  .react-transform-element {
    width: 100% !important;
    height: 100% !important;
  }
`;

const MobilePage = (props) => {
  const { data, videoFallbackThumbnail } = props;
  const { CustomNavigation, ...otherProps } = props;

  const sharedProps = {
    ...otherProps,
    key: data.objectId
  };

  // Content
  const createPageContent = () => {
    switch (data.contentItemType) {
      case fileCategories.image: {
        return <ImageContent {...sharedProps} />;
      }
      case fileCategories.video: {
        return (
          <VideoContent
            {...sharedProps}
            fallbackThumbnail={videoFallbackThumbnail}
          />
        );
      }
      case fileCategories.document:
      case fileCategories.floorplan: {
        return <DocumentContent {...sharedProps} />;
      }
      case fileCategories.album360: {
        return <Album360Content {...sharedProps} />;
      }
      case fileCategories.image360: {
        return (
          <ThreeSixtyImageContent
            {...sharedProps}
            contentUri={`${env().baseImageUrl}/o=true/${data?.contentUri}`}
            onLoad={() => {
              console.log('360 content loaded');
            }}
          />
        );
      }
      case fileCategories.turntable: {
        return null;
      }
      default: {
        return <ImageContent {...sharedProps} />;
      }
    }
  };

  const content = createPageContent();

  return (
    <Wrapper isMobileOnly={isMobileOnly} key={data.name}>
      {content}
    </Wrapper>
  );
};

export default MobilePage;
