import React from 'react';

// Helpers
import formatRelative from 'date-fns/formatRelative';
import localizer from 'localization/localizer';
import { capitalize } from 'helpers/util';
import { motion } from 'framer-motion';

// Styling
import styled from 'styled-components';

const Wrapper = styled(motion.div)`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  transform-origin: left;
`;

const Line = styled.div`
  flex-grow: 1;
  height: 1px;
  background: ${({ theme }) => theme.gray300};
`;

const Date = styled.span`
  font-size: 0.75rem;
  font-weight: normal;
  color: ${({ theme }) => theme.primary300};
  margin: 0 25px;
  b {
    margin-right: 8px;
  }
`;

const DaySeparator = ({ separator }) => {
  const relativeDate = formatRelative(separator.createdAt, new window.Date());
  const relativeDay = relativeDate.split(' ')?.[0];
  const isTodayOrYesterday = ['today', 'yesterday'].includes(relativeDay);

  const date = isTodayOrYesterday ? (
    <>
      <b>{isTodayOrYesterday && capitalize(localizer[relativeDay])}</b>(
      {separator.date})
    </>
  ) : (
    separator.date
  );

  return (
    <Wrapper
      initial={{ opacity: 0, scaleX: 0 }}
      animate={{ opacity: 1, scaleX: 1 }}
      exit={{ opacity: 0, scaleX: 0 }}
    >
      <Line />
      <Date>{date} </Date>
      <Line />
    </Wrapper>
  );
};

export default DaySeparator;
