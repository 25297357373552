export default {
  // Auth status
  authenticated: false,
  statusCode: 200,
  error: false,
  pending: false,
  loadingDictionaries: false,
  pendingRelogin: false,
  thisVaultPermissions: {},
  visitorSessionId: '',
  visitorId: '',
  startCreateVisitorProcess: true
};
