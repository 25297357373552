import { combineReducers } from 'redux';

import AppReducer from './appReducer/AppReducer';
import AuthReducer from './authReducer/AuthReducer';
import VaultReducers from './vaultListReducer/VaultReducers';
import PasswordReducers from './passwordReducer/PasswordReducers';
import CounterTestReducers from './counterTestReducer/CounterTestReducers';
import SubscriptionReducers from './subscriptionReducer/SubscriptionReducers';
import VaultListReducer from './vaultListReducer/VaultListReducer';
import NotificationsReducer from './notificationReducer/NotificationsReducer';
import ProjectListReducer from './projectListReducer/ProjectListReducer';
import SnappingLinesReducer from './SnappingLinesReducer';
import UpdatesReducer from './UpdatesReducer';
import DownloadPortalMediaReducer from './DownloadPortalMediaReducer';
import SlotsUsageReducer from './SlotsUsageReducer';
import PortalSettingsReducer from './PortalSettingsReducer';
import LeadsReducer from './leadsReducer/LeadsReducer';

const rootReducer = combineReducers({
  AppReducer,
  AuthReducer,
  VaultReducers,
  PasswordReducers,
  CounterTestReducers,
  SubscriptionReducers,
  VaultListReducer,
  NotificationsReducer,
  ProjectListReducer,
  SnappingLinesReducer,
  UpdatesReducer,
  DownloadPortalMediaReducer,
  SlotsUsageReducer,
  PortalSettingsReducer,
  LeadsReducer
});

export default rootReducer;
