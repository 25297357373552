import React, { Fragment, useState } from 'react';
import { func } from 'prop-types';
import Modal from 'components/modal/Modal';
import Button from 'components/button/Button';
import { support } from 'config/emails.json';
import WarningFeedback from 'components/feedback/WarningFeedback';
import PromptoLoader from 'components/loader/PromptoLoader';
import Divider from 'components/divider/Divider';

import localizer from 'localization/localizer';

import styled from 'styled-components';

const Title = styled.strong`
  color: ${({ theme }) => theme.primary900};
  font-size: 1.5rem;
`;

const Description = styled.p`
  color: ${({ theme }) => theme.primary400};
  font-size: 0.875rem;
  line-height: 1.5;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
`;

const LoaderWrapper = styled.div`
  margin: 10px auto;
`;

const ModalContent = styled.div`
  padding: 10px;
  padding-top: 20px;
  @include respond-to(tablet, desktop, desktop-hd) {
    min-width: 300px;
  }
`;

const ConfirmationModal = ({ onConfirmAction, onCancelledAction, ...rest }) => {
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(false);

  const onConfirm = async () => {
    setProcessing(true);

    const { error } = await onConfirmAction();
    if (error) {
      setError(error);
      setProcessing(false);
    }
  };

  const onCancelled = async () => {
    setProcessing(true);

    const { error } = await onCancelledAction();
    if (error) {
      setError(error);
      setProcessing(false);
    }
  };

  let buttonsStatus = 'default';
  if (processing) {
    buttonsStatus = 'disabled';
  }

  let content = (
    <Fragment>
      <Title>{localizer.areYouSure}</Title>
      <Divider marginSize={25} />
      <Description>
        {localizer.destructiveAction}
        <br />
        {localizer.wishToContinueQuestion}
      </Description>
      <Divider marginSize={25} />
      <ButtonWrapper>
        <Button
          onClickAction={onCancelled}
          status={buttonsStatus}
          label={localizer.cancel}
          dataTestId="cancelButton"
        />
        <Button
          onClickAction={onConfirm}
          type={'submit'}
          status={buttonsStatus}
          label={localizer.continue}
          dataTestId="continueButton"
        />
      </ButtonWrapper>
    </Fragment>
  );

  if (processing) {
    content = (
      <Fragment>
        <Title>Processing</Title>
        <LoaderWrapper>
          <PromptoLoader />
        </LoaderWrapper>
      </Fragment>
    );
  }
  if (error) {
    content = <WarningFeedback error={error} email={support} />;
  }

  return (
    <Modal
      center
      showCloseIcon={false}
      onClose={onCancelledAction}
      closeOnOverlayClick={!processing}
      closeOnEsc={!processing}
      styles={{
        modal: {
          maxWidth: 500,
          height: 'fit-content'
        }
      }}
      {...rest}
    >
      <ModalContent>{content}</ModalContent>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  onConfirmAction: func.isRequired,
  onCancelledAction: func
};
ConfirmationModal.defaultProps = {
  onCancelledAction: null
};

export default ConfirmationModal;
