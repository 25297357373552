import React from 'react';

import { motion } from 'framer-motion';
import localizer from 'localization/localizer';

import styled, { withTheme } from 'styled-components';

const Label = styled(motion.div)`
  position: absolute;
  z-index: 100;
  top: 10px;
  left: 10px;
  height: 30px;
  padding: 5px 10px;
  border-radius: 3px;
  background-color: #209cea;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.625rem;
  font-weight: 600;
  text-transform: uppercase;
  color: ${({ theme }) => theme.whitePure};
  background: ${({ color }) => color};
  user-select: none;
  ${({ styles }) => styles}
`;

const ProjectStateLabel = ({ state, theme, styles }) => {
  let color;
  let stateText;
  switch (state) {
    case 'COMPLETED':
      color = theme.projectState.completed;
      stateText = localizer.completed;
      break;
    case 'DRAFT':
      color = theme.projectState.draft;
      stateText = localizer.draft;
      break;
    case 'UPCOMING':
      color = theme.projectState.upcoming;
      stateText = localizer.upcoming;
      break;
    default:
      color = theme.projectState.ongoing;
      stateText = localizer.ongoing;
  }

  return (
    <Label
      initial={{ opacity: 0 }}
      animate={{ opacity: state ? 1 : 0 }}
      color={color}
      styles={styles}
    >
      {stateText}
    </Label>
  );
};

export default withTheme(ProjectStateLabel);
