import React from 'react';
// Helpers
import { motion, AnimatePresence } from 'framer-motion';

import LoginBackground from 'resources/backgrounds/loginBackground.jpg';
// Styling
import styled from 'styled-components';
import { styledRespondTo } from 'styles/mixins';

const Wrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background: ${({ theme }) => theme.whitePure};
  display: grid;
  grid-template-columns: 1fr;
  ${styledRespondTo.sm`
    grid-template-columns: minmax(540px, 1fr) 1.25fr;
  `}
`;

const ImageWrapper = styled.div`
  position: relative;
  display: none;
  ${styledRespondTo.sm`
    display: block;
  `}
`;

const Image = styled(motion.img)`
  position: absolute;
  transform: translateX(-20%);
  z-index: 1;
  top: 0;
  height: 100%;
  object-position: center;
  object-fit: cover;
  min-height: ${({ minHeight }) => (minHeight ? minHeight + 'px' : 'unset')};
`;

const RandomBgFormContainer = ({ children, minHeight }) => {
  return (
    <Wrapper>
      {children}
      <ImageWrapper>
        <AnimatePresence>
          <Image
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            src={LoginBackground}
            alt="background"
            minHeight={minHeight}
          />
        </AnimatePresence>
      </ImageWrapper>
    </Wrapper>
  );
};

export default RandomBgFormContainer;
