import { validActionCheck } from 'store/actionUtils';

const initialState = {
  projects: [],
  pending: false,
  error: false,
  done: false,
  lastUpdatedTimestamp: 0
};

const ProjectListReducer = (state = initialState, action) => {
  if (validActionCheck(action)) {
    switch (action.type) {
      case 'GET_PROJECT_LIST_PENDING':
        return {
          ...state,
          pending: true,
          done: false
        };
      case 'GET_PROJECT_LIST_SETTLED':
        return {
          ...state,
          pending: false,
          done: true
        };
      case 'GET_PROJECT_LIST_REJECTED':
        return {
          ...state,
          pending: false,
          error: action.payload,
          done: true
        };
      case 'GET_PROJECT_LIST_FULFILLED':
        return {
          ...state,
          pending: false,
          error: false,
          done: true,
          lastUpdatedTimestamp: Date.now(),
          ...action.payload
        };
      case 'DELETE_PROJECT':
        return {
          ...state,
          projects: state.projects.filter(
            (project) => project.objectId !== action.payload
          )
        };
      case 'CREATE_PROJECT':
        return {
          ...state,
          projects: [action.payload, ...state.projects]
        };
      case 'UPDATE_PROJECT':
        return {
          ...state,
          isSyncWithBackend: false
        };
      case 'RESET_PROJECT_LIST':
        return {
          ...initialState
        };
      default: {
        return state;
      }
    }
  }
  return state;
};

export default ProjectListReducer;
