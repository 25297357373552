import { buildAssetURIWithOptions } from 'helpers/util';
import React from 'react';

import styled from 'styled-components';

const ProfilePicture = styled.img`
  position: relative;
  top: 5px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
`;

const findVariable = (variable, body, i) => {
  const variableFound = body[variable];

  if (variableFound) {
    switch (variable) {
      default:
        return <b key={`card-bold-${i}`}>{variableFound}</b>;
      case 'userProfilePictureUri':
        return (
          <ProfilePicture
            key={`card-profile-${i}`}
            src={buildAssetURIWithOptions(`mr=50,50`, variableFound)}
          />
        );
    }
  }

  return '';
};

export const buildBody = (body, appLang) => {
  let result = [];
  let currentText = '';
  // fallback to english option
  let bodyToBuild = body[appLang] ?? body.en;

  // Iterate over all the characters
  for (let i = 0; i < bodyToBuild.length; i++) {
    // Check if we hit a variable
    if (bodyToBuild[i] === '{') {
      // let's first insert the text we covered until now
      if (currentText !== '') {
        result.push(<span key={`card-text-${i}`}>{currentText}</span>);
        currentText = '';
      }

      // Search for the end of the variable and get the content
      let findEnd = bodyToBuild.indexOf('}', i);
      const variable = bodyToBuild.substring(i + 1, findEnd);
      const foundData = findVariable(variable, body, i);

      if (foundData) {
        result.push(foundData);
      }

      // Skip this variable now
      i += variable.length + 1;
    } else {
      currentText += bodyToBuild[i];
    }
  }

  if (currentText !== '') {
    result.push(<span key={`card-text`}>{currentText}</span>);
  }

  return result;
};
