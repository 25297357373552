// Base
import React, { useState, useEffect, useCallback, memo } from 'react';
import { string, element, bool } from 'prop-types';

import { MdPanorama } from 'react-icons/md';
import { IconContext } from 'react-icons';
import styled from 'styled-components';

const ComponentWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ImagePreview = styled.div`
  position: relative;
  text-align: center;
  min-height: 20px;
  min-width: 20px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background-color: ${({ showBackground }) =>
    showBackground ? '#e1e8ef' : 'transparent'};
  color: ${({ theme }) => theme.mainTextColor2};
`;

const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const ImageCaption = styled.div`
  height: auto;
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
`;

const Img = styled.img`
  object-fit: cover;
  width: 100%;
  max-height: 100%;
  border-radius: 3px;
`;

const Image = memo(
  ({
    imageUri,
    placeholderComponent,
    OverlayComponent,
    draggable,
    Caption,
    dataTestId,
    alt,
    styles
  }) => {
    const [imageComponent, setImageComponent] = useState(<MdPanorama />);

    const onErrorImageLoad = useCallback(() => {
      if (placeholderComponent) {
        setImageComponent(placeholderComponent);
      } else {
        setImageComponent(<MdPanorama />);
      }
    }, [placeholderComponent]);

    useEffect(() => {
      if (imageUri) {
        setImageComponent(
          <ImageWrapper>
            <Img
              key="image"
              src={imageUri}
              alt={alt ?? 'imageComponent'}
              onError={onErrorImageLoad}
              draggable={draggable}
            />
          </ImageWrapper>
        );
      }
    }, [imageUri, onErrorImageLoad, draggable]);

    return (
      <IconContext.Provider value={{ style: { verticalAlign: 'middle' } }}>
        <ComponentWrapper data-testid={dataTestId} styles={styles}>
          <ImagePreview showBackground={!imageUri}>
            {imageComponent}
            {OverlayComponent && (
              <ImageOverlay>{OverlayComponent}</ImageOverlay>
            )}
          </ImagePreview>
          {Caption && <ImageCaption>{Caption}</ImageCaption>}
        </ComponentWrapper>
      </IconContext.Provider>
    );
  }
);

Image.propTypes = {
  /** Image URI for the image (optional) */
  imageUri: string,
  /** Component to render when there is no imageURI (optional) */
  placeholderComponent: element,
  /** Component to render over the image (optional) */
  OverlayComponent: element,
  /** Choose an additional className to add */
  /** Can the image be dragged */
  draggable: bool,
  /** Insert a caption as a react element */
  Caption: element,
  /** Data test id for automated testing */
  dataTestId: string,
  /** Alt text to set on the image */
  alt: string
};

Image.defaultProps = {
  imageUri: null,
  placeholderComponent: null,
  OverlayComponent: null,
  draggable: true,
  Caption: null,
  dataTestId: null
};

export default Image;
