import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Components
import Button from 'components/button/Button';
import InputField from 'components/inputField/InputField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RoleSelectField from 'components/views/admin/pages/Users/RoleSelectField';

// Helpers
import emailValidator from 'email-validator';
import { capitalize } from 'helpers/util';
import localizer from 'localization/localizer';
import { usePrevious } from '@prompto-helpers';

// Styling
import styled from 'styled-components';

const EmailField = styled(InputField)`
  width: 250px;
  margin-bottom: 5px;
`;

const EmailAndRoleAccess = styled.div`
  position: relative;
  display: grid;
  grid-template-areas:
    'e r rb'
    'em em em';
`;

const EmailWrapper = styled.div`
  grid-area: e;
  margin-right: 10px;
`;

const RoleWrapper = styled.div`
  grid-area: r;
`;

const RoleLabel = styled.p`
  display: block;
  position: relative;
  line-height: normal;
  text-align: left;
  color: ${(props) => props.theme.primary100};
  font-size: 12px;
  display: flex;
  flex-direction: column;
  padding-bottom: 5px;
`;

const ErrorMessage = styled.div`
  grid-area: em;
  display: flex;
  flex-flow: row;
  padding-bottom: 10px;
  max-width: 400px;
  justify-content: center;
  align-items: center;
`;

const ErrorText = styled.div`
  font-size: 11px;
  line-height: 1.45;
  color: ${(props) => props.theme.primary200};
  flex-grow: 1;
  padding-left: 10px;
`;

const ErrorIcon = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.warningLight};
`;

const RoleSelectFieldWrapper = styled(RoleSelectField)`
  height: 40px;
`;

const RemoveButton = styled(Button)`
  grid-area: rb;
  margin-top: auto;
  margin-bottom: auto;
  border: none !important;
  color: ${(props) => props.theme.errorLight} !important;
`;

/**
 * Card requesting from the user the information a user needs to be invited to a vault
 * @param {{usersAlreadyInVault: {email: String}[], usersAlreadyInvitedToVault: {email: String}[]}} props
 */
const InviteEmailAndRoleCard = ({
  id,
  roleOptions,
  isFirstCard,
  isOnlyCard,
  onRemove,
  onUpdate,
  usersAlreadyInVault,
  usersAlreadyInvitedToVault,
  selectedRole,
  setSelectedRole
}) => {
  const emailInput = useRef(null);
  const [email, setEmail] = useState('');
  const [emailInvalidMessage, setEmailInvalidMessage] = useState(null);
  const [isEmailValid, setIsEmailValid] = useState(false);

  const [validationError, setIsEmailValidationError] = useState(0);

  const [data, setData] = useState({});
  const previousData = usePrevious(data);

  useEffect(() => {
    setSelectedRole(id, roleOptions[0]);
  }, [roleOptions, setSelectedRole, id]);

  useEffect(() => {
    let newData = { id, email, selectedRole, isEmailValid };

    if (
      previousData === undefined ||
      newData.email !== previousData.email ||
      newData.selectedRole !== previousData.selectedRole ||
      newData.isEmailValid !== previousData.isEmailValid
    ) {
      setData(newData);
      onUpdate(newData);
    }
  }, [id, email, selectedRole, isEmailValid, onUpdate, previousData]);

  const validate = async (fieldName, value) => {
    const emailOfUserToInvite = value;
    setEmail(emailOfUserToInvite);

    // Validate that it is the syntax of an email
    if (!emailValidator.validate(emailOfUserToInvite)) {
      setIsEmailValid(false);
      setIsEmailValidationError(300);
      setEmailInvalidMessage(
        <ErrorMessage>
          <ErrorIcon icon={['far', 'exclamation-triangle']} size="sm" />
          <ErrorText>
            {capitalize(localizer.invalid)} {localizer.emailAddress}
          </ErrorText>
        </ErrorMessage>
      );

      return;
    }

    // Validate that the user is not already in the vault or invited to the vault
    const isUserAlreadyInVault = usersAlreadyInVault.find(
      (userAlreadyInVault) => {
        return userAlreadyInVault.email === emailOfUserToInvite;
      }
    );

    if (isUserAlreadyInVault) {
      setIsEmailValid(false);
      setIsEmailValidationError(300);
      setEmailInvalidMessage(
        <ErrorMessage>
          <ErrorIcon icon={['far', 'exclamation-triangle']} size="sm" />
          <ErrorText>
            {capitalize(localizer.errorInviteUserAlreadyInVault)}{' '}
          </ErrorText>
        </ErrorMessage>
      );
      return;
    }

    // Validate that the user is not already invited to the vault
    const isUserAlreadyInvitedToVault = usersAlreadyInvitedToVault.find(
      (userInvitedAlready) => {
        return userInvitedAlready.email === emailOfUserToInvite;
      }
    );

    if (isUserAlreadyInvitedToVault) {
      setIsEmailValid(false);
      setIsEmailValidationError(400);
      setEmailInvalidMessage(
        <ErrorMessage>
          <ErrorIcon icon={['far', 'exclamation-triangle']} size="sm" />
          <ErrorText>
            {capitalize(localizer.errorInviteUserAlreadyInvitedToVault)}{' '}
          </ErrorText>
        </ErrorMessage>
      );
      return;
    }

    // The email is valid!
    setIsEmailValid(true);
    setIsEmailValidationError(200);
    setEmailInvalidMessage(null);
  };

  return (
    <EmailAndRoleAccess>
      <>
        <EmailWrapper>
          <EmailField
            ref={emailInput}
            onFieldChange={validate}
            handleSubmit={() => {}}
            fieldName={'inviteField'}
            value={email}
            label={isFirstCard ? localizer.email : ''}
            placeholder={localizer.enterEmailToInvite}
            statusCode={validationError}
            hasDebounce={true}
            dataTestId="emailTextField"
          />
        </EmailWrapper>
        <RoleWrapper>
          <RoleLabel>{isFirstCard ? localizer.roleSelectLabel : ''}</RoleLabel>
          <RoleSelectFieldWrapper
            selectedRole={selectedRole}
            roleList={roleOptions}
            onSelectedRoleChange={(role) => setSelectedRole(id, role)}
            topOffset={68}
          />
        </RoleWrapper>
        {!isOnlyCard && (
          <RemoveButton
            status="custom"
            spanStyle={{
              margin: '3px 0px 0px 0px'
            }}
            IconComponent={() => (
              <FontAwesomeIcon icon={['far', 'times']} size="1x" />
            )}
            onClickAction={() => {
              onRemove(id);
            }}
          />
        )}
      </>
      {emailInvalidMessage}
    </EmailAndRoleAccess>
  );
};

InviteEmailAndRoleCard.propType = {
  /** User already in the vault (or already invited to the vault) that this invitation is for */
  usersAlreadyInVault: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['User']).isRequired,
      email: PropTypes.string.isRequired
    })
  ),
  usersAlreadyInvitedToVault: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['Invitation']).isRequired,
      email: PropTypes.string.isRequired
    }).isRequired
  ).isRequired
};

export default InviteEmailAndRoleCard;
