import React, { useReducer, createContext, useContext } from 'react';
export const ProjectStoreContext = createContext();

const initialState = {
  project: null,
  lastFetchedProjects: [],
  loadedContentCollection: null,
  loadedNavigationCollection: null,
  units: [],
  usps: [],
  showcaseConfig: null,
  uploadMediaType: null,
  videoItemThumbnails: {},
  exploreNavigationCollection: null,
  marketplaceSettings: null
};

/**
 * Store to contain the last opened project, opening a different project will clear the store and refill
 * @param {Object} props Contains the children of this component
 */
const ProjectStore = (props) => {
  /**
   * The project state will contain the following objects
   * @param {Object} project The main project object containing the basic information of the project that has last been opened, title, address etc
   * You might find an unloaded unit list or content collection in this project but we do no use these, these will be stored separately (see next items)
   * @param {Object} loadedContentCollection The loaded content collection of the project, this is the content seen in the media tab and used in de showcase tab
   * @param {Object} loadedNavigationCollection The loaded navigation collection of the project, this is the image tour used in the showcase tab
   */
  const [ProjectState, ProjectStateDispatch] = useReducer((state, action) => {
    switch (action?.type) {
      case 'reset':
        return {
          project: null,
          loadedContentCollection: null,
          loadedNavigationCollection: null
        };
      case 'setProjectData':
        return {
          ...initialState,
          ...action.payload
        };
      case 'setLastFetchedProjects':
        return {
          ...state,
          lastFetchedProjects: action.payload
        };
      case 'setLoadedContentCollection':
        return {
          ...state,
          loadedContentCollection: action.payload
        };
      case 'setLoadedNavigationCollection':
        return {
          ...state,
          loadedNavigationCollection: action.payload
        };
      case 'setExploreNavigationCollection':
        return {
          ...state,
          exploreNavigationCollection: action.payload
        };
      case 'setUnits':
        return {
          ...state,
          units: action.payload
        };
      case 'setUSPs':
        return {
          ...state,
          usps: action.payload
        };
      case 'setShowcaseConfig':
        return {
          ...state,
          showcaseConfig: action.payload
        };
      case 'setMediaDefaultSortingOrder':
        return {
          ...state,
          project: {
            ...state.project,
            defaultContentItemSorting: action.payload
          }
        };
      case 'setUploadMediaType':
        return {
          ...state,
          uploadMediaType: action.payload
        };
      case 'setVideoItemThumbnail':
        return {
          ...state,
          videoItemThumbnails: {
            ...state.videoItemThumbnails,
            ...action.payload
          }
        };
      case 'setMarketplaceSettings':
        return {
          ...state,
          marketplaceSettings: action.payload
        };
      default:
        return state;
    }
  }, initialState);

  return (
    <ProjectStoreContext.Provider
      value={{ ProjectState, ProjectStateDispatch }}
    >
      {props.children}
    </ProjectStoreContext.Provider>
  );
};

export const useProjectState = () => useContext(ProjectStoreContext);

export default ProjectStore;
