import React from 'react';
import { string, array } from 'prop-types';

// Styling
import styled, { withTheme } from 'styled-components';
import { styledRespondTo } from 'styles/mixins';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row wrap;
  border-radius: 3px;
  border: solid 1px ${({ theme }) => theme.gray300};
  background-color: ${({ theme }) => theme.whitePure};
  margin-bottom: 10px;
  padding: 5px;
  ${({ styles }) => styles}
`;

const RightFields = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;
  font-size: 1rem;
  width: 100%;
  ${({ styles }) => styles}

  ${styledRespondTo.md`
    width: auto;
    justify-content: flex-end;
`}
`;

const LeftFields = styled(RightFields)`
  flex-grow: 1;
  ${({ styles }) => styles}

  ${styledRespondTo.md`
        width: auto;
        justify-content: flex-start;
    `}
`;

const Toolbar = ({
  actionFieldsLeft,
  actionFieldsRight,
  dataTestId,
  styles
}) => {
  return (
    <Wrapper data-testid={dataTestId} styles={styles?.wrapper}>
      <LeftFields key="left" styles={styles?.rightFields}>
        {actionFieldsLeft}
      </LeftFields>

      <RightFields key="right" styles={styles?.rightFields}>
        {actionFieldsRight}
      </RightFields>
    </Wrapper>
  );
};

Toolbar.propTypes = {
  /** Actionfields starting from the left */
  actionFieldsLeft: array,
  /** Actionfields starting from the right */
  actionFieldsRight: array,
  /** Data test id for automated testing */
  dataTestId: string
};

Toolbar.defaultProps = {
  actionFieldsLeft: null,
  actionFieldsRight: null,
  dataTestId: ''
};

export default withTheme(Toolbar);
