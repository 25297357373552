import { validActionCheck } from 'store/actionUtils';

const initialState = {};

const PortalSettingsReducer = (state = initialState, action) => {
  if (validActionCheck(action)) {
    switch (action.type) {
      case 'SET_PORTAL_SETTINGS':
        return {
          ...state,
          ...action.payload
        };
      case 'AUTH_RESET':
      case 'AUTH_LOGOUT_REQUEST_PENDING':
      case 'VAULT_INFO_REQUEST_REJECTED':
        return initialState;

      default: {
        return state;
      }
    }
  }

  return state;
};

export default PortalSettingsReducer;
