import { validActionCheck } from 'store/actionUtils';
import initialState from 'store/reducers/notificationReducer/defaultNotificationsState';

const NotificationsReducer = (state = initialState, action) => {
  if (validActionCheck(action)) {
    switch (action.type) {
      case 'TOGGLE_NOTIFICATIONS_FEED':
        return {
          ...state,
          isActivityFeedOpened: action.payload
        };
      case 'SET_LAST_TIME_OPENED':
        return {
          ...state,
          lastTimeOpened: action.payload
        };
      case 'SET_NOTIFICATIONS_COUNT':
        return {
          ...state,
          newNotificationsCount: action.payload,
          endReached: action.payload > 0 ? false : state.endReached,
          notifications:
            action.payload > 0 && state.newNotificationsCount !== action.payload
              ? []
              : state.notifications
        };
      case 'SET_NOTIFICATIONS_COUNT_LOADING_STATE':
        return {
          ...state,
          notificationsCountLoadingState: action.payload
        };
      case 'FETCH_NOTIFICATIONS_PENDING':
        return {
          ...state,
          loading: true
        };
      case 'FETCH_NOTIFICATIONS_FAIL':
        return {
          ...state,
          loading: false,
          error: true
        };
      case 'FETCH_NOTIFICATIONS_SUCCESS':
        const updNotifications =
          action.payload.type === 'new'
            ? [...action.payload.notifications, ...state.notifications]
            : [...state.notifications, ...action.payload.notifications];
        const uniqueIDs = [];
        const uniqueNotifications = [];
        updNotifications.forEach((notification) => {
          if (!uniqueIDs.includes(notification.objectId)) {
            uniqueIDs.push(notification.objectId);
            uniqueNotifications.push(notification);
          }
        });
        return {
          ...state,
          notifications: uniqueNotifications,
          endReached: action.payload.endReached,
          loading: false,
          error: false,
          totalCount: action.payload.totalCount
        };
      case 'TOGGLE_SHOW_UNREAD_ONLY':
        return {
          ...state,
          showUnreadOnly: action.payload
        };
      case 'MARK_NOTIFICATION_AS_READ':
        const newNotifications = state.notifications.map((notification) => {
          if (notification.objectId === action.payload.notification.objectId) {
            return {
              ...notification,
              notificationStatus: 'read'
            };
          } else {
            return notification;
          }
        });
        return {
          ...state,
          notifications: newNotifications
        };
      case 'MARK_ALL_NOTIFICATIONS_AS_READ':
        return {
          ...state,
          notifications: state.notifications.map((x) => {
            return { ...x, notificationStatus: 'read' };
          })
        };
      case 'SET_DEFAULT_PROJECT_FILTER':
        return {
          ...state,
          selectedProjects: [action.payload]
        };
      case 'SET_SELECTED_PROJECTS':
        const nextSelectedPropjects = state.selectedProjects.includes(
          action.payload
        )
          ? [...state.selectedProjects.filter((id) => id !== action.payload)]
          : [...state.selectedProjects, action.payload];
        return {
          ...state,
          selectedProjects: nextSelectedPropjects
        };
      case 'SHOW_ALL_NOTIFICATIONS':
        return {
          ...state,
          selectedProjects: []
        };

      case 'SET_LAST_TIME_OPENED_UPDATES':
        return {
          ...state,
          lastTimeOpenedUpdatesTimestamp: action.payload
        };

      case 'AUTH_LOGOUT_REQUEST_PENDING': {
        return {
          ...initialState
        };
      }
      case 'RESET_NOTIFICATIONS':
        return initialState;
      default: {
        return state;
      }
    }
  }
  return state;
};

export default NotificationsReducer;
