import React from 'react';
import { bool, func, object, number, node, string } from 'prop-types';
import ReactModal from 'react-responsive-modal';

const Modal = (props) => <ReactModal {...props} />;
const closeIconSvg = (
  <path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z" />
);

Modal.propTypes = {
  /**
   * Is the modal closable when user press esc key.
   */
  closeOnEsc: bool,
  /**
   * Is the modal closable when user click on overlay.
   */
  closeOnOverlayClick: bool,
  /**
   * Callback fired when the Modal is open and the animation is finished.
   */
  onEntered: func,
  /**
   * Callback fired when the Modal has exited and the animation is finished.
   */
  onExited: func,
  /**
   * Callback fired when the Modal is requested to be closed by a click on the overlay or when user press esc key.
   */
  onClose: func.isRequired,
  /**
   * Callback fired when the escape key is pressed.
   */
  onEscKeyDown: func,
  /**
   * Callback fired when the overlay is clicked.
   */
  onOverlayClick: func,
  /**
   * Control if the modal is open or not.
   */
  open: bool.isRequired,
  /**
   * An object containing classNames to style the modal, can have properties 'overlay' (classname for overlay div), 'modal' (classname for modal content div), 'closeButton' (classname for the button that contain the close icon), 'closeIcon' (classname for close icon svg). You can customize the transition with 'transitionEnter', 'transitionEnterActive', 'transitionExit', 'transitionExitActive'
   */
  classNames: object,
  /**
   * An object containing the styles objects to style the modal, can have properties 'overlay', 'modal', 'closeButton', 'closeIcon'.
   */
  styles: object,
  /**
   * The content of the modal.
   */
  children: node,
  /**
   * Is the dialog centered (**when you don't have a lot of content**).
   */
  center: bool,
  /**
   * Show the close icon.
   */
  showCloseIcon: bool,
  /**
   * Close icon size.
   */
  closeIconSize: number,
  /**
   * A valid svg path to show as icon.
   */
  closeIconSvgPath: node,
  /**
   * Animation duration in milliseconds.
   */
  animationDuration: number,
  /**
   * Enable to allow focustrap to take control.
   */
  focusTrapped: bool,
  /**
   * You can specify a container prop which should be of type `Element`. The portal will be rendered inside that element. The default behavior will create a div node and render it at the at the end of document.body.
   */
  container: object, // eslint-disable-line

  /** Data test id for automated testing */
  dataTestId: string
};

Modal.defaultProps = {
  closeOnEsc: true,
  closeOnOverlayClick: true,
  onEntered: null,
  onExited: null,
  onEscKeyDown: null,
  onOverlayClick: null,
  showCloseIcon: true,
  closeIconSize: 20,
  closeIconSvgPath: closeIconSvg,
  styles: {
    modal: {
      borderRadius: 3,
      padding: 20,
      boxShadow: `0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)`
    }
  },
  children: null,
  center: false,
  animationDuration: 500,
  focusTrapped: false,
  dataTestId: null
};

export default Modal;
