import React, { useState } from 'react';
import { shape } from 'prop-types';

// Component
import ProfileImage from 'components/profileImage/ProfileImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Styling
import styled from 'styled-components';
import { motion } from 'framer-motion';

const VaultCard = styled(motion.div)`
  display: flex;
  flex-flow: row;
  align-items: center;
  width: 100%;
  background: ${(props) => props.theme.whitePure};
  border-radius: 3px;
  border: 0.5px solid;
  border-color: ${(props) => props.theme.gray300};
  padding: 10px 15px;
  margin-bottom: 8px;
  box-shadow: ${(props) =>
    props.isHovered ? '0px 0px 5px 0px rgba(0, 0, 0, 0.2)' : 'none'};
  cursor: pointer;
`;

const VaultName = styled.div`
  font-size: 0.75rem;
  color: ${(props) => props.theme.showcaseBlack};
  user-select: none;
  margin-left: 15px;
  margin-right: 25px;
  max-width: 60%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const SwitchIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
  color: ${(props) => props.theme.primary400};
  margin-left: auto;
`;

const VaultLogo = styled(ProfileImage)`
  width: 35px;
  height: 35px;
  max-width: 35px;
  max-height: 35px;
  border-radius: 50%;
  margin: 0;
`;

const VaultMenuCard = ({ vault, onChangeVault }) => {
  const [isCardHovered, setIsCardHovered] = useState(false);

  return (
    <VaultCard
      isHovered={isCardHovered}
      onHoverStart={() => {
        setIsCardHovered(true);
      }}
      onHoverEnd={() => {
        setIsCardHovered(false);
      }}
      data-testid="vaultCard"
      onClick={() => {
        onChangeVault(vault.objectId);
      }}
    >
      <VaultLogo profileImageUri={vault?.logoUri} />
      <VaultName>{vault?.name}</VaultName>
      <SwitchIcon icon={['far', 'long-arrow-right']} size="1x" />
    </VaultCard>
  );
};

VaultMenuCard.propTypes = {
  vault: shape({}).isRequired
};

VaultMenuCard.defaultProps = {};

export default VaultMenuCard;
