import React, { useMemo } from 'react';

// Components
import Checkbox from 'components/checkbox/Checkbox';

// Styling
import styled, { withTheme } from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  ${({ styles }) => styles}
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ checked, theme }) =>
    checked ? theme.primary300 : theme.primary100};
`;

const Label = styled.label`
  font-size: 0.875rem;
`;

const Description = styled.span`
  font-size: 0.6875rem;
`;

const StyledCheckbox = styled(Checkbox)`
  padding: 1px;
`;

const CustomCheckbox = ({
  label,
  description,
  isChecked,
  onChecked,
  onUnchecked,
  disabled,
  theme,
  updateIsChecked,
  wrapperStyles
}) => {
  const checkBoxColorObject = useMemo(
    () => ({
      labelColor: theme.checkboxLabelColor,
      backgroundColor: theme.checkboxBgColor,
      borderColor: theme.checkboxBorderColor,
      uncheckedBorderColor: theme.checkboxUncheckedBorderColor,
      tickColor: theme.whitePure
    }),
    [theme]
  );

  return (
    <Wrapper styles={wrapperStyles}>
      <LabelWrapper checked={isChecked}>
        <Label>{label}</Label>
        {description && <Description>{description}</Description>}
      </LabelWrapper>
      <StyledCheckbox
        size={3}
        tickSize={2}
        isChecked={isChecked}
        onChecked={onChecked}
        onUnchecked={onUnchecked}
        colorObject={checkBoxColorObject}
        disabled={disabled}
        updateIsChecked={updateIsChecked}
      />
    </Wrapper>
  );
};

export default withTheme(CustomCheckbox);
