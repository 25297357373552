import React, { useState, useEffect, memo, useCallback } from 'react';
import { func, shape, string } from 'prop-types';
import ReactResizeDetector from 'react-resize-detector';

// Redux
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

// Store actions
import { userLogoutAction } from 'store/reducers/authReducer/AuthActions';

// Custom SVGS
import Custom360Icon from 'resources/icons/360Custom';

// Components
import SidebarIconButton from 'helpers/PageSidebar/SidebarIconButton';
import Divider from 'components/divider/Divider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UpsellIncentiveModal from 'components/modals/UpsellIncentiveModal';
import UpsellNotification from './UpsellNotification';
import Tippy from '@tippyjs/react';
import { AnimatePresence, motion } from 'framer-motion';
import VaultMenu from './vaultMenu/VaultMenu';
import FullscreenIframePreview from 'components/other/FullscreenIframePreview';
import UpdateShieldHOC from 'components/other/UpdateShieldHOC';
import ActivityFeed from 'components/notifications/ActivityFeed';
// import { messages } from './updatesPanel/updateMessages.json';
import VoteButton from './components/VoteButton';
// import UpdatesPanel from './updatesPanel/UpdatesPanel';

// Helpers
import { Tracking } from '@prompto-api';
import {
  debounce,
  getRenewTrackingCookie,
  pingPrompto,
  capitalize
} from 'helpers/util';
import qs from 'query-string';
import { launchPortfolio } from 'components/views/project/projectHelpers';
import { respondTo, desktop, desktopHd, breakPointsMax } from 'styles/mixins';
import localizer from 'localization/localizer';
import topRoutes from 'config/topRoutes.json';
import Cookies from 'universal-cookie';
import env from 'environment';
import uuid from 'uuid';
import { useProjectState } from 'store/ProjectStore';
import { getAllNotifications } from 'store/reducers/notificationReducer/NotificationsActions';
import { sales } from 'config/emails.json';

// Logo
import PromptoFullLogoWhite from 'resources/PromptoNewLogoWhite.png';
import PromptoSymbolWhite from 'resources/PromptoNewSymbolWhite.png';

// styling
import styled, { withTheme } from 'styled-components';

const WRAPPER_WIDTH = {
  WIDE: 220,
  COLLAPSED: 70
};

// Variants
const wrapperVariants = {
  wide: {
    width: `${WRAPPER_WIDTH.WIDE}px`
  },
  collapsed: {
    width: `${WRAPPER_WIDTH.COLLAPSED}px`
  },
  mobile: { width: '100vw' }
};

// Styles
const Wrapper = styled(motion.div)`
  flex-shrink: 0;
  position: relative;
`;

const LogoWrapper = styled.img`
  display: inline-block;
  width: 50px;
  cursor: pointer;
`;

const LogoFullWrapper = styled.img`
  display: inline-block;
  width: 140px;
  cursor: pointer;
`;

const BackButtonWrapper = styled.div`
  display: flex;
  color: ${(props) => props.theme.primary100};
  align-items: center;
  margin-left: ${(props) => (props.isSidebarWide ? -40 : 0)};
  cursor: pointer;
`;

const BackButtonText = styled.p`
  margin-left: 20px;
`;

const DividerWrapper = styled(Divider)`
  height: 36px;
  margin-right: 5px;

  ${respondTo([desktop, desktopHd])} {
    height: auto;
    margin-right: 0px;
  }
`;

const FullDivider = styled.div`
  display: none;
  ${respondTo([desktop, desktopHd])} {
    display: block;
    width: 100;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  }
`;

const SidebarWrapper = styled.div`
  top: 0;
  left: 0;
  background-color: ${(props) => props.theme.primary900};
  z-index: 999;
  color: ${(props) => props.theme.whitePure};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  fill: ${(props) => props.theme.whitePure};
  overflow: hidden;
  flex-grow: 1;
  flex: 0 0 220px;

  ${respondTo([desktop, desktopHd])} {
    flex-direction: column;
    height: 100%;
  }
`;

const SidebarPart = styled(motion.div)`
  min-height: 80px;
  max-width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  background: ${(props) => props.theme.primary900};

  ${respondTo([desktop, desktopHd])} {
    width: 100%;
  }
`;

const UpperSidebar = styled(SidebarPart)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

const MiddleSidebar = styled(SidebarPart)`
  width: 100%;
  flex-grow: 1;
  justify-content: space-between;

  ${respondTo([desktop, desktopHd])} {
    flex-flow: column wrap;
    background-image: linear-gradient(
      to bottom,
      ${(props) => props.theme.primary900},
      ${(props) => props.theme.sidebarGradientBright}
    );
  }
`;

const LowerSidebar = styled(SidebarPart)`
  justify-items: flex-end;
  flex-shrink: 0;

  ${respondTo([desktop, desktopHd])} {
    margin-bottom: 10px;
  }
`;

const ButtonGroup = styled.div`
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  width: calc(100% - 20px);
  margin: 0 10px;

  ${respondTo([desktop, desktopHd])} {
    height: auto;
    margin-top: 20px;
    flex-flow: column wrap;
  }
`;

const SidebarButton = styled(SidebarIconButton)`
  margin-bottom: 0;
  padding: 0 15px;
  margin-right: 5px;

  ${respondTo([desktop, desktopHd])} {
    width: 100%;
    margin-bottom: 5px;
    margin-right: 0;
    justify-content: left;
  }
`;

const UpdatesButton = styled(SidebarButton)`
  margin-top: auto;
  width: calc(100% - 20px);
`;

const AppsWrapper = styled.div`
  width: 100%;
`;

const AppsText = styled.p`
  padding: 0 20px;
`;

const SidebarHeader = styled.div`
  font-size: 11px;
  font-weight: 600;
  color: ${(props) => props.theme.primary200};
`;

const StyledLink = styled.a`
  color: ${(props) => props.theme.whitePure};
  font-weight: 600;
`;

const initialFeatures = {
  projectManagement: undefined,
  floorplanner: undefined,
  configurationSessions: undefined,
  orderProject: undefined,
  mediaGallery: undefined,
  '360LeadGen': undefined,
  sceneCloudStreaming: undefined,
  portfolio: undefined,
  leadsForRed: undefined,
  loaded: undefined,
  contacts: undefined
};

export const AdminSidebar = memo(
  ({
    router,
    navigate,
    handleHome,
    AppReducer,
    handleLogout,
    AuthReducer,
    vault,
    handleHomeDeeplinks,
    history,
    NotificationsReducer,
    toggleActivityFeed,
    getNotifications,
    // toggleUpdatesFeed,
    isUpdatesFeedOpened,
    theme
  }) => {
    const cookies = new Cookies();
    const serviceAccount360CookieName = `AroundMedia/${env().env}/sast`;
    const { useDeeplinks } = AppReducer;

    const { isSidebarWide, primary400, primary300 } = theme;

    const [showUpsellModal, setShowUpsellModal] = useState(false);

    const {
      newNotificationsCount,
      isActivityFeedOpened,
      notificationsCountLoadingState,
      notifications,
      lastTimeOpenedUpdatesTimestamp
    } = NotificationsReducer;

    const { ProjectState } = useProjectState();
    const { lastFetchedProjects } = ProjectState;

    const {
      user,
      sessionToken,
      sessionObjectId,
      pendingRelogin,
      thisVaultPermissions
    } = AuthReducer;
    let isServiceAccount = false;
    const userRenews = AuthReducer.user
      ? getRenewTrackingCookie(AuthReducer.user.objectId)
      : 0;

    if (user) {
      isServiceAccount = user.serviceAccount;
    }

    const [resized, setResize] = useState();
    const [isTabletOrMobile, setIsTabletOrMobile] = useState(false);

    const [logo, setLogo] = useState(null);
    const [useUpsellFlow, setUseUpsellFlow] = useState(false);
    const [showUpsellNotification, setShowUpsellNotification] = useState(false);
    const [show360Button, setShow360Button] = useState(false);
    const Apps = [];

    // Streaming
    const [isStreamingEnabled, setIsStreamingEnabled] = useState(false);
    const [isStreamOpened, setIsStreamOpened] = useState(false);
    const [streamUrl, setStreamUrl] = useState('');
    const [streamGalleryUrl, setStreamGalleryUrl] = useState('');

    const [isVoteButtonEnabled, setIsVoteButtonEnabled] = useState(false);
    const [lastTimeOpenedUpdates, setLastTimeOpenedUpdates] =
      useState(50000000000000);

    const [features, setFeatures] = useState(initialFeatures);
    const [featuresAreChecked, setFeaturesAreChecked] = useState(false);

    useEffect(() => {
      setLastTimeOpenedUpdates(lastTimeOpenedUpdatesTimestamp);
    }, [lastTimeOpenedUpdatesTimestamp]);

    useEffect(() => {
      if (pendingRelogin && featuresAreChecked) {
        setFeaturesAreChecked(false);
      }
    }, [pendingRelogin, featuresAreChecked]);

    useEffect(() => {
      if (pendingRelogin) return;
      if (!thisVaultPermissions || Object.keys(thisVaultPermissions).length < 1)
        return;

      setFeatures(() => {
        const updFeatures = { ...initialFeatures };
        const featureList = thisVaultPermissions?.allowedFeatures ?? [];
        Object.keys(updFeatures).forEach(
          (featureName) =>
            (updFeatures[featureName] =
              featureList.filter((feature) => feature === featureName).length >
              0)
        );

        return updFeatures;
      });
      setFeaturesAreChecked(true);
    }, [thisVaultPermissions, pendingRelogin]);

    // Process all the vault permissions
    let vmConfigSessionList;
    let vmSceneList;
    let viewer360ArtifactRead;
    let viewer360ArtifactManagement;
    let contentArtifactWrite;
    let leadsRead;

    if (vault) {
      const operationsToCheck = [
        'vmConfigSessionList',
        'vmSceneList',
        'viewer360ArtifactRead',
        'viewer360ArtifactManagement',
        'contentArtifactWrite',
        'leadRead'
      ];
      const allowedOperations =
        AuthReducer.thisVaultPermissions?.allowedOperations ?? [];
      const permissions = operationsToCheck.map((operationName) =>
        allowedOperations.includes(operationName)
      );

      vmConfigSessionList = permissions[0];
      vmSceneList = permissions[1];
      viewer360ArtifactRead = permissions[2];
      viewer360ArtifactManagement = permissions[3];
      contentArtifactWrite = permissions[4];
      leadsRead = permissions[5];
    }

    useEffect(() => {
      if (
        vault?.sceneCloudStreamingBaseUrl &&
        features['sceneCloudStreaming']
      ) {
        setIsStreamingEnabled(true);

        /**
         * We need to add custom data in order to be able to auto login the application when streaming
         * For more explantation see: https://aroundmedia.atlassian.net/wiki/spaces/DEV/pages/999784452/Prompto+App+Streaming+Cloudalize+-+Internal+documentation#Environment-variables
         */
        let customData = 'PROMPTO_STREAMING_MODE%3Dtrue';
        customData += '%20PROMPTO_SESSION_TOKEN%3D' + AuthReducer.sessionToken;

        let fullStreamUrl =
          vault.sceneCloudStreamingBaseUrl + '&customdata=' + customData;

        /**
         * Build the loading screen gallery, we pass the first project since it will use the loading screen of the first project
         * If that is not set, by default the vault loading screen will be used
         */
        const galleryUrl = `https://mediagallery.prompto.com/?${qs.stringify({
          lang: localizer.getLanguage(),
          env: env().env,
          projectObjectId: lastFetchedProjects
            ? lastFetchedProjects[0]?.objectId ?? ''
            : '',
          projectVaultObjectId: vault.objectId,
          sessionToken: AuthReducer.sessionToken,
          isLoader: true
        })}
        `;

        setStreamGalleryUrl(galleryUrl);
        setStreamUrl(fullStreamUrl);
      }
    }, [vault, AuthReducer, lastFetchedProjects, features]);

    useEffect(() => {
      if (
        !features['360LeadGen'] &&
        viewer360ArtifactRead &&
        viewer360ArtifactManagement &&
        !isServiceAccount
      ) {
        setShowUpsellNotification(true);
        setUseUpsellFlow(true);
        setShow360Button(true);
      } else if (
        (features['360LeadGen'] &&
          viewer360ArtifactRead &&
          viewer360ArtifactManagement) ||
        isServiceAccount
      ) {
        setShowUpsellNotification(false);
        setUseUpsellFlow(false);
        setShow360Button(true);
      }
    }, [
      viewer360ArtifactRead,
      viewer360ArtifactManagement,
      contentArtifactWrite,
      isServiceAccount,
      features,
      userRenews
    ]);

    // Determine the correct logo to use
    useEffect(() => {
      let logo = (
        <LogoFullWrapper
          data-testid="admin-sidebar-logo-full"
          onClick={handleHome}
          src={PromptoFullLogoWhite}
        />
      );
      if (!isSidebarWide || isTabletOrMobile) {
        logo = (
          <LogoWrapper
            data-testid="admin-sidebar-logo-small"
            onClick={handleHome}
            src={PromptoSymbolWhite}
          />
        );
      }
      if (useDeeplinks) {
        logo = (
          <BackButtonWrapper onClick={handleHomeDeeplinks}>
            <FontAwesomeIcon icon={['far', 'arrow-left']} size="lg" />
            {isSidebarWide && (
              <BackButtonText>{capitalize(localizer.mainMenu)}</BackButtonText>
            )}
          </BackButtonWrapper>
        );
      }
      setLogo(logo);
    }, [
      resized,
      isSidebarWide,
      isTabletOrMobile,
      handleHome,
      handleHomeDeeplinks,
      useDeeplinks
    ]);

    useEffect(() => {
      setIsTabletOrMobile(window.innerWidth < breakPointsMax.tablet);
    }, []);

    useEffect(() => {
      const needToDisableVoteButton = cookies.get('disableVoteButton');

      if (!needToDisableVoteButton) {
        // Temp disable vote button, uncomment to enable again
        //setIsVoteButtonEnabled(true);
      }
    }, [cookies]);

    /* istanbul ignore next */
    const onResize = debounce(() => {
      setResize(uuid.v4());
      setIsTabletOrMobile(window.innerWidth <= breakPointsMax.tablet);
    }, 50);

    const { pathname } = router.location;
    if (!pathname) {
      return null;
    }

    // Bottom sidebar buttons
    let bottomSidebarButtons = [
      // <UpdatesButton
      //   key="updates"
      //   iconKey="SidebarIconButton_updates"
      //   iconComponent={<FontAwesomeIcon icon={['far', 'gift']} size="sm" />}
      //   clickHandler={() => toggleUpdatesFeed(!isUpdatesFeedOpened)}
      //   name={localizer.newInPrompto}
      //   notificationsCount={
      //     messages.filter((x) => x.timestamp > lastTimeOpenedUpdates).length
      //   }
      //   isActive={isUpdatesFeedOpened}
      // />,
      <SidebarButton
        key="settings"
        iconKey="SidebarIconButton_settings"
        iconComponent={<FontAwesomeIcon icon={['far', 'cog']} size="sm" />}
        clickHandler={() => {
          if (!user.serviceAccount) {
            Tracking.trackUserSimpleAction(
              {
                sessionId: sessionObjectId,
                action: 'openSettingsPage'
              },
              sessionToken
            ).catch(() => {});
          }
          navigate(`admin${window.location.search}`);
        }}
        isActive={pathname.match(/(\/admin)/g) !== null}
        name={localizer.settings}
      />,
      <VaultMenu
        key="vaultMenu"
        vault={vault}
        handleLogout={() => {
          cookies.remove(serviceAccount360CookieName, {
            domain: env().applicationDomain,
            path: '/'
          });
          handleLogout(AuthReducer.sessionToken);
        }}
      />
    ];

    const showcaseButton = (
      <SidebarButton
        key="showcase"
        iconKey="SidebarIconButton_showcase"
        iconComponent={
          <FontAwesomeIcon icon={['far', 'presentation']} size="sm" />
        }
        name={localizer.portfolio}
        clickHandler={() => {
          launchPortfolio(useDeeplinks, vault);
        }}
        animation={{
          initial: { opacity: 0 },
          animate: { opacity: 1 },
          exit: { opacity: 0 },
          transition: { duration: 0.25 }
        }}
      />
    );

    // Portfolio
    const showcaseTab = features.portfolio ? (
      showcaseButton
    ) : (
      <UpdateShieldHOC
        key="portfolio-update-shield"
        tippyText={localizer.updateShield.portfolio}
        history={history}
        wrapperStyles={`background: none;`}
        styles={
          isSidebarWide
            ? `right: 0; top: calc(50% - 2px);`
            : `height: 20px; min-width: 20px; top: 10px; border-radius: 50%; right: 0;`
        }
        collapsed={!isSidebarWide}
        dark
      >
        {showcaseButton}
      </UpdateShieldHOC>
    );

    // Leads
    const leadsButton = (
      <SidebarButton
        key="leads"
        iconKey="SidebarIconButton_leads"
        iconComponent={<FontAwesomeIcon icon={['far', 'filter']} size="sm" />}
        clickHandler={() =>
          navigate(`${topRoutes.leads}${window.location.search}`)
        }
        isActive={pathname.match(/(\/leads)/g) !== null}
        name={localizer.leads}
        animation={{
          initial: { opacity: 0 },
          animate: { opacity: 1 },
          transition: { duration: 0.25 }
        }}
      />
    );

    const leadsTab =
      features.leadsForRed && leadsRead ? (
        leadsButton
      ) : (
        <UpdateShieldHOC
          key="leads-update-shield"
          tippyText={localizer.formatString(
            localizer.updateShield.leads,
            <StyledLink href={`mailto:${sales}`}>{sales}</StyledLink>
          )}
          history={history}
          wrapperStyles={`background: none;`}
          styles={
            isSidebarWide
              ? `right: 0; top: calc(50% - 2px);`
              : `height: 20px; min-width: 20px; top: 10px; border-radius: 50%; right: 0;`
          }
          collapsed={!isSidebarWide}
          email={sales}
        >
          {leadsButton}
        </UpdateShieldHOC>
      );

    // Top sidebar buttons
    const topButtons = (
      <>
        <SidebarButton
          key="projects"
          iconKey="SidebarIconButton_projects"
          iconComponent={
            <FontAwesomeIcon icon={['far', 'folder-open']} size="sm" />
          }
          clickHandler={() => {
            // as we wanna track opening project list we do that in two places:
            // - right after login (redirect to project list)
            // - and by clicking on project list menu button
            if (!user.serviceAccount) {
              Tracking.trackUserSimpleAction(
                {
                  sessionId: sessionObjectId,
                  action: 'loadProjectList'
                },
                sessionToken
              ).catch(() => {});
            }

            navigate(`${topRoutes.projects}${window.location.search}`);
          }}
          isActive={pathname.match(/(\/projects)/g) !== null}
          name={localizer.projects}
        />

        {features.configurationSessions &&
          vmConfigSessionList &&
          vault?.objectId === 'papLcCKzTf' && (
            <SidebarButton
              key="sessions"
              iconKey="SidebarIconButton_sessions"
              iconComponent={
                <FontAwesomeIcon icon={['far', 'user-clock']} size="sm" />
              }
              clickHandler={() =>
                navigate(`${topRoutes.sessions}${window.location.search}`)
              }
              isActive={pathname.match(/(\/sessions)/g) !== null}
              name={localizer.sessions}
            />
          )}

        {useDeeplinks && vmSceneList && (
          <SidebarButton
            key="scenes"
            iconKey="SidebarIconButton_scenes"
            iconComponent={
              <FontAwesomeIcon icon={['far', 'mountains']} size="sm" />
            }
            clickHandler={() =>
              navigate(`${topRoutes.scenes}${window.location.search}`)
            }
            isActive={pathname.match(/(\/scenes)/g) !== null}
            name={localizer.scenes}
          />
        )}

        <SidebarButton
          key="team"
          iconKey="SidebarIconButton_team"
          iconComponent={<FontAwesomeIcon icon={['far', 'users']} size="sm" />}
          clickHandler={() => {
            // team page opened action tracking
            if (!user.serviceAccount) {
              Tracking.trackUserSimpleAction(
                {
                  sessionId: sessionObjectId,
                  action: 'openTeamsPage'
                },
                sessionToken
              ).catch(() => {});
            }
            navigate(`${topRoutes.team}${window.location.search}`);
          }}
          isActive={pathname.match(/(\/team)/g) !== null}
          name={localizer.team}
        />

        {features.leadsForRed !== undefined && leadsTab}

        {features.contacts && (
          <SidebarButton
            key="contacts"
            iconKey="SidebarIconButton_contacts"
            iconComponent={
              <FontAwesomeIcon icon={['far', 'address-book']} size="sm" />
            }
            clickHandler={() =>
              navigate(`${topRoutes.contacts}${window.location.search}`)
            }
            isActive={pathname.match(/(\/contacts)/g) !== null}
            name={localizer.contactsPage.contacts}
            animation={{
              initial: { opacity: 0 },
              animate: { opacity: 1 },
              transition: { duration: 0.25 }
            }}
          />
        )}

        <DividerWrapper
          key="order_divider"
          isVertical={isTabletOrMobile}
          marginSize={isTabletOrMobile ? 0 : 5}
          thickness={1}
          color={primary400}
        />

        <SidebarButton
          key="activity-feed"
          iconKey="ActivityFeedButton"
          iconComponent={<FontAwesomeIcon icon={['far', 'bell']} size="sm" />}
          name={localizer.notifications.activityFeed}
          onHoverHandler={() => {
            if (notifications?.length === 0) {
              getNotifications(AuthReducer.sessionToken);
            }
          }}
          clickHandler={() => toggleActivityFeed(!isActivityFeedOpened)}
          notificationsCount={newNotificationsCount}
          error={notificationsCountLoadingState === 'error'}
          activeColor={primary300}
          isActive={isActivityFeedOpened}
        />

        {features.portfolio !== undefined && (
          <motion.div
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.25 }}
          >
            {showcaseTab}
          </motion.div>
        )}
      </>
    );

    const on360ButtonClicked = () => {
      if (useUpsellFlow) {
        setShowUpsellModal(true);
      } else {
        if (vault.serviceAccount360Token) {
          cookies.set(
            serviceAccount360CookieName,
            vault.serviceAccount360Token || null,
            {
              domain: env().applicationDomain,
              path: '/',
              // expiry set at 10 years
              expiry: new Date(
                new Date().setFullYear(new Date().getFullYear() + 10)
              )
            }
          );
          window.open(`${env().aroundMediaPortalUrl}`);
        } else {
          setShowUpsellModal(true);
        }
      }
    };

    const leadGen360Button = (
      <SidebarButton
        key="360LeadGen"
        iconKey="SidebarIconButton_360"
        iconComponent={
          <Custom360Icon style={{ height: '24px', width: '24px' }} />
        }
        name={localizer.prompto360}
        clickHandler={on360ButtonClicked}
      />
    );

    const onUpdateOpenedTimestamp = useCallback((timestamp) => {
      setLastTimeOpenedUpdates(timestamp);
    }, []);

    if (isStreamingEnabled) {
      Apps.push(
        <SidebarButton
          key="streamButton"
          iconKey="streamButtonIcon"
          iconComponent={<FontAwesomeIcon icon={['fal', 'tv-alt']} size="sm" />}
          name={'STREAM'} // No localizing here, since it's a name/branding
          clickHandler={() => {
            setIsStreamOpened(true);
          }}
        />
      );
    }

    if (showUpsellNotification) {
      Apps.push(
        <Tippy
          key="tooltip"
          theme="promptoaccent"
          placement="right"
          arrow={true}
          zIndex={1000}
          content={
            <div>
              <UpsellNotification
                dataTestId="upsell360tooltip"
                title={localizer.upsell360NotificationTitle}
                description={localizer.upsell360NotificationDescription}
                next={localizer.learnMore}
                onNextClicked={() => {
                  setShowUpsellModal(true);
                }}
              />
            </div>
          }
          hideOnClick={true}
          interactive={true}
          offset={[0, 20]}
        >
          {leadGen360Button}
        </Tippy>
      );
    } else if (show360Button) {
      Apps.push(leadGen360Button);
    }

    const AppsHeader = (
      <SidebarHeader>
        <AppsText>
          {(isSidebarWide
            ? localizer.applications
            : localizer.apps
          ).toUpperCase()}
        </AppsText>
      </SidebarHeader>
    );

    if (!user) {
      return <div />;
    }

    return (
      <>
        <Wrapper
          key="wrapper"
          initial={
            isTabletOrMobile ? 'mobile' : isSidebarWide ? 'wide' : 'collapsed'
          }
          animate={
            isTabletOrMobile ? 'mobile' : isSidebarWide ? 'wide' : 'collapsed'
          }
          transition={{ type: 'tween', duration: 0.2 }}
          variants={wrapperVariants}
        >
          <SidebarWrapper key="sidebar">
            <UpperSidebar>{logo}</UpperSidebar>
            <FullDivider />

            <AnimatePresence>
              <MiddleSidebar {...animation}>
                <ButtonGroup>{topButtons}</ButtonGroup>

                {isVoteButtonEnabled && (
                  <VoteButton
                    isSidebarWide={isSidebarWide}
                    isTabletOrMobile={isTabletOrMobile}
                    onClick={() =>
                      navigate(`${topRoutes.vote}${window.location.search}`)
                    }
                    onHide={() => {
                      cookies.set('disableVoteButton', true, {
                        maxAge: 86400 // 24 hours
                      });
                      setIsVoteButtonEnabled(false);
                    }}
                  />
                )}

                {!useDeeplinks && !isTabletOrMobile && (
                  <AppsWrapper>
                    {Apps.length > 0 && (
                      <>
                        {AppsHeader}
                        <ButtonGroup>{Apps}</ButtonGroup>
                      </>
                    )}
                  </AppsWrapper>
                )}
              </MiddleSidebar>
            </AnimatePresence>

            {!useDeeplinks && (
              <LowerSidebar>
                <FullDivider />
                <ButtonGroup>{bottomSidebarButtons}</ButtonGroup>
              </LowerSidebar>
            )}

            <ReactResizeDetector
              handleHeight
              handleWidth
              skipOnMount
              targetDomEl={document.body}
              onResize={onResize}
            />
          </SidebarWrapper>
          {isActivityFeedOpened && (
            <ActivityFeed
              history={history}
              styles={`left: ${
                isSidebarWide ? WRAPPER_WIDTH.WIDE : WRAPPER_WIDTH.COLLAPSED
              }px;`}
            />
          )}
        </Wrapper>
        {showUpsellModal && (
          <UpsellIncentiveModal
            initiator={'360Button'}
            vaultId={vault?.objectId}
            sessionToken={AuthReducer?.sessionToken}
            onClose={() => {
              setShowUpsellModal(false);
            }}
          />
        )}
        {isStreamOpened && (
          <FullscreenIframePreview
            src={streamUrl}
            onExit={() => {
              setIsStreamOpened(false);
            }}
            loaderGalleryUrl={streamGalleryUrl}
          />
        )}
        {/* {isUpdatesFeedOpened && (
          <UpdatesPanel
            styles={`left: ${
              isSidebarWide ? WRAPPER_WIDTH.WIDE : WRAPPER_WIDTH.COLLAPSED
            }px;`}
            onUpdateOpenedTimestamp={onUpdateOpenedTimestamp}
          />
        )} */}
      </>
    );
  }
);

const animation = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
};

AdminSidebar.propTypes = {
  navigate: func,
  handleLogout: func,
  handleHome: func,
  handleHomeDeeplinks: func,
  handleDownloads: func,
  AuthReducer: shape({
    sessionToken: string
  }).isRequired,
  VaultReducers: shape({}),
  AppReducer: shape({})
};

AdminSidebar.defaultProps = {
  VaultReducers: {}
};

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  AuthReducer: state.store.AuthReducer,
  vault: state.store.VaultReducers?.vault,
  AppReducer: state.store.AppReducer,
  router: state.router,
  VaultListReducer: state.store.VaultListReducer,
  NotificationsReducer: state.store.NotificationsReducer,
  isUpdatesFeedOpened: state.store.UpdatesReducer?.isUpdatesFeedOpened
});

/* istanbul ignore next */
const nav = (location) => push(`/${localizer.getLanguage()}/${location}`);

/* istanbul ignore next */
const mapDispatchToProps = (dispatch) => ({
  navigate: (loc) => {
    dispatch({ type: 'TOGGLE_NOTIFICATIONS_FEED', payload: false });
    dispatch({ type: 'TOGGLE_UPDATES_FEED', payload: false });
    dispatch(nav(loc));
  },
  handleLogout: (sessionToken) => dispatch(userLogoutAction(sessionToken)),
  handleHome: () => dispatch(nav('projects')),
  handleHomeDeeplinks: () => {
    pingPrompto([{ toMainMenu: true }]);
  },
  toggleActivityFeed: (flag) => {
    dispatch({ type: 'TOGGLE_UPDATES_FEED', payload: false });
    dispatch({ type: 'TOGGLE_NOTIFICATIONS_FEED', payload: flag });
  },
  // toggleUpdatesFeed: (flag) => {
  //   dispatch({ type: 'TOGGLE_NOTIFICATIONS_FEED', payload: false });
  //   dispatch({ type: 'TOGGLE_UPDATES_FEED', payload: flag });
  // },
  getNotifications: (sessionToken, queryParams) =>
    dispatch(getAllNotifications(sessionToken, queryParams))
});

export default withTheme(
  connect(mapStateToProps, mapDispatchToProps)(AdminSidebar)
);
