import { validActionCheck } from 'store/actionUtils';
import isEqual from 'lodash.isequal';

export const initialState = {
  updateFrequency: 10, // update board every 10 seconds
  error: null,
  loading: false,
  board: null,
  columns: [],
  tickets: [],
  expandedColumnWidth: 320,
  collapsedColumnWidth: 80,
  maxColumnTitleLength: 20,
  allLeads: [],
  vaultUsers: null,
  isUpdatingBoard: false
};

const orderColumns = (board, columns, newColumns) => {
  const unorderedColumns = [...columns];
  const orderedColumnsIds = new Set();

  if (board.boardColumnOrderIdList) {
    board.boardColumnOrderIdList.forEach((columnId) => {
      const colIndex = columns.findIndex(
        ({ objectId }) => objectId === columnId
      );
      if (colIndex > -1) {
        orderedColumnsIds.add(columns[colIndex]?.objectId);
      }
    });
  }
  unorderedColumns.forEach((col) => orderedColumnsIds.add(col.objectId));

  let activeOrderedColumns = Array.from(orderedColumnsIds)
    .map((colId) => {
      const column = columns.find(({ objectId }) => objectId === colId);
      if (column && ['active', 'draft'].includes(column.state)) {
        return column;
      } else {
        return null;
      }
    })
    .filter(Boolean);

  // newColumns stands for columns received from backend, while refreshing the board
  // we need to compare ticketOrderList for each column to make sure
  // frontend is aligned with backend data
  if (newColumns) {
    activeOrderedColumns = activeOrderedColumns.map((col) => {
      const newColumn = newColumns.find(
        ({ objectId }) => objectId === col.objectId
      );
      if (
        newColumn &&
        !isEqual(newColumn.ticketOrderList, col.ticketOrderList)
      ) {
        return {
          ...col,
          ticketOrderList: newColumn.ticketOrderList
        };
      } else {
        return col;
      }
    });
  }

  return activeOrderedColumns;
};

const LeadsReducer = (state = initialState, action) => {
  if (validActionCheck(action)) {
    switch (action?.type) {
      case 'SET_LEADS':
        return {
          ...state,
          allLeads: action.payload
        };
      case 'SET_VAULT_USERS':
        return {
          ...state,
          vaultUsers: action.payload
        };
      case 'GET_LEADS_BOARD_PENDING':
        return {
          ...state,
          loading: action.payload
        };
      case 'SET_LEADS_BOARD_ERROR': {
        return {
          ...state,
          loading: false,
          error: action.payload,
          isUpdatingBoard: false
        };
      }
      case 'GET_LEADS_BOARD_FULFILLED':
        const orderedColumns = orderColumns(
          action.payload.board,
          action.payload.columns
        );
        return {
          ...state,
          board: action.payload.board,
          columns: orderedColumns,
          loading: false,
          error: null
        };
      case 'REFRESH_LEADS_BOARD_FULFILLED':
        const newOrderedColumns = orderColumns(
          action.payload.board,
          state.columns,
          action.payload.columns
        );
        return {
          ...state,
          board: action.payload.board,
          columns: newOrderedColumns,
          loading: false,
          error: null
        };
      case 'APPLY_BOARD_DATA_CACHE': {
        const orderedCachedColumns = orderColumns(
          action.payload.board,
          action.payload.columns
        );
        return {
          ...state,
          board: action.payload.board,
          columns: orderedCachedColumns
        };
      }
      case 'ADD_LEADS_BOARD_STATUS':
        return {
          ...state,
          columns: [
            ...state.columns,
            {
              objectId: action.payload,
              state: 'draft',
              name: ''
            }
          ]
        };
      case 'DELETE_LEADS_BOARD_STATUS':
        return {
          ...state,
          columns: state.columns.filter(
            (column) => column.objectId !== action.payload
          )
        };
      case 'UPDATE_LEADS_BOARD_STATUS':
        return {
          ...state,
          columns: state.columns.map((column) => {
            if (column.objectId === action.payload.id) {
              return {
                ...column,
                ...action.payload.data
              };
            } else {
              return column;
            }
          })
        };
      case 'SET_UPDATE_BOARD_STATUS':
        return {
          ...state,
          isUpdatingBoard: action.payload
        };
      case 'UPDATE_LEAD_BOARD_COLUMNS_TICKET_ORDER':
        const updatedColumns = state.columns.map((column) => ({
          ...column,
          ticketOrderList:
            action.payload[column.objectId]?.ticketOrderList ??
            column.ticketOrderList
        }));
        // Update cache
        const boardId = state.board.objectId;
        const cachedBoardData = JSON.parse(
          localStorage.getItem(`leads_board_data_${boardId}`)
        );
        if (cachedBoardData) {
          cachedBoardData.columns = updatedColumns;
          localStorage.setItem(
            `leads_board_data_${boardId}`,
            JSON.stringify(cachedBoardData)
          );
        }

        return {
          ...state,
          columns: updatedColumns
        };
      case 'AUTH_RESET':
      case 'AUTH_LOGOUT_REQUEST_PENDING': {
        return {
          ...initialState
        };
      }
      default: {
        return state;
      }
    }
  }
  return state;
};

export default LeadsReducer;
