import React from 'react';
import SwitcherStore from './SwitcherStore';
import ResourcesGrid from './ResourcesGrid';

const Resources = (props) => {
  return (
    <SwitcherStore>
      <ResourcesGrid {...props} />
    </SwitcherStore>
  );
};

export default Resources;
