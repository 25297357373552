import jwtDecode from 'jwt-decode';

/**
 * @param {string} token - jwt token to be decoded
 */
export const decodeToken = token => {
  let decodedToken;
  try {
    decodedToken = jwtDecode(token);
  } catch (err) {
    decodedToken = {
      error: 'Token could not be parsed correctly'
    };
  }
  return decodedToken;
};

/**
 * check the current token for validity and expiry
 *
 * @param {string} token - jwt token to be checked
 * @returns {bool|Error} - returns wether the token needs renewal or not, or an error if no token was found
 */
export const checkToken = token => {
  const decodedToken = decodeToken(token);
  if (decodedToken.error) {
    return Promise.reject(new Error(decodedToken.error));
  }

  // Check if token was succesfully parsed and has an expiry date
  if (decodedToken.exp && decodedToken.iat) {
    const nowInSeconds = Date.now() / 1000;
    // Divide by 1000 because the tokens are stored in seconds and not in MS
    const timeToExpiry = decodedToken.exp - nowInSeconds;
    // Subtract the time of renewal from the current time
    const timeSinceRenew = nowInSeconds - decodedToken.iat;
    // We set the threshold for the renew to zero after token creation.
    // This makes sure the renewSession is called everytime the page refreshes.
    const renewThreshold = 0;

    // check Token is not expired yet
    if (timeToExpiry > 0) {
      // Check if token needs to be renewed; this happens when the timeSinceRenew crosses the renewThreshold
      if (timeSinceRenew > renewThreshold) {
        return Promise.resolve(true);
      }

      // Tokem is valid and does not need to be renewed.
      return Promise.resolve(false);
    }

    // Token not valid aymore
    return Promise.reject(new Error('Token is invalid'));
  }

  return Promise.reject(new Error('Token is incorrect'));
};
