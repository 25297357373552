import React, { useCallback, useEffect, useRef, useState } from 'react';

// Components
import PromptoLogo from 'resources/PromptoNewLogoBlack.png';
import Resources from './resources/Resources';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ResourcesPlaceholder from './resources/ResourcesPlaceholder';
import PromptoLoader from 'components/loader/PromptoLoader';
import { AnimatePresence } from 'framer-motion';

// Helpers
import { isMobile } from 'react-device-detect';
import {
  SharedContentCollection,
  Tracking,
  PromptoSession,
  Visitor
} from '@prompto-api';
import { connect } from 'react-redux';
import localizer from 'localization/localizer';
import Cookies from 'universal-cookie';
import { getUserData } from 'helpers/util';
import env from 'environment';

// Styles
import styled, { createGlobalStyle, keyframes } from 'styled-components';
import { styledRespondTo } from 'styles/mixins';

const GlobalStyle = createGlobalStyle`
  body {
    min-height: 100%;
    max-height: 100%;
    height: auto;
  }
`;

const downloadableFileTypes = [
  'image',
  'image360',
  'video',
  'document',
  'floorplan'
];

const Logo = styled.img`
  width: 150px;
  object-position: center;
  z-index: 1;

  ${styledRespondTo.md`
    width: 200px;
  `}
`;

const PageWrapper = styled.div`
  background: ${({ theme }) => theme.whitePure};
  display: flex;
  width: 100%;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  position: relative;
  padding-top: 73px;

  ${styledRespondTo.md`
    padding-top: 96px;
  `}
`;

const HeaderWrapper = styled.div`
  background: ${({ theme }) => theme.whitePure};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 14px 16px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 11;

  ${styledRespondTo.md`
    padding: 18px 32px;
  `}
`;

const StyledContextMenuIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.whitePure};
  font-size: 0.75rem;

  ${styledRespondTo.sm`
    font-size: 1rem;
  `}
`;

const spin = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const StyledLoadingIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.whitePure};
  font-size: 0.75rem;
  animation: ${spin} 2s linear infinite;

  ${styledRespondTo.sm`
    font-size: 1rem;
  `}
`;

const StyledButton = styled.button`
  color: ${({ theme }) => theme.whitePure};
  font-size: 0.75rem;
  min-width: 44px;
  height: 44px;
  padding: 5px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  border: none;
  border-radius: 8px;
  background: ${({ theme }) => theme.showcaseBlack};
  cursor: pointer;
  box-sizing: border-box;
  font-weight: bold;

  ${styledRespondTo.sm`
    height: 48px;
    padding: 5px 23px;
    min-width: 160px;
  `}
`;

const InfoSection = styled.section`
  color: ${({ theme }) => theme.showcaseBlack};
  background: ${({ theme }) => theme.beigeBg10};
  display: flex;
  align-items: center;
  padding: 23px 16px;
  height: 72px;

  ${styledRespondTo.md`
    padding: 27px 64px;
    height: 80px;
  `}
`;

const ContentItemsAmount = styled.strong`
  font-style: italic;
  font-size: 1.25rem;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding: 32px 24px;
  height: 100%;
  min-height: calc(100vh - 140px); // full height - headers

  ${styledRespondTo.md`
    padding: 48px 80px;
  `}
`;

export const DownloadButton = ({
  hideDescription,
  onClick,
  customDescription = localizer.collectionPage.downloadAll,
  isLoading
}) => {
  return (
    <StyledButton onClick={onClick} isLoading={isLoading} disabled={isLoading}>
      {isLoading ? (
        <StyledLoadingIcon icon={['far', 'spinner']} size="1x" />
      ) : (
        <StyledContextMenuIcon icon={['far', 'arrow-to-bottom']} size="1x" />
      )}
      {(!isMobile || hideDescription) && customDescription}
    </StyledButton>
  );
};

const CollectionPage = (props) => {
  const {
    initiateDownload,
    initiateVisitorCreate,
    startCreateVisitorProcess,
    setVisitorSession,
    setVisitor,
    setVaultId,
    visitorSessionId
  } = props;
  const [contentCollection, setContentCollection] = useState(null);
  const [sharedContentCollection, setSharedContentCollection] = useState(null);
  const [contentItemList, setContentItemList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [, , collectionID] = window.location.pathname.split('/');
  const createVisitorProcessStarted = useRef(false);
  const createVisitorProcessCounter = useRef(0);
  const cookies = new Cookies();

  useEffect(() => {
    let sessionHeartInterval;
    const visitorIdFromCookie = cookies.get(`buyer_space_visitor_id`);
    const authorizedUserId = cookies.get(`prompto/${env().env}/ust`);

    SharedContentCollection.get(collectionID, '', {
      loadContentCollectionAndContentItems: true
    })
      .then(async (result) => {
        const { data } = result;
        setVaultId(data.vmSharedContentCollection?.vaultId);
        setContentCollection(data.vmContentCollection);
        setContentItemList(
          data.vmContentCollection.vmContentItemList
            .filter((contentItem) => {
              return (
                downloadableFileTypes.includes(contentItem.contentItemType) &&
                contentItem.contentItemState !== 'archived'
              );
            })
            .sort((a, b) => b.lastModifiedAt - a.lastModifiedAt)
        );
        setSharedContentCollection(data.vmSharedContentCollection);
        setIsLoading(false);
        const triggerStartSession = () => {
          createVisitorProcessStarted.current = false;
          initiateVisitorCreate(true);
        };

        // if there is an authorized user - skip everything related to tracking
        if (
          startCreateVisitorProcess &&
          !createVisitorProcessStarted.current &&
          !authorizedUserId
        ) {
          createVisitorProcessStarted.current = true;
          initiateVisitorCreate(false);
          createVisitorProcessCounter.current =
            createVisitorProcessCounter.current + 1;
          const buyerSpacePromptoSessionCreateParams = {
            vaultId: data.vmSharedContentCollection?.vaultId,
            shareContentCollectionObjectId: collectionID
          };

          const handleStartSession = (response) => {
            const { visitorObjectId: visitorId, sessionObjectId: sessionId } =
              response.data;
            // track shared content collection page was opened on
            // successful load of the shared content collection after visitor session id was received
            Tracking.trackSharedCollectionPageOpened({
              sharedCollectionId: collectionID,
              sessionId
            }).catch(() => {
              console.warn('Could not track Buyers space loaded action');
            });
            setVisitorSession(sessionId);
            setVisitor(visitorId);
            sessionHeartInterval = setInterval(() => {
              PromptoSession.updateEndTimestamp({ sessionId }).catch(() => {
                console.warn('Could not update the end session timestamp');
              });
            }, 10000);

            cookies.set(`buyer_space_visitor_id`, visitorId, {
              maxAge: 315360000000, // 10 years,
              sameSite: 'none',
              secure: true
            });
          };

          if (visitorIdFromCookie) {
            Visitor.startSession({
              visitorId: visitorIdFromCookie,
              buyerSpacePromptoSessionCreateParams
            })
              .then((response) => handleStartSession(response))
              .catch(() => {
                // do one more try
                if (createVisitorProcessCounter.current < 2) {
                  triggerStartSession();
                }
              });
          } else {
            let visitorCreateParams = {};
            // todo: uncomment before release
            // if (process.env.NODE_ENV === 'production') {
            const userBrowserData = await getUserData();

            const userAgentData = navigator?.userAgentData;
            const userDeviceData = {
              mobile: userAgentData?.mobile,
              platform: userAgentData?.platform
            };

            visitorCreateParams = {
              ipAddress: userBrowserData?.ip,
              network: userBrowserData?.network,
              version: userBrowserData?.version,
              city: userBrowserData?.city,
              region: userBrowserData?.region,
              region_code: userBrowserData?.region_code,
              country: userBrowserData?.country,
              country_code: userBrowserData?.country_code,
              country_code_iso3: userBrowserData?.country_code_iso3,
              country_name: userBrowserData?.country_name,
              country_capital: userBrowserData?.country_capital,
              country_tld: userBrowserData?.country_tld,
              continent_code: userBrowserData?.continent_code,
              in_eu: userBrowserData?.in_eu,
              postal: userBrowserData?.postal,
              latitude: userBrowserData?.latitude,
              longitude: userBrowserData?.longitude,
              timezone: userBrowserData?.timezone,
              utc_offset: userBrowserData?.utc_offset,
              country_calling_code: userBrowserData?.country_calling_code,
              currency: userBrowserData?.currency,
              currency_name: userBrowserData?.currency_name,
              languages: userBrowserData?.languages,
              country_area: userBrowserData?.country_area,
              asn: userBrowserData?.asn,
              country_population: userBrowserData?.country_population,
              org: userBrowserData?.org,
              ...userDeviceData
            };
            // }
            Visitor.startSession({
              buyerSpacePromptoSessionCreateParams,
              visitorCreateParams
            })
              .then((response) => handleStartSession(response))
              .catch(() => {
                // do one more try
                if (createVisitorProcessCounter.current < 2) {
                  triggerStartSession();
                }
              });
          }
        }
      })
      .catch((error) => {});

    return () => {
      if (sessionHeartInterval) clearInterval(sessionHeartInterval);
      initiateVisitorCreate(true);
    };
  }, [
    startCreateVisitorProcess,
    initiateVisitorCreate,
    setContentItemList,
    setSharedContentCollection
  ]);

  const onDownloadAllClick = useCallback(() => {
    if (initiateDownload) {
      const folders = {
        name: 'Prompto Files',
        projectFiles: [],
        type: 'FOLDER',
        uuid: 'unsorted-items-folder'
      };
      contentItemList.forEach((contentItem) => {
        folders.projectFiles.push({
          contentItemId: contentItem.objectId,
          type: 'CONTENT_ITEM'
        });
      });
      initiateDownload([folders], contentCollection?.vmContentItemList ?? []);
    }
  }, [contentItemList, initiateDownload, contentCollection]);

  const content =
    contentItemList.length > 0 ? (
      <>
        <InfoSection>
          <ContentItemsAmount>{contentItemList.length || 0}</ContentItemsAmount>
          &nbsp;
          {contentItemList.length > 1
            ? localizer.collectionPage.files
            : localizer.collectionPage.file}
        </InfoSection>
        <ContentWrapper>
          <Resources
            contentItemList={contentItemList}
            previewContent={contentItemList.length ? contentItemList : []}
            fetchingContent={!!contentItemList.length}
            sharedContentCollection={sharedContentCollection}
            vaultId={sharedContentCollection?.vaultId}
            visitorSessionId={visitorSessionId}
            hideModal
          />
        </ContentWrapper>
      </>
    ) : (
      <ResourcesPlaceholder />
    );

  return (
    <PageWrapper>
      <GlobalStyle />
      <HeaderWrapper>
        <Logo src={PromptoLogo} alt="Prompto logo" />
        <DownloadButton onClick={onDownloadAllClick} />
      </HeaderWrapper>
      <AnimatePresence>
        {isLoading ? <PromptoLoader width={50} height={50} /> : content}
      </AnimatePresence>
    </PageWrapper>
  );
};

const mapDispatchToProps = (dispatch) => ({
  initiateDownload: (foldersToDownload, contentItemsList) => {
    dispatch({
      type: 'INITIATE_DOWNLOAD_PORTAL_MEDIA',
      payload: {
        foldersToDownload,
        contentItemsList,
        sectionId: 'Prompto-Files',
        doNotTrack: true
      }
    });
  },
  initiateVisitorCreate: (flag) => {
    dispatch({ type: 'START_VISITOR_CREATE_PROCESS', payload: flag });
  },
  setVisitor: (visitorId) => {
    dispatch({ type: 'SET_VISITOR', payload: visitorId });
  },
  setVisitorSession: (visitorSessionId) => {
    dispatch({ type: 'SET_VISITOR_SESSION', payload: visitorSessionId });
  },
  setVaultId: (vaultId) => {
    dispatch({
      type: 'SET_VAULT_INITIAL_INFO',
      payload: { objectId: vaultId }
    });
  }
});

const mapStateToProps = (state) => ({
  startCreateVisitorProcess: state.store.AuthReducer.startCreateVisitorProcess,
  visitorId: state.store.AuthReducer.visitorId,
  visitorSessionId: state.store.AuthReducer.visitorSessionId
});
export default connect(mapStateToProps, mapDispatchToProps)(CollectionPage);
