import React, { useEffect, useState } from 'react';
import { shape, string, func } from 'prop-types';

// Components
import Image from 'components/image/Image';
import AssignProjectsCard from 'components/views/invite/AssignProjectsCard';
import Button from 'components/button/Button';
import Modal from 'components/modal/Modal';

// Helpers
import { Vault, Project } from '@prompto-api';
import localizer from 'localization/localizer';
import { buildAssetURIWithOptions } from 'helpers/util';

// Styling
import styled from 'styled-components';

const Title = styled.p`
  margin-top: 65px;
  margin-bottom: 50px;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: ${(props) => props.theme.primary400};
`;

const ModalContent = styled.div`
  width: 400px;
  margin-left: auto;
  margin-right: auto;
`;

const UserCard = styled.div`
  display: flex;
  border: 1px solid ${(props) => props.theme.gray300};
  padding: 10px;
`;

const ProfileImage = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 3px;
  overflow: hidden;
  margin-right: 35px;
  flex-shrink: 0;
`;

const UserCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 20px;
  width: 60%;
`;

const Role = styled.span`
  display: block;
  font-size: 12px;
  color: ${(props) => props.theme.primary200};
  text-transform: capitalize;
`;

const Email = styled.span`
  display: block;
  font-size: 12px;
  font-weight: 600;
  color: ${(props) => props.theme.primary400};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 45px;
`;

const SubmitButton = styled(Button)`
  margin: 0;
`;

const SuccessButton = styled(Button)`
  margin: 0;
  background-color: ${(props) => props.theme.successColor} !important;
  width: auto;
`;

const CancelButton = styled(Button)`
  margin: 0;
`;

const ErrorMessage = styled.div`
  color: ${(props) => props.theme.errorColor};
  font-size: 12px;
  margin-top: 16px;
  text-align: right;
`;

const AssignProjectsModal = ({
  vaultObjectId,
  sessionToken,
  userData,
  updateUserProjects,
  onClose,
  vaultId,
  AuthReducer
}) => {
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [initialSelectedProjects, setInitialSelectedProjects] = useState([]);

  const [updateError, setUpdateError] = useState(false);
  const [updateErrorMessage, setUpdateErrorMessage] = useState(
    localizer.assignProjectsToTeamMemberInfo
  );
  const [isUpdating, setIsUpdating] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    async function getAllProjects() {
      const result = await Project.getAll(vaultObjectId, null, sessionToken);

      let allProjects = [];
      result.data.projectSectionList.forEach((projectSection) => {
        allProjects.push(projectSection.objectId);
      });

      let assignedResult;

      if (userData.type === 'Invitation') {
        assignedResult = await Vault.getPermissionsOfInvitation(
          vaultObjectId,
          userData.objectId,
          sessionToken
        );
      } else {
        assignedResult = await Vault.getPermissionOfUser(
          vaultObjectId,
          userData.objectId,
          sessionToken
        );
      }

      let assigned = [];
      if (assignedResult.data.projectList) {
        assignedResult.data.projectList.forEach((project) => {
          assigned.push(project.objectId);
        });
      }

      assigned = assigned.filter((item) => allProjects.includes(item));

      setInitialSelectedProjects(assigned);
    }
    getAllProjects();
  }, [sessionToken, userData, vaultObjectId]);

  const onSubmit = async () => {
    setIsUpdating(true);
    const [error] = await updateUserProjects(userData, selectedProjects);

    if (error) {
      if (error.error?.message) {
        setUpdateErrorMessage(error.error.message);
      } else setUpdateErrorMessage(localizer.assignProjectsToTeamMemberInfo);
      setUpdateError(true);
      setIsUpdating(false);
      return;
    }

    setSuccess(true);
    setIsUpdating(false);
    setTimeout(() => {
      setSuccess(false);
      onClose();
    }, 2000);
  };

  return (
    <Modal
      styles={{
        overlay: { padding: 0 },
        modal: {
          maxWidth: 'none',
          width: '100%',
          height: 'fit-content',
          minHeight: '100%',
          overfloYy: 'auto',
          padding: '20px 0'
        }
      }}
      onClose={() => {
        onClose();
      }}
      open={true}
      showCloseIcon={!isUpdating}
    >
      <ModalContent>
        <Title>{localizer.assignProjectsToTeamMember}</Title>
        <UserCard>
          <ProfileImage>
            <Image
              imageUri={buildAssetURIWithOptions(
                'q=100:mr=60,60',
                userData.metadata?.profilePictureUri
              )}
              alt="profileImage"
              draggable={false}
            />
          </ProfileImage>
          <UserCardInfo>
            <Role>{localizer[userData.role]}</Role>
            <Email>{userData.email}</Email>
          </UserCardInfo>
        </UserCard>
        <AssignProjectsCard
          vaultObjectId={vaultObjectId}
          sessionToken={sessionToken}
          onSelectionUpdated={setSelectedProjects}
          initialSelected={initialSelectedProjects}
          selectedRole={{ databaseEntry: userData.role }}
        />
        <ButtonWrapper>
          <CancelButton
            label={localizer.cancel}
            onClickAction={onClose}
            dataTestId="cancelButton"
            status={isUpdating ? 'disabled' : 'default'}
          />
          {success ? (
            <SuccessButton
              type="submit"
              onClickAction={onSubmit}
              dataTestId="submitButton"
              status="success"
            />
          ) : (
            <SubmitButton
              type="submit"
              label={localizer.submit}
              onClickAction={onSubmit}
              dataTestId="submitButton"
              status={isUpdating ? 'disabled' : 'default'}
            />
          )}
        </ButtonWrapper>
        {updateError && <ErrorMessage>{updateErrorMessage}</ErrorMessage>}
      </ModalContent>
    </Modal>
  );
};

AssignProjectsModal.propTypes = {
  vaultObjectId: string.isRequired,
  sessionToken: string.isRequired,
  userData: shape({}).isRequired,
  updateUserProjects: func.isRequired,
  onClose: func.isRequired
};

export default AssignProjectsModal;
