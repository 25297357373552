import { validActionCheck } from 'store/actionUtils';
import initialState from 'store/reducers/appReducer/defaultAppState';

/**
 * DEPRECATED, remove after we get rid of the Unreal studio
 * Reducer for using deeplinks and other params or filters for the studio
 */
const AppReducer = (state = initialState, action) => {
  if (validActionCheck(action)) {
    switch (action.type) {
      case 'APP_USE_DEEPLINKS': {
        return {
          ...state,
          ...action.payload
        };
      }
      case 'APP_USE_MIPIM': {
        return {
          ...state,
          ...action.payload
        };
      }
      case 'APP_SET_ASSIMP_VERSION_FILTER': {
        return {
          ...state,
          ...action.payload
        };
      }
      case 'APP_SET_UE4_VERSION_FILTER': {
        return {
          ...state,
          ...action.payload
        };
      }
      case 'AUTH_RESET':
      case 'AUTH_LOGOUT_REQUEST_PENDING': {
        return {
          ...initialState
        };
      }
      default: {
        return state;
      }
    }
  }
  return state;
};

export default AppReducer;
