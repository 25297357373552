import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useRef,
  memo
} from 'react';

// Components
import { PlayThinIcon } from 'resources/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Cloudinary
import { Video, Transformation } from 'cloudinary-react';

// Helpers
import env from 'environment';
import { isMobile } from 'react-device-detect';
import {
  computeCloudinaryId,
  computeCloudinaryThumbnail,
  isCloudinaryTransformationAvailable
} from 'helpers/util';
import localizer from 'localization/localizer';

// Styling
import styled, { css } from 'styled-components';

const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh !important;
  color: white;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ThumbnailWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const StyledPlayThinIcon = styled(PlayThinIcon)`
  position: absolute;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
`;

const ErrorOuterWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
`;

const ErrorInnerWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`;

const ErrorHeading = styled.p`
  font-size: 1rem;
  font-weight: 500;
  margin: 5px 0;
`;

const ErrorDescription = styled.p`
  font-size: 0.75rem;
  margin: 0;
`;

const overlaySharedStyles = css`
  height: 100%;
  width: 100%;
  filter: blur(10px);
  -moz-filter: blur(10px);
  transform: scale(1.1);
`;

const StyledImg = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
  ${({ claudinaryNotProvided }) =>
    claudinaryNotProvided ? overlaySharedStyles : ''}
`;

const ActiveOverlay = styled.div`
  height: 100%;
  width: 100%;
  object-fit: contain;
  ${({ claudinaryNotProvided }) =>
    claudinaryNotProvided ? overlaySharedStyles : ''}
  background-color: black;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10000;
`;

const VideoContent = memo(
  ({
    autoplay,
    data,
    showGrid,
    fallbackThumbnail = '',
    transitioning
  }) => {
    const [isVideoActive, setIsVideoActive] = useState(false);
    const [interacted, setInteracted] = useState(false);
    const [transformationAvailable, setTransformationAvailable] =
      useState(false);
    const [overlayActive, setOverlayActive] = useState(true);

    const player = useRef();

    useEffect(() => {
      setTimeout(() => setOverlayActive(false), 500);
    }, []);

    const currentEnvironment = env();

    const [cloudinaryImageUri] = useState(
      computeCloudinaryThumbnail(
        decodeURIComponent(data.contentUri),
        currentEnvironment.googleStorageBucketId,
        currentEnvironment.cloudinaryVideoBaseUrl
      )
    );

    const [cloudinaryId] = useState(
      computeCloudinaryId(
        decodeURIComponent(data.contentUri),
        currentEnvironment.googleStorageBucketId
      )
    );

    const wrapIsCloudinaryTransformationAvailable = useCallback(
      isCloudinaryTransformationAvailable,
      []
    );

    // Pause the video if current page is transitioning out
    useEffect(() => {
      if (transitioning && player.current) {
        // @ts-ignore
        player.current.pause();
      }
    }, [transitioning]);

    useEffect(() => {
      if (isVideoActive) {
        if (!interacted) {
          setInteracted(true);
        }
      }
    }, [isVideoActive, data.objectId, interacted]);

    useEffect(() => {
      if (autoplay) {
        setIsVideoActive(true);
      }
    }, [autoplay]);

    useEffect(() => {
      if (!isVideoActive) {
        return;
      }

      if (showGrid) {
        setIsVideoActive(false);
      }
    }, [showGrid, isVideoActive]);

    //check if transformation is available async
    useEffect(() => {
      const effectHeight = isMobile ? 720 : 1080;
      wrapIsCloudinaryTransformationAvailable(
        setTransformationAvailable,
        decodeURIComponent(data.contentUri),
        currentEnvironment.googleStorageBucketId,
        currentEnvironment.cloudinaryVideoBaseUrl,
        `c_limit,h_${effectHeight}`,
        'mp4'
      );
    }, [
      wrapIsCloudinaryTransformationAvailable,
      data.contentUri,
      currentEnvironment.googleStorageBucketId,
      currentEnvironment.cloudinaryVideoBaseUrl
    ]);

    //add handlers for the video player to know when video is active
    const handlers = {
      onPlay: () => {
        setIsVideoActive(true);
      }
    };

    let thumbnailOrVideo = (
      <ThumbnailWrapper
        onClick={() => {
          if (cloudinaryId) {
            setIsVideoActive(true);
            setInteracted(true);
          }
        }}
      >
        {cloudinaryId ? (
          <StyledPlayThinIcon />
        ) : (
          <ErrorOuterWrapper>
            <ErrorInnerWrapper>
              <FontAwesomeIcon icon={['fal', 'info-circle']} size="2x" />
              <ErrorHeading>
                {localizer.collectionPage.videoUnavailable}
              </ErrorHeading>
              <ErrorDescription>
                {localizer.collectionPage.somethingWentWrong}
              </ErrorDescription>
            </ErrorInnerWrapper>
          </ErrorOuterWrapper>
        )}
        <StyledImg
          src={
            cloudinaryId ? cloudinaryImageUri : (fallbackThumbnail)
          }
          claudinaryNotProvided={!cloudinaryId}
          alt="thumbnail"
        />
      </ThumbnailWrapper>
    );

    //determine max width/height based on mobile: sd (720) on mobile or hd otherwise (1280)
    const maxHeight = isMobile ? 720 : 1080;
    const transformation = transformationAvailable && (
      <Transformation height={maxHeight} crop="limit" />
    );

    if (isVideoActive) {
      thumbnailOrVideo = (
        <Fragment>
          {overlayActive && <ActiveOverlay />}
          <Video
            {...handlers}
            innerRef={player}
            cloudName={currentEnvironment.cloudinaryCloudName}
            publicId={cloudinaryId}
            sourceTypes={['mp4', 'ogv']}
            autoPlay={true}
            controls={true}
            width="100%"
            height="100%"
            disablePictureInPicture
            controlsList="nodownload"
          >
            {transformation}
          </Video>
        </Fragment>
      );
    }

    return (
      <VideoContainer id="video=container">{thumbnailOrVideo}</VideoContainer>
    );
  }
);

export default VideoContent;
