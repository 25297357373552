import React, { useState } from 'react';

// Component
import ProfileImage from 'components/profileImage/ProfileImage';
import VaultAcceptInvitationModal from './VaultAcceptInvitationModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
import localizer from 'localization/localizer';

// Styling
import styled from 'styled-components';
import { motion } from 'framer-motion';

const VaultCard = styled(motion.div)`
  display: flex;
  flex-flow: column;
  align-items: center;
  background: ${(props) => props.theme.whitePure};
  border-radius: 3px;
  border: 1px solid ${(props) => props.theme.gray300};
  padding: 10px 15px;
  margin-bottom: 5px;
  cursor: pointer;
`;

const VaultInfoWrapper = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  width: 100%;
`;

const VaultLogo = styled(ProfileImage)`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin: 0;
`;

const VaultName = styled.div`
  font-size: 0.75rem;
  color: ${(props) => props.theme.showcaseBlack};
  user-select: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 5px;
`;

const Description = styled.div`
  font-size: 0.75rem;
  color: rgba(0, 0, 0.5, 0.5);
`;

const VerticalWrapper = styled.div`
  display: flex;
  flex-flow: column;
  max-width: 70%;
  margin-left: 15px;
`;

const MailIcon = styled(FontAwesomeIcon)`
  font-size: 1rem;
  color: ${(props) => props.theme.primary400};
  margin-left: auto;
`;

const VaultMenuInvitationCard = ({
  invitation,
  onHideVaultMenu,
  onShowVaultMenu,
  onRemoveInvitation,
  onAcceptedInvitation,
  sessionToken
}) => {
  const [showInvitationModal, setShowInvitationModal] = useState(false);

  const vault = invitation?.publicVaultInfo?.vault;

  return (
    <>
      <VaultCard
        whileHover={{ boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.3)' }}
        data-testid="vaultInvitationCard"
        onClick={() => {
          setShowInvitationModal(true);
          onHideVaultMenu();
        }}
      >
        <VaultInfoWrapper>
          <VaultLogo profileImageUri={vault?.logoUri} />
          <VerticalWrapper>
            <VaultName>{vault.name}</VaultName>
            <Description>{localizer.hasInvitedYou}</Description>
          </VerticalWrapper>
          <MailIcon icon={['far', 'envelope']} size="1x" />
        </VaultInfoWrapper>
      </VaultCard>
      {showInvitationModal && (
        <VaultAcceptInvitationModal
          invitation={invitation}
          onClose={() => {
            setShowInvitationModal(false);
            onShowVaultMenu();
          }}
          sessionToken={sessionToken}
          onRemoveInvitation={onRemoveInvitation}
          onAcceptedInvitation={onAcceptedInvitation}
        />
      )}
    </>
  );
};

export default VaultMenuInvitationCard;
