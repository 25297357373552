import { validActionCheck } from 'store/actionUtils';

const SnappingLinesReducer = (
  state = {
    threshold: 15
  },
  action
) => {
  if (validActionCheck(action)) {
    switch (action.type) {
      case 'SET_SNAPPING_LINES':
        return {
          ...state,
          ...action.payload
        };
      case 'SET_RELATIVE_TO_ABSOLUTE_MAPPING':
        return {
          ...state,
          ...action.payload
        };
      case 'SET_ACTIVE_VERTICAL_LINE':
        return {
          ...state,
          activeVerticalLine: action.payload
        };
      case 'SET_ACTIVE_HORIZONTAL_LINE':
        return {
          ...state,
          activeHorizontalLine: action.payload
        };
      case 'SET_DRAG_COORDINATES':
        return {
          ...state,
          dragCoords: action.payload
        };
      case 'RESET_ACTIVE_SNAPPING_LINES':
        return {
          ...state,
          activeVerticalLine: null,
          activeHorizontalLine: null
        };
      case 'TOGGLE_SNAPPING':
        return {
          ...state,
          snappingEnabled: action.payload
        };
      default: {
        return state;
      }
    }
  }
  return state;
};

export default SnappingLinesReducer;
