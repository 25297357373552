import React, { useEffect } from 'react';
import { number, bool, shape } from 'prop-types';

// Components
import GridviewIcon from 'resources/icons/GridviewIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
import { motion } from 'framer-motion';
import { isMobileOnly } from 'react-device-detect';

// Styling
import styled from 'styled-components';
import { styledRespondTo } from 'styles/mixins';

const Button = styled.button`
  align-items: center;
  backdrop-filter: blur(3px);
  border: none;
  background-color: rgba(152, 152, 152, 0.2);
  color: white;
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  justify-content: center;
  outline: none;
  height: 44px;
  width: 44px;
  cursor: pointer;
  z-index: 15;
  margin-right: ${({ noMargin }) => noMargin ? '0' : '8px'};
  border-radius: 6px;

  ${styledRespondTo.sm`
    border-radius: 8px;
    height: calc(60px + 1vmin);
    min-width: 80px;
  `}
`;

const CloseButtonWrapper = styled(motion.div)`
  position: fixed;
  top: 14px;
  left: 16px;

  ${styledRespondTo.sm`
    position: relative;
    top: 0;
    left: 0;
  `}
`;

const Navigator = styled.div`
  display: flex;
  justify-content: center;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  width: ${isMobileOnly ? '100%' : 'auto'};
  z-index: 10;
  ${({ extraPadding }) => {
    if(isMobileOnly) {
      return extraPadding ? 'bottom: 45px;' : 'bottom: 15px;'
    }
    return 'top: 32px;'
  }};
`;

const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 1rem;

  ${styledRespondTo.sm`
    font-size: 1.25rem;
  `}
`;

Navigation.propTypes = {
  activePageIndex: number,
  pageLength: number,
  showGrid: bool,
  showUI: bool,
  centerButtonContent: shape({})
};

function Navigation({
  activePage = 0,
  onGridView,
  onNextPage,
  onPreviousPage,
  pageLength = 0,
  showGrid,
  showUI,
  centerButtonContent,
  extraPadding
}) {
  // UI
  const uiMotionState = showUI && !showGrid;
  const showPrevious = activePage > 0;
  const showNext = activePage < pageLength - 1;

  let nextButtonState = showNext ? 'visible' : 'disabled';
  let previousButtonState = showPrevious ? 'visible' : 'disabled';
  let gridViewButtonState = 'visible';

  if (!uiMotionState) {
    nextButtonState = 'hidden';
    previousButtonState = 'hidden';
    gridViewButtonState = 'hidden';
  }

  let centerButton = <GridviewIcon />;
  if (centerButtonContent) {
    centerButton = centerButtonContent;
  }

  useEffect(() => {
    if (onPreviousPage && onNextPage) {
      const handleKeyDown = (event) => {
        if (event.key === 'ArrowLeft') {
          onPreviousPage && onPreviousPage();
        } else if (event.key === 'ArrowRight') {
          onNextPage && onNextPage();
        }
      };

      document.addEventListener('keydown', handleKeyDown);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [onPreviousPage, onNextPage]);

  return (
    <Navigator extraPadding={extraPadding}>
      <motion.div
        key="prevButton"
        initial={'hidden'}
        animate={previousButtonState}
        variants={buttonVariants}
      >
        <Button
          onClick={() => {
            onPreviousPage && onPreviousPage();
          }}
        >
          <StyledIcon icon={['far', 'long-arrow-left']} size="sm" />
        </Button>
      </motion.div>
      <CloseButtonWrapper
        key="gridButton"
        initial={'hidden'}
        animate={gridViewButtonState}
        variants={buttonVariants}
      >
        <Button
          onClick={() => {
            onGridView && onGridView();
          }}
        >
          <StyledIcon icon={['far', 'times']} size="sm" />
        </Button>
      </CloseButtonWrapper>
      <motion.div
        key="nextButton"
        initial={'hidden'}
        animate={nextButtonState}
        variants={buttonVariants}
      >
        <Button
          onClick={() => {
            onNextPage && onNextPage();
          }}
          noMargin
        >
          <StyledIcon icon={['far', 'long-arrow-right']} size="sm" />
        </Button>
      </motion.div>
    </Navigator>
  );
}

export default Navigation;

// Motion
const buttonVariants = {
  visible: {
    opacity: 1,
    display: 'block',
    pointerEvents: 'auto'
  },
  disabled: {
    opacity: 0.5,
    display: 'block',
    pointerEvents: 'none'
  },
  hidden: {
    opacity: 0,
    pointerEvents: 'none',
    transitionEnd: {
      display: 'none'
    }
  }
};
