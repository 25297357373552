import React from 'react';
import styled from 'styled-components';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const Label = styled.label`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.gray};
  font-size: 0.875em;
  position: relative;
  margin-bottom: 16px;
  width: ${({ width }) => width || '100%'};
`;

const Text = styled.p`
  color: ${({ error, info, success, theme }) => {
    if (error) return theme.error;
    if (info) return theme.accent;
    if (success) return theme.success;
  }};
  margin: 0;
  text-align: ${({ info }) => (info ? 'left' : 'right')};
`;

const StyledPhoneInput = styled.div`
  & .selected-flag {
    border-radius: 0;

    &:hover,
    &:focus {
      background-color: ${({ theme }) => theme.gray300} !important;
    }
    & .open {
      background-color: ${({ theme }) => theme.gray300} !important;
    }

    .arrow {
      border-bottom-color: ${({ theme }) => theme.primary200} !important;
      border-top-color: ${({ theme }) => theme.primary200} !important;
    }
  }

  & .flag-dropdown {
    border-radius: 0;
    transition: 0.3s;
    border: 1px solid ${({ theme }) => theme.gray300} !important;
    border-right-color: transparent !important;
    background-color: ${({ theme }) => theme.grayWhiteOff} !important;
    & .open {
      background-color: ${({ theme }) => theme.grayWhiteOff} !important;
    }
  }

  & .country-list {
    background-color: ${({ theme }) => theme.gray300};
    color: ${({ theme }) => theme.primary200};
    width: 320px !important;
    .country {
      .dial-code {
        color: ${({ theme }) => theme.primary200};
      }
      &:hover {
        background-color: ${({ theme }) => theme.gray500};
      }

      &.highlight {
        background-color: ${({ theme }) => theme.gray500} !important;
      }
    }
  }

  & .form-control {
    font-size: 1rem !important;
    border: 1px solid ${({ theme }) => theme.gray300} !important;
    height: 40px !important;
    width: 100% !important;
    border-radius: 3px !important;
    transition: 0.3s;
    color: ${({ theme }) => theme.primary200} !important;
    background: ${({ theme }) => theme.grayWhiteOff} !important;
    &:focus {
      border: 1px solid ${({ theme }) => theme.gray300};

      & + .flag-dropdown {
        border: 1px solid ${({ theme }) => theme.gray300};
      }
    }
  }

  & .form-control-selected {
    background: ${({ theme }) => theme.grayWhiteOff};
  }

  & .form-control:disabled {
    color: ${({ theme }) => theme.gray};
  }
`;

export function PhoneInputField({
  className,
  disabled,
  error,
  info,
  onChange,
  placeholder,
  success,
  text,
  value,
  width,
  wrapperProps,
  currentLanguage,
  name,
  onBlur,
  dontUseThemeColors,
  handleSubmit,
  ...restProps
}) {
  return (
    <Label {...wrapperProps} width={width}>
      {text}
      <StyledPhoneInput
        error={error}
        success={success}
        dontUseThemeColors={dontUseThemeColors}
      >
        <PhoneInput
          country={'be'}
          placeholder={placeholder}
          disabled={disabled}
          value={value}
          onEnterKeyPress={e => {
            handleSubmit();
          }}
          onChange={onChange}
          inputProps={{
            name: 'phoneNumber',
            required: true
          }}
          localization={{ currentLanguage }}
          onBlur={onBlur}
          {...restProps}
        />
      </StyledPhoneInput>
      {error && <Text error={error}>{error}</Text>}
      {success && <Text success={success}>{success}</Text>}
      {info && <Text info={info}>{info}</Text>}
    </Label>
  );
}

export default PhoneInputField;

PhoneInputField.defaultProps = {
  className: '',
  disabled: false,
  errors: {},
  info: '',
  onChange: () => {},
  handleSubmit: () => {},
  placeholder: '',
  success: '',
  text: '',
  value: '',
  width: '',
  wrapperProps: {}
};
