import React, { useState, useEffect, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { func, shape, bool, number } from 'prop-types';

// Components
import Button from 'components/button/Button';
import InputField from 'components/inputField/InputField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion, AnimatePresence } from 'framer-motion';
import RandomBgFormContainer from 'components/other/RandomBgFormContainer';

// Helpers
import {
  userLoginAction,
  resetAuthAction,
  storeLoginInfo
} from 'store/reducers/authReducer/AuthActions';
import qs from 'query-string';
import localizer from 'localization/localizer';
import { capitalize } from 'helpers/util';
import env from 'environment';
import useSingleSignOn from './use-single-sign-on';
// import { User } from '@prompto-api';

// App Language
import strings from 'localization/localizer';
import PromptoLogo from 'resources/PromptoNewLogoBlack.png';

import GoogleLogo from 'resources/google.png';
import MicrosoftLogo from 'resources/microsoft.png';

// Styling
import styled from 'styled-components';
import { styledRespondTo } from 'styles/mixins';
import { CustomInput } from 'components/views/register/ProductLedRegisterForm';

const FormWrapper = styled.div`
  z-index: 10;
  padding: 70px 10px;
  padding-bottom: 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.whitePure};
  ${styledRespondTo.lg`
    padding: 100px 10px;
  `}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 480px;
  ${styledRespondTo.sm`
    max-width: 480px;
    min-width: 480px;
  `}
`;

const Form = styled.div`
  width: 100%;
  min-height: 300px;
  color: ${({ theme }) => theme.primary900};

  h2 {
    font-size: 1rem;
    font-weight: normal;
    margin: 0 0 10px;
  }

  h3 {
    font-size: 1.875rem;
    font-weight: normal;
    line-height: 1.33;
    margin: 0 0 16px;
    width: 100%;
  }

  ${styledRespondTo.md`
    h3 {
      margin: 0 0 30px;
    }
  `}
`;

const Logo = styled.img`
  position: absolute;
  top: -65px;
  left: -18px;
  height: 65px;
`;

const StyledInputField = styled(InputField)`
  input {
    background-color: ${({ theme }) => theme.grayWhiteOff};
    border-color: ${({ theme }) => theme.gray300};
    font-size: 1rem;
    color: ${(props) => props.theme.primary200};
    padding: ${(props) =>
      props.withVisibilityToggler ? '8px 40px 8px 10px' : '8px 10px'};
    margin: 0;
    letter-spacing: ${({ wide }) => (wide ? 3 : 0)}px;
    height: 34px;
    line-height: normal;
    ${styledRespondTo.lg`
      height: 40px;
    `}
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const PasswordInputGroup = styled(InputGroup)`
  margin-bottom: 16px;
  ${styledRespondTo.md`
    margin-bottom: 30px;
  `}
`;

const LabelGroup = styled.div`
  display: flex;
  align-items: flex-start;
`;

const InputLabel = styled.label`
  font-size: 0.75rem;
  color: ${(props) => props.theme.primary100};
  margin: 0 0 5px;
  flex-shrink: 0;
  position: relative;
  align-self: flex-start;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.gray200 : theme.accentAlt500} !important;
  border: none !important;
  border-radius: 2px;
  font-size: 1rem;
  font-weight: 600;
  color: ${({ theme }) => theme.whitePure} !important;
  border: none;
  margin: 0 0 16px;
  padding-top: 0;
  transition: all 180ms ease;

  span {
    display: flex;
    align-items: center;
  }

  ${styledRespondTo.md`
    margin: 0 0 30px;
  `}
`;

const RedirectButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  color: ${({ theme }) => theme.accentAlt300};
  font-size: 1rem;
  font-weight: 600;
  margin-left: 10px;
`;

const ForgotPasswordButton = styled(RedirectButton)`
  font-size: 0.75rem;
  margin-left: auto;
`;

const NoAccount = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  flex-wrap: wrap;
`;

const TermsOfService = styled(RedirectButton)`
  font-size: 0.75rem;
  margin-top: auto;
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 8px;
`;

const Error = styled(motion.div)`
  padding: 8px;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.errorColor};
  align-self: center;
  text-align: center;
`;

const AlternativeSignInBlock = styled.div`
  padding: 16px 0;
  border-top: 1px solid ${({ theme }) => theme.gray300};
  border-bottom: 1px solid ${({ theme }) => theme.gray300};
  margin-bottom: 16px;
  ${styledRespondTo.md`
    padding: 30px 0;
    margin-bottom: 30px;
  `}
`;

const AlternativeSignInText = styled.p`
  color: ${({ theme }) => theme.primary900};
  margin: 0 0 20px;
`;

const AuthProviders = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
`;

const AuthProviderButton = styled.button`
  height: 48px;
  border: none;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.grayWhiteOff};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  font-weight: 600;
  color: ${({ theme }) => theme.primary300};
  cursor: pointer;
  padding: 10px;
  box-sizing: border-box;

  span {
    font-size: 0.75rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-align: left;
    word-break: break-word;
    line-height: 1;
  }
`;

const AuthProviderLogo = styled.img`
  width: 24px;
  height: 24px;
  object-position: center;
  object-fit: contain;
  margin-right: 10px;
`;

const LoginView = (props) => {
  const { AuthReducer, resetAuth, login, storeLogin, authRedirect, history } =
    props;

  const localized = localizer.loginView;

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoggingInFailed, setLoggingInFailed] = useState(false);

  const [formInitialized, setFormInitialized] = useState(false);

  const { error, pending, statusCode } = AuthReducer;

  const emailInputRef = useRef(null);

  const { signInWithAltAuthProvider, currentUser } = useSingleSignOn();

  const checkAuthStatus = useCallback(({ AuthReducer, authRedirect }) => {
    const { authenticated, user } = AuthReducer;
    if (authenticated) {
      const { language } = user;
      strings.setLanguage(language || 'en');
      authRedirect();
    }
  }, []);

  const goForgotPassword = () =>
    history.push(`/${strings.getLanguage()}/forgot-password`);

  const goToSignUp = () => {
    let link = `/${strings.getLanguage()}/signup`;
    if (username) {
      link += `?email=${encodeURIComponent(username)}`;
    }
    history.push(link);
  };

  const onUserLogin = useCallback(
    (e) => {
      if (pending) return;
      if (!username || !password) {
        setFormInitialized(true);
        return emailInputRef.current?.focus();
      }

      setLoggingInFailed(false);

      if (e) {
        e.preventDefault();
      }
      storeLogin(username, password);
      login(username, password);
    },
    [login, storeLogin, username, password, pending]
  );

  // Effects
  useEffect(() => {
    checkAuthStatus({ AuthReducer, authRedirect });
    if (window.location.search) {
      const { email } = qs.parse(window.location.search.substring(1));
      if (email) {
        setUsername(email);
      }
    }
  }, [checkAuthStatus, AuthReducer, authRedirect]);

  useEffect(() => {
    if (error) resetAuth();
  }, [error, resetAuth]);

  useEffect(() => {
    if ([404, 401].includes(statusCode)) {
      setLoggingInFailed(true);
    }
  }, [statusCode]);

  const onAltSignInSuccess = (token) => login('', '', 'LOGIN', token);

  const onAltSignInError = () => setLoggingInFailed(true);

  const altSignInButtons = [
    {
      label: 'Google',
      logo: GoogleLogo,
      providerId: 'google.com'
    },
    {
      label: 'Microsoft',
      logo: MicrosoftLogo,
      providerId: 'microsoft.com'
    }
  ];

  return (
    <RandomBgFormContainer>
      <FormWrapper onClick={() => setFormInitialized(true)}>
        <Content>
          <Logo src={PromptoLogo} alt="prompto-logo" />
          <Form>
            <h2>{capitalize(localized.welcome)}</h2>
            <h3>{localized.signInToPrompto}</h3>

            {/* email */}
            <InputGroup>
              <InputLabel>{localized.login}</InputLabel>
              <InputWrapper>
                <StyledInputField
                  ref={emailInputRef}
                  dataTestId="username"
                  fieldName={`username`}
                  onFieldChange={(_, content) => {
                    setLoggingInFailed(false);
                    setUsername(content);
                  }}
                  value={username}
                  maxLength={256}
                  handleSubmit={onUserLogin}
                />
              </InputWrapper>
            </InputGroup>

            {/* password */}
            <PasswordInputGroup>
              <LabelGroup>
                <InputLabel>{localized.password}</InputLabel>
                <ForgotPasswordButton
                  onClick={goForgotPassword}
                  data-testid="forgotPasswordButton"
                >
                  {localized.forgot}
                </ForgotPasswordButton>
              </LabelGroup>

              <InputWrapper>
                <CustomInput
                  field={{
                    key: 'password',
                    withVisibilityToggler: true,
                    wide: true
                  }}
                  key={'password'}
                  value={password}
                  onChange={(fieldName, content) => {
                    setLoggingInFailed(false);
                    setPassword(content);
                  }}
                  maxLength={256}
                  handleSubmit={onUserLogin}
                />
              </InputWrapper>
            </PasswordInputGroup>

            <AnimatePresence>
              {isLoggingInFailed && (
                <Error
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -20 }}
                >
                  {localized.errorMessage}
                </Error>
              )}
            </AnimatePresence>

            <StyledButton
              onClickAction={onUserLogin}
              isError={error}
              isPending={pending}
              disabled={formInitialized && (!username || !password)}
              dataTestId="signInButton"
            >
              {pending && <Icon icon={['far', 'spinner']} size="sm" pulse />}
              {localized.signIn}
            </StyledButton>

            <AlternativeSignInBlock>
              <AlternativeSignInText>
                {localizer.orContinueWith}
              </AlternativeSignInText>
              <AuthProviders>
                {altSignInButtons.map((item) => {
                  const text =
                    currentUser?.providerId === item.providerId ? (
                      <span>
                        {localizer.signInAs} <b>{currentUser.email}</b>
                      </span>
                    ) : (
                      item.label
                    );
                  return (
                    <AuthProviderButton
                      key={item.providerId}
                      onClick={() =>
                        signInWithAltAuthProvider(
                          item.providerId,
                          onAltSignInSuccess,
                          onAltSignInError
                        )
                      }
                    >
                      <AuthProviderLogo src={item.logo} /> {text}
                    </AuthProviderButton>
                  );
                })}
              </AuthProviders>
            </AlternativeSignInBlock>

            <NoAccount>
              <span>{localized.noAccount}</span>
              <RedirectButton onClick={goToSignUp}>
                {localized.signUp}
              </RedirectButton>
            </NoAccount>
          </Form>
        </Content>
        <TermsOfService
          onClick={() => {
            window.open(env().termsOfService, '_blank');
          }}
          data-testid="TermsOfService"
        >
          {capitalize(localizer.termsOfService)}
        </TermsOfService>
      </FormWrapper>
    </RandomBgFormContainer>
  );
};

LoginView.propTypes = {
  login: func.isRequired,
  history: shape({
    push: func.isRequired
  }).isRequired,
  resetAuth: func.isRequired,
  AuthReducer: shape({
    statusCode: number,
    error: bool
  }).isRequired
};
/* istanbul ignore next */
const mapStateToProps = (state) => ({
  AuthReducer: state.store.AuthReducer
});
/* istanbul ignore next */
const mapDispatchToProps = (dispatch) => ({
  resetAuth: () => dispatch(resetAuthAction()),
  login: (username, password, loginType, token) =>
    dispatch(userLoginAction(username, password, loginType, token)),
  storeLogin: (username, password) =>
    dispatch(storeLoginInfo(username, password)),
  authRedirect: () =>
    dispatch(
      push(`/${strings.getLanguage()}/projects${window.location.search}`)
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginView);
