import React, { useState, useEffect } from 'react';
import PropTypes, { shape, func, string } from 'prop-types';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';

// Helpers
import { usePrevious } from '@prompto-helpers';

// Styling
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'normal' : 'pointer')};
  position: relative;
  margin: 6px 10px;
  ${({ styles }) => styles}
`;

const Prefix = styled.p`
  font-size: 12px;
  color: ${(props) => props.theme.primary200};
`;

const SelectedSortOption = styled.p`
  font-size: 12px;
  color: ${(props) => props.theme.primary200};
  font-weight: bold;
  display: flex;
  align-items: center;
`;

const SwitchIsAscendingButton = styled.button`
  background: none;
  border: none;
  cursor: ${({ disabled }) => (disabled ? 'normal' : 'pointer')};
  outline: none;

  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.primary100};
`;

const SortOption = styled.p`
  font-size: 12px;
  width: 100%;
  font-weight: 400;
  color: ${(props) => props.theme.primary200};
  cursor: pointer;
  user-select: none;
  margin: 0;
  padding: 5px 30px;
  &:hover {
    color: ${(props) => props.theme.accentAlt700};
    background: ${(props) => props.theme.gray100};
  }
  ${({ styles }) => styles}
`;

const ActiveSortOption = styled(SortOption)`
  font-weight: 700;
  color: ${(props) => props.theme.accentAlt700};
`;

const DropdownWrapper = styled(motion.div)`
  position: absolute;
  width: max-content;
  min-width: 100%;
  max-width: 400%;
  top: 100%;
  background: white;
  border-radius: 2px;
  padding: 15px 0px;
  z-index: 105;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.4);
  ${({ styles }) => styles}
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
`;

const SortBy = ({
  sortOptions,
  selectedSortOption,
  ascending,
  onSortChanged,
  onSortOrderChanged,
  prefix,
  id,
  styles,
  disabled
}) => {
  const [sortOptionsOpened, setSortOptionsOpened] = useState(false);
  const [isAscending, setIsAscending] = useState(ascending);
  const previousIsAscending = usePrevious(isAscending);

  useEffect(() => {
    setIsAscending(ascending);
  }, [ascending]);

  useEffect(() => {
    if (previousIsAscending !== isAscending) {
      onSortOrderChanged(isAscending);
    }
  }, [isAscending, previousIsAscending, onSortOrderChanged]);

  const sortOptionsComp = sortOptions.map((sortOption) => {
    const sortOptionProps = {
      id: `${id}_sortOption_sortBy-${sortOption.value}`,
      key: sortOption.label,
      onClick: (e) => {
        e.stopPropagation();
        onSortChanged(sortOption);
        setSortOptionsOpened(false);
      },
      styles: styles?.option
    };

    if (sortOption.value === selectedSortOption.value) {
      return (
        <ActiveSortOption {...sortOptionProps}>
          {sortOption.label}
        </ActiveSortOption>
      );
    } else {
      return <SortOption {...sortOptionProps}>{sortOption.label}</SortOption>;
    }
  });

  return (
    <>
      {sortOptionsOpened && (
        <Overlay
          onClick={() => {
            setSortOptionsOpened(false);
          }}
        />
      )}
      <Wrapper
        onClick={() => {
          // If there's just one option available, we don't show selection menu
          if (sortOptions.length > 1 && !disabled) {
            setSortOptionsOpened(true);
          }
        }}
        styles={styles?.wrapper}
        disabled={disabled}
      >
        <Prefix id={id}>{prefix}&nbsp;</Prefix>
        <SelectedSortOption aria-labelledby="sortByLabel">
          {selectedSortOption?.label}
        </SelectedSortOption>
        <SwitchIsAscendingButton
          role="switch"
          aria-checked={isAscending}
          aria-label="is sort ascending"
          onClick={(e) => {
            if (!disabled) {
              e.stopPropagation();
              setIsAscending((current) => !current);
            }
          }}
          id={`${id}_isSortAscendingSwitcher`}
          disabled={disabled}
        >
          <Icon
            key="icon"
            icon={['fas', isAscending ? 'caret-down' : 'caret-up']}
            size="sm"
          />
        </SwitchIsAscendingButton>

        <AnimatePresence>
          {sortOptionsOpened && (
            <DropdownWrapper
              styles={styles?.dropdown}
              initial={{ opacity: 0, x: 10 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 10 }}
            >
              {sortOptionsComp}
            </DropdownWrapper>
          )}
        </AnimatePresence>
      </Wrapper>
    </>
  );
};

SortBy.propTypes = {
  selectedSortOption: shape({}),
  /**
   * Sort criterias the user can pick to decide which field to sort on
   */
  sortOptions: PropTypes.arrayOf(
    PropTypes.shape({
      /** The name of the field (in code) to sort on */
      value: PropTypes.string.isRequired,
      /** The label that will be displayed to the user for this sort option */
      label: PropTypes.string.isRequired
    })
  ),
  /**
   * Called when the sort criteria has changed
   * @callback
   * @param {{value: string, label: string}} sortOption sort option the user picked
   */
  onSortChanged: func,
  /**
   * Called when the sort order has changed
   * @param {boolean} isAscending
   */
  onSortOrderChanged: func,
  id: string
};

SortBy.defaultProps = {
  selectedSortOption: null,
  sortOptions: [],
  onSortChanged: () => {}
};

export default SortBy;
