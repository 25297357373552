import React, { useState, useEffect } from 'react';
import { string, func, array } from 'prop-types';

// Components
import SearchField from 'components/form/searchField/SearchField';
import Button from 'components/button/Button';
import CardList from 'components/lists/CardList';
import InviteUserProjectCard from './InviteUserProjectCard';

// Helpers
import localizer from 'localization/localizer';
import { Project } from '@prompto-api';
import { capitalize } from 'helpers/util';
import { usePrevious } from '@prompto-helpers';

// Styling
import styled from 'styled-components';

const Card = styled.div``;

const AssignToProjects = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => props.theme.primary400};
  align-items: center;

  span:last-child {
    font-size: 12px;
    color: ${(props) => props.theme.primary400};
    text-align: right;
    text-transform: lowercase;
  }
`;

const AssignToProjectsTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  color: ${(props) => props.theme.gray300};
`;

const AssignToProjectsInstructions = styled.p`
  font-size: 12px;
  line-height: 17px;
  margin-top: 8px;
  color: ${(props) => props.theme.primary400};
  padding-bottom: 2px;
`;

const ProjectResultWrapper = styled.div`
  height: 275px;
  padding-top: 10px;
  padding-bottom: 20px;
`;

const SelectedCounter = styled.p`
  text-align: right;
`;

const AssignProjectsCard = ({
  vaultObjectId,
  sessionToken,
  onSelectionUpdated,
  initialSelected,
  className,
  disabled,
  selectedRole
}) => {
  const [selectedProjects, setSelectedProjects] = useState([]);

  const [fetching, setFetching] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const [projectList, setProjectList] = useState([]);
  const [filteredProjectList, setFilteredProjectList] = useState([]);
  const [projectsAreFetched, setProjectsAreFetched] = useState(false);

  const previousRole = usePrevious(selectedRole?.databaseEntry);

  useEffect(() => {
    if (
      !selectedRole?.databaseEntry ||
      fetching ||
      (projectsAreFetched && previousRole === selectedRole?.databaseEntry)
    )
      return;

    setProjectList([]);
    setFetching(true);
    Project.getAll(vaultObjectId, {}, sessionToken)
      .then((result) => {
        let resultProjectList = result.data.projectSectionList;

        if (selectedRole?.databaseEntry === 'redVaultAgent') {
          resultProjectList = resultProjectList.filter(
            (project) => project.state !== 'DRAFT'
          );
        }

        setProjectsAreFetched(true);
        setProjectList(resultProjectList);
        setFetching(false);
      })
      .catch(() => {});
  }, [
    vaultObjectId,
    sessionToken,
    selectedRole,
    fetching,
    previousRole,
    projectsAreFetched
  ]);

  useEffect(() => {
    if (disabled) {
      setSearchValue('');
    }
  }, [disabled]);

  useEffect(() => {
    if (searchValue && searchValue !== '') {
      let newList = projectList.filter((x) =>
        x.title?.toLowerCase().includes(searchValue?.toLowerCase())
      );
      setFilteredProjectList(newList);
    } else {
      setFilteredProjectList(projectList);
    }
  }, [projectList, searchValue]);

  useEffect(() => {
    if (initialSelected) {
      setSelectedProjects(initialSelected);
    }
  }, [initialSelected]);

  useEffect(() => {
    onSelectionUpdated(selectedProjects);
  }, [selectedProjects, onSelectionUpdated]);

  const onSearchWasMade = (fieldName, value) => {
    setSearchValue(value);
  };

  const toggleSelectProject = (project) => {
    setSelectedProjects((list) => {
      if (list.includes(project.objectId)) {
        return list.filter((projectId) => projectId !== project.objectId);
      } else {
        return [...list, project.objectId];
      }
    });
  };

  const toggleSelectAllProjects = () => {
    // inclusive select: only deselect all when all is selected
    if (selectedProjects.length < filteredProjectList.length) {
      setSelectedProjects(
        filteredProjectList.map((project) => project.objectId)
      );
    } else {
      setSelectedProjects([]);
    }
  };

  const renderItem = ({ item, id }) => (
    <InviteUserProjectCard
      key={id}
      project={item}
      disabled={disabled}
      selected={selectedProjects.includes(item.objectId) || disabled}
      onToggle={toggleSelectProject}
    />
  );

  return (
    <Card className={className}>
      <AssignToProjects>
        {disabled ? (
          <AssignToProjectsTitle>
            {localizer.assignToProjects}
          </AssignToProjectsTitle>
        ) : (
          <span>{localizer.assignToProjects}</span>
        )}
        {!disabled && (
          <Button
            type="link"
            onClickAction={() => {
              toggleSelectAllProjects();
            }}
          >
            {selectedProjects.length < filteredProjectList.length
              ? localizer.selectAll
              : localizer.deselectAll}
          </Button>
        )}
      </AssignToProjects>
      {!disabled && (
        <AssignToProjectsInstructions>
          {localizer.assignToProjectsInstructions}
        </AssignToProjectsInstructions>
      )}
      <SearchField
        key={'searchField'}
        fieldName={'searchField'}
        onFieldChange={onSearchWasMade}
        childKey={'searchField'}
        hasDebounce
        disabled={disabled}
        inputValue={searchValue}
        clearButtonEnabled={!disabled}
        placeholder={capitalize(localizer.search)}
      />
      <ProjectResultWrapper>
        <CardList
          dataList={filteredProjectList}
          dataTestId="project-card-list"
          disabled={disabled}
          placeholderComponent={null}
          renderItem={renderItem}
          toolbarItem={null}
          onBottomReached={() => {}}
          fetching={fetching || !selectedRole?.databaseEntry}
        />
      </ProjectResultWrapper>
      <SelectedCounter>
        {disabled ? filteredProjectList.length : selectedProjects.length}{' '}
        {selectedProjects.length === 1 ? localizer.project : localizer.projects}{' '}
        {localizer.selected}
      </SelectedCounter>
    </Card>
  );
};

AssignProjectsCard.propTypes = {
  vaultObjectId: string.isRequired,
  sessionToken: string.isRequired,
  onSelectionUpdated: func.isRequired,
  initialSelected: array
};

AssignProjectsCard.defaultProps = {
  initialSelected: []
};

export default AssignProjectsCard;
