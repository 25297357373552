import { validActionCheck } from 'store/actionUtils';

const UpdatesReducer = (state = { isUpdatesFeedOpened: false }, action) => {
  if (validActionCheck(action)) {
    switch (action.type) {
      case 'TOGGLE_UPDATES_FEED':
        return {
          ...state,
          isUpdatesFeedOpened: action.payload
        };
      default: {
        return state;
      }
    }
  }
  return state;
};

export default UpdatesReducer;
