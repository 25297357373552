import React from 'react';

// Helpers
// https://www.npmjs.com/package/pannellum-react
import { Pannellum } from 'pannellum-react';
import { isMobile } from 'react-device-detect';
import localizer from 'localization/localizer';

// Styling
import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.white};
  position: relative;
  width: 100%;
  height: 100%;
`;

const Instructions = styled.div`
  position: absolute;
  z-index: 1;
  width: 360px;
  bottom: 0;
  right: 0;
  padding: 0.75rem;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 0.875rem;
  line-height: 1.1;
  letter-spacing: 1px;
  text-align: center;
`;

const ThreeSixtyImageContent = ({
  contentUri,
  onLoad
}) => {
  return (
    <Wrapper>
      <Instructions>
        {
          isMobile
          ? localizer.collectionPage.swipeToNavigate
          : localizer.collectionPage.useMouseOrKeyboardToNavigate
        }
      </Instructions>
      <Pannellum
        width="100%"
        height="100%"
        image={contentUri}
        pitch={10}
        yaw={180}
        hfov={110}
        autoLoad
        onLoad={() => {
          onLoad();
        }}
        showControls={false}
      />
    </Wrapper>
  );
};

export default ThreeSixtyImageContent;
