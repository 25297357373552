import React from 'react';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tippy from '@tippyjs/react';

import localizer from 'localization/localizer';

import styled from 'styled-components';
import { motion } from 'framer-motion';

const BigButtonWrapper = styled(motion.div)`
  position: relative;
  width: 200px;
  height: 120px;
  margin-top: auto;
  margin-bottom: 20px;
  padding: 5px 5px 12px 10px;
  border-radius: 4px;
  background-color: #3a2a74;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  user-select: none;
  cursor: pointer;
`;

const SmallButtonWrapper = styled.div`
  position: relative;
  width: 50px;
  height: 50px;
  padding: 13px;
  border-radius: 4px;
  margin-top: auto;
  margin-bottom: 20px;
  background-color: #3a2a74;
  color: ${({ theme }) => theme.accent};

  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;
`;

const VoteIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.accent};
`;

const BigVoteIcon = styled(VoteIcon)`
  font-size: 30px;
`;
const SmallVoteIcon = styled(VoteIcon)`
  font-size: 18px;
`;

const Title = styled.div`
  font-size: 0.75rem;
  font-weight: 600;
  color: ${({ theme }) => theme.whitePure};
  margin-top: 10px;
  margin-bottom: 5px;
`;

const Description = styled.div`
  font-size: 0.69rem;
  font-weight: normal;
  color: ${({ theme }) => theme.whitePure};
  text-align: center;
`;

const ClearIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 5px;
  right: 5px;
  color: ${({ theme }) => theme.primary100};
`;

const TippyContent = styled.div`
  width: 250px;
  word-wrap: normal;
`;

const VoteButton = ({ isSidebarWide, isTabletOrMobile, onClick, onHide }) => {
  return (
    <>
      {isSidebarWide && !isTabletOrMobile ? (
        <BigButtonWrapper
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.5 }}
          onClick={onClick}
        >
          <ClearIcon
            icon={['far', 'times']}
            size="1x"
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              onHide();
            }}
          />
          <BigVoteIcon icon={['far', 'microphone-stand']} size="1x" />
          <Title>{localizer.voteButton.title}</Title>
          <Description>{localizer.voteButton.description}</Description>
        </BigButtonWrapper>
      ) : (
        <Tippy
          key="tooltip"
          theme="prompto"
          placement="right"
          arrow={true}
          zIndex={1000}
          content={
            <TippyContent>{localizer.voteButton.description}</TippyContent>
          }
          hideOnClick={true}
          interactive={true}
          offset={[0, 20]}
        >
          <SmallButtonWrapper onClick={onClick}>
            <SmallVoteIcon icon={['far', 'microphone-stand']} size="1x" />
          </SmallButtonWrapper>
        </Tippy>
      )}
    </>
  );
};

export default VoteButton;
