import React from 'react';

// Helpers
import { motion } from 'framer-motion';

import PlaceholderEmptyFeed from 'resources/PlaceholderEmptyNotificationsFeed.png';

// Styling
import styled from 'styled-components';

const Wrapper = styled(motion.div)`
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.whitePure};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 540px;
  padding: 50px 0;
`;

const Image = styled.img`
  width: 160px;
  height: auto;
  margin-bottom: 10px;
`;

const Text = styled.p`
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.43;
  margin: 0;
  color: ${({ theme }) => theme.primary300};
  text-align: center;
  max-width: 300px;
`;

const EmptyFeed = ({ text }) => (
  <Wrapper
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ delay: 0.3 }}
  >
    <Image src={PlaceholderEmptyFeed} alt={text} />
    <Text>{text}</Text>
  </Wrapper>
);

export default EmptyFeed;
