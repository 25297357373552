import React from 'react';
import { string, shape } from 'prop-types';

// Components
import { MdWarning } from 'react-icons/md';

// Helpers
import localizer from 'localization/localizer';
import { capitalize } from 'helpers/util';

// Styling
import styled from 'styled-components';

const SupportLink = styled.div`
  & > a {
    font-size: 0.8125rem !important;
    text-decoration: none;
    color: ${({ theme }) => theme.accent};
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;

const Message = styled.div`
  padding-top: 20px;
  color: ${({ theme }) => theme.primary400};
  font-size: 0.75rem;
  text-align: center;
`;

const StyledIcon = styled(MdWarning)`
  height: 60px;
  width: 60px;
  color: ${({ theme }) => theme.errorColor};
`;

const Warning = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const MoreInfo = styled.p`
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: -10px;
`;

const ErrorTechnicalMessage = styled.p`
  font-size: 0.6875rem;
  opacity: 0.6;
`;

const WarningFeedback = ({ error, email }) => {
  let content;

  const { code, message } = error;

  let contactInfo;
  if (email) {
    contactInfo = (
      <SupportLink>
        <a href={`mailto:${email}`}>{email}</a>
      </SupportLink>
    );
  }

  content = (
    <Wrapper>
      <Warning>
        <StyledIcon />
        <Message>
          <p>{localizer[code] || localizer.genericError}</p>
          {contactInfo}

          {message && (
            <>
              <br />
              <MoreInfo>{capitalize(localizer.moreInfo)}</MoreInfo>
              <br />
              <ErrorTechnicalMessage>{message}</ErrorTechnicalMessage>
            </>
          )}
        </Message>
      </Warning>
    </Wrapper>
  );

  return content;
};

WarningFeedback.propTypes = {
  error: shape({}),
  email: string
};

WarningFeedback.defaultProps = {
  error: {
    code: 'genericError',
    message: ''
  }
};

export default WarningFeedback;
