import React, {
  useState,
  forwardRef,
  useCallback,
  useEffect,
  memo
} from 'react';
import { string, bool, func, number } from 'prop-types';
import { DebounceInput } from 'react-debounce-input';
import { capitalize } from 'helpers/util';
import styled from 'styled-components';

const MainWrapper = styled.div`
  width: 100%;
`;

const Warning = styled.span`
  display: block;
  position: relative;
  margin-top: 4px;
  float: none;
  clear: both;
  color: ${({ theme }) => theme.errorColor};
  font-size: 0.75rem;
  font-weight: 300;
  line-height: normal;
`;

const StyledLabel = styled.label`
  display: block;
  position: relative;
  line-height: normal;
  text-align: left;
  color: ${({ theme }) => theme.descriptionActions};
  font-size: 0.75rem;
  display: flex;
  flex-direction: column;
`;

const StyledFieldLabel = styled.div`
  flex-basis: 30%;
`;

const FieldAndWarningWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  > span {
    padding-left: 10px;
  }
`;

const FormField = styled.input`
  display: block;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  margin-top: 5px;
  background: ${({ theme }) => theme.gray100};
  border: 1px solid transparent;
  color: ${({ theme, disabled }) =>
    disabled ? theme.primary100 : theme.primary400};
  font-size: 1rem;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  outline: none;
  border: solid 1px #e7e6ea;

  &[type='submit'] {
    display: block;
    position: relative;
    margin: 0 auto;
    width: auto;
  }
  @include single-transition(0.2s border-color ease-in);
  &:hover,
  &:focus {
    border: 1px solid darken(#eaedf0, 8%);
  }
`;

const FormFieldArea = styled.textarea`
  display: block;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  margin-top: 5px;
  background: ${({ theme }) => theme.gray100};
  border: 1px solid transparent;
  color: ${({ theme, disabled }) =>
    disabled ? theme.primary100 : theme.primary400};
  font-size: 1rem;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  outline: none;
  border: solid 1px #e7e6ea;

  &[type='submit'] {
    display: block;
    position: relative;
    margin: 0 auto;
    width: auto;
  }
  transition: 0.2s border-color ease-in;
  &:hover,
  &:focus {
    border: 1px solid darken(#eaedf0, 8%);
  }
  min-height: 50px;
`;

const StyledDebounceInput = styled(DebounceInput)`
  display: block;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  margin-top: 5px;
  color: ${({ theme, disabled }) =>
    disabled ? theme.primary100 : theme.primary400};
  background: ${({ theme }) => theme.gray100};
  border: 1px solid transparent;
  font-size: 1rem;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  outline: none;
  border: solid 1px #e7e6ea;

  &[type='submit'] {
    display: block;
    position: relative;
    margin: 0 auto;
    width: auto;
  }
  @include single-transition(0.2s border-color ease-in);
  &:hover,
  &:focus {
    border: 1px solid darken(#eaedf0, 8%);
  }
`;

const SuggestionsDropdown = styled.div`
  width: 100%;
  max-height: 150px;
  overflow: auto;
  position: absolute;
  right: 0;
  display: flex;
  flex-flow: column;
  background: ${({ theme }) => theme.whitePure};
  border: 1px solid ${({ theme }) => theme.gray300};
  border-radius: 3px;
  margin-top: 5px;
  z-index: 5000;
`;

const SuggestionItem = styled.div`
  padding: 10px;
  margin-bottom: 5px;
  user-select: none;
  cursor: pointer;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.primary300};
  &:hover {
    background: ${({ theme }) => theme.grayWhiteOff};
  }
`;

const InputField = forwardRef(
  (
    {
      childKey,
      dataTestId,
      className,
      disabled,
      fieldName,
      hasDebounce,
      isRequired,
      onFieldChange,
      handleSubmit,
      onBlur,
      label,
      numLines,
      placeholder,
      statusCode,
      statusMessage,
      type,
      value,
      maxLength,
      debounceTimeout,
      suggestions
    },
    ref
  ) => {
    const [localValue, setLocalValue] = useState(value);
    const [filteredSuggestions, setFilteredSuggestions] = useState(suggestions);
    const [focused, setFocused] = useState(false);

    useEffect(() => {
      if (value !== localValue) {
        setLocalValue(value);
      }
    }, [value, localValue]);

    const filterSuggestions = (e) => {
      setFilteredSuggestions(
        suggestions?.filter((x) => {
          return x.toLowerCase().includes(e.toLowerCase());
        })
      );
    };

    // === CALLBACKS ===
    const handleFieldChange = useCallback(
      (e) => {
        if (maxLength && e.target.value.length > maxLength) return;
        if (!disabled) {
          onFieldChange(fieldName, e.target.value);
          setLocalValue(e.target.value);
          filterSuggestions(e.target.value);
        }
      },
      [maxLength, disabled, onFieldChange]
    );

    const onFieldBlur = (e) => {
      if (!disabled) {
        onBlur(fieldName, e.target.value);
        setLocalValue(e.target.value);
        filterSuggestions(e.target.value);
      }
    };

    const onSubmit = (e) => {
      if (e.key === 'Enter') {
        handleSubmit();
      }
    };

    const createContent = useCallback(
      (warning) => {
        // fill out off the props and render the correct type of input
        const formFieldProps = {
          disabled,
          id: fieldName,
          key: childKey,
          name: fieldName,
          onChange: handleFieldChange,
          onKeyPress: onSubmit,
          onBlur: (e) => {
            onFieldBlur(e);
            if (focused) {
              setTimeout(() => {
                setFocused(false);
              }, 100);
            }
          },
          onFocus: () => {
            setFocused(true);
          },
          onFocusIn: () => {
            setFocused(true);
          },
          onFocusOut: () => {
            setFocused(false);
          },
          placeholder,
          type,
          value: localValue
        };

        if (hasDebounce) {
          formFieldProps.debounceTimeout = debounceTimeout ?? 500;
          formFieldProps.minLength = 1;
        }

        const formField = hasDebounce ? (
          <StyledDebounceInput {...formFieldProps} inputRef={ref} />
        ) : numLines > 1 ? (
          <FormFieldArea {...formFieldProps} rows={numLines} ref={ref} />
        ) : (
          <FormField {...formFieldProps} ref={ref} />
        );

        let content = (
          <>
            {formField}
            {focused && filteredSuggestions?.length > 0 && (
              <SuggestionsDropdown>
                {filteredSuggestions.map((x) => {
                  return (
                    <SuggestionItem
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        handleFieldChange({
                          target: {
                            value: x
                          }
                        });
                        setFilteredSuggestions([]);
                      }}
                    >
                      {x}
                    </SuggestionItem>
                  );
                })}
              </SuggestionsDropdown>
            )}
          </>
        );

        // If a label is supplied it needs to wrap Around the formField to satisfy jsx-a11y
        if (label) {
          content = (
            <StyledLabel htmlFor={fieldName}>
              <StyledFieldLabel>{`${capitalize(label)} ${
                isRequired ? '*' : ''
              }`}</StyledFieldLabel>
              <FieldAndWarningWrapper>
                {formField}
                {warning}
              </FieldAndWarningWrapper>
            </StyledLabel>
          );
        }

        return content;
      },
      [localValue, type, handleFieldChange, focused]
    );

    let warning;
    if (statusCode >= 300 && statusMessage && statusMessage !== '') {
      warning = <Warning>{statusMessage}</Warning>;
    }

    return (
      <MainWrapper className={className} data-testid={dataTestId}>
        {createContent(warning)}
      </MainWrapper>
    );
  }
);

InputField.propTypes = {
  /** title to be shown over the input field */
  label: string,
  /** placeholder to be shown in the input field */
  placeholder: string,
  /** fieldName, this is returned in the onFieldChange so you can keep them appart */
  fieldName: string.isRequired,
  /** value of the inputField used to set an inital value (no read) */
  value: string,
  /** used to disable the input field and make it read only */
  disabled: bool,
  /**
   * this function gets called when the field changes, with the following arguments;
   *
   * @param {string} fieldName - returns the name of this input field
   * @param {string} content - returns content this input field
   */
  onFieldChange: func.isRequired,
  /** this function gets called when the field loses focus */
  onBlur: func,
  /** this function is called when the user presses Enter on the input field */
  handleSubmit: func,
  /** status message to be shown when something is wrong */
  statusMessage: string,
  /**
   * status code to change the status of the input field.
   * will show an error on anything above 300, like the http codes
   */
  statusCode: number,
  /**
   * choose the type of the input field
   * this is passed through to the <input/> tag so the options the normal html ones
   */
  type: string,
  /** Add a classname to further style this component externally */
  className: string,
  /** Add a classname to further style this input component externally */
  /** should the form send back it's value immediately or debounce it */
  hasDebounce: bool,
  /** Number of lines of the inputfield */
  numLines: number,
  /** whether the inputfield is required (puts a star after the label) */
  isRequired: bool,
  /** key to give the input field itself */
  childKey: string,
  /** Data test id for automated testing */
  dataTestId: string,
  /** Change the layout to horizontal */
  alt: bool
};

InputField.defaultProps = {
  label: '',
  placeholder: '',
  value: '',
  disabled: false,
  statusMessage: '',
  statusCode: 200,
  type: 'text',
  className: null,
  handleSubmit: () => {},
  onBlur: () => {},
  hasDebounce: false,
  numLines: 1,
  isRequired: false,
  dataTestId: null,
  alt: false
};

export default memo(InputField);
