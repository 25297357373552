import React from 'react';
// Components
import Album360Content from './types/album360/Album360Content';
import ImageContent from './types/image/ImageContent';
import VideoContent from './types/video/VideoContent';
import { PdfViewer } from '@prompto-ui';
import Fullscreen360Viewer from './types/album360/Fullscreen360Viewer';

// Helpers
import fileCategories from 'config/SupportedFileCategories.json';

// Styles
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 100%;
  background: black;
  z-index: 10;
  border-radius: 20px;
  overflow: hidden;

  .react-transform-component {
    width: 100% !important;
    height: 100% !important;
  }

  .react-transform-element {
    width: 100% !important;
    height: 100% !important;
  }
`;

const Page = (props) => {
  const { data, videoFallbackThumbnail } = props;
  const { CustomNavigation, ...otherProps } = props;

  const sharedProps = {
    ...otherProps,
    key: data.objectId
  };

  // Content
  let content = <ImageContent {...sharedProps} />;
  switch (data.contentItemType) {
    default:
    case fileCategories.image: {
      content = <ImageContent {...sharedProps} />;
      break;
    }
    case fileCategories.video: {
      content = (
        <VideoContent
          {...sharedProps}
          fallbackThumbnail={videoFallbackThumbnail}
        />
      );
      break;
    }
    case fileCategories.document:
    case fileCategories.floorplan: {
      content = (
        <PdfViewer
          key={data.objectId}
          url={data.contentUri}
          objectId={data.objectId}
          onLoad={() => undefined}
          onToggleSidebar={() => undefined}
          fileTitle={data?.title?.textMap?.en}
        />
      );
      break;
    }
    case fileCategories.album360: {
      content = <Album360Content {...sharedProps} />;
      break;
    }
    case fileCategories.image360: {
      content = (
        <Fullscreen360Viewer
          key={data.objectId}
          image360ContentItem={data}
          hideBackButton={true}
          isEmbedded={true}
        />
      );
      break;
    }
  }

  return <Wrapper key={data.name}>{content}</Wrapper>;
};

export default Page;
