import React, { useState, useCallback, useEffect, useRef } from 'react';
import { array } from 'prop-types';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tippy from '@tippyjs/react';

// Helpers
import { capitalize } from 'helpers/util.js';

// Styling
import styled, { css } from 'styled-components';

const FoldoutWrapper = styled.div`
  position: relative;
`;

const ButtonWrapper = styled.div`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.grayWhiteOff};
  border-radius: 3px;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background: ${(props) => props.theme.gray300};
  }
`;

const ButtonIcon = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.primary300};
  flex-shrink: 0;
`;

const DropdownWrapper = styled.div`
  position: absolute;
  z-index: 10;
  width: max-content;
  top: ${({ size }) => size}px;
  right: 0;
  margin: 5px 0;
  background: ${(props) => props.theme.whitePure};
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.gray100};
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.4);
`;

const ActionWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.whiteOff : 'transparent'};
  ${({ isNoHover }) => {
    if (!isNoHover) {
      return css`
        &:hover {
          background-color: ${(props) => props.theme.whiteOff};
        }
      `;
    }
  }}
  &:not(:last-child) {
    border-bottom: ${({ isNoBorder, theme }) =>
      isNoBorder ? 'none' : `solid 1px ${theme.gray300}`};
  }
`;

const NoActionWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 5px;
  background-color: ${(props) => props.theme.gray100};
`;

const ActionIcon = styled(FontAwesomeIcon)`
  width: 10px !important;
  height: 10px !important;
  margin: 0 5px;
  color: ${(props) => props.theme.primary200};
`;

const NoActionIcon = styled(FontAwesomeIcon)`
  width: 10px !important;
  height: 10px !important;
  margin: 0 5px;
  color: ${(props) => props.theme.gray400};
`;

const ActionLabel = styled.p`
  font-size: 11px;
  margin: 0 5px;
  color: ${(props) => props.theme.primary200};
  user-select: none;
`;

const ActionPlaceholderLabel = styled(ActionLabel)`
  color: ${(props) => props.theme.primary100};
`;

const NoActionLabel = styled.p`
  font-size: 11px;
  margin: 0 5px;
  color: ${(props) => props.theme.gray400};
  user-select: none;
`;

const NoActionDescription = styled.div`
  width: 300px;
`;

const FoldoutButton = ({
  actions,
  className,
  size = 30,
  renderButton: RenderButton
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef();

  const onClickCallback = useCallback((e) => {
    e.stopPropagation();
    if (buttonRef.current && buttonRef.current.contains(e.target)) {
      return;
    }

    // Click outside of component
    setIsOpen(false);
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', onClickCallback);

    return () => {
      document.removeEventListener('mouseDown', onClickCallback);
    };
  }, [onClickCallback]);

  return (
    <div className={className}>
      <FoldoutWrapper ref={buttonRef}>
        {RenderButton ? (
          <RenderButton
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen((open) => !open);
            }}
          />
        ) : (
          <ButtonWrapper
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen((open) => !open);
            }}
            data-testid={'contextMenu'}
            size={size}
          >
            <ButtonIcon
              key="buttonIcon"
              icon={['far', 'ellipsis-h']}
              size="sm"
            />
          </ButtonWrapper>
        )}

        {isOpen && (
          <DropdownWrapper>
            {actions.map((action) => {
              if (action.isPlaceholder) {
                return (
                  <ActionWrapper
                    key={action.label}
                    onClick={(e) => e.stopPropagation()}
                    isNoBorder={action.isNoBorder}
                    isNoHover
                  >
                    {action.icon && (
                      <ActionIcon
                        key="actionIcon"
                        icon={['far', action.icon]}
                        size="sm"
                      />
                    )}
                    <ActionPlaceholderLabel>
                      {capitalize(action.label)}
                    </ActionPlaceholderLabel>
                  </ActionWrapper>
                );
              } else if (action && action.onClick) {
                return (
                  <ActionWrapper
                    key={action.label}
                    onClick={(e) => {
                      e.stopPropagation();
                      action.onClick();
                      setIsOpen(false);
                    }}
                    data-testid={action.dataTestId ?? 'contextMenuItem'}
                    isNoBorder={action.isNoBorder}
                    isActive={action.isActive}
                  >
                    {action.icon && (
                      <ActionIcon
                        key="actionIcon"
                        icon={['far', action.icon]}
                        size="sm"
                      />
                    )}
                    <ActionLabel>{capitalize(action.label)}</ActionLabel>
                  </ActionWrapper>
                );
              } else if (action) {
                // To have the action in the disabled state, don't provide an onClick action
                return (
                  <Tippy
                    theme="prompto"
                    placement="top"
                    content={
                      <NoActionDescription>
                        {action.noActionDescription}
                      </NoActionDescription>
                    }
                    arrow={true}
                  >
                    <NoActionWrapper key={action.label}>
                      {action.icon && (
                        <NoActionIcon
                          key="actionIcon"
                          icon={['far', action.icon]}
                          size="sm"
                        />
                      )}
                      <NoActionLabel>{capitalize(action.label)}</NoActionLabel>
                    </NoActionWrapper>
                  </Tippy>
                );
              } else {
                return null;
              }
            })}
          </DropdownWrapper>
        )}
      </FoldoutWrapper>
    </div>
  );
};

FoldoutButton.propTypes = {
  actions: array.isRequired
};

FoldoutButton.defaultProps = {};

export default FoldoutButton;
