import React from 'react';
import { number, string } from 'prop-types';

import Animation from 'resources/Logo_1.gif';

// Styling
import styled from 'styled-components';

const Wrapper = styled.div`
  width: ${({ useFullSize }) => (useFullSize ? '100%' : 'auto')};
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;

const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;

// Currently the prompto loader is the triangle option,
// but this could change in the future.
const PromptoLoader = ({
  width,
  height,
  className,
  dataTestId,
  useFullSize = true
}) => (
  <Wrapper
    data-testid={dataTestId}
    className={className}
    useFullSize={useFullSize}
  >
    <LoaderWrapper>
      <img src={Animation} width={width} height={height} alt="" />
    </LoaderWrapper>
  </Wrapper>
);

PromptoLoader.propTypes = {
  className: string,
  width: number,
  height: number,
  dataTestId: string,
  fontSize: number
};

PromptoLoader.defaultProps = {
  className: null,
  width: 80,
  height: 80,
  dataTestId: 'loader',
  fontSize: 0
};

export default PromptoLoader;
