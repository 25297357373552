const KB = 1024;
const MB = KB * 1024;
export default {
  categories: [
    {
      contentItemTypes: [
        {
          name: 'image360',
          extensions: ['.jpg', '.jpeg', '.png', '.webp'],
          limits: {
            maxSize: MB * 64, // 64mb image limit
            softMaxDimensionsPx: 80000000
          }
        },
        {
          name: 'image',
          extensions: ['.jpg', '.jpeg', '.png', '.webp'],
          limits: {
            maxSize: MB * 64, // 64mb image limit
            softMaxDimensionsPx: 80000000
          }
        },
        {
          name: 'video',
          extensions: ['.mp4', '.avi', '.webm'],
          limits: {
            maxSize: MB * 500 // 500mb video limit
          }
        },
        {
          name: 'album360',
          extensions: [],
          limits: {}
        },
        {
          name: 'iFrame',
          extensions: [],
          limits: {}
        }
      ],
      name: 'media'
    },
    {
      contentItemTypes: [
        {
          name: 'floorplan',
          extensions: ['.pdf'],
          limits: {
            maxSize: MB * 40 // 40mb pdf limit
          }
        },
        {
          name: 'document',
          extensions: ['.pdf'],
          limits: {
            maxSize: MB * 40 // 40mb pdf limit
          }
        }
      ],
      name: 'documents'
    }
  ]
};
