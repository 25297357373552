import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

// Components
import Notifications from './Notifications';
import NotificationCard from './NotificationCard';

// Helpers
import { motion, AnimatePresence } from 'framer-motion';
import localizer from 'localization/localizer';
import { Notification } from '@prompto-api';
import { updateLastTimeOpenedNotifications } from 'store/reducers/notificationReducer/NotificationsActions';

// Styling
import styled from 'styled-components';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  z-index: 40000;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  transition: left 0.2s ease;
  ${(props) => props.styles}
`;

const Modal = styled(motion.div)`
  position: absolute;
  left: 10px;
  top: 10px;
  height: calc(100% - 20px);
  max-height: calc(100% - 20px);
  background: ${({ theme }) => theme.whitePure};
  border-radius: 3px;
  box-shadow: 0 0 10px 0 rgba(87, 79, 107, 0.2);
  border: solid 1px ${({ theme }) => theme.gray300};
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  padding: 10px 20px;
  box-sizing: border-box;
  font-size: 1.25rem;
  line-height: 1.875rem;
  color: ${({ theme }) => theme.primary700};
`;

// const OpenButton = styled.button`
//   width: 100px;
//   height: 32px;
//   margin-left: 8px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border: none;
//   border-radius: 3px;
//   background-color: ${({ theme }) => theme.primary400};
//   cursor: pointer;
//   font-size: 0.75rem;
//   font-weight: 600;
//   color: ${({ theme }) => theme.whitePure};
// `;

// const OpenIcon = styled(FontAwesomeIcon)`
//   margin-right: 8px;
// `;

const Feed = styled.div`
  display: flex;
  flex-grow: 1;
  background: ${({ theme }) => theme.grayWhiteOff};
  max-height: calc(100% - 60px);
  overflow: hidden;
  flex-direction: column;
  position: relative;
`;

const ActivityFeed = ({
  styles,
  closeActivityFeed,
  sessionToken,
  user,
  setLastTimeOpened,
  history,
  projects
}) => {
  const L = localizer.notifications;

  // Effects
  // Once the feed is opened, update the lastTimeOpenedNotifications in backend
  // and save updated timestamp in Notifications store
  useEffect(() => {
    if (user?.objectId && sessionToken) {
      Notification.updateLastTimeOpenedTimestamp(user.objectId, sessionToken)
        .then((result) => {
          const lastTimeOpenedTimestamp =
            result.data?.lastTimeOpenedNotifications;
          if (lastTimeOpenedTimestamp) {
            setLastTimeOpened(lastTimeOpenedTimestamp);
          }
        })
        .catch(() =>
          console.log(
            'Could not update the lastTimeOpenedNotificationsTimestamp'
          )
        );
    }
  }, [user, sessionToken, setLastTimeOpened]);

  // Handlers
  const onCardClick = useCallback(
    () => closeActivityFeed(),
    [closeActivityFeed]
  );

  const renderNotification = useCallback(
    (props) => (
      <NotificationCard
        user={user}
        sessionToken={sessionToken}
        notification={props.notification}
        setDaySeparator={props.setDaySeparator}
        history={history}
        relatedProject={projects?.find(
          ({ objectId }) => objectId === props.notification.projectObjectId
        )}
        onCardClick={onCardClick}
      />
    ),
    [history, projects, onCardClick, user, sessionToken]
  );

  return (
    <Overlay styles={styles} onClick={closeActivityFeed}>
      <Modal
        initial={{ x: -650 }}
        animate={{ x: 0 }}
        transition={{ duration: 0.2, type: 'tween' }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Header>
          {L.activityFeed}
          {/* <OpenButton>
            <OpenIcon icon={['far', 'external-link']} />
            {capitalize(L.open)}
          </OpenButton> */}
        </Header>

        <Feed>
          <AnimatePresence>
            <Notifications renderItem={renderNotification} user={user} />
          </AnimatePresence>
        </Feed>
      </Modal>
    </Overlay>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  sessionToken: state.store.AuthReducer?.sessionToken,
  user: state.store.AuthReducer?.user,
  projects: state.store.ProjectListReducer?.projects
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch) => ({
  closeActivityFeed: () =>
    dispatch({ type: 'TOGGLE_NOTIFICATIONS_FEED', payload: false }),
  setLastTimeOpened: (timestamp) =>
    dispatch(updateLastTimeOpenedNotifications(timestamp))
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivityFeed);
