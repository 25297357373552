import { validActionCheck } from 'store/actionUtils';
import defaultPasswordState from 'store/reducers/passwordReducer/defaultPasswordState';

const PasswordReducers = (state = defaultPasswordState, action) => {
  if (validActionCheck(action)) {
    switch (action.type) {
      case 'RESET_PASSWORD_STATE': {
        return {
          ...defaultPasswordState
        };
      }
      case 'REQUEST_RESET_PASSWORD_PENDING': {
        return {
          ...state,
          requestResetData: {
            ...state.requestResetData,
            resetting: true,
            resetted: false
          },
          error: null
        };
      }
      case 'REQUEST_RESET_PASSWORD_REJECTED': {
        return {
          ...state,
          requestResetData: {
            ...state.requestResetData,
            resetting: false,
            resetted: false
          },
          error: action.payload
        };
      }
      case 'REQUEST_RESET_PASSWORD_FULFILLED': {
        return {
          ...state,
          ...action.payload,
          requestResetData: {
            ...state.requestResetData,
            resetting: false,
            resetted: true
          },
          error: null
        };
      }
      case 'RESET_PASSWORD_PENDING': {
        return {
          ...state,
          resetData: {
            resetting: true,
            resetted: false
          }
        };
      }
      case 'RESET_PASSWORD_REJECTED': {
        return {
          ...state,
          resetData: {
            resetting: false,
            resetted: false
          },
          error: action.payload
        };
      }
      case 'RESET_PASSWORD_FULFILLED': {
        return {
          ...state,
          ...action.payload,
          resetData: {
            resetting: false,
            resetted: true
          },
          error: null
        };
      }
      case 'AUTH_RESET':
      case 'AUTH_LOGOUT_REQUEST_PENDING': {
        return {
          ...defaultPasswordState
        };
      }
      default: {
        return state;
      }
    }
  }
  return state;
};

export default PasswordReducers;
