import { validActionCheck } from 'store/actionUtils';

const initialState = {
  relatedProjectId: '',
  sectionId: '',
  foldersToDownload: null,
  contentItemsList: null,
  downloading: false,
  doNotTrack: false,
};

const DownloadPortalMediaReducer = (state = initialState, action) => {
  if (validActionCheck(action)) {
    switch (action.type) {
      case 'INITIATE_DOWNLOAD_PORTAL_MEDIA':
        return {
          ...state,
          ...action.payload,
          downloading: true
        };
      case 'RESET_DOWNLOAD_PORTAL_MEDIA':
        return {
          ...initialState
        };
      default: {
        return state;
      }
    }
  }
  return state;
};

export default DownloadPortalMediaReducer;
