import React, { useState, useEffect, memo } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProjectCard from './ProjectCard';

// Helpers
import { motion, AnimatePresence } from 'framer-motion';
import { actionSource } from './notificationsConfig';
import localizer, { defaultLocale } from 'localization/localizer';
import format from 'date-fns/format';
import { enUS, fr, de, nl } from 'date-fns/esm/locale';
import isEqual from 'lodash.isequal';

// Styling
import styled from 'styled-components';
import { buildBody } from './helpers/NotificationHelper';
import { Notification } from '@prompto-api';

const Wrapper = styled(motion.div)`
  padding-left: 55px;
  background: ${({ theme, isRead }) =>
    isRead ? theme.grayWhiteOff : theme.whitePure};
  color: ${({ theme, isRead }) =>
    isRead ? theme.primary200 : theme.primary700};
  box-shadow: none;
  transition: background 0.2s ease;
  transform-origin: left;
  &:hover {
    background: rgba(99, 92, 247, 0.08);
    box-shadow: inset 2px 0 0 0 ${({ theme }) => theme.accentAlt300};
    cursor: ${({ relatesToActiveProject }) =>
      relatesToActiveProject ? 'pointer' : 'cursor'};
  }
`;

const Container = styled.div`
  position: relative;
  border-left: 2px dotted #dcdcdc;
  padding: 10px 40px 10px 36px;
  min-height: 80px;
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: -17px;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background: ${({ theme, isRead, bg }) => (isRead ? theme.gray400 : bg)};
  transition: backgrounf 180ms ease;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.whitePure};
  font-size: 0.875rem;
`;

const Attributes = styled.div`
  font-size: 0.75rem;
  line-height: 1rem;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const Type = styled.b`
  margin-right: auto;
  line-height: 1;
`;

const Project = styled.div`
  padding-right: 6px;
  position: relative;

  span {
    color: ${({ theme }) => theme.primary100};
    line-height: 1;
    text-align: right;
    display: block;
    max-width: 160px;
    overflow: hidden;
    word-break: break-word;

    &:hover {
      color: ${({ theme }) => theme.primary900};
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

const Time = styled.span`
  color: ${({ theme }) => theme.primary100};
  line-height: 1;
  text-align: left;
`;

const NotReadBadge = styled(motion.div)`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 5px;
  background: ${({ theme }) => theme.successColor};
  flex-shrink: 0;
  align-self: flex-start;
`;

const VDivider = styled.div`
  align-self: stretch;
  width: 1px;
  background: ${({ theme }) => theme.gray300};
  margin: 0 10px;
`;

const Content = styled.div`
  font-size: 1rem;
  line-height: 1.5;
  display: inline-block;
  position: relative;
  word-break: break-word;
`;

const dateLocales = {
  en: enUS,
  de,
  nl,
  fr
};

const calculateDate = (timestamp) => {
  const now = Date.now();

  const minute = 60 * 1000;
  const hour = 60 * minute;

  const diff = now - timestamp;
  let formattedDate = '';

  if (diff < minute) {
    formattedDate = localizer.time.now;
  } else if (diff < hour) {
    const minutesAgo = Math.floor(diff / minute);
    formattedDate = `${minutesAgo} ${localizer.time.minAgo}`;
  } else if (diff < hour * 2) {
    const hoursAgo = Math.floor(diff / hour);
    const minutesAgo = Math.floor((diff - hoursAgo * hour) / minute);
    formattedDate = `${minutesAgo} ${localizer.time.minAgo}`;
    if (hoursAgo > 0) {
      formattedDate = `${hoursAgo} ${localizer.time.hour} ${formattedDate}`;
    }
  } else if (diff < hour * 24) {
    const hoursAgo = Math.floor(diff / hour);
    formattedDate = `${hoursAgo} ${localizer.time.hoursAgo}`;
  } else {
    const rule = "kk':'mm',' dd MMM";
    formattedDate = format(timestamp, rule, {
      locale: dateLocales[defaultLocale]
    });
  }

  return formattedDate;
};

const NotificationCard = memo(
  ({
    notification,
    user,
    sessionToken,
    setDaySeparator,
    history,
    relatedProject,
    onCardClick,
    navigate,
    closeActivityFeed,
    onNotificationRead
  }) => {
    const {
      objectId,
      createdAt,
      notificationStatus,
      projectObjectId,
      promptoNotificationBody,
      actionReferedItemLabel,
      actionReferredItemObjectId,
      actionName
    } = notification;
    const { title, body } = promptoNotificationBody;

    const [createdAtFormatted, setCreatedAtFormatted] = useState('');
    const [showProjectPreview, setShowProjectPreview] = useState(false);

    // Effects
    useEffect(() => {
      setDaySeparator({
        id: objectId,
        date: format(createdAt, "iii',' dd MMM", {
          locale: dateLocales[defaultLocale]
        }),
        createdAt: createdAt + 1,
        type: 'day-separator'
      });
    }, [createdAt, setDaySeparator, objectId]);

    // refresh relative creation date-time
    useEffect(() => {
      setCreatedAtFormatted(calculateDate(createdAt));

      const interval = setInterval(() => {
        setCreatedAtFormatted(calculateDate(createdAt));
      }, 60 * 1000); // every minute

      return () => clearInterval(interval);
    }, [createdAt]);

    const appLang = localizer.getLanguage();

    const notificationConfig =
      actionSource[actionName] ?? actionSource[actionReferedItemLabel];
    const isRead = notificationStatus !== 'unread';

    const projectTitle =
      appLang === 'en'
        ? relatedProject?.title
        : relatedProject?.localizedTitle?.textMap?.[appLang] ??
          relatedProject?.title;

    const markAsRead = () => {
      Notification.markAsRead(
        {
          userId: user.objectId,
          notificationIdList: [notification.objectId]
        },
        sessionToken
      ).then((result) => {
        onNotificationRead(notification);
      });
    };

    const onNotificationClicked = () => {
      // Mark notification as read
      markAsRead();

      if (relatedProject) {
        // Ask parent to close modal
        onCardClick();

        // Navigate to the subject of the notification
        history.push(
          `/${localizer.getLanguage()}/project/${projectObjectId}/projectunits/units`,
          { unitObjectId: actionReferredItemObjectId }
        );
      }
    };

    return (
      <Wrapper
        initial={{ opacity: 0, x: -40 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -40 }}
        transition={{ duration: 0.15 }}
        isRead={isRead ? 1 : 0}
        onClick={onNotificationClicked}
        relatesToActiveProject={!!relatedProject}
      >
        <Container>
          <IconWrapper
            bg={notificationConfig?.badgeColor ?? 'gray'}
            isRead={isRead}
          >
            <Icon
              icon={notificationConfig?.badgeIcon ?? 'envelope'}
              size="1x"
            />
          </IconWrapper>
          <Attributes>
            <Type>{title[appLang] ?? title.en}</Type>

            {projectTitle && (
              <>
                <Project
                  onMouseEnter={() => setShowProjectPreview(true)}
                  onMouseLeave={() => setShowProjectPreview(false)}
                  onClick={(e) => {
                    e.stopPropagation();
                    const isOnClickedProjectPage =
                      window.location.pathname.includes(
                        `/project/${projectObjectId}`
                      );

                    if (isOnClickedProjectPage) {
                      closeActivityFeed();
                    } else {
                      closeActivityFeed();
                      navigate(
                        `/${appLang}/project/${projectObjectId}${window.location.search}`
                      );
                    }
                  }}
                >
                  <span>{projectTitle}</span>
                  <AnimatePresence>
                    {showProjectPreview && (
                      <ProjectCard project={relatedProject} />
                    )}
                  </AnimatePresence>
                </Project>
                <VDivider />
              </>
            )}

            <Time>{createdAtFormatted}</Time>
            <AnimatePresence>
              {!isRead && (
                <NotReadBadge
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  exit={{ scale: 0 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    markAsRead();
                  }}
                />
              )}
            </AnimatePresence>
          </Attributes>

          <Content>
            <p>{buildBody(body, appLang)}</p>
          </Content>
        </Container>
      </Wrapper>
    );
  },
  (prevProps, nextProps) => {
    let noUpdate = true;
    Object.entries(prevProps).forEach(([key]) => {
      if (['notification', 'history', 'relatedProject'].includes(key)) {
        if (!isEqual(prevProps[key], nextProps[key])) {
          noUpdate = false;
        }
      } else if (prevProps[key] !== nextProps[key]) {
        noUpdate = false;
      }
    });

    return noUpdate;
  }
);

/* istanbul ignore next */
const mapDispatchToProps = (dispatch) => ({
  navigate: (newPath) => dispatch(push(newPath)),
  closeActivityFeed: () =>
    dispatch({ type: 'TOGGLE_NOTIFICATIONS_FEED', payload: false }),
  onNotificationRead: (notification) =>
    dispatch({ type: 'MARK_NOTIFICATION_AS_READ', payload: { notification } })
});

export default connect(null, mapDispatchToProps)(NotificationCard);
