import React, { useState } from 'react';

// Components
import { Tooltip } from 'react-tippy';
import PdfBackgroundUrl from 'resources/backgrounds/pdf-background.png';

// Helpers
import env from 'environment';
import { computeCloudinaryThumbnail } from 'helpers/util';
import { shape, number, string, oneOfType } from 'prop-types';
import { isMobile } from 'react-device-detect';
import 'react-tippy/dist/tippy.css';

// Styles
import styled from 'styled-components';
import { styledRespondTo } from 'styles/mixins';

const PdfPlaceholder = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  object-content: cover;
`;

const MainWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const MainMobileWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.div`
  font-family: ${({ theme }) => theme.fonts.DMSans};
  color: ${({ theme }) => theme.gray450};
  font-size: 0.875rem;
  z-index: 1;

  ${styledRespondTo.sm`
    font-size: 1.125rem;
  `}
`;

const NoPreviewWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;
`;

const RATIO = 0.7; // standart A4 format ratio

const DocumentPreview = ({
  data,
  size,
  isVerticalLayout,
  onLoaded
}) => {
  const currentEnvironment = env();

  // calculate size
  let height = size;
  let width = height * RATIO;

  if (isVerticalLayout) {
    width = size;
    height = width / RATIO;
  }

  const [thumbnailError, setThumbnailError] = useState(false);

  if (!data) return null;

  const [cloudinaryImageUri] = useState(
    computeCloudinaryThumbnail(
      decodeURIComponent(data.contentUri),
      currentEnvironment.googleStorageBucketId,
      currentEnvironment.cloudinaryImageBaseUrl,
      'h_600,c_fill,dn_50'
    )
  );

  const fileName = data?.name ?? data?.title?.textMap?.en;
  const extension = fileName.split('.').pop();
  const isPdf = extension === 'pdf';

  // Rendering
  if (!isPdf) {
    return null;
  }

  let mainContent = (
    <NoPreviewWrapper>
      <PdfPlaceholder src={PdfBackgroundUrl} alt="pdfThumb" />
      <Title>PDF</Title>
    </NoPreviewWrapper>
  );

  let contentWrapper = <MainWrapper>{mainContent}</MainWrapper>;
  if (isMobile) {
    contentWrapper = <MainMobileWrapper>{mainContent}</MainMobileWrapper>;
  }

  return contentWrapper;
};

DocumentPreview.propTypes = {
  data: shape({}),
  pageWidth: number,
  size: oneOfType([number, string])
};

DocumentPreview.defaultProps = {
  data: {},
  pageWidth: null,
  size: null
};

export default DocumentPreview;
