import React from 'react';
import { func } from 'prop-types';
import { connect } from 'react-redux';

// Components
import Modal from 'components/modal/Modal';
import Button from 'components/button/Button';
import Divider from 'components/divider/Divider';

// Helpers
import localizer from 'localization/localizer';
import { support } from 'config/emails.json';
import { userLogoutAction } from 'store/reducers/authReducer/AuthActions';
import Cookies from 'universal-cookie';
import env from 'environment';

// Styling
import styled from 'styled-components';
import { styledRespondTo } from 'styles/mixins';

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 50px;
  max-width: 100%;

  ${styledRespondTo.md`
    max-width: 500px;
  `}
`;

const Title = styled.p`
  font-size: 1rem;
  font-weight: 600;
  color: ${({ theme }) => theme.errorColor};
  padding-bottom: 15px;
`;

const Subtitle = styled.p`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.primary200};
`;

const ActionButton = styled.div`
  display: flex;
  justify-content: center;

  > button > span {
    &:first-letter {
      text-transform: capitalize;
    }
  }
`;

const Description = styled.p`
  font-size: 0.6875rem;
  color: ${({ theme }) => theme.primary200};
`;

const Link = styled.a`
  font-size: 0.6875rem;
  color: ${({ theme }) => theme.accentAlt400};
`;

const InactiveUserModal = ({ handleLogout, AuthReducer }) => {
  const cookies = new Cookies();
  const serviceAccount360CookieName = `AroundMedia/${env().env}/sast`;

  return (
    <Modal
      open
      center
      showCloseIcon={false}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      onClose={() => {}}
      styles={{
        modal: {
          maxWidth: 500,
          height: 'fit-content'
        }
      }}
    >
      <ModalContent>
        <Title>{localizer.userInactiveTitle}</Title>
        <Subtitle>{localizer.userInactiveSubtitle}</Subtitle>
        <Divider marginSize={20} />
        <Description>
          {`${localizer.errorContactText} `}
          <Link href={`mailto:${support}`}>{support}</Link>
        </Description>
        <Divider marginSize={20} />
        <ActionButton>
          <Button
            label={localizer.backToLogin}
            type="default"
            onClickAction={() => {
              cookies.remove(serviceAccount360CookieName, {
                domain: env().applicationDomain,
                path: '/'
              });
              handleLogout(AuthReducer.sessionToken);
            }}
          />
        </ActionButton>
      </ModalContent>
    </Modal>
  );
};

InactiveUserModal.propTypes = {
  handleLogout: func
};

InactiveUserModal.defaultProps = {};

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  AuthReducer: state.store.AuthReducer,
  VaultReducers: state.store.VaultReducers,
  AppReducer: state.store.AppReducer,
  router: state.router
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch) => ({
  handleLogout: (sessionToken) => dispatch(userLogoutAction(sessionToken))
});

export default connect(mapStateToProps, mapDispatchToProps)(InactiveUserModal);
