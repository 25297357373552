import * as React from 'react';

function SvgPlayThinIcon(props) {
  return (
    <svg width={78} height={78} fill="none" {...props}>
      <path
        opacity={0.9}
        d="M39 77c20.987 0 38-17.013 38-38S59.987 1 39 1 1 18.013 1 39s17.013 38 38 38z"
        fill="#000"
        fillOpacity={0.05}
        stroke="#fff"
        strokeWidth={2}
      />
      <path
        opacity={0.9}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.1 36.657L31.603 25.132c-1.584-.936-4.01-.028-4.01 2.287v23.046c0 2.077 2.254 3.328 4.01 2.287l19.495-11.52c1.74-1.024 1.745-3.55 0-4.575zM30.251 49.883V28.006c0-.255.283-.415.504-.288l18.51 10.939a.332.332 0 010 .57l-18.51 10.944a.337.337 0 01-.504-.288z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgPlayThinIcon;
