import React, { useState } from 'react';

// Components
import ProjectStateLabel from 'components/other/ProjectStateLabel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
import { buildAssetURIWithOptions, makeAddressString } from 'helpers/util';
import { motion } from 'framer-motion';
import localizer from 'localization/localizer';

// Styling
import styled from 'styled-components';

const ProjectPreview = styled(motion.div)`
  position: absolute;
  z-index: 2;
  top: 25px;
  right: 0;
  border-radius: 3px;
  box-shadow: 0 0 10px 0 rgba(87, 79, 107, 0.2);
  border: solid 1px ${({ theme }) => theme.gray300};
  background: ${({ theme }) => theme.whitePure};
  height: 180px;
  width: 220px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${({ styles }) => styles}
`;

const ProjectThumbnail = styled(motion.img)`
  width: 100%;
  height: 112px;
  margin-bottom: 10px;
  object-fit: cover;
  object-position: center;
  border-radius: 2px;
`;
const ProjectThumbnailHQ = styled(ProjectThumbnail)`
  position: absolute;
  top: 10px;
  left: 10px;
  width: calc(100% - 20px);
`;

const ProjectAddress = styled.span`
  font-size: 0.75rem;
  line-height: 1rem;
  color: ${({ theme }) => theme.primary100};
  text-align: left;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-word;
  overflow: hidden;
`;

const ProjectTitle = styled(ProjectAddress)`
  font-size: 1rem;
  line-height: 1.5;
  color: ${({ theme }) => theme.primary700} !important;
  word-break: normal;
  margin-top: auto;
  margin-bottom: 5px;
`;

const NoImage = styled.div`
  width: 100%;
  min-height: 112px;
  margin-bottom: 10px;
  background: ${({ theme }) => theme.grayWhiteOff};
  display: flex;
`;
const ImageIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.gray300};
  margin: auto;
`;

const ProjectCard = ({ project, styles, withState = true }) => {
  const [HQThumbnailLoaded, setHQThumbnailLoaded] = useState(false);

  const appLang = localizer.getLanguage();

  const projectTitle =
    appLang === 'en'
      ? project?.title
      : project?.localizedTitle?.textMap?.[appLang] ?? project?.title;

  const projectImage = project?.thumbnailUri ? (
    <>
      <ProjectThumbnail
        src={buildAssetURIWithOptions('q=10:h=40', project?.thumbnailUri)}
      />
      <ProjectThumbnailHQ
        initial={{ opacity: 0 }}
        animate={{
          opacity: HQThumbnailLoaded ? 1 : 0
        }}
        src={buildAssetURIWithOptions('q=100:h=1100', project?.thumbnailUri)}
        onLoad={() => setHQThumbnailLoaded(true)}
      />
    </>
  ) : (
    <NoImage>
      <ImageIcon icon={['far', 'image']} size="3x" />
    </NoImage>
  );

  return (
    <ProjectPreview
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -10 }}
      styles={styles}
    >
      {projectImage}
      {withState && (
        <ProjectStateLabel
          state={project?.state}
          styles={`border-radius: 13px; top: 15px; left: 100%; transform: translateX(calc(-100% - 15px));`}
        />
      )}
      <ProjectTitle>{projectTitle}</ProjectTitle>
      <ProjectAddress>{makeAddressString(project?.address)}</ProjectAddress>
    </ProjectPreview>
  );
};

export default ProjectCard;
