import React, { useEffect, useState, useCallback } from 'react';

// Components
import ConfirmationModal from 'components/modals/ConfirmationModal';
import EmptyListIcon from 'components/icons/EmptyListIcon';
import Button from 'components/button/Button';
import ListToolbar from 'components/lists/ListToolbar';
import CardList from 'components/lists/CardList';
import UserCard from './UserCard';
import InviteUserModal from 'components/views/invite/InviteUserModal.js';
import PromptoLoader from 'components/loader/PromptoLoader';
import FoldoutButton from 'components/other/FoldoutButton.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AssignProjectsModal from './AssignProjectsModal';

// Helpers
import { AnimatePresence } from 'framer-motion';
import { Vault } from '@prompto-api';
import localizer from 'localization/localizer';
import env from 'environment';
import to from 'await-to-js';
import {
  getUsersFromRoleList,
  parseDateToDDMMYYYY,
  capitalize,
  nameSort,
  dateSort,
  vaultOperationIsAllowed
} from 'helpers/util';
import Tippy from '@tippyjs/react';
import { plans } from 'config/billing.json';
import { connect } from 'react-redux';
import { vaultCheckSubscriptionAction } from 'store/reducers/subscriptionReducer/SubscriptionActions';

// Styling
import styled from 'styled-components';
import UpsellIncentiveModal from 'components/modals/UpsellIncentiveModal';

const ContentWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FaqReferral = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row wrap;
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.gray300};
  background-color: #ffffff;
  padding: 10px 20px;
  margin-top: 10px;
`;

const FaqReferralText = styled.p`
  font-size: 12px;
  color: ${(props) => props.theme.primary200};
`;

const FaqReferralLink = styled.a`
  font-size: 12px;
  color: ${(props) => props.theme.accent};
`;

const YouAreHereLabel = styled.div`
  background: ${(props) => props.theme.primary400};
  border-radius: 4px;
  color: white;
  font-size: 12px;
  font-weight: 500;
  padding: 10px;
`;

const SuccessWrapper = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.successColor};
  border-radius: 3px;
  cursor: pointer;
`;

const SuccessIcon = styled(FontAwesomeIcon)`
  width: 30px !important;
  height: 30px !important;
  color: ${(props) => props.theme.whitePure};
  padding: 0 10px;
  flex-shrink: 0;
`;

const TippyContent = styled.div`
  width: 250px;
  word-wrap: normal;
  word-break: normal;
`;

const sortOptions = [
  { value: 'firstName', label: capitalize(localizer.firstName) },
  { value: 'lastName', label: capitalize(localizer.lastName) },
  { value: 'email', label: capitalize(localizer.email) },
  { value: 'status', label: capitalize(localizer.status) },
  {
    value: 'lastActive',
    label: capitalize(localizer.date)
  },
  { value: 'role', label: capitalize(localizer.roleSelectLabel) }
];

export const UsersPage = ({
  VaultReducers,
  AuthReducer,
  SubscriptionReducers,
  vaultCheckSubscription,
  leadsStateDispatch
}) => {
  const [sortValue, setSortValue] = useState(sortOptions[0]);
  const [isAscending, setIsAscending] = useState(true);
  const [searchValue, setSearchValue] = useState();

  const currentUserId = AuthReducer.user?.objectId;
  const [userList, setUserList] = useState([]);
  const [roles, setRoles] = useState([]);
  const [projectRoles, setProjectRoles] = useState([]);
  const [filteredUserList, setFilteredUserList] = useState();
  const [fetching, setFetching] = useState(false);
  const [inviting, setInviting] = useState(false);

  const [userToRemove, setUserToRemove] = useState();
  const [invitationToRemove, setInvitationToRemove] = useState();
  const [confirmationModalKey, setConfirmationModalKey] = useState(Date.now());

  const [processing, setProcessing] = useState(false);
  const [resendSuccess, setResendSuccess] = useState();

  const [assignProjectsTo, setAssignProjectsTo] = useState(null);

  const { vault } = VaultReducers;
  const vaultId = vault?.objectId;
  const { sessionToken, allowedVaultOperationDictionary } = AuthReducer;

  const [showUpsellModal, setShowUpsellModal] = useState(false);
  const { currentSubscription } = SubscriptionReducers;
  const { maxUserCount } = currentSubscription;

  const subscriptionPlanId =
    currentSubscription?.activeSubscriptionPlan?.objectId;

  // Profile
  const [vaultUserUpdate] = vaultOperationIsAllowed(
    allowedVaultOperationDictionary,
    vault.objectId,
    ['vaultUserUpdate']
  );

  const isEssentialPlan = [
    plans.essentialYearly,
    plans.essentialMonthly
  ].includes(subscriptionPlanId);

  useEffect(() => {
    leadsStateDispatch({ type: 'SET_VAULT_USERS', payload: userList });
  }, [userList, leadsStateDispatch]);

  useEffect(() => {
    if (vault.objectId && sessionToken) {
      vaultCheckSubscription(vault.objectId, sessionToken);
    }
  }, [vaultCheckSubscription, vault, sessionToken]);

  useEffect(() => {
    let newList = userList.slice().sort((a, b) => {
      switch (sortValue.value) {
        case 'lastActive':
          return dateSort(a.lastModifiedAt, b.lastModifiedAt, isAscending);
        case 'role':
          return nameSort(
            localizer[a.role] || a.role,
            localizer[b.role] || b.role,
            isAscending
          );
        case 'firstName':
        case 'lastName':
        case 'email':
        case 'status':
        default:
          return nameSort(
            a[sortValue.value]?.toUpperCase(),
            b[sortValue.value]?.toUpperCase(),
            isAscending
          );
      }
    });

    if (searchValue && searchValue !== '') {
      newList = newList.filter(
        (x) =>
          (x.firstName &&
            x.firstName?.toLowerCase().includes(searchValue?.toLowerCase())) ||
          (x.lastName &&
            x.lastName?.toLowerCase().includes(searchValue?.toLowerCase())) ||
          (x.email &&
            x.email?.toLowerCase().includes(searchValue?.toLowerCase())) ||
          (x.status &&
            x.status?.toLowerCase().includes(searchValue?.toLowerCase())) ||
          (x.lastModifiedAt &&
            parseDateToDDMMYYYY(x.lastModifiedAt).includes(searchValue)) ||
          (x.role && x.role?.toLowerCase().includes(searchValue?.toLowerCase()))
      );
    }

    setFilteredUserList(newList);
  }, [userList, searchValue, sortValue, isAscending]);

  const fetchAllUsersThatCanAccessVault = useCallback(() => {
    setFetching(true);
    Vault.getAllUsersThatCanAccess(vaultId, sessionToken).then((result) => {
      const userRoles = result.data.userDictionary;
      const invitationRoles = result.data.invitationDictionary;

      const newUsers = getUsersFromRoleList(userRoles);
      const newInvitations = getUsersFromRoleList(invitationRoles);

      setUserList([...newUsers, ...newInvitations]);

      setFetching(false);
    });
  }, [setFetching, vaultId, sessionToken]);

  const fetchAllowedPermissionRoles = useCallback(() => {
    Vault.getAllowedPermissionRoles(vaultId, sessionToken).then((result) => {
      setRoles(result.data.allowedPermissionRoleList);
      setProjectRoles(result.data.allowedProjectPermissionRoleList);
    });
  }, [vaultId, sessionToken]);

  useEffect(() => {
    // Get users
    fetchAllUsersThatCanAccessVault();

    // Get vaultPermissionRoles
    fetchAllowedPermissionRoles();
  }, [
    VaultReducers,
    AuthReducer,
    fetchAllUsersThatCanAccessVault,
    fetchAllowedPermissionRoles
  ]);

  useEffect(() => {
    if (resendSuccess) {
      setTimeout(() => {
        setResendSuccess(null);
      }, 2000);
    }
  });

  // Remove User
  const onRemoveUser = (user) => {
    setUserToRemove(user);
    setConfirmationModalKey(Date.now());
  };

  const onRemoveUserConfirmed = async () => {
    const [error, result] = await to(
      Vault.kickUser(vaultId, userToRemove.objectId, sessionToken)
    );

    if (error) {
      return { error: error.error };
    }

    const { successful } = result.data;
    if (!successful) {
      return {
        error: { code: 'genericError' }
      };
    }

    setUserList((list) =>
      list.filter((x) => x.objectId !== userToRemove.objectId)
    );
    setUserToRemove(null);
    setConfirmationModalKey(Date.now());

    return { error: null };
  };

  const onRemoveUserCancelled = () => {
    setUserToRemove(null);
    setConfirmationModalKey(Date.now());

    return { error: null };
  };

  // Remove Invitation
  const onRemoveInvitation = (invitation) => {
    setInvitationToRemove(invitation);
    setConfirmationModalKey(Date.now());
  };

  const onRemoveInvitationConfirmed = async () => {
    const [error, result] = await to(
      Vault.uninviteUser(vaultId, invitationToRemove.uuid, sessionToken)
    );
    if (error) {
      return { error: error?.error };
    }

    const { successful } = result.data;
    if (!successful) {
      return {
        error: { code: 'genericError' }
      };
    }

    setUserList((list) =>
      list.filter((x) => x.objectId !== invitationToRemove.objectId)
    );
    setInvitationToRemove(null);
    setConfirmationModalKey(Date.now());
    return { error: null };
  };

  const onRemoveInvitationCancelled = () => {
    setInvitationToRemove(null);
    setConfirmationModalKey(Date.now());
    return { error: null };
  };

  const toolbarProps = {
    sortOptions,
    initialSortOption: sortValue,
    initialIsAscending: true,
    sortFieldPrefix: localizer.sortBy,
    onChangeSearchField: (fieldName, value) => {
      setSearchValue(value);
    },
    onChangeSortField: (value) => {
      setSortValue(value);
    },
    onChangeSortOrderField: (value) => {
      setIsAscending(value);
    },
    dataTestId: 'toolbar'
  };

  const usersLimitNotReached =
    userList.length < maxUserCount || maxUserCount === -1;

  let button = (
    <Button
      key={'createButton'}
      onClickAction={() => {
        if (usersLimitNotReached) {
          setInviting(true);
        }
      }}
      type={'submit'}
      status={maxUserCount && usersLimitNotReached ? 'default' : 'disabled'}
      label={localizer.addMember}
      dataTestId="toolbarAddMember"
    />
  );

  // When the user has the permission, we add an "add member button
  if (vaultUserUpdate) {
    toolbarProps.actionFieldsRight = [
      usersLimitNotReached ? (
        button
      ) : (
        <Tippy
          key="tooltip"
          theme="prompto"
          placement="bottom"
          zIndex={999}
          arrow={true}
          content={
            <TippyContent>
              {localizer.formatString(
                localizer.maxNumberOfUsersTooltip,
                maxUserCount ?? 0
              )}
            </TippyContent>
          }
        >
          {button}
        </Tippy>
      )
    ];
  }

  let content;

  const onUpdateCard = async (userData, role) => {
    if (userData.type === 'User') {
      return await onUpdateUserRole(userData, role);
    } else {
      return await onUpdateInvitationRole(userData, role);
    }
  };

  const onUpdateUserRole = async (userData, role) => {
    if (!processing) {
      setProcessing(true);
      const callData = {
        permissionRoleObjectId: role.databaseEntry
      };

      const [error, result] = await to(
        Vault.updateUserPermissions(
          vaultId,
          userData.objectId,
          callData,
          sessionToken
        )
      );

      if (result) {
        setFilteredUserList((list) => {
          list.forEach((item) => {
            if (item.objectId === userData.objectId) {
              item.role = role.databaseEntry;
            }
          });
          return list;
        });
      }

      setProcessing(false);
      return [error, result];
    }
  };

  const onUpdateInvitationRole = async (userData, role) => {
    if (!processing) {
      setProcessing(true);
      const [error, result] = await to(
        Vault.inviteUser(
          vaultId,
          {
            email: userData.email,
            permissionRoleObjectId: role.databaseEntry
          },
          sessionToken,
          true
        )
      );

      if (result) {
        setFilteredUserList((list) => {
          list.forEach((item) => {
            if (item.objectId === userData.objectId) {
              item.role = role.databaseEntry;
            }
          });
          return list;
        });
      }

      setProcessing(false);
      return [error, result];
    }
  };

  const onResendInvitation = async (userData) => {
    if (!processing) {
      setProcessing(true);
      const vaultObjectId = VaultReducers.vault?.objectId;
      const sessionToken = AuthReducer.sessionToken;

      const result = await to(
        Vault.inviteUser(
          vaultObjectId,
          {
            email: userData.email
          },
          sessionToken
        )
      );

      setProcessing(false);
      setResendSuccess(userData);

      return result;
    }
  };

  const onUpdateProjects = async (userData, selectedProjects) => {
    if (userData.type === 'User') {
      return await onUpdateUserProjects(userData, selectedProjects);
    } else {
      return await onUpdateInvitationProjects(userData, selectedProjects);
    }
  };

  const onUpdateUserProjects = async (userData, selectedProjects) => {
    const callData = {
      projectObjectIdList: selectedProjects
    };

    const [error, result] = await to(
      Vault.updateUserPermissions(
        vaultId,
        userData.objectId,
        callData,
        sessionToken
      )
    );

    return [error, result];
  };

  const onUpdateInvitationProjects = async (userData, selectedProjects) => {
    const [error, result] = await to(
      Vault.inviteUser(
        vaultId,
        {
          email: userData.email,
          projectObjectIdList: selectedProjects
        },
        sessionToken,
        true
      )
    );

    return [error, result];
  };

  if (fetching) {
    content = (
      <ContentWrapper>
        <PromptoLoader />
      </ContentWrapper>
    );
  } else if (filteredUserList && filteredUserList.length > 0) {
    content = (
      <AnimatePresence>
        <CardList
          key="cardlist"
          fetching={fetching}
          onBottomReached={() => {}}
          dataList={filteredUserList}
          placeholderComponent={null}
          enableAnimation={true}
          renderItem={({ item }) => {
            let actionSection;
            if (
              resendSuccess?.uuid === item.uuid &&
              item.type === 'Invitation'
            ) {
              actionSection = (
                <SuccessWrapper>
                  <SuccessIcon icon={['far', 'check']} size="sm" />
                </SuccessWrapper>
              );
            } else {
              if (currentUserId === item.objectId) {
                actionSection = (
                  <YouAreHereLabel>{capitalize(localizer.you)}</YouAreHereLabel>
                );
              } else if (vaultUserUpdate) {
                // Actions
                const actions = [
                  {
                    label: capitalize(localizer.removeFromTeam),
                    icon: 'unlink',
                    onClick: () => {
                      if (item.type === 'User') {
                        onRemoveUser(item);
                      } else {
                        onRemoveInvitation(item);
                      }
                    }
                  }
                ];

                // Check to only allow assigning projects to users who are able to
                if (projectRoles.includes(item.role)) {
                  actions.unshift({
                    label: capitalize(localizer.assignToProjects),
                    icon: 'folder-plus',
                    onClick: () => {
                      setAssignProjectsTo(item);
                    }
                  });
                } else {
                  actions.unshift({
                    label: capitalize(localizer.assignToProjects),
                    icon: 'folder-plus',
                    noActionDescription: capitalize(
                      localizer.managerAndOwnerDescription
                    )
                  });
                }

                if (item.type === 'Invitation') {
                  actions.push({
                    label: capitalize(localizer.resendInvite),
                    icon: 'paper-plane',
                    onClick: () => {
                      onResendInvitation(item);
                    }
                  });
                }

                actionSection = <FoldoutButton actions={actions} />;
              }
            }

            return (
              <UserCard
                key={item.objectId}
                userData={item}
                isCurrentUser={currentUserId === item.objectId}
                roles={roles}
                onUpdateUserRole={onUpdateCard}
                actionSection={actionSection}
                vaultUserUpdate={vaultUserUpdate}
                isRoleEditable={!isEssentialPlan}
              />
            );
          }}
        />
      </AnimatePresence>
    );
  } else if (filteredUserList && filteredUserList.length === 0) {
    content = (
      <ContentWrapper key={'empty'}>
        <EmptyListIcon iconType={'file'} text={localizer.noUsers} />
      </ContentWrapper>
    );
  }

  return (
    <>
      <ListToolbar {...toolbarProps} />
      {content}
      <FaqReferral>
        <FaqReferralText>{localizer.userRoleFaqReferral}&nbsp;</FaqReferralText>
        <FaqReferralLink href={env().supportBaseLink} target={'_blank'}>
          {localizer.ourFaq}
        </FaqReferralLink>
        {'.'}
      </FaqReferral>
      <InviteUserModal
        inviting={inviting}
        usersAlreadyInVault={userList.filter(
          (userOrInvitation) => userOrInvitation.type === 'User'
        )}
        usersAlreadyInvitedToVault={userList.filter(
          (userOrInvitation) => userOrInvitation.type === 'Invitation'
        )}
        maxUserCount={maxUserCount}
        vaultObjectId={VaultReducers.vault?.objectId}
        sessionToken={AuthReducer.sessionToken}
        addInvitationAction={fetchAllUsersThatCanAccessVault}
        onClose={() => {
          setInviting(false);
        }}
        subscriptionPlanId={subscriptionPlanId}
      />
      <ConfirmationModal
        key={confirmationModalKey}
        open={!!userToRemove || !!invitationToRemove}
        onConfirmAction={
          userToRemove ? onRemoveUserConfirmed : onRemoveInvitationConfirmed
        }
        onCancelledAction={
          userToRemove ? onRemoveUserCancelled : onRemoveInvitationCancelled
        }
      />
      {assignProjectsTo !== null && (
        <AssignProjectsModal
          vaultObjectId={VaultReducers.vault?.objectId}
          sessionToken={AuthReducer.sessionToken}
          userData={assignProjectsTo}
          updateUserProjects={onUpdateProjects}
          onClose={() => {
            setAssignProjectsTo(null);
          }}
          AuthReducer={AuthReducer}
          vaultId={vaultId}
        />
      )}
      {showUpsellModal && (
        <UpsellIncentiveModal
          initiator="UserLimit"
          vaultId={VaultReducers.vault?.objectId}
          sessionToken={AuthReducer.sessionToken}
          onClose={() => {
            setShowUpsellModal(false);
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  AppReducer: state.store.AppReducer,
  AuthReducer: state.store.AuthReducer,
  VaultReducers: state.store.VaultReducers,
  SubscriptionReducers: state.store.SubscriptionReducers
});
/* istanbul ignore next */
const mapDispatchToProps = (dispatch) => ({
  vaultCheckSubscription: (vaultObjectId, sessionToken) => {
    dispatch(vaultCheckSubscriptionAction(vaultObjectId, sessionToken));
  },
  leadsStateDispatch: (action) => dispatch(action)
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
