export const VL_GET_VAULTS = 'VL_GET_VAULTS'
export const VL_ACCEPT_INVITATION = 'VL_ACCEPT_INVITATION'




export function handleGetVaultListResult(apiResult) {

  let handleResult = {
    type: VL_GET_VAULTS,
    apiResult: apiResult
  }
  return handleResult
}

export function handleAcceptInvitationResult(apiResult) {

  let handleResult = {
    type: VL_ACCEPT_INVITATION,
    apiResult: apiResult
  }
  return handleResult
}


