import React, { useState } from 'react';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
import { computeCloudinaryThumbnail } from 'helpers/util';
import env from 'environment';
import localizer from 'localization/localizer';

// Styling
import styled from 'styled-components';
import { styledRespondTo } from 'styles/mixins';

const PlayIcon = styled(FontAwesomeIcon)`
  font-size: 1.5rem;
  color: ${(props) => props.theme.whitePure};

  ${styledRespondTo.sm`
    font-size: 1.875rem;
  `}
`;

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  color: white;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PreviewContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const ThumbnailError = styled.p`
  z-index: 1;
  height: 15px;
  opacity: 0.75;
  font-size: 0.688rem;
  text-align: center;
  color: white;
  margin: 5px;
`;

const Thumbnail = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  ${({ error }) =>
    error &&
    `
    filter: blur(10px);
    -moz-filter: blur(10px);
    transform: scale(1.1);
  `}
`;

const VideoPreview = ({
  data,
  fallbackThumbnail
}) => {
  const currentEnvironment = env();

  const [thumbnailError, setThumbnailError] = useState(false);
  const [thumbnailUri, setThumbnailUri] = useState(
    computeCloudinaryThumbnail(
      decodeURIComponent(data.contentUri),
      currentEnvironment.googleStorageBucketId,
      currentEnvironment.cloudinaryVideoBaseUrl
    )
  );

  return (
    <Wrapper>
      <PreviewContainer>
        <PlayIcon icon={['far', 'play-circle']} size="1x" />
        {thumbnailError && <ThumbnailError>{localizer.collectionPage.noPreview}</ThumbnailError>}
      </PreviewContainer>
      <Thumbnail
        error={thumbnailError}
        src={thumbnailUri}
        alt="thumbnail"
        onError={() => {
          setThumbnailError(true);
          setThumbnailUri(fallbackThumbnail);
        }}
      />
    </Wrapper>
  );
};

export default VideoPreview;
