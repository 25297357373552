export const useDeeplinksAction = useDeeplinks => ({
  type: 'APP_USE_DEEPLINKS',
  payload: { useDeeplinks }
});

export const useMipimAction = useMipim => ({
  type: 'APP_USE_MIPIM',
  payload: { useMipim }
});

export const setAssimpVersionFilter = assimpVersionFilter => ({
  type: 'APP_SET_ASSIMP_VERSION_FILTER',
  payload: { assimpVersionFilter }
});
export const setUe4VersionFilter = ue4VersionFilter => ({
  type: 'APP_SET_UE4_VERSION_FILTER',
  payload: { ue4VersionFilter }
});
