export default {
  productId: 'Prompto',
  productId424: 'PromptoV2',
  maxExporterProductId: 'PromptoExporter3dsMax',
  sketchupExporterProductId: 'PromptoExporterSketchup',
  revitExporterProductId: 'PromptoExporterRevit',
  env: 'production',
  baseUrl: 'https://apiv2.prompto.com/v2',
  apiToken:
    'eyJhbGciOiJIUzUxMiJ9.eyJqdGkiOiIyODE3OTE2Zi05YzliLTRiZWQtOTljZS0xMjYzNjVhY2UwMDEiLCJpYXQiOjE0OTU0NDQyNjIsImV4cCI6MjEyNjE2NDI2MiwiYXVkIjoiYXBpIiwidmVyIjoiMS4wLjAiLCJzY29wZSI6IltcInB1YmxpY2FwaVwiXSJ9.j0ZBPCECPkmYJFlaGyPa5tYueL-btKPoJD6XSCPd8YMol469E59EA_Yy5mvW9moHl93scGuOAHy_afpJ15XB0g',
  baseImageUrl: 'https://imagecdn.prompto.com',
  cdnUrl: '',
  entityObjectId: 'aroundmedia',
  braintreeTokenizationKey: 'production_kfpzmtxx_q9y2pd3pqrhq9m96',
  applicationDomain: '.prompto.com',
  applicationUrl: 'app.prompto.com',
  sessionTokenRenewTimeout: 60 * 120 * 1000,

  googleStorageBucketId: 'assetsvxgi.vr-tual.media',
  googleMapsAPIKey: 'AIzaSyC__OLtuhUjX4al5F6humPLtvZxlnYiyoQ',

  trialSubscriptionPlanId: 'around-media-trial-custom',
  defaultAppExpiration: '20m',
  stripe: 'pk_live_iHL3y8Hs76Rc76GB6E5QI58A',
  zippopotamusAPI: 'https://api.zippopotam.us/',
  typeFormOrderProjectUrl: 'https://prompto.typeform.com/to/Y3aUSL',
  orderSceneDismissCookiePopupTimeout: 7 * 24 * 60 * 60 * 1000, //1 week
  orderSceneDismissCookieName: 'orderSceneMaybeLater',
  loggedOutCookieName: 'loggedOut',
  aroundMediaPortalUrl: 'https://360app.prompto.com',
  aroundMediaViewerlUrl: 'https://360.prompto.com',
  hubspotPortalId: '4943441',
  upsell360FormId: 'cb83198e-2a84-4898-85c0-a2aa4bb78ee1',
  upsellSessionTokenRenewThreshold: 4,
  essentialPackageInfo: 'https://prompto.com/subscriptions-essential/',
  defaultMaxProjectCount: '1',
  defaultMaxUserCount: '2',
  showcaseUrl: 'https://showcase.prompto.com',
  supportBaseLink: 'https://support.prompto.com',
  supportBillingPerUnitLink: 'https://support.prompto.com/billing-per-unit',
  supportPromptoPortalLink: 'https://support.prompto.com/prompto-portal',
  base360ImageUrl: 'https://imagecdn.around.media',
  base360Url: 'https://api.around.media/v1',
  termsOfService: 'https://promptogroup.com/terms-of-service/',
  privacyPolicy: 'https://promptogroup.com/privacy-policy-2/',
  apiToken360:
    'eyJhbGciOiJIUzUxMiJ9.eyJqdGkiOiI0NDRmMjBhZi04NWFiLTQzYWEtOTJkOC1lZTk1ZWIyYzVkM2QiLCJpYXQiOjE0Njk1MjAzMTMsImV4cCI6MjEwMDI0MDMxMywiYXVkIjoidmlld2VyIiwidmVyIjoiMS4wLjAiLCJzY29wZSI6IltcInB1YmxpY3ZpZXdlclwiXSJ9.DLrC1mSZGo0kl2eVPd5iJEpfFo8UBaiD6gGF-5pn9yec7XnN9QOvCe_NoiltCMjQaLK3Dy7F4j27oCXn-0sBBg',
  cloudinaryVideoBaseUrl: 'https://res.cloudinary.com/prompto/video/upload/',
  cloudinaryImageBaseUrl: 'https://res.cloudinary.com/prompto/image/upload/',
  cloudinaryCloudName: 'prompto',
  contractTestSigningCookieName: 'contractTestSigningCookie',
  appcueFlowWelcomeToPrompto: '-M5gKZ74LwcJvNfA-RUV',
  appcueFlowFirstProjectCreation: '47f26f52-5f82-4d0c-bf77-39a53486eb8e',
  appcueFlowUnitTypes: '36874fad-1be5-4548-ac8c-c914b359b5bc',
  appcueFlowFieldBuilder: '784b606c-629a-49d5-b6d0-f6768963ee2c',
  productLedRegistrationHubspotPortalId: '4943441',
  productLedRegistrationHubspotFormId: '2cc12361-7323-4a0b-86bd-073d2ebb1e40',
  howToSetupUnitTypes:
    'https://support.prompto.com/billing-per-unit#per-unit-billing-model',
  howToImportUnitsFromCSV:
    'https://support.prompto.com/how-to-import-units-to-the-showcase-from-csv-file',
  firebaseApiKey: 'AIzaSyC__OLtuhUjX4al5F6humPLtvZxlnYiyoQ',
  firebaseAuthDomain: 'vrtual-media-api-v2.firebaseapp.com',
  firebaseProjectId: 'vrtual-media-api-v2',
  firebaseStorageBucket: 'vrtual-media-api-v2.appspot.com',
  firebaseMessagingSenderId: '777643864247',
  firebaseAppId: '1:777643864247:web:12aa34c8e72c5b36b65d32',
  firebaseMeasurementId: 'G-C3EJLVG9RK'
};
