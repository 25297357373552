import React, { Fragment } from 'react';
import { string, bool, number } from 'prop-types';

const Divider = ({
  color,
  opacity,
  thickness,
  marginSize,
  isVertical,
  lineStyle,
  className,
  dataTestId,
  styles
}) => {
  let result;

  if (lineStyle && lineStyle !== 'solid') {
    let width = isVertical ? `${marginSize}px` : 'auto';
    let height = isVertical ? '100%' : `${marginSize}px`;
    let borderRightWidth = isVertical ? `${thickness}px` : '0';
    let borderBottomWidth = isVertical ? '0' : `${thickness}px`;

    result = (
      <Fragment>
        <div
          style={{
            width: width,
            height: height,
            margin: '0',
            border: `0px ${color} ${lineStyle}`,
            borderBottomWidth: borderBottomWidth,
            borderRightWidth: borderRightWidth,
            opacity: opacity
          }}
        />
        <div
          style={{
            width: width,
            height: height,
            margin: '0'
          }}
        />
      </Fragment>
    );
  } else {
    let width = isVertical ? `${thickness}px` : 'auto';
    let height = isVertical ? '100%' : `${thickness}px`;
    let margin = isVertical ? `0 ${marginSize}px` : `${marginSize}px 0`;

    result = (
      <div
        style={{
          height: height,
          width: width,
          margin: margin,
          background: color,
          opacity: opacity
        }}
      />
    );
  }

  return (
    <div className={className} data-testid={dataTestId} style={styles}>
      {result}
    </div>
  );
};

Divider.propTypes = {
  /** color for the divider */
  color: string,
  /** opacity for the divider */
  opacity: number,
  /** thickness for the divider */
  thickness: number,
  /** margin size (top and bottom) */
  marginSize: number,
  /** whether it needs to be vertical or not */
  isVertical: bool,
  /** the border style for this divider */
  lineStyle: string,
  /** classname for this component */
  className: string,
  /** Data test id for automated testing */
  dataTestId: string
};

Divider.defaultProps = {
  color: '#f3f4f4',
  opacity: 1,
  thickness: 2,
  marginSize: 10,
  isVertical: false,
  lineStyle: 'solid',
  dataTestId: null
};

export default Divider;
