// These are all the AUTHENTICATION related Actions

// Import API Calls here
import { User, Tracking, Notification, Vault } from '@prompto-api';

import to from 'await-to-js';

import { updateLastTimeOpenedNotifications } from 'store/reducers/notificationReducer/NotificationsActions';
import { checkForCachedProjectsAction } from 'store/reducers/projectListReducer/ProjectListActions';

// = ACTIONS =
export const userLoginAction = (
  usernameOrEmail,
  password,
  loginType = 'LOGIN',
  firebaseToken
) => {
  return (dispatch) => {
    dispatch({ type: `AUTH_${loginType}_REQUEST_PENDING` });

    const loginPromise = firebaseToken
      ? User.loginOAuth({ firebaseToken, entityId: 'aroundmedia' })
      : User.login(
          { loadVaultDictionarySets: false },
          { entityObjectId: 'aroundmedia', usernameOrEmail, password }
        );

    return loginPromise
      .then(async (res) => {
        const { user, sessionToken, sessionObjectId } = res.data;
        const vaultId = user.vaultList?.[0]?.objectId;

        // Set vault basic data
        dispatch({
          type: 'SET_VAULT_INITIAL_INFO',
          payload: { objectId: vaultId }
        });

        // Since project list page is a default page, we track the 'loadProjectList' action when:
        // - login succeeded (redirect to project list)
        // - project list menu tab is clicked
        if (!user.serviceAccount) {
          Tracking.trackUserSimpleAction(
            {
              sessionId: sessionObjectId,
              action: 'loadProjectList'
            },
            sessionToken
          ).catch(() => {});
        }

        let lastTimeOpenedNotificationsTimestamp = 0;
        if (user && sessionToken) {
          const [, response] = await to(
            Notification.getLastTimeOpenedTimestamp(user.objectId, sessionToken)
          );

          if (response?.data?.lastTimeOpenedNotifications) {
            lastTimeOpenedNotificationsTimestamp =
              response.data.lastTimeOpenedNotifications;
          }

          let lastTimeOpenedUpdates;
          if (response?.data?.lastTimeOpenedUpdates) {
            lastTimeOpenedUpdates = response.data.lastTimeOpenedUpdates;
          } else {
            // If user never opened the updates panel let's show them all the updates :) we can add default timestamp on product led users if needed
            lastTimeOpenedUpdates = 0;
          }

          dispatch({
            type: 'SET_LAST_TIME_OPENED_UPDATES',
            payload: lastTimeOpenedUpdates
          });

          dispatch({
            type: `AUTH_${loginType}_REQUEST_FULFILLED`,
            payload: res.data
          });

          // once used is logged in get all vault dictionaries
          if (vaultId) {
            dispatch(
              getVaultUserPermissionsAction(
                vaultId,
                user.objectId,
                sessionToken
              )
            );
          }

          // get projects right after user is logged in
          if (vaultId && sessionToken) {
            dispatch(checkForCachedProjectsAction(vaultId, sessionToken));
          }
        } else {
          dispatch({ type: `AUTH_${loginType}_REQUEST_REJECTED` });
        }

        dispatch(
          updateLastTimeOpenedNotifications(
            lastTimeOpenedNotificationsTimestamp
          )
        );

        return { successful: true };
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: `AUTH_${loginType}_REQUEST_REJECTED`,
          payload: { statusCode: error.response.status }
        });
      });
  };
};

export const getVaultUserPermissionsAction =
  (vaultId, userId, sessionToken) => (dispatch) => {
    dispatch({ type: 'GET_VAULT_DICTIONARIES_PENDING' });
    Vault.getUserPermissions(vaultId, userId, sessionToken)
      .then((response) => {
        const dictionaries = {};
        const thisVaultPermissions = {};
        if (response.data?.operationList) {
          dictionaries.allowedVaultOperationDictionary = {
            [vaultId]: response.data.operationList
          };
          thisVaultPermissions.allowedOperations = response.data.operationList;
        }
        if (response.data?.featureList) {
          dictionaries.includedFeatureDictionary = {
            [vaultId]: response.data.featureList
          };
          thisVaultPermissions.allowedFeatures = response.data.featureList;
        }

        dispatch({
          type: 'AUTH_GET_VAULT_DICTIONARIES_SUCCESS',
          payload: dictionaries
        });

        dispatch({
          type: 'SET_THIS_VAULT_PERMISSIONS',
          payload: thisVaultPermissions
        });
      })
      .catch((error) => {
        dispatch({ type: 'GET_VAULT_DICTIONARIES_REJECTED' });
        dispatch({
          type: 'AUTH_LOGIN_REQUEST_REJECTED',
          payload: { statusCode: error.response.status }
        });
      });
  };

export const storeLoginInfo = (usernameOrEmail, password) => ({
  type: 'AUTH_STORE_LOGIN_INFO',
  payload: { usernameOrEmail, password }
});

export const userReloginAction = (usernameOrEmail, password) => ({
  type: 'AUTH_RELOGIN_REQUEST',
  payload: User.login(
    { loadVaultDictionarySets: false },
    {
      entityObjectId: 'aroundmedia',
      usernameOrEmail,
      password
    }
  )
});

export const userLogoutAction = (sessionToken) => (dispatch) => {
  dispatch({ type: 'RESET_NOTIFICATIONS' });

  dispatch({ type: 'AUTH_LOGOUT_REQUEST_PENDING' });

  User.logout(sessionToken).catch(() =>
    console.error('ERROR WHILE LOGGING OUT')
  );
};

export const resetAuthAction = () => ({
  type: 'AUTH_RESET',
  payload: {}
});

export const userUpdateAction = (callData, userObjectId, sessionToken) => ({
  type: 'AUTH_UPDATE_REQUEST',
  payload: User.update(userObjectId, callData, sessionToken)
});
