// These are all the SUBSCRIPTION related Actions

// Import API Calls here
import { Vault } from '@prompto-api';
import { push } from 'react-router-redux';
import localizer from 'localization/localizer';

// = ACTIONS =
export function vaultCheckSubscriptionAction(
  vaultObjectId,
  sessionToken,
  onError
) {
  return {
    type: 'CHECK_SUBSCRIPTION_REQUEST',
    payload: Vault.checkSubscription(vaultObjectId, sessionToken).catch(
      (err) => {
        if (onError) {
          onError(sessionToken);
        }
        push(`/${localizer.getLanguage()}/login${window.location.search}`);
      }
    )
  };
}
