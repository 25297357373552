import React from 'react';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';

// Helpers
import localizer from 'localization/localizer';

// Styles
import styled from 'styled-components';
import { styledRespondTo } from 'styles/mixins';

const Wrapper = styled(motion.div)`
  font-family: ${({ theme }) => theme.fonts.DMSans};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: ${({ theme }) => theme.beigeBg10};
  flex-grow: 1;
`;

const Header = styled.h1`
  color: ${({ theme }) => theme.showcaseBlack};
  font-size: 1.25rem;
  font-weight: normal;
  margin-bottom: 12px;

  ${styledRespondTo.sm`
    font-size: 1.5rem;
    margin-bottom: 24px;
  `}
`;

const Description = styled.article`
  color: ${({ theme }) => theme.gray600};
  font-size: 0.75rem;

  ${styledRespondTo.sm`
    font-size: 1rem;
  `}
`;

const LinkIcon = styled(FontAwesomeIcon)`
  font-size: 3rem;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.gray350};

  ${styledRespondTo.sm`
    margin-bottom: 24px;
    font-size: 3.75rem;
  `}
`;

const ResourcesPlaceholder = () => {
  return (
    <Wrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <LinkIcon icon={['far', 'unlink']} size="1x" />
      <Header>{localizer.collectionPage.linkIsNotActive}</Header>
      <Description>{localizer.collectionPage.linkIsNotActiveDescription}</Description>
    </Wrapper>
  );
};

export default ResourcesPlaceholder;
