import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

// Components
import SidebarIconButton from 'helpers/PageSidebar/SidebarIconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import VaultMenuCard from './VaultMenuCard';
import CurrentVaultMenuCard from './CurrentVaultMenuCard';
import VaultMenuInvitationCard from './VaultMenuInvitationCard';
import Tippy from '@tippyjs/react';

// Helpers
import { User, PromptoSession } from '@prompto-api';
import {
  handleGetVaultListResult,
  handleAcceptInvitationResult
} from 'store/reducers/vaultListReducer/VaultListActions';
import { vaultInfoAction } from 'store/reducers/vaultListReducer/VaultActions';
import {
  userLoginAction,
  resetAuthAction
} from 'store/reducers/authReducer/AuthActions';
import { getVaultList } from 'store/reducers/vaultListReducer/VaultListApi';
import { buildAssetURIWithOptions, capitalize } from 'helpers/util';
import localizer from 'localization/localizer';
import { motion } from 'framer-motion';

// Styling
import styled from 'styled-components';
import { respondTo, desktop, desktopHd } from 'styles/mixins';

const Container = styled.div`
  width: 100%;
  max-height: 85vh;
  overflow: auto;
`;

const Header = styled.div`
  padding: 15px;
  background: ${(props) => props.theme.accentAlt700};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  position: sticky;
  z-index: 10;
  top: 0;
`;

const Body = styled.div`
  padding: 15px;
`;

const SidebarButton = styled(SidebarIconButton)`
  margin-bottom: 0;
  padding: 0 15px;
  margin-right: 5px;

  ${respondTo([desktop, desktopHd])} {
    width: 100%;
    margin-bottom: 5px;
    margin-right: 0;
    justify-content: left;
  }
`;

const LogoutButton = styled(motion.div)`
  margin-left: auto;
  margin-right: 15px;
  height: 30px;
  width: fit-content;
  min-width: 120px;
  display: flex;
  flex-flow: row;
  color: ${(props) => props.theme.primary100};
  align-items: center;
  justify-content: flex-end;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

const LogoutText = styled.div`
  margin-right: 10px;
`;

const Title = styled.div`
  font-size: 0.875rem;
  color: ${(props) => props.theme.primary900};
  margin-bottom: 15px;
`;

const NoCompaniesMessage = styled(Title)`
  margin: 20px 0px;
  color: ${(props) => props.theme.gray400};
  align-items: center;
  text-align: center;
  word-wrap: normal !important;
  word-break: normal !important;
`;

const LoaderContainer = styled.div`
  width: 100%;
  padding: 35px;
  display: flex;
  justify-content: center;
  font-size: 1.25rem;
`;

const Loader = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.primary900};
`;

const SwitchingLoader = styled(Loader)`
  margin-right: 10px;
`;

const SwitchingVaultMessage = styled.div`
  font-size: 0.875rem;
  align-items: center;
  margin: 20px 0px;
  justify-content: center;
  color: ${(props) => props.theme.primary900};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const ErrorMessageContainer = styled.div`
  display: flex;
  flex-flow: row;
  font-size: 0.875rem;
  align-items: center;
  margin: 20px 0px;
  justify-content: center;
  color: ${(props) => props.theme.primary900};
`;

const ErrorIcon = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.warning};
  margin-right: 10px;
`;

const ErrorMessage = styled.div`
  word-wrap: normal !important;
  word-break: normal !important;
  text-align: center;
`;

const logoOptions = 'mr=128,128';

const getVaultLogos = (vaultList) => {
  const newList = vaultList.map((vault) => {
    const logoUri = vault?.metadata?.logoUri || null;
    if (logoUri) {
      const uri = buildAssetURIWithOptions(logoOptions, logoUri);
      vault.logoUri = uri;
    }
    return vault;
  });

  return newList;
};

const getInvitationLogos = (invitationList) => {
  const newList = invitationList.map((invitation) => {
    const logoUri =
      invitation?.publicVaultInfo?.vault?.metadata?.logoUri || null;
    if (logoUri) {
      const uri = buildAssetURIWithOptions(logoOptions, logoUri);
      invitation.publicVaultInfo.vault.logoUri = uri;
    }
    return invitation;
  });

  return newList;
};

export const VaultMenu = ({
  VaultListReducer,
  AuthReducer,
  reLogin,
  handleGetVaultList,
  handleLogout,
  resetAuth,
  vault,
  onTabClicked
}) => {
  const [vaultList, setVaultList] = useState([]);
  const [invitationList, setInvitationList] = useState([]);

  const [switchingVault, setSwitchingVault] = useState();
  const [switchError, setSwitchError] = useState(false);
  const [redirectTimer, setRedirectTimer] = useState(4);
  const tippyRef = useRef();

  //try to get the authenticated user until we succeed
  useEffect(() => {
    let sessionToken = AuthReducer?.sessionToken;
    if (sessionToken !== undefined) {
      getVaultList(sessionToken, handleGetVaultList);
    }
  }, [AuthReducer, handleGetVaultList]);

  useEffect(() => {
    if (VaultListReducer?.vaultList && VaultListReducer?.vaultList.length > 0) {
      let newVaults = getVaultLogos(VaultListReducer?.vaultList);

      newVaults = [
        ...newVaults.filter((v) => v?.objectId === vault?.objectId),
        ...newVaults.filter((v) => v?.objectId !== vault?.objectId)
      ];

      setVaultList(newVaults);
    }
    if (VaultListReducer?.invitationList) {
      const newInvitations = getInvitationLogos(
        VaultListReducer?.invitationList
      );

      setInvitationList(newInvitations);
    }
  }, [VaultListReducer, vault]);

  const loadedButton = (
    <div>
      <SidebarButton
        key="logout"
        iconKey="SidebarIconButton_Workspaces"
        iconComponent={<FontAwesomeIcon icon={['far', 'user']} size="sm" />}
        name={localizer.workspaces}
        clickHandler={() => onTabClicked()}
      />
    </div>
  );

  const vaultsSection = vaultList
    .filter((n) => n?.objectId && n?.objectId !== vault?.objectId)
    .map((v) => (
      <VaultMenuCard
        key={`vault-menu-card-${v?.objectId}`}
        vault={v}
        onChangeVault={(vaultId) => {
          setSwitchingVault(v);
          // Set the default vault in the backend
          User.setDefaultVault(
            AuthReducer.user.objectId,
            { vaultObjectId: vaultId },
            AuthReducer.sessionToken
          ).then((result) => {
            if (result) {
              reLogin(AuthReducer.usernameOrEmail, AuthReducer.password)
                .then(() => {
                  setSwitchingVault();
                  tippyRef.current._tippy.hide();
                })
                .catch((e) => {
                  setSwitchError(true);
                  setInterval(() => {
                    setRedirectTimer((current) => current - 1);
                  }, 1000);
                  setTimeout(() => {
                    resetAuth();
                  }, 4000);
                });
            }
          });
        }}
      />
    ));

  const invitationsSection = invitationList.map((invitation, index) => (
    <VaultMenuInvitationCard
      key={`invitation${index}`}
      invitation={invitation}
      onHideVaultMenu={() => {
        tippyRef.current._tippy.hide();
      }}
      onShowVaultMenu={() => {
        tippyRef.current._tippy.show();
      }}
      sessionToken={AuthReducer?.sessionToken}
      onRemoveInvitation={(uuid) => {
        setInvitationList((current) => [
          ...current.filter((invitation) => invitation.invitation.uuid !== uuid)
        ]);
      }}
      onAcceptedInvitation={(newVaultList, uuid) => {
        const newVaults = getVaultLogos(newVaultList);
        setVaultList(newVaults);

        setInvitationList((current) => [
          ...current.filter((invitation) => invitation.invitation.uuid !== uuid)
        ]);
      }}
    />
  ));

  let menuBody = (
    <>
      {invitationList.length > 0 || vaultList?.length > 1 ? (
        <Title>{localizer.switchToDifferentWorkspace}</Title>
      ) : (
        <NoCompaniesMessage>{localizer.whenYouAreInvited}</NoCompaniesMessage>
      )}
      {invitationList.length > 0 && invitationsSection}
      {vaultsSection}
    </>
  );

  if (switchingVault) {
    menuBody = (
      <SwitchingVaultMessage>
        <SwitchingLoader icon={['far', 'spinner']} pulse size="1x" />
        {localizer.formatString(
          localizer.switchingToCompany,
          switchingVault.name
        )}
      </SwitchingVaultMessage>
    );
  }

  if (switchError) {
    menuBody = (
      <ErrorMessageContainer>
        <ErrorIcon icon={['far', 'exclamation-triangle']} size="1x" />
        <ErrorMessage>
          {localizer.formatString(
            localizer.failedToSwitch,
            switchingVault?.name || '',
            redirectTimer
          )}
        </ErrorMessage>
      </ErrorMessageContainer>
    );
  }

  return (
    <div>
      <Tippy
        key="tooltip"
        ref={tippyRef}
        theme="vaultMenu"
        placement="right"
        trigger="click"
        arrow={false}
        zIndex={40100}
        content={
          <>
            {vaultList?.length > 0 ? (
              <Container>
                <Header>
                  <CurrentVaultMenuCard
                    key={`currentVault${vaultList[0]?.objectId}`}
                    vault={vaultList[0]}
                    isCurrentVault={true}
                  />
                </Header>
                <Body>{menuBody}</Body>
              </Container>
            ) : (
              <LoaderContainer>
                <Loader icon={['far', 'spinner']} pulse size="1x" />
              </LoaderContainer>
            )}

            <LogoutButton
              initial={{ opacity: 0, x: 15 }}
              animate={{
                opacity: switchingVault ? 0 : 1,
                x: switchingVault ? 15 : 0
              }}
              onClick={(event) => {
                if (switchingVault) return;

                PromptoSession.updateEndTimestamp({
                  sessionId: AuthReducer.sessionObjectId
                }).catch(() => {
                  console.warn('Could not update the end session timestamp');
                });

                handleLogout(event);
              }}
              id="button_logout"
              disabled={switchingVault}
            >
              <LogoutText>{capitalize(localizer.logout)}</LogoutText>
              <FontAwesomeIcon icon={['far', 'sign-out-alt']} size="sm" />
            </LogoutButton>
          </>
        }
        hideOnClick={true}
        interactive={true}
        offset={[20, 20]}
      >
        {loadedButton}
      </Tippy>
    </div>
  );
};

const mapStateToProps = (state) => ({
  VaultListReducer: state.store.VaultListReducer,
  AuthReducer: state.store.AuthReducer
});

const mapDispatchToProps = (dispatch) => ({
  handleGetVaultList: (apiResult) =>
    dispatch(handleGetVaultListResult(apiResult)),
  handleAcceptInvitation: (apiResult) =>
    dispatch(handleAcceptInvitationResult(apiResult)),
  vaultInfo: (stringParams, vaultObjectId, sessionToken) =>
    dispatch(vaultInfoAction(stringParams, vaultObjectId, sessionToken)),
  reLogin: (username, password) =>
    dispatch(userLoginAction(username, password, 'RELOGIN')),
  resetAuth: () => dispatch(resetAuthAction()),
  onTabClicked: () => {
    dispatch({ type: 'TOGGLE_NOTIFICATIONS_FEED', payload: false });
    dispatch({ type: 'TOGGLE_UPDATES_FEED', payload: false });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(VaultMenu);
