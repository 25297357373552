import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Vault } from '@prompto-api';
import { string, func } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import to from 'await-to-js';
import { plans } from 'config/billing.json';

// App Language
import localizer from 'localization/localizer';
import Button from 'components/button/Button';
import styled from 'styled-components';
import AssignProjectsCard from './AssignProjectsCard';
import InviteEmailAndRoleCard from './InviteEmailAndRoleCard';

// Styling

const UserForm = styled.div`
  width: 400px;
  margin-left: auto;
  margin-right: auto;
`;

const Inviting = styled.div`
  text-align: center;
  margin: auto;
`;

const Title = styled.p`
  margin-top: 0;
  margin-bottom: 50px;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: ${(props) => props.theme.primary400};
`;

const OwnerInfo = styled.div`
  margin-top: 10px;
  font-size: 13px;
  line-height: 1.45;
  color: ${(props) => props.theme.primary200};
  flex-grow: 1;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 45px;
`;

const InviteButton = styled(Button)`
  margin: 0;
`;

const CancelButton = styled(Button)`
  margin: 0;
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 7px;
`;

const AddButton = styled.div`
  font-size: 11px;
  margin-top: 6px;
  border: none !important;
  color: ${(props) => props.theme.accentAlt300};
  cursor: pointer;
`;

const ErrorMessage = styled.div`
  color: ${(props) => props.theme.errorColor};
  font-size: 12px;
  margin-top: 16px;
  text-align: right;
`;

/**
 * Invite new users to the vault
 * @param {{usersAlreadyInVault: Object[], usersAlreadyInvitedToVault: Object[]}}} props
 */
const InviteUserForm = ({
  vaultObjectId,
  sessionToken,
  addInvitationAction,
  onInvitingStart,
  onInvitingEnd,
  usersAlreadyInVault,
  usersAlreadyInvitedToVault,
  maxUserCount,
  subscriptionPlanId,
}) => {
  const [roles, setRoles] = useState([]);
  const [projectRoles, setProjectRoles] = useState([]);
  const [projectSelectionEnabled, setProjectSelectionEnabled] = useState(false);

  const [isSendingInvite, setIsSendingInvite] = useState(false);
  const [invitationError, setInvitationError] = useState();
  const [inviteSuccess, setInviteSuccess] = useState(false);
  const [emailsSent, setEmailsSent] = useState([]);

  const [selectedProjects, setSelectedProjects] = useState([]);

  const [invitations, setInvitations] = useState([]);
  const [addButtonEnabled, setAddButtonEnabled] = useState(true);

  const [isEverytingValid, setIsEverythingValid] = useState(false);

  const [selectedRole, setSelectedRole] = useState({});
  const [canAssignAnyProject, setCanAssignAnyProject] = useState(false);

  useEffect(() => {
    const noInvitationForAgent = !Object.values(selectedRole).some(
      ({ databaseEntry }) => databaseEntry === 'redVaultAgent'
    );
    setCanAssignAnyProject(noInvitationForAgent);
  }, [selectedRole]);

  const updateSelectedRole = useCallback((invitationId, role) => {
    setSelectedRole((prev) => ({ ...prev, [invitationId]: role }));
  }, []);

  useEffect(() => {
    let roleOptions = [];
    // Getting the available role options
    Vault.getAllowedPermissionRoles(vaultObjectId, sessionToken).then(
      (result) => {
        const allowedPermissionRoles = result.data.allowedPermissionRoleList;
        setProjectRoles(result.data.allowedProjectPermissionRoleList);
        allowedPermissionRoles.forEach((role) => {
          roleOptions.push({
            title: localizer[role],
            description: localizer[`${role}Description`],
            databaseEntry: role,
          });
        });

        const allowedRolesBySubscriptionPlan = roleOptions.filter((role) => {
          if (
            [plans.essentialYearly, plans.essentialMonthly].includes(
              subscriptionPlanId
            )
          ) {
            return role.databaseEntry === 'redOwner';
          } else {
            return true;
          }
        });

        setRoles(allowedRolesBySubscriptionPlan);
      }
    );
  }, [vaultObjectId, sessionToken, subscriptionPlanId]);

  useEffect(() => {
    // Set limit of 10 invite fields at a time
    if (invitations.length >= 10) {
      setAddButtonEnabled(false);
    }

    // Check if all fields are valid to enable invite button
    let valid = true;
    invitations.forEach((invitation) => {
      valid = valid && invitation.isEmailValid;
    });

    // Check if there are no duplicate emails
    let duplicates = false;
    invitations.forEach((invitation) => {
      if (invitation.email === '') {
        return;
      }
      invitations.forEach((item) => {
        if (invitation.id !== item.id && item.email === invitation.email) {
          valid = false;
          duplicates = true;
          setInvitationError('You have duplicate emails');
        }
      });
    });
    if (!duplicates) {
      setInvitationError(null);
    }

    // Check if there are invitations that need project selection
    let projectSelectionNeeded = false;
    invitations.forEach((invitation) => {
      if (projectRoles.includes(invitation.selectedRole?.databaseEntry)) {
        projectSelectionNeeded = true;
      }
    });
    setProjectSelectionEnabled(projectSelectionNeeded);

    setIsEverythingValid(valid);
  }, [invitations, projectRoles]);

  const addInvitation = useCallback(() => {
    const newCard = {
      id: Math.random().toString(36).substring(2, 15),
      email: '',
      selectedRole: null,
      isEmailValid: false,
    };
    setInvitations((list) => [...list, newCard]);
  }, []);

  useEffect(() => {
    if (roles.length > 0) {
      const newCard = {
        id: Math.random().toString(36).substring(2, 15),
        email: '',
        selectedRole: null,
        isEmailValid: false,
      };

      setInvitations([newCard]);
    }
  }, [roles]);

  useEffect(() => {
    const canStillInviteMoreUsers =
      usersAlreadyInvitedToVault.length +
        usersAlreadyInVault.length +
        invitations <
        maxUserCount || maxUserCount === -1;

    if (canStillInviteMoreUsers) {
      setAddButtonEnabled(true);
    } else {
      setAddButtonEnabled(false);
    }
  }, [
    usersAlreadyInvitedToVault,
    usersAlreadyInVault,
    invitations,
    maxUserCount,
  ]);

  const onSubmit = async () => {
    setInvitationError(null);

    if (isEverytingValid && invitations.length > 0) {
      setIsSendingInvite(true);
      onInvitingStart();

      let userList = [];
      let roleList = [];
      let emailList = [];
      invitations.forEach((invitation) => {
        roleList.push(invitation.selectedRole.databaseEntry);
        emailList.push(invitation.email);
        let newUser = {
          email: invitation.email,
          permissionRoleObjectId: invitation.selectedRole.databaseEntry,
        };
        if (projectRoles.includes(invitation.selectedRole.databaseEntry)) {
          newUser.projectObjectIdList = selectedProjects;
        }
        userList.push(newUser);
      });

      const [inviteError, inviteResult] = await to(
        Vault.inviteUserBulk(
          vaultObjectId,
          {
            inviteUserList: userList,
          },
          sessionToken
        )
      );

      if (inviteError || !inviteResult) {
        setIsSendingInvite(false);
        setInvitationError(inviteError.error);
        setTimeout(() => {
          onInvitingEnd();
        }, 5000);
        return;
      }

      addInvitationAction();
      setIsSendingInvite(false);

      setEmailsSent(emailList);
      setInviteSuccess(true);

      setTimeout(() => {
        onInvitingEnd();
      }, 2000);
    }
  };

  if (isSendingInvite) {
    return (
      <Inviting>
        <h3>{localizer.invitingUser}</h3>
      </Inviting>
    );
  }

  if (inviteSuccess) {
    return (
      <Inviting>
        <h3>{localizer.inviteSuccess}</h3>
        <br></br>
        <h4>{localizer.inviteSuccessSubtext}</h4>
        <>
          {emailsSent.map((email) => {
            return <h5 key={email}>{email}</h5>;
          })}
        </>
      </Inviting>
    );
  }

  const onInviteCardUpdated = (inviteCard) => {
    let localList = invitations.slice();

    let exists = false;
    let id = -1;
    localList.forEach((invitation, index) => {
      if (invitation.id === inviteCard.id) {
        exists = true;
        id = index;
      }
    });

    if (!exists) {
      localList.push(inviteCard);
    } else {
      localList[id] = inviteCard;
    }

    setInvitations(localList);
  };

  const onRemoveInviteCard = (id) => {
    let localList = invitations.filter((invitation) => {
      return invitation.id !== id;
    });
    setInvitations(localList);
  };

  const renderItem = ({ item, id }) => (
    <InviteEmailAndRoleCard
      key={item.id}
      id={item.id}
      roleOptions={roles}
      invitations={invitations}
      isFirstCard={id === 0}
      isOnlyCard={invitations.length === 1}
      onRemove={onRemoveInviteCard}
      onUpdate={onInviteCardUpdated}
      usersAlreadyInVault={usersAlreadyInVault}
      usersAlreadyInvitedToVault={usersAlreadyInvitedToVault}
      selectedRole={selectedRole[item.id]}
      setSelectedRole={updateSelectedRole}
    />
  );

  return (
    <UserForm>
      <Title>{localizer.inviteNewTeamMember}</Title>
      <>{invitations.map((item, id) => renderItem({ item, id }))}</>
      {addButtonEnabled && (
        <AddButton onClick={addInvitation}>
          <Icon icon={['far', 'plus-square']} size="lg" />
          {localizer.addNew}
        </AddButton>
      )}
      {!projectSelectionEnabled && (
        <OwnerInfo>{localizer.managerAndOwnerDescription}</OwnerInfo>
      )}
      <AssignProjectsCard
        vaultObjectId={vaultObjectId}
        sessionToken={sessionToken}
        onSelectionUpdated={setSelectedProjects}
        disabled={!projectSelectionEnabled}
        selectedRole={{
          databaseEntry: canAssignAnyProject ? 'not-agent' : 'redVaultAgent',
        }}
      />
      <ButtonWrapper>
        <CancelButton
          label={localizer.cancel}
          onClickAction={onInvitingEnd}
          dataTestId="cancelButton"
        />
        <InviteButton
          type="submit"
          label={localizer.invite}
          onClickAction={onSubmit}
          status={isEverytingValid ? 'default' : 'disabled'}
          dataTestId="inviteButton"
        />
      </ButtonWrapper>
      {invitationError && <ErrorMessage>{invitationError}</ErrorMessage>}
    </UserForm>
  );
};

InviteUserForm.propTypes = {
  vaultObjectId: string.isRequired,
  sessionToken: string.isRequired,
  addInvitationAction: func,
  onInvitingStart: func,
  onInvitingEnd: func,
  usersAlreadyInVault: PropTypes.array.isRequired,
  usersAlreadyInvitedToVault: PropTypes.array.isRequired,
};

InviteUserForm.defaultProps = {
  addInvitationAction: () => {},
  onInvitingStart: () => {},
  onInvitingEnd: () => {},
};

export default InviteUserForm;
