import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

// Components
import StyledScrollbarWrapper from 'components/other/StyledScrollbarsWrapper';
import EmptyFeed from './EmptyFeed';
import PromptoLoader from 'components/loader/PromptoLoader';
import ProjectsPanel from './ProjectsPanel';
import DaySeparator from './DaySeparator';
import { Virtuoso } from 'react-virtuoso';

// Helpers
import { motion, AnimatePresence } from 'framer-motion';
import { debounce } from 'helpers/util';
import localizer from 'localization/localizer';
import {
  updateLastTimeOpenedNotifications,
  getAllNotifications
} from 'store/reducers/notificationReducer/NotificationsActions';
import format from 'date-fns/format';
import to from 'await-to-js';
import { Notification } from '@prompto-api';

// Styling
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CONTROLS_HEIGHT = 50;

const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: ${({ theme }) => theme.whitePure};
  max-height: 100%;
`;

const Controls = styled.div`
  height: ${CONTROLS_HEIGHT}px;
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.grayWhiteOff};
  padding: 5px 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-contetn: space-between;
  color: ${({ theme, checked }) =>
    checked ? theme.primary700 : theme.primary200};
  font-size: 0.875rem;
`;

const Content = styled.div`
  display: flex;
  min-height: calc(100% - ${CONTROLS_HEIGHT}px);
  max-height: calc(100% - ${CONTROLS_HEIGHT}px);
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: ${({ theme }) => theme.whitePure};
  overflow-x: hidden;
  flex-shrink: 0;
  width: 540px;
  min-height: ${({ minHeight }) => minHeight}px;
`;

const SliderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 300ms;
  border-radius: 10px;
  border: 2px solid ${({ theme }) => theme.gray400};

  &:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: -2px;
    transform: translateX(0px);
    bottom: -2px;
    background-color: transparent;
    transition: 300ms;
    border-radius: 50%;
    border: 2px solid ${({ theme }) => theme.gray400};
  }
`;

const SwitchWrapper = styled.label`
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
  margin: 0 8px 0 0;
  input {
    opacity: 0;
    width: 0;
    height: 0;
    &:checked + ${Slider} {
      background: ${({ theme }) => theme.accentAlt500};
      border: 2px solid ${({ theme }) => theme.accentAlt500};
      &:before {
        transform: translateX(14px);
        background: ${({ theme }) => theme.grayWhiteOff};
        border: 2px solid ${({ theme }) => theme.accentAlt500};
      }
    }
    &:disabled + ${Slider} {
      opacity: 0.6;
      cursor: default;
    }
  }
`;

const LoaderWrapper = styled(motion.div)`
  width: 540px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SmallLoaderWrapper = styled(LoaderWrapper)`
  height: 200px;
`;

const MarkAllAsReadButton = styled.div`
  font-size: 0.875rem;
  font-weight: 600;
  margin-left: auto;
  color: ${({ theme }) => theme.successColor};
  cursor: pointer;
  user-select: none;
`;

const MarkAllAsReadIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.successColor};
  margin-right: 5px;
  cursor: pointer;
`;

const StyledVirtuoso = styled(Virtuoso)`
  flex-grow: 1;
`;

const PAGE_SIZE = 200;
const MIN_CARD_HEIGHT = 80;

const Notifications = ({
  notifications,
  renderItem: RenderItem,
  loading,
  toggleShowUnreadOnly,
  showUnreadOnly,
  getNofitications,
  sessionToken,
  selectedProjects,
  endReached,
  user,
  onAllNotificationsRead,
  totalCount
}) => {
  const [page, setPage] = useState(0);

  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [daySeparators, setDaySeparators] = useState([]);

  const [maxContentHeight, setMaxContentHeight] = useState(0);
  const wrapperRef = useRef();
  const contentRef = useRef();

  const L = localizer.notifications;

  // Handlers
  const getMoreNofitications = useCallback(
    (queryParams) => {
      getNofitications(sessionToken, queryParams, 'old');
    },
    [sessionToken, getNofitications]
  );

  const onScroll = debounce((event) => {
    if (!event || (event && !event.target) || loading || endReached) {
      return;
    }
    const { offsetHeight, scrollTop, scrollHeight } = event.target;
    if (offsetHeight + scrollTop >= scrollHeight - MIN_CARD_HEIGHT * 10) {
      setPage((prev) => prev + 1);
    }
  }, 100);

  const setDaySeparator = useCallback(
    (separator) =>
      setDaySeparators((prev) => {
        const alreadyExists = prev.find((item) => item.date === separator.date);
        if (!alreadyExists) {
          return [...prev, separator];
        }

        const shouldUpdateSeparator =
          alreadyExists.createdAt < separator.createdAt;
        if (shouldUpdateSeparator) {
          const updatedSeparators = [...prev];
          const alreadyExistsIndex = prev.findIndex(
            (item) => item.date === separator.date
          );
          updatedSeparators.splice(alreadyExistsIndex, 1, separator);
          return updatedSeparators;
        }

        return prev;
      }),
    []
  );

  const markAllUnitsAsRead = useCallback(async () => {
    let allNotifications = [...notifications];
    if (!endReached) {
      const params = {
        userId: user?.objectId,
        offset: 0,
        limit: totalCount,
        orderBy: 'createdAt',
        descendantOrder: true
      };

      const [, response] = await to(Notification.getAll(sessionToken, params));

      if (response) {
        allNotifications = [...response.data.notificationList];
      }
    }

    const unreadNotificationIds = allNotifications
      .filter((x) => x.notificationStatus === 'unread')
      .map((notification) => notification.objectId);

    Notification.markAsRead(
      {
        userId: user.objectId,
        notificationIdList: unreadNotificationIds
      },
      sessionToken
    ).then(() => {
      onAllNotificationsRead();
    });
  }, [
    notifications,
    endReached,
    user,
    sessionToken,
    onAllNotificationsRead,
    totalCount
  ]);

  // Effects
  useEffect(() => {
    if (loading) return;
    if (maxContentHeight > 0) return;
    const timer = setTimeout(() => {
      const wrapperHeight = wrapperRef.current?.getBoundingClientRect()?.height;
      if (wrapperHeight) {
        setMaxContentHeight(wrapperHeight - CONTROLS_HEIGHT);
      }
      clearTimeout(timer);
    }, 50);
  }, [maxContentHeight, wrapperRef, loading]);

  useEffect(() => {
    if (page === 0) return;

    const offset = page * PAGE_SIZE;
    const limit = PAGE_SIZE;

    getMoreNofitications({ offset, limit });
  }, [page, getMoreNofitications]);

  useEffect(() => {
    if (loading || endReached) return;
    if (showUnreadOnly || selectedProjects?.length > 0) {
      if (filteredNotifications.length < PAGE_SIZE) {
        setPage((prev) => prev + 1);
      }
    }
  }, [
    filteredNotifications,
    endReached,
    showUnreadOnly,
    selectedProjects,
    loading
  ]);

  // filter notifications
  useEffect(() => {
    let filtered = [...notifications];
    if (showUnreadOnly) {
      filtered = notifications.filter((n) => n.notificationStatus === 'unread');
    }
    if (selectedProjects?.length > 0) {
      filtered = filtered.filter((n) =>
        selectedProjects.includes(n.projectObjectId)
      );
    }

    if (filtered.length > 0) {
      const groupedByDays = filtered.reduce((groups, notification) => {
        const dayKey = format(notification.createdAt, "iii',' dd MMM");
        if (groups[dayKey]) {
          groups[dayKey].push(notification);
        } else {
          groups[dayKey] = [notification];
        }
        return groups;
      }, {});

      const notificationsList = Object.entries(groupedByDays).reduce(
        (list, [groupKey, notifications]) => {
          const daySeparator = {
            type: 'day-separator',
            date: groupKey,
            createdAt: notifications[0].createdAt
          };
          return [...list, daySeparator, ...notifications];
        },
        []
      );

      setFilteredNotifications(notificationsList);
    } else {
      setFilteredNotifications([]);
    }
  }, [notifications, showUnreadOnly, selectedProjects, daySeparators]);

  const loader = (
    <PromptoLoader dataTestId={'fetch-loader'} width={50} height={50} />
  );

  if (notifications.length === 0) {
    return loading ? (
      <LoaderWrapper>{loader}</LoaderWrapper>
    ) : (
      <EmptyFeed text={L.emptyFeedText} />
    );
  }

  let content = <EmptyFeed text={L.noResults} {...sharedAnimation} />;

  if (filteredNotifications?.length > 0) {
    content = (
      <>
        <StyledVirtuoso
          data={filteredNotifications}
          itemContent={(idx, item) => {
            if (item.type === 'day-separator') {
              return (
                <DaySeparator
                  separator={item}
                  key={`day-separator-${item.createdAt}-${idx}`}
                />
              );
            }
            return (
              <RenderItem
                key={`${item.objectId}-${idx}`}
                notification={item}
                setDaySeparator={setDaySeparator}
              />
            );
          }}
        />
        {loading && (
          <SmallLoaderWrapper {...sharedAnimation}>{loader}</SmallLoaderWrapper>
        )}
      </>
    );
  } else if (loading) {
    content = (
      <SmallLoaderWrapper key="small-loader" {...sharedAnimation}>
        {loader}
      </SmallLoaderWrapper>
    );
  }

  return (
    <Wrapper
      ref={wrapperRef}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Controls checked={showUnreadOnly}>
        <SliderWrapper>
          <SwitchWrapper>
            <input
              id="checkbox_showUnreadNotificationsOnly"
              type="checkbox"
              checked={showUnreadOnly}
              onChange={() => toggleShowUnreadOnly(!showUnreadOnly)}
              disabled={loading}
            />
            <Slider />
          </SwitchWrapper>
        </SliderWrapper>
        <span>
          {localizer.formatString(L.showOnly, <b>{L.unreadEvents}</b>)}
        </span>

        {notifications.filter((x) => x.notificationStatus === 'unread').length >
          0 && (
          <MarkAllAsReadButton onClick={markAllUnitsAsRead}>
            <MarkAllAsReadIcon icon={['far', 'check-double']} />
            {localizer.markAllAsRead}
          </MarkAllAsReadButton>
        )}
      </Controls>

      <Content height={maxContentHeight}>
        <ProjectsPanel />
        {maxContentHeight ? (
          <StyledScrollbarWrapper
            autoHeightMin={maxContentHeight}
            autoHeightMax={maxContentHeight}
            onScrollFrame={(values) =>
              onScroll({
                target: {
                  offsetHeight: values.clientHeight,
                  scrollTop: values.scrollTop,
                  scrollHeight: values.scrollHeight
                }
              })
            }
          >
            <List ref={contentRef} minHeight={maxContentHeight}>
              <AnimatePresence>{content}</AnimatePresence>
            </List>
          </StyledScrollbarWrapper>
        ) : null}
      </Content>
    </Wrapper>
  );
};

const sharedAnimation = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
};

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  notifications: state.store.NotificationsReducer.notifications,
  showUnreadOnly: state.store.NotificationsReducer.showUnreadOnly,
  loading: state.store.NotificationsReducer.loading,
  sessionToken: state.store.AuthReducer?.sessionToken,
  user: state.store.AuthReducer?.user,
  projects: state.store.ProjectListReducer?.projects,
  selectedProjects: state.store.NotificationsReducer?.selectedProjects,
  endReached: state.store.NotificationsReducer.endReached,
  totalCount: state.store.NotificationsReducer.totalCount
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch) => ({
  closeActivityFeed: () =>
    dispatch({ type: 'TOGGLE_NOTIFICATIONS_FEED', payload: false }),
  setLastTimeOpened: (timestamp) =>
    dispatch(updateLastTimeOpenedNotifications(timestamp)),
  getNofitications: (sessionToken, queryParams, type) =>
    dispatch(getAllNotifications(sessionToken, queryParams, type)),
  onAllNotificationsRead: (notification) =>
    dispatch({ type: 'MARK_ALL_NOTIFICATIONS_AS_READ' }),
  toggleShowUnreadOnly: (flag) =>
    dispatch({ type: 'TOGGLE_SHOW_UNREAD_ONLY', payload: flag })
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
