// Base
import React from 'react';
import { string, element } from 'prop-types';

// Components
import ThumbnailImage from 'components/thumbnailImage/ThumbnailImage';
import PlaceholderImage from 'resources/profile-placeholder.jpg';

// Styling
import styled from 'styled-components';

const StyledPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  background: url(${PlaceholderImage}) center center;
  background-size: cover;

  .thumbnail-image__preview {
    display: none;
  }
`;

const ProfileImage = ({
  profileImageUri,
  overlayComponent,
  className,
  uploadEnabled,
  triggerActive,
  triggerTitle,
  onUploadTrigger,
  dataTestId,
  thumbnailStyles
}) => {
  const placeholderComponent = <StyledPlaceholder />;

  return (
    <ThumbnailImage
      thumbnailImageUri={profileImageUri}
      placeholderComponent={placeholderComponent}
      overlayComponent={overlayComponent}
      className={className}
      uploadEnabled={uploadEnabled}
      triggerActive={triggerActive}
      triggerTitle={triggerTitle}
      onUploadTrigger={onUploadTrigger}
      dataTestId={dataTestId}
      styles={
        thumbnailStyles ??
        `
        border-radius: 50%;
        width: 120px;
        height: 120px;
      `
      }
    />
  );
};

ProfileImage.propTypes = {
  /** Image URI for the profile image (optional) */
  profileImageUri: string,
  /** Component to render over the image (optional) */
  overlayComponent: element,
  /** Choose an additional className to add */
  className: string,
  /** Data test id for automated testing */
  dataTestId: string
};

ProfileImage.defaultProps = {
  profileImageUri: null,
  overlayComponent: null,
  className: null,
  dataTestId: null
};

export default ProfileImage;
