import React, { useState, useEffect, useCallback, useRef } from 'react';
import { createPortal } from 'react-dom';

// Components
import Page from '../contentItems/Page';
import MobilePage from '../contentItems/MobilePage';
import { DownloadButton } from '../../CollectionPage';

// Helpers
import { motion, AnimatePresence } from 'framer-motion';
import { usePrevious } from '@prompto-helpers';
import { useKeyPress } from 'helpers/customHooks';
import { wrap } from '@popmotion/popcorn';
import useResize from 'use-resize';
import { buildAssetURIWithOptions, downloadFilesFromUrl } from 'helpers/util';
import { isMobile as isMobileDevice, isMobileOnly } from 'react-device-detect';
import localizer from 'localization/localizer';
import { Tracking } from '@prompto-api';
import env from 'environment';
import Cookies from 'universal-cookie';

// Styles
import styled from 'styled-components';
import { styledRespondTo } from 'styles/mixins';

const Heading = styled.div`
  display: flex;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.whitePure};
  position: fixed;
  left: 70px;
  top: 27px;
  max-width: 60vw;

  span {
    font-family: ${({ theme }) => theme.fonts.DMSans};
    word-break: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${styledRespondTo.sm`
    display: none;
  `}
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  position: fixed;
  top: 14px;
  right: 16px;

  ${styledRespondTo.sm`
    position: absolute;
    top: 32px;
    right: 32px;
  `}

  & > button {
    backdrop-filter: blur(3px);
    background-color: rgba(128, 128, 128, 0.4);

    svg {
      font-size: 1rem;
    }
  }
`;

const MainWrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  top: 72px;
  left: 16px;
  width: calc(100% - ${16 * 2}px);
  height: calc(100% - 88px); // 88 = 16 + 72

  ${styledRespondTo.sm`
    top: 60px;
    left: 60px;
    width: calc(100% - ${60 * 2}px);
    height: calc(100% - ${60 * 2}px);
  `}
`;

const PageCounter = styled.p`
  position: absolute;
  font-size: 1rem;
  text-align: right;
  top: 20px;
  left: 20px;
  margin: 0;
  color: white;
  z-index: 10;
  font-family: ${({ theme }) => theme.fonts.DMSans};
`;

const AnimationWrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
`;

const PageSwiperWrapper = styled(motion.div)`
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(46, 49, 56, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3000;
`;

const PageSwiper = (props) => {
  const cookies = new Cookies();
  const authorizedUserId = cookies.get(`prompto/${env().env}/ust`);
  const {
    activePage,
    pages,
    onNextPage,
    onPreviousPage,
    onClose,
    videoFallbackThumbnail,
    CustomNavigation,
    onPageLoaded,
    isActive,
    show,
    SwitcherStateDispatch,
    vaultId,
    visitorSessionId
  } = props;

  const [[page, direction], setPage] = useState([activePage, 0]);
  const [dragAxis, setDragAxis] = useState();
  const [isFileDownloading, setIsDownloading] = useState(false);
  const [isMobile, setIsMobileDevice] = useState(isMobileDevice);
  const [transitioning, setTransitioning] = useState(false);

  const previousActivePage = usePrevious(activePage);
  const nextKeyPressed = useKeyPress('ArrowRight', false, false);
  const previousKeyPressed = useKeyPress('ArrowLeft', false, false);
  const [isDragEnabled, setIsDragEnabled] = useState(
    isMobileOnly ? false : 'x'
  );
  const size = useResize();

  const tracked = useRef(false);
  const trackPageOpened = useCallback(
    (contentItem) => {
      // skip tracking at content collection page for authorized users
      if (contentItem && !authorizedUserId) {
        tracked.current = true;
        Tracking.trackSingleItemFromSharedCollectionViewed({
          contentItemId: contentItem.objectId,
          sessionId: visitorSessionId,
          vaultId
        }).catch(() => {});
      }
    },
    [page, onPageLoaded, vaultId, visitorSessionId]
  );

  // each time when active page changed and
  // tracking for current content item wasn't done yet
  // do tracking
  useEffect(() => {
    if (
      tracked.current === false &&
      pages &&
      (activePage || activePage === 0)
    ) {
      trackPageOpened(pages[activePage]);
    }
  }, [pages, activePage]);

  const handleDownload = (event, page) => {
    setIsDownloading(true);
    event.stopPropagation();
    downloadFilesFromUrl(
      [
        {
          url: ['image', 'image360'].includes(page.contentItemType)
            ? buildAssetURIWithOptions('o=true', page.contentUri)
            : page.contentUri,
          title: page.title.textMap.en,
          objectId: page.objectId
        }
      ],
      '',
      '',
      () => {
        setIsDownloading(false);
        // skip tracking at content collection page for authorized users
        if (!authorizedUserId) {
          Tracking.trackSharedCollectionContentDownload({
            vaultId,
            sessionId: visitorSessionId,
            action: 'sharedMediaDownloadSucceeded',
            contentItemIdList: [page.objectId]
          }).catch(() => {});
        }
      }
    );
  };

  // Only do this when activePage changes
  useEffect(() => {
    if (
      activePage !== previousActivePage &&
      activePage !== page % (pages?.length || 0)
    ) {
      setPage([activePage, 0]);
    }
  }, [activePage, pages, page, previousActivePage]);

  const pageIndex = wrap(0, pages?.length || 0, page);
  const paginate = useCallback(
    (newDirection) => {
      setPage([page + newDirection, newDirection]);
      onPageLoaded();
    },
    [page, onPageLoaded]
  );

  const goToNextPage = useCallback(() => {
    if (isMobile || activePage + 1 < (pages?.length || 0)) {
      setTransitioning(true);
      setTimeout(() => {
        setTransitioning(false);
        paginate(1);
        onNextPage();
      }, 0);
    }
  }, [paginate, onNextPage, isMobile, activePage, pages]);

  const goToPreviousPage = useCallback(() => {
    if (isMobile || activePage > 0) {
      setTransitioning(true);
      setTimeout(() => {
        setTransitioning(false);
        paginate(-1);
        onPreviousPage();
      }, 0);
    }
  }, [paginate, onPreviousPage, isMobile, activePage]);

  // Arrow key presses
  useEffect(() => {
    if (
      nextKeyPressed &&
      isActive &&
      pages &&
      pages[pageIndex]?.contentItemType !== 'image360'
    ) {
      goToNextPage();
    }
  }, [nextKeyPressed, goToNextPage, isActive, pageIndex, pages]);

  useEffect(() => {
    if (
      previousKeyPressed &&
      isActive &&
      pages &&
      pages[pageIndex]?.contentItemType !== 'image360'
    ) {
      goToPreviousPage();
    }
  }, [previousKeyPressed, goToPreviousPage, isActive, pageIndex, pages]);

  // Resize
  useEffect(() => {
    setIsMobileDevice(isMobileDevice);
  }, [size]);

  useEffect(() => {
    if (
      (pages && pages[pageIndex]?.contentItemType === 'image360') ||
      isMobileOnly
    ) {
      setIsDragEnabled(false);
    } else {
      setIsDragEnabled('x'); //true
    }
  }, [pageIndex, pages]);

  const showPageCounter = isMobileOnly;

  const doTrackingReset = () => {
    tracked.current = false;
    SwitcherStateDispatch({ type: 'setActivePage', payload: -1 });
  };

  // UI
  let navigationControlsProps = {
    ...CustomNavigation.props,
    showUI: true,
    onNextPage: (props) => {
      goToNextPage(props);
      setIsDownloading(false);
      // track opening of new content item, + 1 to current active page
      trackPageOpened(pages[activePage + 1]);
    },
    onPreviousPage: (props) => {
      goToPreviousPage(props);
      setIsDownloading(false);
      // track opening of new content item, - 1 from current active page
      trackPageOpened(pages[activePage - 1]);
    },
    pageLength: pages?.length,
    activePage: pageIndex,
    onGridView: (props) => {
      onClose(props);
      setIsDownloading(false);
      // on gallery close - reset tracking state and active page to be able to track if
      // same content item was opened again
      doTrackingReset();
    }
  };

  let navigationControls;
  if (CustomNavigation) {
    navigationControls = {
      ...CustomNavigation,
      props: navigationControlsProps
    };
  }

  if (!show) return null;

  const heading = (
    <Heading>
      <span>{pages[activePage].title.textMap.en}</span>
    </Heading>
  );

  return createPortal(
    <PageSwiperWrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: show ? 1 : 0 }}
      transition={{ duration: 0.1 }}
      onClick={(props) => {
        setIsDownloading(false);
        onClose(props);
        doTrackingReset();
      }}
    >
      {heading}
      <MainWrapper onClick={(e) => e.stopPropagation()}>
        {/* Page counter */}
        {showPageCounter && (
          <PageCounter>{`${pageIndex + 1}/${pages?.length}`}</PageCounter>
        )}

        {/* Swiper */}
        {/* @ts-ignore */}
        <AnimatePresence initial={false} custom={direction}>
          <AnimationWrapper
            key={page}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: 'spring', stiffness: 300, damping: 200 },
              opacity: { duration: 0.2 }
            }}
            drag={isDragEnabled}
            dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
            dragElastic={0.5}
            dragDirectionLock={true}
            onDirectionLock={(axis) => setDragAxis(axis)}
            onDragEnd={(e, info) => {
              const { offset, velocity } = info;

              // Horizontal swipe
              if (dragAxis === 'x') {
                const horizontalSwipe = swipePower(offset.x, velocity.x);
                if (horizontalSwipe < -swipeConfidenceThreshold) {
                  goToNextPage();
                } else if (horizontalSwipe > swipeConfidenceThreshold) {
                  goToPreviousPage();
                }
              }
              // Vertical swipe
              else if (dragAxis === 'y') {
                const verticalSwipe = swipePower(offset.y, velocity.y);
                if (
                  verticalSwipe < -swipeConfidenceThreshold ||
                  verticalSwipe > swipeConfidenceThreshold
                ) {
                  onClose();
                  doTrackingReset();
                }
              }
            }}
            dragPropagation={true}
          >
            {isMobileOnly ? (
              <MobilePage
                {...props}
                pageIndex={pageIndex}
                data={(pages && pages[pageIndex]) || {}}
                allPages={pages}
                videoFallbackThumbnail={videoFallbackThumbnail}
                autoplay={true}
                onNextPage={goToNextPage}
                onPreviousPage={goToPreviousPage}
                setIsDragEnabled={setIsDragEnabled}
                isDragEnabled={isDragEnabled}
              />
            ) : (
              <Page
                {...props}
                key={pages && pages[activePage]?.objectId}
                onNextPage={goToNextPage}
                onPreviousPage={goToPreviousPage}
                data={(pages && pages[pageIndex]) || {}}
                allPages={pages}
                autoplay={true}
                transitioning={transitioning}
                setIsDragEnabled={setIsDragEnabled}
                isDragEnabled={isDragEnabled}
              />
            )}
          </AnimationWrapper>
        </AnimatePresence>

        {navigationControls}
        <ButtonWrapper>
          <DownloadButton
            onClick={(e) => {
              handleDownload(e, pages[activePage]);
            }}
            customDescription={localizer.collectionPage.downloadFile}
            isLoading={isFileDownloading}
          />
        </ButtonWrapper>
      </MainWrapper>
    </PageSwiperWrapper>,
    document.body
  );
};

export default PageSwiper;

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? '110vw' : '-110vw',
      opacity: 0
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? '110vw' : '-110vw',
      opacity: 0
    };
  }
};

/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */
const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};
