import React, { useContext } from 'react';
import { string, number, array, func, bool, shape } from 'prop-types';

// Components
import Select from 'react-select';

// Styling
import styled, {css, ThemeContext} from "styled-components";

const Warning = styled.div`
  display: block;
  position: relative;
  margin-top: 4px;
  float: none;
  clear: both;
  color: ${({theme, isError}) => isError ? theme.errorColor : theme.mainTextColor2};
  font-size: 0.75rem;
  font-weight: 300;
  line-height: normal;
`;

const LabelWrapper = styled.label`
  display: block;
  position: relative;
  line-height: normal;
  text-align: left;
  color: ${({theme}) => theme.mainTextColor2};
  font-size: 0.75rem;
  display: flex;
  flex-direction: column;
  ${({alt}) => {
    if(alt) {
      return css`
        flex-direction: row;
        align-items: center;
        padding: 5px 0;
      `;
    }
  }}
`;

const Label = styled.div`
  ${({alt}) => alt ? 'flex-basis: 30%;' : ''}
`;

const FieldAndWarning = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  > span {
    padding-left: 10px;
  }
`;

const SelectFieldWrapper = styled.div`
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', 'Helvetica Neue', 'proxima-nova', sans-serif;
  font-weight: 400;
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
  outline: none;
  width: 100%;
  ${({warningStatus, theme}) => warningStatus ? `color: ${theme.errorColor};` : ''}
`;

const SelectFieldSelectedValue = styled.span`
  font-weight: bold;
`;

const SelectFieldValue = styled.span`
  align-items: center;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 0 0 0 2px;
  font-size: 0.875rem;
`;

const SingleValueComponent = (props, prefix, valueBold, dataTestId) => {
  let value = (
    <span data-testid={`${dataTestId}_selectedValue`}>
      {prefix}&nbsp;{props.data.label}
    </span>
  );

  if (valueBold) {
    value = (
      <>
        {prefix}&nbsp;
        <SelectFieldSelectedValue data-testid={`${dataTestId}_selectedValue`}>
          {props.data.label}
        </SelectFieldSelectedValue>
      </>
    );
  }

  return <SelectFieldValue>{value}</SelectFieldValue>;
};

const SelectField = (props) => {
  const {
    fieldName,
    label,
    prefix,
    valueBold,
    statusCode,
    statusMessage,
    isRequired,
    disabled,
    dataTestId,
    alt
  } = props;

  const { gray100, primary100 } = useContext(ThemeContext);

  // Warning rendering
  let warning;
  let warningStatus;
  if (statusCode >= 300 && statusMessage && statusMessage !== '') {
    warningStatus = true;
    warning = (
      <Warning isError>
        {statusMessage}
      </Warning>
    );
  }

  // Prefix
  let selectFieldComponents = {
    SingleValue: (props) =>
      SingleValueComponent(props, prefix, valueBold, dataTestId)
  };

  let fieldStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: gray100
    }),
    input: (provided) => ({
      ...provided,
      color: primary100,
      backgroundColor: gray100
    })
  };
  if (disabled) {
    fieldStyles = {
      control: (provided) => ({
        ...provided,
        backgroundColor: gray100,
        marginTop: '5px'
      }),
      input: (provided) => ({
        ...provided,
        color: primary100,
        backgroundColor: gray100
      })
    };
  }

  // Basic form field without label
  let content = (
    <Select
      {...props}
      styles={fieldStyles}
      isDisabled={disabled}
      components={selectFieldComponents}
    />
  );

  // If a label is supplied it needs to wrap Around the formField to satisfy jsx-a11y
  if (label) {
    content = (
      <LabelWrapper
        alt={alt}
        htmlFor={fieldName}
      >
        <Label alt={alt}>
          {`${label} ${isRequired ? '*' : ''}`}
        </Label>
        <FieldAndWarning>
          <Select
            {...props}
            styles={fieldStyles}
            isDisabled={disabled}
            components={selectFieldComponents}
          />
          {warning}
        </FieldAndWarning>
      </LabelWrapper>
    );
  }

  return (
    <SelectFieldWrapper warningStatus={warningStatus} data-testid={dataTestId}>
      {content}
    </SelectFieldWrapper>
  );
};

SelectField.propTypes = {
  /** Name of the field */
  fieldName: string,
  /** Label text */
  label: string,
  /** Prefix text is added to the input value before the selected value */
  prefix: string,
  /** selected value */
  value: shape({}),
  /** Should selected value be bold */
  valueBold: bool,
  /** List of possible options to select */
  options: array,
  /** Function called when a new option is selected */
  onFieldChanged: func,
  /** Status code */
  statusCode: number,
  /** Status message */
  statusMessage: string,
  /** Sets the field required */
  isRequired: bool,
  /** Id for testing */
  dataTestId: string,
  /** Change the layout to horizontal */
  alt: bool
};

SelectField.defaultProps = {
  fieldName: '',
  label: '',
  prefix: '',
  options: [],
  onFieldChanged: () => {},
  statusCode: 200,
  statusMessage: '',
  isRequired: false,
  dataTestId: '',
  alt: false
};

export default SelectField;
