export default {
  newNotificationsCount: 0,
  isActivityFeedOpened: false,
  notifications: [],
  lastTimeOpened: Date.now(),
  notificationsCountLoadingState: 'success',
  showUnreadOnly: false,
  selectedProjects: [],
  lastTimeOpenedUpdatesTimestamp: Date.now()
};
