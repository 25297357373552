import React, { forwardRef } from 'react';
import { string, element, func, bool } from 'prop-types';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
import { capitalize } from 'helpers/util';
import { motion, AnimatePresence } from 'framer-motion';

// Styling
import styled, { css, withTheme } from 'styled-components';
import { doubleTransition, styledRespondTo } from 'styles/mixins';

const Button = styled(motion.button)`
  position: relative;
  color: ${({ theme, isActive }) =>
    isActive ? theme.gray100 : theme.primary100};
  background-color: ${({ theme, isActive, activeColor }) =>
    isActive ? activeColor ?? theme.accentAlt300 : 'transparent'};
  font-size: 1.25rem;
  height: 36px;
  display: flex;
  justify-content: flex-start;
  padding-left: 15px;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  outline: none;
  margin-left: 0;
  ${doubleTransition(`0.2s opacity ease-in-out`, `0.2s color ease-in-out`)}
  &:hover {
    color: ${({ theme }) => theme.gray100};
  }
`;

const Name = styled.span`
  display: none;
  width: 0;
  transform-origin: left;
  ${styledRespondTo.md`
    display: block;
    width: auto;
    font-size: 0.875rem;
    padding-left: 25px;
    white-space: normal;
    text-align: left;
    line-height: 1;
  `}
`;

const TextWrapper = styled(motion.div)`
  overflow: hidden;
  transform-origin: left;
`;

const IconWrapper = styled.div`
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Counter = styled(motion.div)`
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.primary900};
  font-size: 0.6875rem;
  color: ${({ theme }) => theme.whitePure};
  background-color: ${({ theme, error }) =>
    error ? theme.errorColor : theme.successColor};
  display: flex;
  align-items: center;
  justify-content: center;
  top: -4px;
  right: -4px;
  transition: all 200ms ease;
  ${({ wide }) => {
    if (wide)
      return css`
        right: -2px;
        top: 8px;
      `;
  }};
`;

const SidebarIconButton = forwardRef(
  (
    {
      clickHandler,
      iconKey,
      iconComponent,
      isActive,
      name,
      notificationsCount,
      error,
      activeColor,
      onHoverHandler,
      animation,
      theme
    },
    ref
  ) => {
    const isSidebarWide = theme.isSidebarWide;

    return (
      <Button
        id={`sidebarButton_${name.replace(' ', '')}`}
        data-testid={`sidebarButton_${name.replace(' ', '')}`}
        ref={ref}
        onClick={clickHandler}
        onMouseEnter={onHoverHandler}
        key={iconKey}
        isActive={isActive}
        activeColor={activeColor}
        wide={isSidebarWide}
        {...animation}
      >
        <IconWrapper>{iconComponent}</IconWrapper>

        <AnimatePresence>
          {isSidebarWide && (
            <TextWrapper
              initial={{ opacity: 0, scaleX: 0 }}
              animate={{ opacity: 1, scaleX: 1 }}
              exit={{ opacity: 0, scaleX: 0 }}
              transition={{ duration: 0.1, ease: 'linear' }}
            >
              <Name>{capitalize(name)}</Name>
            </TextWrapper>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {notificationsCount && (
            <Counter
              exit={{ scale: 0 }}
              wide={isSidebarWide}
              error={error}
              title={
                error
                  ? 'Could not get notifications number. Please, refresh the page'
                  : 'Number of new notifications'
              }
            >
              {error ? (
                <FontAwesomeIcon
                  icon={['far', 'exclamation-triangle']}
                  size="sm"
                />
              ) : (
                notificationsCount
              )}
            </Counter>
          )}
        </AnimatePresence>
      </Button>
    );
  }
);

SidebarIconButton.propTypes = {
  iconKey: string.isRequired,
  iconComponent: element.isRequired,
  clickHandler: func.isRequired,
  className: string,
  isActive: bool,
  name: string,
  onHoverHandler: func
};

SidebarIconButton.defaultProps = {
  isActive: false,
  name: '',
  onHoverHandler: () => {},
  animate: {}
};

export default withTheme(SidebarIconButton);
