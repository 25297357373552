// Import the envs to pick from
import envDev from 'config/development/env';
import envProd from 'config/production/env';

const environment = (envQueryParam) => {
  if (envQueryParam) {
    return envQueryParam === 'production' ? envProd : envDev;
  }

  if (import.meta.env.VITE_APP_PROD === 'true') {
    return envProd;
  }

  return envDev;
};

export default environment;
