import React from 'react';
import { string, bool, number, shape } from 'prop-types';
import styled, { withTheme } from 'styled-components';

const ProgressbarWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
  margin: 0 5px;
`;

const ProgressbarLabel = styled.div`
  width: 100%;
  font-size: 0.75rem;
  line-height: 0.75rem;
  padding-bottom: 5px;
`;

const StyledProgressbar = styled.div`
  position: relative;
  width: 100%;
  height: 6px;
  border-radius: 10px;
  background: ${({ theme }) => theme.descriptionActionsRGBA025};
`;

const ProgressbarFiller = styled.div`
  background: ${({ theme, isFull }) =>
    isFull ? theme.errorColor + '!important' : theme.projectDescription};
  border-radius: inherit;
  height: 100%;
  max-width: 100%;
  transition: width 0.2s ease-in;
`;

const Progressbar = ({
  currentValue,
  maxValue,
  labelText,
  progressbarHidden,
  className,
  stylesObject,
  dataTestId,
  theme,
  isSuccessState,
  isErrorState,
  isEmptyState
}) => {
  const successBarStyles = {
    backgroundColor: theme.successColor
  };
  const errorBarStyles = { backgroundColor: theme.errorColor };
  const emptyBarStyles = { backgroundColor: theme.gray400 };
  const filledBarStyles = { backgroundColor: theme.accentAlt300 };

  // Style adding
  const getProgressbarStyleFromContext = () => {
    if (stylesObject && stylesObject.progressbar) {
      return { ...stylesObject.progressbar };
    }
  };

  const getLabelStyleFromContext = () => {
    if (stylesObject && stylesObject.label) {
      return { ...stylesObject.label };
    }
  };

  let fillerStyle = {};
  if (isSuccessState) {
    fillerStyle = successBarStyles;
  } else if (isErrorState) {
    fillerStyle = errorBarStyles;
  } else if (isEmptyState) {
    fillerStyle = emptyBarStyles;
  } else {
    fillerStyle = filledBarStyles;
  }

  if (stylesObject && stylesObject.filler) {
    fillerStyle = {
      ...fillerStyle,
      ...stylesObject.filler
    };
  }

  // Calculate percent
  const percentage = Math.max(0, (currentValue / maxValue) * 100);

  // Render
  return (
    <ProgressbarWrapper className={className} data-testid={dataTestId}>
      {labelText && (
        <ProgressbarLabel style={getLabelStyleFromContext()}>
          {`${labelText}`}
        </ProgressbarLabel>
      )}
      {!progressbarHidden && (
        <StyledProgressbar style={getProgressbarStyleFromContext()}>
          <ProgressbarFiller
            style={{
              width: `${percentage}%`,
              ...fillerStyle
            }}
          />
        </StyledProgressbar>
      )}
    </ProgressbarWrapper>
  );
};

Progressbar.propTypes = {
  /** Amount of segments filled */
  currentValue: number,
  /** Total amount of segments */
  maxValue: number,
  /** Text shown on label. If empty no label is shown*/
  labelText: string,
  /** Is progressbar hidden */
  progressbarHidden: bool,
  stylesObject: shape({
    progressbar: shape({}),
    label: shape({}),
    filler: shape({})
  }),
  /** Data test id for automated testing */
  dataTestId: string,
  isSuccessState: bool,
  isErrorState: bool,
  isEmptyState: bool
};

Progressbar.defaultProps = {
  currentValue: 0,
  maxValue: 1,
  labelText: null,
  labelHidden: false,
  progressbarHidden: false,
  stylesObject: null,
  dataTestId: null,
  isSuccessState: false,
  isErrorState: false,
  isEmptyState: false
};

export default withTheme(Progressbar);
