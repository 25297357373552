import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
// https://www.npmjs.com/package/pannellum-react
import { Pannellum } from 'pannellum-react';
import 'pannellum-react/lib/pannellum/css/pannellum.css';
import env from 'environment';
import {
  addQueryParams,
  removeQueryParams,
  fetchSettingsFromURL
} from 'helpers/util.js';
import urlJoin from 'url-join';
import localizer from 'localization/localizer';

// Styling
import styled from 'styled-components';

const queryParamAbbreviations = {
  "turntable": "tt",
  "threeSixtyImage": "tsi",
  "activeMediaView": "amv"
}


const PageWrapper = styled.div`
  position: ${({ isEmbedded }) => (isEmbedded ? 'absolute' : 'fixed')};
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Instructions = styled.div`
  position: ${({ isEmbedded }) => (isEmbedded ? 'absolute' : 'fixed')};
  z-index: 1;
  width: 360px;
  top: 0;
  left: 0;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-family: ${({ theme }) => theme.fonts.DMSans};
  font-size: 1rem;
  line-height: 1.3;
  letter-spacing: 1px;
  text-align: center;
`;

const BackButton = styled.button`
  z-index: 101;
  position: absolute;
  background: rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  backdrop-filter: blur(10px);
  padding: 15px 20px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  bottom: 25px;
  left: 25px;
  outline: none;
`;

const BackButtonText = styled.div`
  color: white;
  font-weight: bold;
  font-size: 0.75rem;
  font-size: 0.75rem;
  letter-spacing: 0.03em;
  text-transform: uppercase;
`;

const BackButtonIcon = styled(FontAwesomeIcon)`
  color: white;
  padding-right: 10px;
`;

const Fullscreen360Viewer = ({
  image360ContentItem,
  onExit,
  hideBackButton,
  isEmbedded = false
}) => {
  const { objectId, contentUri } = image360ContentItem;
  const [viewerIsTracked, setViewerIsTracked] = useState(false);

  const pannellumRef = useRef();

  const transform = 'q=100';
  const fullSpotContentUri = urlJoin(env().baseImageUrl, transform, contentUri);

  const { search } = useLocation();
  const qp = queryParamAbbreviations.threeSixtyImage;

  // respond to browser Back arrow click
  useEffect(() => {
    if (!viewerIsTracked) return;
    const queryParams = fetchSettingsFromURL();

    if (!queryParams[qp] && onExit) onExit();
  }, [search, qp, onExit, viewerIsTracked]);

  // keep track of currently opened 360 image viewer
  useEffect(() => {
    addQueryParams([{ [qp]: objectId }]);
    setViewerIsTracked(true);
    return () => removeQueryParams([{ [qp]: '' }]);
  }, [objectId, qp]);

  return (
    <PageWrapper isEmbedded={isEmbedded}>
      <Instructions isEmbedded={isEmbedded}>
        {localizer.collectionPage.useMouseOrKeyboardToNavigate}
      </Instructions>
      <Pannellum
        ref={pannellumRef}
        width="100%"
        height="100%"
        image={fullSpotContentUri}
        pitch={10}
        yaw={180}
        hfov={110}
        autoLoad
        showControls={false}
      />
      {!hideBackButton && (
        <BackButton
          onClick={() => {
            if (onExit) {
              onExit();
            }
          }}
          key="backButton"
        >
          <BackButtonIcon icon={['fal', 'arrow-left']} />
          <BackButtonText>{localizer.collectionPage.back}</BackButtonText>
        </BackButton>
      )}
    </PageWrapper>
  );
};

export default Fullscreen360Viewer;
