// @ts-nocheck
import React, { useReducer, createContext, useContext, useRef } from 'react';

import { useMotionValue } from 'framer-motion';

import fileCategories from 'config/SupportedFileCategories.json';
const navigationFilters = {
  all: 'all',
  video: 'video',
  image: 'image',
  album360: '360'
};

const defaultStoreValue = {
  pages: [],
  activePage: 0,
  gridItemClicked: false,
  showGrid: true,
  activeCategory: 'media',
  activeFilter: navigationFilters.all,
  orderedContentCollection: null,
  folders: [],
  showFolders: false,
  activeFolder: '',
  foldersRefs: null,
  enableOnUpdateEvent: false
};

export const Context = createContext(defaultStoreValue);

const SwitcherStore = (props) => {
  const [SwitcherState, SwitcherStateDispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'setPages':
          return {
            ...state,
            pages: action.payload
          };
        case 'setShowGrid':
          return {
            ...state,
            showGrid: action.payload
          };
        case 'setActivePage':
          const newActivePage = state.pages[action.payload];
          let newCategory = state.activeCategory;
          if (newActivePage?.contentItemType === fileCategories.document) {
            newCategory = 'documents';
          } else {
            newCategory = 'media';
          }

          return {
            ...state,
            activePage: action.payload,
            activeCategory: newCategory
          };
        case 'setGridItemClicked':
          return { ...state, gridItemClicked: action.payload };
        case 'setOrderedCollection':
          return {
            ...state,
            ...action.payload
          };
        case 'setActiveFolder':
          return {
            ...state,
            activeFolder: action.payload
          };
        case 'toggleOnUpdateEvent':
          return {
            ...state,
            enableOnUpdateEvent: action.payload
          };
        default:
          return state;
      }
    },
    {
      pages: [],
      // needed to set to -1 to avoid tracking on initial load that is triggered on active page change
      activePage: -1,
      gridItemClicked: false,
      showGrid: true,
      activeCategory: 'media',
      activeFilter: navigationFilters.all,
      masonryGrid: useRef(),
      mediaMasonryGrid: useRef(),
      documentMasonryGrid: useRef(),
      verticalGrid: useRef(),
      documentVerticalGrid: useRef(),
      dragXOffsetValue: 0,
      dragXOffset: useMotionValue(0),
      orderedContentCollection: null,
      folders: [],
      showFolders: false,
      activeFolder: '',
      foldersRefs: null,
      enableOnUpdateEvent: false
    }
  );

  return (
    <Context.Provider value={{ SwitcherState, SwitcherStateDispatch }}>
      {props.children}
    </Context.Provider>
  );
};

export const useSwitcherState = () => useContext(Context);

export default SwitcherStore;
