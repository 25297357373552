export default {
    requestResetData: {
        email: null,
        resetting: false,
        resetted: false,
    },
    resetData: {
        resetting: false,
        resetted: false
    },
    error: null
};
