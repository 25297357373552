import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// Components
import ProfileImage from 'components/profileImage/ProfileImage';
import Modal from 'components/modal/Modal';
import Button from 'components/button/Button';

// Helpers
import localizer from 'localization/localizer';
import { Vault, User } from '@prompto-api';
import { getVaultUserPermissionsAction } from 'store/reducers/authReducer/AuthActions';

// Styling
import styled from 'styled-components';
import { modalStepTwo } from 'styles/modalStyles';

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  display: flex;
  flex-flow: row;
  align-items: center;
  color: ${(props) => props.theme.primary900};
`;

const Description = styled.div`
  font-size: 12px;
  line-height: 1.6;
  color: ${(props) => props.theme.primary200};
  display: flex;
  flex-flow: row;
  align-items: center;
`;

const UserRoleDescription = styled(Description)`
  margin-top: 20px;
  margin-bottom: 5px;
`;
const ProjectsDescription = styled(Description)`
  margin-bottom: 20px;
`;

const DescriptionHighlight = styled(Description)`
  margin-left: 3px;
  font-weight: 600;
`;

const ButtonWrapper = styled.div`
  display: flex;
`;

const RejectButton = styled(Button)`
  margin-right: auto;
`;

const VaultInfoWrapper = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  color: ${(props) => props.theme.primary900};
  margin-top: 25px;
  margin-bottom: 25px;
  word-break: break-word;
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
  background: ${(props) => props.theme.gray300};
`;

const BottomDivider = styled(Divider)`
  margin-top: 25px;
`;

const Message = styled.div`
  font-size: 12px;
  line-height: 1.67;
  color: ${(props) => props.theme.primary200};
  margin-top: 10px;
`;

const ErrorMessage = styled(Message)`
  color: ${(props) => props.theme.errorColor};
  text-align: right;
`;

const ProcessingMessage = styled(Message)`
  color: ${(props) => props.theme.primary200};
  text-align: right;
`;

const SuccessMessage = styled(Message)`
  color: ${(props) => props.theme.successColor};
  text-align: right;
`;

const VaultAcceptInvitationModal = ({
  invitation,
  onClose,
  onRemoveInvitation,
  onAcceptedInvitation,
  sessionToken,
  showDecideLaterButton = true,
  successMessageTimeout = 2000,
  getPermissions
}) => {
  const vault = invitation?.publicVaultInfo?.vault;

  const userRole = invitation?.invitation?.permissionRoleObjectId;
  const projectsInfo = invitation?.projectListInfo;

  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [vaultLogo, setVaultLogo] = useState('');

  useEffect(() => {
    setVaultLogo(vault?.logoUri);
  }, [vault]);

  const declineInvitation = () => {
    setIsProcessing(true);

    Vault.uninviteUser(
      invitation?.invitation.subject.objectId,
      invitation?.invitation?.uuid,
      sessionToken
    )
      .then((result) => {
        if (result) {
          const { successful } = result.data;
          setIsProcessing(false);

          if (successful) {
            setSuccess(true);

            setTimeout(() => {
              onClose();
              onRemoveInvitation(invitation?.invitation?.uuid);
            }, successMessageTimeout);
          } else {
            setError(localizer.genericError);
          }
        }
      })
      .catch((e) => {
        setError(localizer.genericError);
        setIsProcessing(false);
      });
  };

  const acceptInvitation = () => {
    setIsProcessing(true);

    User.acceptInvitation(
      invitation?.invitation?.user.objectId,
      invitation?.invitation?.uuid,
      sessionToken
    )
      .then((result) => {
        setIsProcessing(false);

        if (result) {
          const { data } = result;
          setSuccess(true);

          const vaultId = data.user.vaultList?.[0]?.objectId;
          if (vaultId) {
            getPermissions(vaultId, data.user.objectId, sessionToken);
          }

          setTimeout(() => {
            onClose();
            onAcceptedInvitation(
              data.user.vaultList,
              invitation?.invitation?.uuid
            );
          }, successMessageTimeout);
        } else {
          setError(localizer.genericError);
        }
      })
      .catch((e) => {
        setError(localizer.genericError);
        setIsProcessing(false);
      });
  };

  return (
    <Modal
      styles={{
        modal: {
          ...modalStepTwo,
          position: 'static',
          alignSelf: 'center',
          maxWidth: 500
        }
      }}
      open={true}
      onClose={onClose}
      closeOnOverlayClick={true}
      closeOnEsc={true}
      showCloseIcon={false}
      center
    >
      <ContentWrapper>
        <Title>{localizer.youHaveBeenInvited}</Title>
        <Divider />
        <VaultInfoWrapper>
          <ProfileImage
            profileImageUri={vaultLogo}
            data-testid="companyLogo"
            thumbnailStyles={`
              width: 25px !important;
              height: 25px !important;
              margin: 0;
              margin-left: 5px;
              margin-right: 5px;
              display: flex;
              flex-shrink: 0;
            `}
          />
          {vault.name}
        </VaultInfoWrapper>
        <UserRoleDescription>
          {localizer.yourUserRole}
          <DescriptionHighlight>{`${localizer[userRole]} - ${
            localizer[`${userRole}Description`]
          }`}</DescriptionHighlight>
        </UserRoleDescription>
        {projectsInfo?.length > 0 && (
          <ProjectsDescription>
            {localizer.youWillHaveAccessTo}
            <DescriptionHighlight>
              {projectsInfo.map((info) => {
                return info?.vmProjectSection?.title;
              })}
            </DescriptionHighlight>
          </ProjectsDescription>
        )}
        <BottomDivider />
        <ButtonWrapper>
          <RejectButton
            onClickAction={declineInvitation}
            status={isProcessing ? 'processing' : 'default'}
            label={localizer.declineInvitation}
          />
          {showDecideLaterButton && (
            <Button
              onClickAction={() => {
                onClose();
              }}
              status={isProcessing ? 'processing' : 'default'}
              label={localizer.decideLater}
            />
          )}
          <Button
            onClickAction={acceptInvitation}
            type="submit"
            status={isProcessing ? 'processing' : 'default'}
            label={localizer.acceptInvitation}
          />
        </ButtonWrapper>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {isProcessing && (
          <ProcessingMessage>{localizer.processing}</ProcessingMessage>
        )}
        {success && <SuccessMessage>{localizer.success}!</SuccessMessage>}
      </ContentWrapper>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getPermissions: (vaultId, userId, sessionToken) =>
    dispatch(getVaultUserPermissionsAction(vaultId, userId, sessionToken))
});

export default connect(null, mapDispatchToProps)(VaultAcceptInvitationModal);
