import React, { useState } from 'react';
import { array, shape, func, number } from 'prop-types';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
import localizer from 'localization/localizer';

// Styling
import styled from 'styled-components';

const FieldWrapper = styled.div`
  min-width: 140px;
  height: 100%;
  display: flex;
  flex-flow: row;
  align-items: center;
  background: ${props => props.theme.whiteOff};
  border-radius: 3px;
  border: solid 1px ${props => props.theme.gray100};
  cursor: pointer;
`;

const FieldText = styled.p`
  flex-grow: 1;
  font-size: 11px;
  margin-left: 15px;
  color: ${props => props.theme.primary200};
  user-select: none;
`;

const FieldIcon = styled(FontAwesomeIcon)`
  width: 30px !important;
  height: 100%;
  color: ${props => props.theme.primary100};
  padding: 0 10px;
  flex-shrink: 0;
`;

const DropdownWrapper = styled.div`
  position: absolute;
  width: fit-content;
  min-width: 100%;
  top: ${props => props.topOffset}px;
  right: 0;
  padding: 5px 0;
  background: ${props => props.theme.whitePure};
  border-radius: 3px;
  border: solid 1px ${props => props.theme.gray100};
  z-index: 100;
`;

const DropdownWrapperPadding = styled.div`
  padding-top: 5px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 10px 30px;
`;

const Title = styled.p`
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${props => props.theme.primary200};
  user-select: none;
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 5px 30px;
  cursor: pointer;
  &:hover {
    background-color: ${props => props.theme.whiteOff};
  }
`;

const SelectedItemWrapper = styled(ItemWrapper)`
  padding-left: 0;
  background-color: ${props => props.theme.whiteOff};
  flex-flow: row;
`;

const CheckIcon = styled(FontAwesomeIcon)`
  width: 30px !important;
  color: ${props => props.theme.successLight};
  padding: 0 10px;
  flex-shrink: 0;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  flex-grow: 1;
`;

const ItemTitle = styled.p`
  width: 100%;
  font-size: 11px;
  font-weight: 600;
  color: ${props => props.theme.primary300};
  user-select: none;
`;

const ItemDescription = styled.p`
  width: 100%;
  font-size: 10px;
  color: ${props => props.theme.primary200};
  user-select: none;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
`;

const RoleSelectField = ({
  selectedRole,
  roleList,
  onSelectedRoleChange,
  topOffset,
  className
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={className}>
      {isOpen && (
        <Overlay
          onClick={() => {
            setIsOpen(false);
          }}
        />
      )}
      <FieldWrapper
        onClick={() => {
          setIsOpen(open => !open);
        }}
      >
        <FieldText>{selectedRole?.title}</FieldText>
        <FieldIcon
          key="icon"
          icon={['fas', isOpen ? 'caret-up' : 'caret-down']}
          size="sm"
        />
      </FieldWrapper>

      {isOpen && (
        <DropdownWrapperPadding>
          <DropdownWrapper topOffset={topOffset}>
            <TitleWrapper>
              <Title>{localizer.selectYourRole}</Title>
            </TitleWrapper>
            {roleList &&
              roleList.map(role => {
                if (selectedRole && selectedRole.title === role.title) {
                  return (
                    <SelectedItemWrapper
                      key={role.title}
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      <CheckIcon key="icon" icon={['far', 'check']} size="sm" />
                      <TextWrapper>
                        <ItemTitle>{role.title}</ItemTitle>
                        <ItemDescription>{role.description}</ItemDescription>
                      </TextWrapper>
                    </SelectedItemWrapper>
                  );
                }

                return (
                  <ItemWrapper
                    key={role.title}
                    onClick={() => {
                      onSelectedRoleChange(role);
                      setIsOpen(false);
                    }}
                  >
                    <ItemTitle>{role.title}</ItemTitle>
                    <ItemDescription>{role.description}</ItemDescription>
                  </ItemWrapper>
                );
              })}
          </DropdownWrapper>
        </DropdownWrapperPadding>
      )}
    </div>
  );
};

RoleSelectField.propTypes = {
  selectedRole: shape({}),
  roleList: array.isRequired,
  onSelectedRoleChange: func.isRequired,
  topOffset: number
};

RoleSelectField.defaultProps = {
  topOffset: 0
};

export default RoleSelectField;
