import React from 'react';
import { func } from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

// Components
import Modal from 'components/modal/Modal';
import Button from 'components/button/Button';
import Divider from 'components/divider/Divider';

// Helpers
import { userLogoutAction } from 'store/reducers/authReducer/AuthActions';
import { respondTo, tablet, desktop, desktopHd } from 'styles/mixins';
import localizer from 'localization/localizer';
import Cookies from 'universal-cookie';
import env from 'environment';

// Styling
import styled from 'styled-components';
import { modalStepTwo } from 'styles/modalStyles';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 50px;
  max-width: 100%;

  ${respondTo([tablet, desktop, desktopHd])} {
    max-width: 500px;
  }
`;

const Title = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: ${(props) => props.theme.errorColor};
`;

const Subtitle = styled.p`
  font-size: 12px;
  color: ${(props) => props.theme.primary200};
  margin-bottom: 20px;
`;

const Description = styled.p`
  font-size: 12px;
  color: ${(props) => props.theme.primary200};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CompanyInfoWrapper = styled.div`
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.gray100};
  background-color: ${(props) => props.theme.whiteOff};
  margin-top: 20px;
  display: flex;
  flex-flow: column;
  padding: 10px 15px;
`;

const CompanyName = styled.p`
  font-size: 12px;
  font-weight: bold;
  color: ${(props) => props.theme.primary400};
  margin-bottom: 10px;
`;

const CompanyInfo = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'e ei' 'p pi';
  grid-row-gap: 8px;
`;

const CompanyInfoLabel = styled.p`
  font-size: 12px;
  color: ${(props) => props.theme.primary100};
`;

const CompanyInfoText = styled.p`
  font-size: 12px;
  font-weight: 600;
  color: ${(props) => props.theme.primary200};
`;

const EmailLabel = styled(CompanyInfoLabel)`
  grid-area: e;
`;

const PhoneLabel = styled(CompanyInfoLabel)`
  grid-area: p;
`;
const EmailText = styled(CompanyInfoText)`
  grid-area: ei;
`;

const PhoneText = styled(CompanyInfoText)`
  grid-area: pi;
`;

const InactiveAccountModal = ({
  navigate,
  handleLogout,
  VaultReducers,
  AuthReducer
}) => {
  const cookies = new Cookies();
  const serviceAccount360CookieName = `AroundMedia/${env().env}/sast`;

  const { vault } = VaultReducers;
  const { allowedVaultOperationDictionary } = AuthReducer;

  let canSeeBilling = false;
  const operations = allowedVaultOperationDictionary[vault?.objectId];
  if (operations) {
    canSeeBilling = operations.includes('vaultWrite');
  }

  return (
    <Modal
      open
      center
      showCloseIcon={false}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      onClose={() => {}}
      styles={{
        modal: modalStepTwo
      }}
    >
      <ContentWrapper>
        <Title>{localizer.accountInactiveTitle}</Title>
        <Divider marginSize={25} />
        <Subtitle>{localizer.accountInactiveSubtitle}</Subtitle>
        <Description>
          {canSeeBilling
            ? localizer.accountInactiveDescriptionManager
            : localizer.accountInactiveDescriptionUser}
        </Description>
        {!canSeeBilling && (
          <CompanyInfoWrapper>
            <CompanyName>{vault.name}</CompanyName>
            <CompanyInfo>
              <EmailLabel>{localizer.email}</EmailLabel>
              <EmailText>{vault.email}</EmailText>
              <PhoneLabel>{localizer.phoneNumber}</PhoneLabel>
              <PhoneText>{vault.phoneNumber}</PhoneText>
            </CompanyInfo>
          </CompanyInfoWrapper>
        )}
        <Divider marginSize={25} />
        <ButtonsWrapper>
          <Button
            label={localizer.backToLogin}
            type="default"
            onClickAction={() => {
              cookies.remove(serviceAccount360CookieName, {
                domain: env().applicationDomain,
                path: '/'
              });
              handleLogout(AuthReducer.sessionToken);
            }}
          />
          {canSeeBilling && (
            <Button
              label={localizer.manageBilling}
              type="submit"
              onClickAction={() => {
                navigate(`/admin/billing${window.location.search}`);
              }}
            />
          )}
        </ButtonsWrapper>
      </ContentWrapper>
    </Modal>
  );
};

InactiveAccountModal.propTypes = {
  handleLogout: func
};

InactiveAccountModal.defaultProps = {};

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  AuthReducer: state.store.AuthReducer,
  VaultReducers: state.store.VaultReducers,
  AppReducer: state.store.AppReducer,
  router: state.router
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch) => ({
  navigate: (newPath) => dispatch(push(newPath)),
  handleLogout: (sessionToken) => dispatch(userLogoutAction(sessionToken))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InactiveAccountModal);
