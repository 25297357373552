import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Components
import Modal from 'components/modal/Modal';
import InviteUserForm from 'components/views/invite/InviteUserForm';

const InviteUserModal = (props) => {
  const [processing, setProcessing] = useState(false);

  const { inviting, onClose, ...propsToPassAlong } = props;

  return (
    <Modal
      styles={{
        overlay: { padding: 0 },
        modal: {
          maxWidth: 'none',
          width: '100%',
          height: 'auto',
          minHeight: '100%',
          padding: '20px 0',
          display: 'flex',
          alignItems: 'center'
        }
      }}
      open={inviting}
      onClose={() => {
        onClose();
      }}
      closeOnOverlayClick={!processing}
      closeOnEsc={!processing}
      showCloseIcon={true}
      center
    >
      <InviteUserForm
        {...propsToPassAlong}
        onInvitingStart={() => {
          setProcessing(true);
        }}
        onInvitingEnd={() => {
          setProcessing(false);
          onClose();
        }}
      />
    </Modal>
  );
};

InviteUserModal.propTypes = {
  /** Is currently inviting users, this means the modal should be visible */
  inviting: PropTypes.bool.isRequired,
  /**
   * Called when the user requested this modal to be closed
   * @callback
   */
  onClose: PropTypes.func.isRequired
};

export default InviteUserModal;
