// Store/Redux
import { createStore, combineReducers, applyMiddleware } from 'redux';

// Redux thunk
import thunk from 'redux-thunk';

// Redux logger
import { logger } from 'redux-logger';

// Router setup
import { routerReducer, routerMiddleware } from 'react-router-redux';

// Redux Promise middleware
import promise from 'redux-promise-middleware';

import { loadPersistedState } from './localstore';

// Store reducers
import rootReducer from './reducers'; // Or wherever you keep your reducers

// Import history
import history from './history';

// Build the middleware for intercepting and dispatching navigation actions
const builtRouterMiddleware = routerMiddleware(history);
let store;
const isProd = import.meta.env.VITE_APP_PROD === 'true';
const middleware = isProd
  ? applyMiddleware(builtRouterMiddleware, promise(), thunk)
  : applyMiddleware(builtRouterMiddleware, promise(), thunk, logger);

// Add the reducer to your store on the `router` key
// Also apply our middleware for navigating
const create = (persistedState) =>
  createStore(
    combineReducers({
      store: rootReducer,
      router: routerReducer
    }),
    persistedState,
    middleware
  );

const storeBuilder = (sessionToken) =>
  new Promise((resolver) => {
    loadPersistedState(sessionToken)
      .then((state) => {
        store = create(state);
        resolver(store);
      })
      .catch(() => {
        store = create();
        resolver(store);
      });
  });

export default storeBuilder;
