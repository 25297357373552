import { User, Vault } from '@prompto-api';

export function getVaultList(sessionToken, callback) {
  let vaultList = [];
  let invitationList = [];

  // get the user id
  if (sessionToken !== undefined && User.getInfo) {
    User.getInfo(sessionToken).then((result) => {
      if (result) {
        // get the vault ifo for each vaultId
        let promiseList = result?.data?.user?.vaultList.map((vault) => {
          return Vault.getInfo(vault.objectId, sessionToken).catch((e) => {});
        });

        Promise.all(promiseList).then((resVaultInfoList) => {
          vaultList = resVaultInfoList.map((vaultInfo) => {
            return vaultInfo?.data?.vault;
          });

          invitationList = result?.data?.pendingInvitationList;

          let apiReturnObject = {
            vaultList,
            invitationList,
          };
          callback(apiReturnObject);
        });
      }
    });
  }
}
