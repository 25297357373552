import { configureScope } from '@sentry/browser';
import { vaultOperationIsAllowed } from '../../helpers/util';

const VaultInfoCall = (AuthReducer, vaultInfoDispatcher) => {
  if (!AuthReducer) {
    return;
  }
  const { user, sessionToken, allowedVaultOperationDictionary } = AuthReducer;
  const { vaultList } = user;
  const firstVault = vaultList[0];
  if (!firstVault) {
    return;
  }

  const [vaultPurchaseInfoRead, vaultUserRead] = vaultOperationIsAllowed(
    allowedVaultOperationDictionary,
    firstVault.objectId,
    ['vaultPurchaseInfoRead', 'vaultUserRead']
  );

  vaultInfoDispatcher(
    {
      includeVaultPurchaseInfo: vaultPurchaseInfoRead,
      includeUserList: vaultUserRead,
      includeInvitationList: vaultUserRead,
      includeBillingInfo: vaultPurchaseInfoRead
    },
    firstVault.objectId,
    sessionToken
  );
  configureScope((scope) => {
    scope.setExtra('vaultId', firstVault.objectId);
  });
};

export default VaultInfoCall;
