// Base
import React, { useState, forwardRef } from 'react';
import { string, bool, func, shape } from 'prop-types';
import { MdAutorenew, MdWarning, MdCheck } from 'react-icons/md';
import { IconContext } from 'react-icons';
import styled, { css, keyframes, withTheme } from 'styled-components';

const rotation = keyframes`
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  `;

const StyledButton = styled.button`
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', 'Helvetica Neue',
    'proxima-nova', sans-serif;
  font-weight: 400;
  list-style: none;
  box-sizing: border-box;
  outline: none;

  display: inline-block;
  position: relative;
  width: auto;
  height: 36px;
  line-height: 36px;
  padding: 0 10px;
  background: ${({ theme }) => theme.primary900};
  text-align: center;
  color: ${({ theme }) => theme.whiteOff};
  font-size: 0.75rem;
  text-decoration: none;
  border-radius: 3px;
  border: 1px solid transparent;
  cursor: pointer;
  margin: 0 5px;

  span {
    display: block;
    line-height: normal;
    padding: 0 5px;
    float: left;
    white-space: nowrap;
    &::selection {
      -webkit-user-select: none; /* Safari */
      user-select: none;
    }

    &:first-letter {
      text-transform: capitalize;
    }
  }

  ${({ status }) => {
    if (status === 'processing') {
      return css`
        span {
          cursor: default;
          animation: ${rotation} 4s linear infinite;
          padding: 0;
          & svg {
            margin-top: -1px;
          }
        }
      `;
    }
    if (status === 'error' || status === 'denied') {
      return css`
        cursor: default;
        color: ${({ theme }) => theme.whiteOff};
        background: ${({ theme }) => theme.errorColor} !important;
        span {
          padding: 0;
        }
      `;
    }
    if (status === 'success') {
      return css`
        cursor: default;
        color: ${({ theme }) => theme.whiteOff};
        background: ${({ theme }) => theme.accentMenuDefault} !important;
        font-weight: 800;
        span {
          padding: 0;
        }
      `;
    }
    if (status === 'disabled') {
      return css`
        cursor: default;
        color: ${({ theme }) => theme.textDisabled} !important;
        background: ${({ theme }) => theme.whiteOff} !important;
      `;
    }
    if (status === 'custom') {
      return css`
        padding: 0;
        & span {
          margin-top: -1px;
        }
      `;
    }
    if (status === 'customDisabled') {
      return css`
        padding: 0;
        cursor: default;
        color: ${({ theme }) => theme.textDisabled} !important;
        background: ${({ theme }) => theme.whiteOff} !important;
        & span {
          margin-top: -1px;
        }
      `;
    }
  }}
  ${({ type }) => {
    const baseLink = css`
      background: none;
      color: inherit;
      &:visited {
        color: ${({ theme }) => theme.descriptionActions};
      }
      &:hover {
        ${({ status }) => {
          if (status !== 'disabled') return 'background: none !important;';
          return '';
        }}
      }
    `;
    if (type === 'highlight') {
      return css`
        background: ${({ theme }) => theme.accentMenuDefault};
        color: ${({ theme }) => theme.whitePure};
      `;
    }
    if (
      type === 'default' ||
      type === 'cancel' ||
      type === 'close' ||
      type === 'previous'
    ) {
      return css`
        background: ${({ theme }) => theme.whiteOff};
        color: ${({ theme }) => theme.projectDescription};
      `;
    }
    if (type === 'submit' || type === 'next' || type === 'action') {
      return css`
        background: ${({ theme }) => theme.primary900};
        color: ${({ theme }) => theme.whiteOff};
      `;
    }
    if (type === 'warning') {
      return css`
        background: ${({ theme }) => theme.warning};
        color: ${({ theme }) => theme.whiteOff};
      `;
    }
    if (type === 'error' || type === 'delete') {
      return css`
        background: ${({ theme }) => theme.errorColor};
        color: ${({ theme }) => theme.whiteOff};
      `;
    }
    if (type === 'link') {
      return baseLink;
    }
    if (type === 'link-warning') {
      return css`
        ${baseLink}
        &:hover {
          ${({ status }) => {
            if (status !== 'disabled')
              return `
              background: none !important;
              color: ${({ theme }) => theme.warning};
            `;
            return '';
          }}
        }
      `;
    }
    if (type === 'link-error') {
      return css`
        ${baseLink}
        &:hover {
          ${({ status }) => {
            if (status !== 'disabled')
              return `
              background: none !important;
              color: ${({ theme }) => theme.errorColor};
            `;
            return '';
          }}
        }
      `;
    }
    return '';
  }}
  ${({ size }) => {
    if (size === 'small') {
      return css`
        height: 30px;
        line-height: 30px;
        text-align: center;
        svg {
          font-size: 1.25rem;
          float: none;
        }
      `;
    }
    return '';
  }}
`;

const Button = forwardRef(
  (
    {
      status,
      onClickAction,
      hidden,
      label,
      type,
      size,
      IconComponent,
      style,
      spanStyle,
      stopPropagation,
      dataTestId,
      children,
      accent,
      className,
      theme,
      disabled,
      title
    },
    ref
  ) => {
    const [mouseOver, setMouseOver] = useState(false);

    const onClickHandler = (event) => {
      if (stopPropagation) {
        event.stopPropagation();
      }
      if (status === 'default' || status === 'custom') {
        onClickAction(event);
      }
    };

    if (hidden) {
      return <div ref={ref} />;
    }

    let buttonContent = label;
    let leftIcon;
    let rightIcon;

    // Build button according to status prop
    // (processing | error | success | disabled | default)
    switch (status) {
      case 'processing':
        buttonContent = <MdAutorenew />;
        break;
      case 'error':
      case 'denied':
        buttonContent = <MdWarning />;
        break;
      case 'success':
        buttonContent = <MdCheck />;
        break;
      case 'disabled':
        break;
      case 'custom':
        if (!children) {
          buttonContent = <IconComponent /> || <div />;
        }
        break;
      case 'customDisabled':
        if (!children) {
          buttonContent = <IconComponent /> || <div />;
        }
        break;
      default:
    }

    // If the status doesn't match disabled or default, hide arrow icons!
    if (status !== 'disabled' && status !== 'default') {
      leftIcon = null;
      rightIcon = null;
    }

    const getButtonStyleFromContext = ({
      primaryColor,
      primary900,
      primary400,
      primaryButtonColor,
      accentButtonColor,
      secondaryColor,
      gray400,
      primary200
    }) => {
      let buttonStyle = style;

      let color =
        primaryButtonColor || primary400 || primaryColor || primary900;
      if (accent) {
        color = accentButtonColor || secondaryColor;
      }
      if (typeof type !== 'undefined') {
        switch (type) {
          case 'submit':
          case 'next':
          case 'action':
          case 'active':
            buttonStyle = {
              background: color,
              ...style
            };
            break;
          case 'link':
            if (mouseOver) {
              buttonStyle = {
                color,
                ...style
              };
            }

            break;
          case 'default':
            buttonStyle = {
              border: `1px solid ${gray400}`,
              background: 'white',
              color: primary200,
              ...buttonStyle
            };
            break;
          default:
            return;
        }
      }
      return buttonStyle;
    };

    return (
      <IconContext.Provider value={{ style: { verticalAlign: 'middle' } }}>
        <StyledButton
          ref={ref}
          id={`button_${dataTestId}`}
          onClick={onClickHandler}
          style={getButtonStyleFromContext(theme)}
          data-testid={dataTestId}
          onPointerEnter={() => {
            setMouseOver(true);
          }}
          onPointerLeave={() => {
            setMouseOver(false);
          }}
          status={status}
          type={type}
          size={size}
          accent={accent}
          className={className}
          disabled={disabled}
          title={title}
        >
          {leftIcon}
          <span style={spanStyle}>{children || buttonContent}</span>
          {rightIcon}
        </StyledButton>
      </IconContext.Provider>
    );
  }
);

Button.propTypes = {
  /** Is button hidden */
  hidden: bool,
  /** Text shown on button */
  label: string,
  /** Action callback for when the button is clicked */
  onClickAction: func.isRequired,
  /** The only option right now is small */
  size: string,
  /** Choose from: default, processing, error, denied, success, disabled, custom */
  status: string,
  /** Choose from: default, close, previous, submit, action, next, highlight, warning, error, delete, link */
  type: string,
  /** Choose an additional className to add */
  className: string,
  /** Icon to show on the button */
  IconComponent: func,
  style: shape({}),
  spanStyle: shape({}),
  /** Stops propagation to other elements */
  stopPropagation: bool,
  /** Data test id for automated testing */
  dataTestId: string,
  /** use AccentButtonColor or not */
  accent: bool,
  title: string,
};

Button.defaultProps = {
  status: 'default',
  label: 'Button',
  type: 'default',
  size: 'small',
  hidden: false,
  className: null,
  IconComponent: null,
  style: {},
  spanStyle: {},
  stopPropagation: true,
  dataTestId: null,
  accent: false,
  title: ''
};

export default withTheme(Button);
