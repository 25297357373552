// Base
import React from 'react';
import { string, element } from 'prop-types';

// Components
import ImageUploadTrigger from 'components/imageUploadTrigger/ImageUploadTrigger';
import Image from 'components/image/Image';

// Styling
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  display: block;
  position: relative;
  width: 180px;
  height: 180px;
  padding: 0;
  margin: 10px auto 20px auto;
  border: 1px solid ${({ theme }) => theme.borderDarkerColor};
  border-radius: 3px;
  background: transparent;
  overflow: hidden;
  ${({ styles }) => styles};

  ${({ noImage }) => {
    if (noImage) {
      return css`
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        img {
          display: block;
          position: relative;
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
        }
      `;
    }
  }}
`;

const ThumbnailImage = ({
  thumbnailImageUri,
  placeholderComponent,
  overlayComponent,
  uploadEnabled,
  triggerActive,
  triggerTitle,
  onUploadTrigger,
  dataTestId,
  styles,
  className
}) => {
  if (!overlayComponent) {
    overlayComponent = uploadEnabled ? (
      <ImageUploadTrigger
        active={triggerActive}
        title={triggerTitle}
        onUploadTrigger={onUploadTrigger}
        uploadType="profile"
        iconSize="medium"
      />
    ) : null;
  }
  return (
    <Wrapper
      noImage={!thumbnailImageUri || thumbnailImageUri === ''}
      data-testid={dataTestId}
      className={className}
      styles={styles}
    >
      <Image
        imageUri={thumbnailImageUri}
        placeholderComponent={placeholderComponent}
      />
      {overlayComponent}
    </Wrapper>
  );
};

ThumbnailImage.propTypes = {
  /** Image URI for the thumbnail image (optional) */
  thumbnailImageUri: string,
  /** Component to render when there is no imageURI (optional) */
  placeholderComponent: element,
  /** Component to render over the image (optional) */
  overlayComponent: element,
  /** Choose an additional className to add */
  className: string,
  /** Data test id for automated testing */
  dataTestId: string
};

ThumbnailImage.defaultProps = {
  thumbnailImageUri: null,
  overlayComponent: null,
  className: null,
  dataTestId: null
};

export default ThumbnailImage;
