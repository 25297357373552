// Base
import React from 'react';
import { string, bool, func } from 'prop-types';
import classNames from 'classnames';
import { MdFileUpload } from 'react-icons/md';

// Styling
import styled, { withTheme, css } from 'styled-components';

const StyledFileUploadIcon = styled(MdFileUpload)``;

const TriggerIcon = styled.div`
  display: block;
  position: relative;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  line-height: 40px;
  border-radius: 60px;
  background: rgb(214, 185, 89);

  color: #f9fcff;
  text-align: center;
  opacity: 0.8;

  transition: 0.2s background linear;

  .afl-icon {
    line-height: inherit;
    font-size: inherit;
  }

  ${({ iconSize }) => {
    let size;
    let fontSize;
    switch (iconSize) {
      case 'small':
        size = '40px';
        fontSize = '1.25rem';
        break;
      case 'medium':
        size = '50px';
        fontSize = '1.5rem';
        break;
      case 'large':
        size = '60px';
        fontSize = '1.875rem';
        break;
      default:
    }
    return css`
      width: ${size};
      height: ${size};
      line-height: ${size};
      ${StyledFileUploadIcon} {
        font-size: ${fontSize};
      }
    `;
  }}
`;

const Wrapper = styled.div`
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  opacity: 0;
  transition: 0.2s opacity linear;

  ${({ active, theme }) => {
    if (active) {
      return css`
        &:hover {
          opacity: 1;
          ${TriggerIcon} {
            background: ${theme.warningColor};
          }
        }
      `;
    }
  }}
  ${({ styles }) => styles}
`;

const TriggerTitle = styled.strong`
  display: block;
  position: relative;
  margin-top: 10px;
  font-weight: 400;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.mainTextColor1};
  transition: color 0.2s linear;
`;

const TriggerInfo = styled.div`
  display: block;
  margin: 0 auto;
  text-align: center;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
`;

/**
 * Static Handler for click or keydowns
 * @param {Event} event - interaction event (click or keydown)
 * @param {function} onUploadTrigger - Callback to be called when the upload is triggered
 * @param {string} uploadType - Type of upload - "profile" - "cover"
 */
const handleUpload = (event, onUploadTrigger, uploadType) => {
  if (event) {
    event.preventDefault();
    event.stopPropagation();
  }

  onUploadTrigger(uploadType);
};

const ImageUploadTrigger = ({
  active,
  overlay,
  onUploadTrigger,
  uploadType,
  iconSize,
  title,
  className,
  dataTestId,
  styles
}) => {
  return (
    <Wrapper
      active={active}
      overlay={overlay}
      className={classNames(className)}
      data-testid={dataTestId}
      onKeyDown={(e) => {
        handleUpload(e, onUploadTrigger, uploadType);
      }}
      onClick={(e) => {
        handleUpload(e, onUploadTrigger, uploadType);
      }}
      styles={styles}
    >
      <TriggerInfo>
        <TriggerIcon iconSize={iconSize}>
          <StyledFileUploadIcon />
        </TriggerIcon>

        {title && title !== '' && <TriggerTitle>{title}</TriggerTitle>}
      </TriggerInfo>
    </Wrapper>
  );
};

ImageUploadTrigger.propTypes = {
  /** If active, trigger is shown by default (eg. empty cover image) */
  active: bool,

  /** If trigger is overlayed on existing images, specific styling is applied */
  overlay: bool,

  /** Used to display a title below the upload icon (optional) */
  title: string,

  /** 'small', 'medium' or 'large' */
  iconSize: string,

  /** Called when upload is triggered */
  onUploadTrigger: func,

  /** Type of upload - 'profile', 'cover' */
  uploadType: string.isRequired,
  /** Choose an additional className to add */
  className: string,
  /** Data test id for automated testing */
  dataTestId: string
};

ImageUploadTrigger.defaultProps = {
  active: false,
  overlay: false,
  title: '',
  iconSize: 'medium',
  onUploadTrigger: (uploadType) => {
    console.log(`upload clicked with uploadType ${uploadType}`);
  },
  className: null,
  dataTestId: null
};

export default withTheme(ImageUploadTrigger);
