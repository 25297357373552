import React, { useState, useEffect } from 'react';
import { string, bool, func, shape, number } from 'prop-types';

import { default as SimpleCheckbox } from 'react-simple-checkbox';

import styled from 'styled-components';

const StyledSimpleCheckbox = styled(SimpleCheckbox)`
  width: 20px !important;
  height: 20px !important;
  position: inherit !important;
  border-radius: 2px;
  margin-right: 2px;
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:focus {
    outline: none;
  }

  * {
    width: 20px;
    height: 20px;
  }
`;

const LabelWrapper = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row wrap;
  padding: 5px;
  cursor: pointer;
`;

const Label = styled.p`
  font-size: 0.8125rem;
  padding-bottom: 1px;
  user-select: none;
  color: ${({ color, theme }) => color ?? theme.primary200};
`;

const Checkbox = ({
  className,
  colorObject,
  isChecked,
  label,
  size,
  tickSize,
  borderThickness,
  onChecked,
  onUnchecked,
  dataTestId,
  updateIsChecked,
  disabled
}) => {
  const [checked, setChecked] = useState(isChecked);

  useEffect(() => {
    if (updateIsChecked) setChecked(isChecked);
  }, [isChecked, updateIsChecked]);

  const onChange = () => {
    if (disabled) return;
    if (!checked) {
      onChecked();
    } else {
      onUnchecked();
    }

    setChecked(!checked);
  };

  const checkboxProps = {
    id: 'checkbox',
    size,
    tickSize,
    borderThickness,
    checked,
    disabled
  };

  if (colorObject) {
    checkboxProps.color = colorObject;
  }

  // Checkbox
  const checkboxComp = (
    <StyledSimpleCheckbox data-testid={dataTestId} {...checkboxProps} />
  );

  // Label
  let labelComp;
  if (label) {
    labelComp = <Label color={colorObject?.labelColor}>{label}</Label>;
  }

  return (
    <LabelWrapper className={className} onClick={onChange} disabled={disabled}>
      {checkboxComp}
      {labelComp}
    </LabelWrapper>
  );
};

Checkbox.propTypes = {
  /** Add a classname to further style the checkbox */
  className: string,
  /** Object containing colors for the checkbox.
   * It has the following properties:
   *  labelColor, backgroundColor, borderColor, uncheckedBorderColor, tickColor
   */
  colorObject: shape({}),
  /** Is the checkbox checked */
  isChecked: bool,
  /** Size (values 1, 2, 3) -- see React-Simple-Checkbox */
  size: number,
  /** TickSize (values 1, 2, 3) -- see React-Simple-Checkbox */
  tickSize: number,
  /** Border thickness (values 1, 2, 3, 4) in pixels -- see React-Simple-Checkbox */
  borderThickness: number,
  /** Label set behind the checkbox */
  label: string,
  /** Time of the animation */
  onChecked: func,
  /** Function called when checkbox is unchecked */
  onUnchecked: func,
  /** Data test id for automated testing */
  dataTestId: string
};

Checkbox.defaultProps = {
  className: '',
  colorObject: null,
  isChecked: false,
  size: 2,
  tickSize: 3,
  borderThickness: 3,
  label: '',
  onChecked: () => {},
  onUnchecked: () => {},
  dataTestId: ''
};

export default Checkbox;
