import React, { useState, useEffect, useCallback } from 'react';
import { func, string, shape } from 'prop-types';

// Components
import Modal from 'components/modal/Modal';
import Button from 'components/button/Button';
import Divider from 'components/divider/Divider';

// Helpers
import localizer from 'localization/localizer';
import env from 'environment';
import { vaultCheckSubscriptionAction } from 'store/reducers/subscriptionReducer/SubscriptionActions';
import { sales } from 'config/emails.json';
import copy from 'copy-to-clipboard';

// Redux
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

// Styling
import styled, { withTheme } from 'styled-components';
import { styledRespondTo } from 'styles/mixins';
import { modalStepTwo } from 'styles/modalStyles';

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 25px;
  color: ${(props) => props.theme.primary900};
`;

const Description = styled.p`
  font-size: 12px;
  line-height: 1.6;
  color: ${(props) => props.theme.primary200};
`;

const ButtonWrapper = styled.div`
  display: flex;
`;

const LearnMoreButton = styled.p`
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  padding: 5px;
  margin: 5px 0;
  margin-right: auto;
  color: ${(props) => props.theme.primary200};
`;
const ContactUs = styled.p`
  font-size: 12px;
  line-height: 1.6;
  color: ${(props) => props.theme.primary200};
  margin-top: 10px;
`;

const Link = styled.span`
  font-size: 12px;
  font-weight: 600;
  color: ${(props) => props.theme.primary400};
`;

const AddressCopiedMessage = styled.div`
  font-size: 12px;
  font-weight: 600;
  display: flex;
  margin-left: 15px;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.successColor};
`;

const StyledButton = styled(Button)`
  margin: 5px;
`;

const StyledSubmitButton = styled(Button)`
  margin: 5px;
  background-color: ${({ theme }) => theme.successColor}; !important;
`;

const Wrapper = styled.div`
  width: 100%;
  min-width: 100%;
  padding: 40px 10px;

  ${styledRespondTo.md`
    width: 600px;
    min-width: 600px;
    padding: 30px 50px;
`}
`;

const UpsellIncentiveModal = ({
  navigate,
  initiator,
  SubscriptionReducers,
  vaultCheckSubscription,
  onClose,
  vaultId,
  sessionToken,
  theme
}) => {
  const [currentSubscription, setCurrentSubscription] = useState(
    SubscriptionReducers?.currentSubscription
  );

  const [showEventTracked, setShowEventTracked] = useState(false);
  const [openingClient, setOpeningClient] = useState(false);
  const [isFreemium, setIsFreemium] = useState(false);

  const updateSubscription = useCallback(async () => {
    vaultCheckSubscription(vaultId, sessionToken);
  }, [vaultId, sessionToken, vaultCheckSubscription]);

  const onModalClose = () => {
    onClose();
  };

  const onLearnMore = () => {
    window.open(env().essentialPackageInfo, '_blank');
  };

  const onUpgradeNow = () => {
    onClose();
    navigate('/admin/billing?openUpgradeModal=true');
  };

  const onContactUs = () => {
    copy(sales);
    setOpeningClient(true);
    setTimeout(() => {
      setOpeningClient(false);
    }, 2000);
    document.location.href = `mailto:${sales}`;
  };

  useEffect(() => {
    if (SubscriptionReducers) {
      setCurrentSubscription(SubscriptionReducers.currentSubscription);
    }
  }, [SubscriptionReducers]);

  useEffect(() => {
    if (!showEventTracked) {
      setShowEventTracked(true);

      updateSubscription();
    }

    if (currentSubscription) {
      setIsFreemium(currentSubscription.freemium);
    }
  }, [
    currentSubscription,
    updateSubscription,
    initiator,
    vaultId,
    showEventTracked
  ]);

  const createContactUs = (extra) => (
    <ContactUs>
      {`${localizer.contactUsAt} `}
      <Link>{sales}</Link>
      {` ${localizer.toUpgradeYourProjectLimit}`}
      {extra && ` ${extra}`}
      {`.`}
    </ContactUs>
  );

  let title;
  let description;
  let contactUs;
  switch (initiator) {
    default:
    case 'ProjectLimit':
      // There is a project limit on the freemium package, but also on the essentials package. So display content accordingly
      if (isFreemium) {
        title = localizer.projectLimitUpsellTitle;
        description = localizer.formatString(
          localizer.projectLimitUpsellDescription,
          currentSubscription?.maxProjectCount || env().defaultMaxProjectCount
        );
      } else {
        title = localizer.projectLimitEssentialUpsellTitle;
        description = localizer.formatString(
          localizer.projectLimitEssentialUpsellDescription,
          currentSubscription?.maxProjectCount || env().defaultMaxProjectCount
        );
        contactUs = createContactUs();
      }
      break;
    case 'ManageProjects':
      // There is a project limit on the freemium package, but also on the essentials package. So display content accordingly
      if (isFreemium) {
        title = localizer.manageProjectsUpsellTitle;
        description = localizer.formatString(
          localizer.manageProjectsUpsellDescription,
          currentSubscription?.maxProjectCount || env().defaultMaxProjectCount
        );
      } else {
        title = localizer.projectLimitEssentialUpsellTitle;
        description = localizer.formatString(
          localizer.projectLimitEssentialUpsellDescription,
          currentSubscription?.maxProjectCount || env().defaultMaxProjectCount
        );
        contactUs = createContactUs();
      }
      break;
    case 'UserLimit':
      title = localizer.userLimitUpsellTitle;
      description = localizer.formatString(
        localizer.userLimitUpsellDescription,
        currentSubscription?.maxUserCount || env().defaultMaxUserCount
      );
      break;
    case 'UpgradePlan':
      title = localizer.changeSubscriptionUpsellTitle;
      description = localizer.formatString(
        localizer.changeSubscriptionUpsellDescription
      );
      break;
    case '360Button':
      title = localizer.upsell360Title;
      description = localizer.formatString(localizer.upsell360Description);
      break;
    case 'UnitExperiences':
      title = localizer.experiencesUpsellTitle;
      description = localizer.formatString(
        localizer.experiencesUpsellDescription
      );
      break;
    case 'Contracts':
      title = localizer.digitalSigningUpsellTitle;
      description = localizer.formatString(
        localizer.digitalSigningUpsellDescription
      );
      break;
  }

  let actionButton = (
    <StyledSubmitButton
      onClickAction={() => {
        onUpgradeNow();
      }}
      type="submit"
      label={localizer.upgradeNow}
    />
  );

  if (!isFreemium) {
    actionButton = (
      <StyledSubmitButton
        onClickAction={() => {
          onContactUs();
        }}
        type="submit"
        label={localizer.contactUs}
      />
    );
  }

  // buttons
  const actions = (
    <ButtonWrapper>
      <LearnMoreButton
        onClick={() => {
          onLearnMore();
        }}
      >
        {localizer.learnMore}
      </LearnMoreButton>
      <StyledButton
        onClickAction={() => {
          onModalClose();
        }}
        label={localizer.cancel}
      />

      {openingClient ? (
        <AddressCopiedMessage> {localizer.emailCopied}</AddressCopiedMessage>
      ) : (
        actionButton
      )}
    </ButtonWrapper>
  );

  return (
    <Modal
      open={true}
      onClose={onModalClose}
      closeOnOverlayClick={true}
      closeOnEsc={true}
      showCloseIcon={false}
      center
      styles={{
        modal: modalStepTwo
      }}
    >
      <Wrapper>
        <Title>{title}</Title>
        <Description>{description}</Description>
        {!isFreemium && contactUs}
        <Divider thickness={1} marginSize={25} color={theme.gray300} />
        {actions}
      </Wrapper>
    </Modal>
  );
};

UpsellIncentiveModal.propTypes = {
  initiator: string.isRequired,
  SubscriptionReducers: shape({}),
  onClose: func.isRequired,
  vaultId: string.isRequired
};

UpsellIncentiveModal.defaultProps = {
  SubscriptionReducers: null
};

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  SubscriptionReducers: state.store.SubscriptionReducers,
  router: state.router
});
/* istanbul ignore next */
const mapDispatchToProps = (dispatch) => ({
  vaultCheckSubscription: (vaultObjectId, sessionToken) => {
    dispatch(vaultCheckSubscriptionAction(vaultObjectId, sessionToken));
  },
  navigate: (newPath) => dispatch(push(newPath))
});

export default withTheme(
  connect(mapStateToProps, mapDispatchToProps)(UpsellIncentiveModal)
);
