import React from 'react';
import { string } from 'prop-types';

import strings from 'localization/localizer';
import Svg from 'resources/icons/PlaceholderEmptyPage';

// Styling
import styled from 'styled-components';
import { styledRespondTo } from 'styles/mixins';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  height: 100%;
`;
const Image = styled(Svg)`
  color: inherit;
  max-width: 300px;
  padding-top: 40px;
  ${styledRespondTo.lg`
    padding-top: 100px;
`}
`;
const Text = styled.p`
  padding-top: 20px;
  color: #110820;
  font-weight: 600;
  font-size: 1em;
`;

const Placeholder = ({ name, ...props }) => (
  <Wrapper>
    <Image {...props} />
    <Text data-testid="placeholder-text">
      {`${strings.comingSoon} - ${name}`}
    </Text>
  </Wrapper>
);

Placeholder.propTypes = {
  name: string
};

Placeholder.defaultProps = {
  name: 'something new'
};

export default Placeholder;
