import React from 'react';
import { string } from 'prop-types';

import { ReactComponent as FileIcon } from 'resources/Placeholder-EmptyPage_inline.svg';
import { ReactComponent as FolderIcon } from 'resources/Placeholder-EmptyProjects_inline.svg';

import styled, { css } from 'styled-components';

const iconStyle = css`
  max-width: 350px;
  margin-left: 10px;
`;

const FileIconStyled = styled(FileIcon)`
  ${iconStyle}
`;

const FileFolderIcon = styled(FolderIcon)`
  ${iconStyle}
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
`;

const EmptyListText = styled.div`
  max-width: 350px;
  margin-top: 20px;
  line-height: 1.5;
  text-align: center;
`;

const EmptyListIcon = ({ iconType, text, dataTestId }) => {
  let iconComponent;
  switch (iconType) {
    case 'file': {
      iconComponent = <FileIconStyled key="icon" data-testid={'file'} />;
      break;
    }

    case 'folder': {
      iconComponent = <FileFolderIcon key="icon" data-testid={'folder'} />;
      break;
    }

    default: {
      break;
    }
  }

  return (
    <Wrapper data-testid={dataTestId}>
      {iconComponent}
      <EmptyListText key="text">{text}</EmptyListText>
    </Wrapper>
  );
};

EmptyListIcon.propTypes = {
  text: string,
  iconType: string,
  dataTestId: string,
};

EmptyListIcon.defaultProps = {
  text: '',
  iconType: '',
  dataTestId: '',
};

export default EmptyListIcon;
