import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProjectCard from './ProjectCard';
import StyledScrollbarWrapper from 'components/other/StyledScrollbarsWrapper';

// Helpers
import { motion, AnimatePresence } from 'framer-motion';
import localizer from 'localization/localizer';

// Styling
import styled, { css } from 'styled-components';

const MENU_HEIGHT = 40;

const Wrapper = styled(motion.div)`
  height: 100%;
  box-shadow: inset -1px 0 0 0 ${({ theme }) => theme.gray300};
  flex-shrink: 0;
  background: ${({ theme, hovered }) =>
    hovered ? theme.whitePure : theme.grayWhiteOff};
`;

const Menu = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.primary300};
  display: flex;
  flex-direction: column;
  padding: 0;
  span {
    writing-mode: vertical-rl;
    text-orientation: sideways-right;
    transform: rotate(180deg);
  }
  align-items: center;
  transition: all 180ms ease;
  ${({ hovered }) => {
    if (hovered) {
      return `
        height: ${MENU_HEIGHT}px;
        flex-direction: row;
        padding: 0 10px;
        span {
            writing-mode: horizontal-tb;
                transform: rotate(0);
            }
        `;
    }
  }}
`;

const SelectedProjectsIndicator = styled.div`
  position: absolute;
  left: 3px;
  top: 0;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: ${({ theme }) => theme.accentAlt300};
`;

const ShowAllButton = styled(motion.button)`
  background: none;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.accentAlt500};
  font-size: inherit;
  font-weight: 600;
  margin-left: auto;
`;

const Icon = styled(FontAwesomeIcon)``;

const ProjectCardWrapper = styled.div`
  position: relative;
  height: 192px;
  padding: 0 10px;
  transition: all 150ms ease;
  &:hover {
    background: rgba(99, 92, 247, 0.15);
    box-shadow: inset 2px 0 0 0 ${({ theme }) => theme.accentAlt300};
    cursor: pointer;
  }
  ${({ selected }) => {
    if (selected) {
      return css`
        background: rgba(99, 92, 247, 0.15);
        box-shadow: inset 2px 0 0 0 ${({ theme }) => theme.accentAlt300};
      `;
    }
  }}
`;

const ListWrapper = styled(motion.div)`
  position: relative;
`;

const ToggleProjectsButton = styled.button`
  height: 100%;
  align-self: stretch;
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  flex-direction: ${({ opened }) => (opened ? 'row' : 'column')};
  align-items: center;
  & > ${Icon} {
    margin: ${({ opened }) => (opened ? '0 5px 0 0' : '0 0 8px')};
  }
`;

const List = styled(motion.div)`
  min-height: ${({ minHeight }) => minHeight}px;
`;

const ProjectsPanel = ({
  projects,
  selectedProjects,
  setSelectedProject,
  showAll
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const [maxProjectsListHeight, setMaxProjectsListHeight] = useState(0);

  const wrapperRef = useRef();

  const localized = localizer.notifications;

  // Effects
  useEffect(() => {
    if (maxProjectsListHeight > 0) return;
    const timer = setTimeout(() => {
      const wrapperHeight = wrapperRef.current?.getBoundingClientRect()?.height;
      if (wrapperHeight) {
        setMaxProjectsListHeight(wrapperHeight - MENU_HEIGHT);
      }
      clearTimeout(timer);
    }, 450);
  }, [maxProjectsListHeight, wrapperRef]);

  if (projects.length < 2) return null;

  return (
    <Wrapper
      animate={{ width: isOpened ? 240 : 40 }}
      transition={{ ease: 'linear', duration: 0.18 }}
      ref={wrapperRef}
      hovered={isOpened}
    >
      <Menu hovered={isOpened}>
        {selectedProjects?.length > 0 && !isOpened && (
          <SelectedProjectsIndicator />
        )}
        <ToggleProjectsButton
          opened={isOpened}
          onClick={() => setIsOpened(prev => !prev)}
        >
          <Icon
            icon={[
              'far',
              isOpened ? 'angle-double-left' : 'angle-double-right'
            ]}
            size="1x"
          />
          <span>{localized.projects}</span>
        </ToggleProjectsButton>
        <AnimatePresence>
          {isOpened && (
            <ShowAllButton {...sharedAnimation} onClick={showAll}>
              {localized.showAll}
            </ShowAllButton>
          )}
        </AnimatePresence>
      </Menu>

      <ListWrapper
        initial={{ x: -250 }}
        animate={{ x: isOpened ? 0 : -250 }}
        transition={{ ease: 'linear', duration: 0.15 }}
      >
        {maxProjectsListHeight ? (
          <StyledScrollbarWrapper
            autoHeightMin={maxProjectsListHeight}
            autoHeightMax={maxProjectsListHeight}
          >
            <List minHeight={maxProjectsListHeight}>
              {projects.map(project => (
                <ProjectCardWrapper
                  key={`NotificationProjectCard_${project.objectId}`}
                  onClick={() => setSelectedProject(project.objectId)}
                  selected={selectedProjects?.includes(project.objectId)}
                >
                  <ProjectCard
                    project={project}
                    styles={`box-shadow: none; background: transparent; border: none; top: 6px; right: 10px;`}
                    withState={false}
                  />
                </ProjectCardWrapper>
              ))}
            </List>
          </StyledScrollbarWrapper>
        ) : null}
      </ListWrapper>
    </Wrapper>
  );
};

const sharedAnimation = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.1 }
};

/* istanbul ignore next */
const mapStateToProps = state => ({
  projects: state.store.ProjectListReducer?.projects,
  selectedProjects: state.store.NotificationsReducer?.selectedProjects
});

/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({
  setSelectedProject: projectId =>
    dispatch({ type: 'SET_SELECTED_PROJECTS', payload: projectId }),
  showAll: () => dispatch({ type: 'SHOW_ALL_NOTIFICATIONS' })
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsPanel);
