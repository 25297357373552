import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

// Helpers
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { buildAssetURIWithOptions, parseBytesToReadable } from 'helpers/util';
import localizer from 'localization/localizer';
import { Tracking } from '@prompto-api';

// Components
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Styling
import styled from 'styled-components';

const Wrapper = styled(motion.div)`
  position: fixed;
  z-index: 500;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: 0;

  section {
    position: relative;
    bottom: 90px;
    width: 400px;
    max-width: 95vw;
    height: 70px;
    background-color: rgba(0, 0, 0, 0.8);
    color: ${({ theme }) => theme.grayWhiteOff};
    backdrop-filter: blur(2px);
    border-radius: 4px;
    margin: 0 auto 20px;
    padding: 15px;
    display: flex;
    align-items: center;
  }
`;

const Icon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.primary300};
  color: ${({ theme }) => theme.showcaseWhite};
  margin-left: 15px;
  display: flex;
  flex-shrink: 0;
  cursor: pointer;
  transition: all 200ms ease;
  &:hover {
    background-color: ${({ theme, success, error }) =>
      success
        ? theme.successColor
        : error
        ? theme.errorColor
        : theme.primary300};
  }
`;

const StyledIcon = styled(FontAwesomeIcon)`
  margin: auto;
  font-size: 1rem;
`;

const Text = styled.div`
  flex-grow: 1;
  p {
    font-size: 0.875rem;
    font-weight: 600;
  }
  span {
    font-size: 0.75rem;
  }
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 3px;
  border-radius: 4px;
  background-image: ${({ progress, theme, downloading }) =>
    `linear-gradient(90deg, ${
      downloading ? theme.successColor : theme.warningLight
    } ${progress}%, ${
      downloading ? theme.warningLight : theme.showcaseBlack
    } ${progress}%)`};
  transition: all 180ms ease;
  margin-top: 8px;
`;

const FileName = styled.div`
  margin-top: 4px;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.showcaseWhite};
  max-width: 230px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const downloadableFileTypes = [
  'image',
  'image360',
  'video',
  'document',
  'floorplan'
];

const folderContentType = {
  CONTENT_ITEM: 'CONTENT_ITEM',
  FOLDER: 'FOLDER'
};

const prepareFile = (file) => {
  let src = file.contentUri;
  if (file.contentItemType === 'image' || file.contentItemType === 'image360') {
    src = buildAssetURIWithOptions('o=true', file.contentUri);
  }
  return {
    name: file.title?.textMap?.en ?? 'Untitled',
    src
  };
};

const Button = ({ icon, pulse, ...props }) => (
  <Icon {...props}>
    <StyledIcon icon={['far', icon]} size="1x" pulse={pulse} />
  </Icon>
);

const DownloadPortalMediaAsZip = ({
  downloadPortalMediaReducer,
  resetDownloadPortalMedia,
  projects,
  sessionToken,
  sessionId,
  vaultId,
  isServiceAccount,
  visitorSessionId
}) => {
  // doNotTrack - set to true at CollectionPage and used for authorized users
  const {
    foldersToDownload,
    contentItemsList,
    relatedProjectId,
    sectionId,
    doNotTrack
  } = downloadPortalMediaReducer;
  // if sessionToken provided and doNotTrack === true -> means
  // that it an authorized user at CollectionPage - do not track actions in that case
  const skipTracking = doNotTrack && sessionToken;
  let zipTitle = sectionId
    ? foldersToDownload?.[0]?.name ?? 'Untitled_Folder'
    : projects?.find((proj) => proj?.objectId === relatedProjectId)?.title ??
      'Untitled_Project';

  const [downloadingInitiated, setDownloadingInitiated] = useState(false);
  const [zip, setZip] = useState(null);
  const [readyForFetching, setReadyForFetching] = useState(false);

  const [folders, setFolders] = useState({});
  const [filesToFetch, setFilesToFetch] = useState(0);
  const [fetchedFiles, setFetchedFiles] = useState(0);
  const [fileSrcs, setFileSrcs] = useState({});
  const [fileIds, setFileIds] = useState([]);

  const [zipSize, setZipSize] = useState(0);
  const [zipPrepared, setZipPrepared] = useState(false);
  const [progress, setProgress] = useState(0);

  const [generatingZip, setGeneratingZip] = useState(false);
  const [generatingProgress, setGeneratingProgress] = useState(0);

  const [trackingParams, setTrackingParams] = useState({});

  // track downloading progress
  useEffect(() => {
    setProgress(Math.round((fetchedFiles / filesToFetch) * 100));
  }, [filesToFetch, fetchedFiles]);

  useEffect(() => {
    if (progress === 100) {
      setZipPrepared(true);
    }
  }, [progress]);

  // 0. check if downloading initiated
  useEffect(() => {
    // if no folders provided - only content collection is needed
    setDownloadingInitiated(foldersToDownload && contentItemsList);
  }, [foldersToDownload, contentItemsList]);

  // 1. initiate a ZIP file
  useEffect(() => {
    if (downloadingInitiated && !zip) {
      // track prepare for download
      if (!skipTracking) {
        // tracking for authorized user
        if (sessionToken && sessionId) {
          const params = {
            sessionId,
            action: 'preparePortalMediaDownload',
            vaultId,
            projectId: relatedProjectId
          };
          if (sectionId) {
            params.action = 'prepareSectionMediaDownload';
            params.sectionId = sectionId;
          }
          setTrackingParams(params);
          if (!isServiceAccount) {
            Tracking.trackContentDownload(params, sessionToken).catch(() => {});
          }
          // tracking for visitor
          // currently visitor exists only at collection page -> track action
          // related to shared collection -> 'prepareSharedMediaDownload'
        } else if (visitorSessionId) {
          const params = {
            vaultId,
            sessionId: visitorSessionId,
            action: 'prepareSharedMediaDownload'
          };
          setTrackingParams(params);
          Tracking.trackSharedCollectionContentDownload(params).catch(() => {});
        }
      }
      setZip(new JSZip());
    }
  }, [
    zip,
    downloadingInitiated,
    sessionToken,
    sessionId,
    sectionId,
    relatedProjectId,
    vaultId,
    isServiceAccount,
    skipTracking
  ]);

  // 2. prepare folders tree
  //    - once it is done, we display the number of calculated folders and files
  //      so that a user knows how much is about to be downloaded
  useEffect(() => {
    if (!zip) return;
    if (
      !foldersToDownload ||
      !contentItemsList ||
      contentItemsList.length === 0
    )
      return;
    if (readyForFetching) return;

    const handleFolder = (folder, parentFolderId) => {
      const folderKey = parentFolderId
        ? `${parentFolderId}.${folder.uuid}`
        : folder.uuid;

      // create folders
      setFolders((prev) => {
        const folders = { ...prev };
        const isNestedFolder = folderKey.split('.').length > 1;
        if (prev[parentFolderId]) {
          folders[folderKey] = prev[parentFolderId].folder(folder.name);
        } else {
          folders[folderKey] =
            folder.uuid === 'unsorted-items-folder' ||
            (foldersToDownload.length === 1 && !isNestedFolder)
              ? null
              : zip.folder(folder.name);
        }
        return folders;
      });

      if (folder.projectFiles?.length > 0) {
        let files = [];
        folder.projectFiles.forEach((file) => {
          // handle content items
          if (file.type === folderContentType.CONTENT_ITEM) {
            const validFile = contentItemsList.find(
              (item) =>
                item.objectId === file.contentItemId &&
                item.contentItemState !== 'archived'
            );
            if (
              validFile &&
              downloadableFileTypes.includes(validFile.contentItemType)
            ) {
              files.push(validFile);
              setFilesToFetch((prev) => (prev += 1));
            }
          } else if (file.type === folderContentType.FOLDER) {
            handleFolder(file, folderKey);
          }
        });

        setFileSrcs((prev) => ({
          ...prev,
          [folderKey]: files.map(prepareFile)
        }));
        setFileIds((prev) => [...prev, ...files.map((file) => file.objectId)]);

        setReadyForFetching(true);
      }
    };
    // recursively look through folder structure
    foldersToDownload.forEach((folder) => handleFolder(folder));
  }, [contentItemsList, foldersToDownload, zip, readyForFetching]);

  // 3. an actual downloading
  useEffect(() => {
    if (!readyForFetching) return;
    if (!foldersToDownload) return;

    Object.entries(folders).forEach(async ([key, folder], idx) => {
      const files = fileSrcs[key] ?? [];
      const fileBlobs = await Promise.all(
        files.map(async (props) => {
          const res = await fetch(props.src);
          const blobSize = res.headers.get('content-length');
          setZipSize((size) => (size += Number(blobSize)));
          const blob = res.blob();

          return blob;
        })
      );

      setFetchedFiles((prev) => (prev += fileBlobs.length));

      fileBlobs.forEach((fileBlob, index) => {
        // unsorted files should not have separate folder
        const isNestedFolder = key.split('.').length > 1;
        const targetLocation =
          key === 'unsorted-items-folder' ||
          (foldersToDownload?.length === 1 && !isNestedFolder)
            ? zip
            : folder;
        targetLocation.file(files[index].name, fileBlob, {
          blob: true
        });
      });
    });
  }, [readyForFetching, fileSrcs, folders, zip, foldersToDownload]);

  const resetStates = useCallback(() => {
    resetDownloadPortalMedia();

    // reset local states
    setTrackingParams({});
    setDownloadingInitiated(false);
    setReadyForFetching(false);
    setGeneratingZip(false);
    setZipPrepared(false);

    setFolders({});
    setFileSrcs({});
    setFileIds([]);

    setGeneratingProgress(0);
    setFilesToFetch(0);
    setFetchedFiles(0);
    setProgress(0);
    setZipSize(0);
    setZip(null);
  }, [resetDownloadPortalMedia]);

  const doTrackingOfDownload = useCallback(
    (actionForMediaLibrary = '', actionForBuyersSpace = '') => {
      if (!skipTracking) {
        if (!isServiceAccount && sessionToken && sessionId) {
          Tracking.trackContentDownload(
            {
              ...trackingParams,
              action: actionForMediaLibrary,
              zipFileSize: String(zipSize),
              contentItemIdList: fileIds
            },
            sessionToken
          ).catch(() => {});
        } else if (visitorSessionId) {
          // if visitor session is provided - means that component rendered at
          // buyers space page, then it's needed to use tracking action related to shared content collection
          Tracking.trackSharedCollectionContentDownload({
            ...trackingParams,
            action: actionForBuyersSpace,
            zipFileSize: String(zipSize),
            contentItemIdList: fileIds
          }).catch(() => {});
        }
      }
    },
    [
      trackingParams,
      zipSize,
      fileIds,
      skipTracking,
      isServiceAccount,
      sessionToken,
      sessionId,
      visitorSessionId
    ]
  );

  // 4. once user accepts downloading, download it
  const downloadZip = useCallback(() => {
    if (!zip) return;
    if (generatingZip) return;

    if (
      downloadingInitiated &&
      filesToFetch !== 0 &&
      filesToFetch === fetchedFiles
    ) {
      setGeneratingZip(true);
      zip
        .generateAsync(
          // https://stuk.github.io/jszip/documentation/api_jszip/generate_async.html
          {
            type: 'blob',
            compression: 'DEFLATE',
            compressionOptions: {
              level: 9
            }
          },
          function updateCallback(metadata) {
            setGeneratingProgress(Number(metadata.percent.toFixed(2)));
          }
        )
        .then(function (content) {
          saveAs(content, zipTitle);
          // track successful download
          doTrackingOfDownload(
            'mediaDownloadSucceeded',
            'sharedMediaDownloadSucceeded'
          );
          resetStates();
        })
        .catch(() => {
          // track failed download
          doTrackingOfDownload(
            'mediaDownloadFailed',
            'sharedMediaDownloadFailed'
          );
          resetStates();
        });
    }
  }, [
    filesToFetch,
    fetchedFiles,
    zip,
    resetStates,
    zipTitle,
    downloadingInitiated,
    generatingZip,
    fileIds,
    zipSize,
    trackingParams,
    sessionToken,
    isServiceAccount
  ]);

  const localized = localizer.downloadPortalMedia;

  return (
    <AnimatePresence>
      {downloadingInitiated && (
        <Wrapper
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 30 }}
        >
          <section>
            <Text>
              {!zipPrepared ? (
                <>
                  <p>{localized.preparingZip}</p>
                  <ProgressBar progress={progress} />
                </>
              ) : (
                <p>
                  {generatingZip
                    ? localized.doNotCloseWindow
                    : localizer.formatString(
                        localized.zipSizeWarning,
                        `"${zipTitle}.zip"`,
                        parseBytesToReadable(zipSize).join(' ')
                      )}
                </p>
              )}

              {zipPrepared && (
                <>
                  <ProgressBar progress={generatingProgress} downloading />
                  {generatingZip && (
                    <FileName>
                      {localized.downloaded} {generatingProgress}%{' '}
                    </FileName>
                  )}
                </>
              )}
            </Text>

            {zipPrepared && !generatingZip && (
              <>
                <Button
                  key="download"
                  icon="cloud-download"
                  onClick={downloadZip}
                  success
                />
                <Button
                  key="cancel"
                  icon="times-circle"
                  onClick={() => {
                    // track cancel download
                    doTrackingOfDownload(
                      'cancelMediaDownload',
                      'cancelSharedMediaDownload'
                    );
                    resetStates();
                  }}
                  error
                />
              </>
            )}

            {!zipPrepared && <Button key="loading" icon="spinner" pulse />}
          </section>
        </Wrapper>
      )}
    </AnimatePresence>
  );
};

const mapStateToProps = (state) => ({
  downloadPortalMediaReducer: state.store.DownloadPortalMediaReducer,
  projects: state.store.ProjectListReducer?.projects ?? [],
  sessionToken: state.store.AuthReducer.sessionToken,
  sessionId: state.store.AuthReducer.sessionObjectId,
  vaultId: state.store.VaultReducers?.vault?.objectId,
  isServiceAccount: !!state.store.AuthReducer?.user?.serviceAccount,
  visitorSessionId: state.store.AuthReducer.visitorSessionId
});

const mapDispatchToProps = (dispatch) => ({
  resetDownloadPortalMedia: () =>
    dispatch({ type: 'RESET_DOWNLOAD_PORTAL_MEDIA' })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DownloadPortalMediaAsZip);
