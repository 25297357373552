import React from 'react';
import { shape } from 'prop-types';

// Component
import ProfileImage from 'components/profileImage/ProfileImage';

// Helpers
import localizer from 'localization/localizer';

// Styling
import styled from 'styled-components';
import { motion } from 'framer-motion';

const VaultCard = styled(motion.div)`
  display: flex;
  flex-flow: row;
  align-items: center;
  width: 100%;
  padding-left: 5px;
`;

const VaultLogo = styled(ProfileImage)`
  width: 45px;
  height: 45px;
  max-width: 45px;
  max-height: 45px;
  border-radius: 50%;
  margin: 0;
`;

const VaultName = styled.div`
  font-size: 1rem;
  color: ${(props) => props.theme.showcaseWhite};
  user-select: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Description = styled.div`
  font-size: 0.75rem;
  color: ${(props) => props.theme.showcaseWhite};
`;

const VerticalWrapper = styled.div`
  display: flex;
  flex-flow: column;
  max-width: 70%;
  margin-left: 15px;
`;

const CurrentVaultMenuCard = ({ vault }) => {
  return (
    <VaultCard data-testid="vaultCard">
      <VaultLogo profileImageUri={vault?.logoUri} />
      <VerticalWrapper>
        <VaultName>{vault?.name}</VaultName>
        <Description>{localizer.currentWorkspace}</Description>
      </VerticalWrapper>
    </VaultCard>
  );
};

CurrentVaultMenuCard.propTypes = {
  vault: shape({}).isRequired
};

CurrentVaultMenuCard.defaultProps = {};

export default CurrentVaultMenuCard;
