import { User, Vault } from '@prompto-api';

import { checkToken, decodeToken } from '../helpers/auth';
import { updateRenewTrackingCookie } from '../helpers/util';
import to from 'await-to-js';

import defaultNotificationsState from 'store/reducers/notificationReducer/defaultNotificationsState';

export const saveState = (state) => {
  const serializedState = JSON.stringify(state);
  localStorage.setItem('state', serializedState);
};

export const loadPersistedState = async (sessionToken) => {
  try {
    const serializedState = localStorage.getItem('state');
    const state = JSON.parse(serializedState);

    const lastTimeOpenedNotifications = Number(
      localStorage.getItem('lastTimeOpenedPromptoNotifications')
    );
    const notificationsReducer = {
      ...defaultNotificationsState,
      lastTimeOpened: lastTimeOpenedNotifications
    };

    let existingToken;
    if (state && state.store && state.store.AuthReducer) {
      existingToken = state.store.AuthReducer.sessionToken;
    }

    if (sessionToken && sessionToken !== existingToken) {
      const [error, response] = await to(User.getInfo(sessionToken));
      if (error) {
        return error;
      }

      const newState = {
        store: {
          AuthReducer: {
            ...response.data,
            sessionToken,
            authenticated: true
          }
        }
      };

      if (lastTimeOpenedNotifications) {
        newState.store = {
          ...newState.store,
          NotificationsReducer: {
            ...notificationsReducer
          }
        };
      }

      saveState(newState);
      return newState;
    } else if (sessionToken && sessionToken === existingToken) {
      return {
        ...state,
        store: {
          ...state.store,
          NotificationsReducer: {
            ...notificationsReducer
          }
        }
      };
    } else if (!sessionToken && existingToken) {
      const [error, needsRenew] = await to(checkToken(existingToken));
      if (error) {
        return error;
      }

      if (needsRenew) {
        const [error, result] = await to(
          User.renewSessionToken(
            { loadVaultDictionarySets: false },
            {},
            existingToken
          )
        );

        if (error) {
          return error;
        }
        const decodedToken = decodeToken(result.data.sessionToken);
        if (!decodedToken.error) {
          updateRenewTrackingCookie(decodedToken.usr);
          state.store.AuthReducer = {
            ...state.store.AuthReducer,
            ...result.data
          };
          saveState(state);

          const currentVaultId =
            state.store.AuthReducer?.user?.vaultList?.[0]?.objectId;
          const currentUserId = state.store.AuthReducer?.user?.objectId;
          if (currentVaultId && currentUserId) {
            const [permissionsError, permissionsResult] = await to(
              Vault.getUserPermissions(
                currentVaultId,
                currentUserId,
                result.data.sessionToken
              )
            );

            if (permissionsError) {
              return permissionsError;
            } else if (permissionsResult) {
              const dictionaries = {};
              const thisVaultPermissions = {};
              if (permissionsResult.data?.operationList) {
                dictionaries.allowedVaultOperationDictionary = {
                  [currentVaultId]: permissionsResult.data.operationList
                };
                thisVaultPermissions.allowedOperations =
                  permissionsResult.data.operationList;
              }
              if (permissionsResult.data?.featureList) {
                dictionaries.includedFeatureDictionary = {
                  [currentVaultId]: permissionsResult.data.featureList
                };
                thisVaultPermissions.allowedFeatures =
                  permissionsResult.data.featureList;
              }

              // update state with dictionaries
              state.store.AuthReducer = {
                ...state.store.AuthReducer,
                ...dictionaries,
                thisVaultPermissions
              };

              saveState(state);
            }
          }
        }
        return {
          ...state,
          store: {
            ...state.store,
            NotificationsReducer: {
              ...notificationsReducer
            }
          }
        };
      }
      return {
        ...state,
        store: {
          ...state.store,
          NotificationsReducer: {
            ...notificationsReducer
          }
        }
      };
    } else {
      throw new Error('No sessionToken found');
    }
  } catch (err) {
    throw err;
  }
};
