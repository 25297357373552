import React, { useEffect, Suspense, lazy, useState } from 'react';
import PropTypes from 'prop-types';

// Redux setup
import { connect, Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';

// Router setup
import { Switch, Route, Redirect } from 'react-router';
import PrivateRoute from 'components/routing/PrivateRoute';
import { userLogoutAction } from 'store/reducers/authReducer/AuthActions.js';
import { getNotificationsCount } from 'store/reducers/notificationReducer/NotificationsActions.js';
import { checkForCachedProjectsAction } from 'store/reducers/projectListReducer/ProjectListActions.js';
import Cookies from 'universal-cookie';
import env from 'environment';

// Stores
import ProjectStore from 'store/ProjectStore';

// App Language
import strings, { langRegex, defaultLocale } from 'localization/localizer';

// ACL
import { IconContext } from 'react-icons';

// tracking
import { configureScope } from '@sentry/browser';

// config
import topRoutes from 'config/topRoutes.json';

import PromptoLoader from 'components/loader/PromptoLoader';

// helpers
import { fetchSettingsFromURL } from 'helpers/util';

import { Notification, PortalSettings, PromptoSession } from '@prompto-api';

import ToastStore from './store/ToastStore.js';
import UsersPage from 'components/views/admin/pages/Users/UsersPage';
import AdminSidebar from 'components/views/sidebar/AdminSidebar';
import DownloadPortalMediaAsZip from 'components/other/DownloadPortalMediaAsZip';
import CollectionPage from 'components/views/collection/CollectionPage';

import LoginView from 'components/authentication/LoginView';
import LoginViewSSO from 'components/authentication/LoginViewSSO.js';

// styling
import styled, { ThemeProvider, withTheme } from 'styled-components';
import { styledRespondTo } from 'styles/mixins';

const Loader = styled.div`
  color: ${({ theme }) => theme.primary900};
  width: 100%;
  height: 100%;
  position: fixed;
  top: -20px;
  left: 30px;
  padding: 0;
  margin: 0;
  text-align: center;
  > div {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const SwitchingVaultLoader = styled(Loader)`
  position: absolute;
  z-index: 10;
`;

const StyledDashboard = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  right: 0;
  left: 0;
  margin-left: 0;
  flex-direction: column;
  ${styledRespondTo.sm`
    margin-top: 0;
    flex-direction: row;
  `}
`;

// Views
const ProjectPage = lazy(() =>
  import('components/views/project/pages/ProjectPage')
);
const ProjectDashboard = lazy(() =>
  import('components/views/project/ProjectDashboard')
);
const VotePage = lazy(() => import('components/views/vote/VotePage'));

const ForgotPassword = lazy(() =>
  import('components/authentication/ForgotPassword')
);
const ResetPassword = lazy(() =>
  import('components/authentication/ResetPassword')
);
// const LoginView = lazy(() => import('./components/authentication/LoginView'));
const SignUpView = lazy(() => import('components/authentication/SignUpView'));
const AdminDashboard = lazy(() =>
  import('components/views/admin/AdminDashboard')
);
const SceneDashboard = lazy(() =>
  import('components/views/scene/SceneDashboard')
);
const SessionDashboard = lazy(() =>
  import('components/views/sessions/SessionDashboard')
);

const RegisterUserForm = lazy(() =>
  import('components/views/register/RegisterUserForm')
);

const Image360Viewer = lazy(() =>
  import('components/views/image360Viewer/Image360Viewer.js')
);

const ProductLetRegisterForm = lazy(() =>
  import('components/views/register/ProductLedRegisterForm')
);

const LeadsPage = lazy(() => import('components/views/leads/LeadsPage'));
const ContactsPage = lazy(() =>
  import('components/views/contacts/ContactsPage')
);

const EmailVerificationView = lazy(() =>
  import('components/views/verification/EmailVerification')
);

const mapStateToProps = (state) => ({
  AuthReducer: state.store.AuthReducer,
  fetchingProjectList: state.store.ProjectListReducer.pending,
  doneFetchingProjectList: state.store.ProjectListReducer.done,
  thisVaultPermissions: state.store.AuthReducer.thisVaultPermissions
});

const mapDispatchToProps = (dispatch) => ({
  handleLogout: (sessionToken) => dispatch(userLogoutAction(sessionToken)),
  getNotificationsCount: () =>
    dispatch(getNotificationsCount(Notification.getAll)),
  refreshProjects: (vaultId, sessionToken) =>
    dispatch(checkForCachedProjectsAction(vaultId, sessionToken)),
  setSharedPortalSettings: (payload) =>
    dispatch({ type: 'SET_PORTAL_SETTINGS', payload })
});

// The main app component, which is obvious right?
export const AppRouter = ({
  store,
  history,
  handleLogout,
  AuthReducer,
  getNotificationsCount,
  fetchingProjectList,
  doneFetchingProjectList,
  refreshProjects,
  setSharedPortalSettings,
  theme,
  thisVaultPermissions
}) => {
  const cookies = new Cookies();

  // set Leads page available by default to avoid
  // redirect to Projects page when navigating from email
  const [permissions, setPermissions] = useState({
    leadsEnabled: true,
    leadsAvailableForUser: true
  });

  // This logic logs the user out on all open tabs
  useEffect(() => {
    const intervalID = window.setInterval(function () {
      const isOnLoginPage = window.location.pathname.endsWith('/login');
      const isOnRequestPassword =
        window.location.pathname.endsWith('/forgot-password');
      const isOnResetPassword =
        window.location.pathname.endsWith('/reset-password');
      const isOnProductLedPage =
        window.location.pathname.endsWith('/product-led');
      const isOnEmailVerificationPage =
        window.location.pathname.includes('email-verification');
      const isOnSignUpPage = window.location.pathname.includes('signup');
      const isOnLoginSSOPage = window.location.pathname.endsWith('/login-sso');

      if (
        cookies.get(env().loggedOutCookieName) === 'true' &&
        !isOnLoginPage &&
        !isOnRequestPassword &&
        !isOnResetPassword &&
        !isOnProductLedPage &&
        !isOnEmailVerificationPage &&
        !isOnSignUpPage &&
        !isOnLoginSSOPage
      ) {
        handleLogout(AuthReducer.sessionToken);
      }
    }, 1000);

    return () => {
      clearInterval(intervalID);
    };
  }, [cookies, handleLogout, AuthReducer]);

  useEffect(() => {
    let sessionHeartInterval;
    if (AuthReducer.sessionObjectId) {
      sessionHeartInterval = setInterval(() => {
        PromptoSession.updateEndTimestamp({
          sessionId: AuthReducer.sessionObjectId
        }).catch(() => {
          console.warn('Could not update the end session timestamp');
        });
      }, 10000);
    }
    return () => {
      if (sessionHeartInterval) clearInterval(sessionHeartInterval);
    };
  }, [AuthReducer.sessionObjectId]);

  // Check this vault permissions
  useEffect(() => {
    if (thisVaultPermissions && Object.keys(thisVaultPermissions)?.length > 0) {
      let leadsEnabled = false;
      let leadsAvailableForUser = false;
      let leadsAbleToArchive = false;
      let vmBoardColumnCreate = false;
      let vmBoardColumnDelete = false;
      let vmBoardColumnWrite = false;
      let showcaseBuilderEnabled = false;
      let contactsEnabled = false;

      const features = thisVaultPermissions.allowedFeatures;
      const allowedOperations = thisVaultPermissions.allowedOperations;

      showcaseBuilderEnabled = features?.includes('showcaseBuilder');
      leadsEnabled = features?.includes('leadsForRed');
      leadsAvailableForUser = allowedOperations?.includes('leadRead');
      leadsAbleToArchive = allowedOperations?.includes('ticketArchive');
      vmBoardColumnCreate = allowedOperations?.includes('vmBoardColumnCreate');
      vmBoardColumnDelete = allowedOperations?.includes('vmBoardColumnDelete');
      vmBoardColumnWrite = allowedOperations?.includes('vmBoardColumnWrite');
      contactsEnabled = features?.includes('contacts');

      setPermissions({
        leadsEnabled,
        leadsAvailableForUser,
        leadsAbleToArchive,
        vmBoardColumnCreate,
        vmBoardColumnDelete,
        vmBoardColumnWrite,
        showcaseBuilderEnabled,
        contactsEnabled
      });
    }
  }, [thisVaultPermissions]);

  const {
    leadsEnabled,
    leadsAvailableForUser,
    leadsAbleToArchive,
    vmBoardColumnCreate,
    vmBoardColumnDelete,
    vmBoardColumnWrite,
    showcaseBuilderEnabled,
    contactsEnabled
  } = permissions;

  let vaultObjectId;

  strings.setLanguage(defaultLocale);
  if (
    AuthReducer &&
    AuthReducer.authenticated &&
    AuthReducer.includedFeatureDictionary
  ) {
    const {
      user: {
        firstName,
        lastName,
        email,
        objectId,
        username,
        language,
        vaultList
      }
    } = AuthReducer;
    const fullName = `${firstName} ${lastName}`;
    /* istanbul ignore next */
    configureScope((scope) => {
      scope.setUser({ email, id: objectId, username, fullName });
    });

    // Hubspot Identification
    const _hsq = (window._hsq = window._hsq || []);
    _hsq.push([
      'identify',
      {
        email: email,
        id: objectId
      }
    ]);
    _hsq.push(['trackPageView']);

    const vaultId = vaultList ? vaultList[0]?.objectId : '';
    vaultObjectId = vaultId;

    strings.setLanguage(language || defaultLocale);
  }

  useEffect(() => {
    // remove customFieldLostFocus cookies if it exists. should be cleared after page refresh
    cookies.remove('customFieldLostFocus', {
      path: '/'
    }); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!AuthReducer) return;
    const { authenticated } = AuthReducer;
    if (authenticated) {
      getNotificationsCount();
    }
  }, [AuthReducer, getNotificationsCount]);

  // get cached projects on page reload
  useEffect(() => {
    if (!AuthReducer || !vaultObjectId) return;
    const { sessionToken, user } = AuthReducer;

    if (doneFetchingProjectList || fetchingProjectList) return;

    refreshProjects(vaultObjectId, sessionToken);
  }, [
    fetchingProjectList,
    doneFetchingProjectList,
    refreshProjects,
    vaultObjectId,
    AuthReducer
  ]);

  // Set shared Portal settings
  useEffect(() => {
    if (vaultObjectId) {
      const { sessionToken } = AuthReducer;
      if (sessionToken) {
        PortalSettings.get(vaultObjectId, sessionToken)
          .then((response) => {
            const portalSettings = response?.data?.vmPortalSettings;
            setSharedPortalSettings(portalSettings);
          })
          .catch((error) => {
            if (error?.response?.data?.code === 92800) {
              // 92800 -> Not able to locate portalSettings by id
              // do not needed create new settings object in other case
              PortalSettings.create(
                {
                  vaultObjectId: vaultObjectId,
                  permissions: {
                    agent: {
                      allowDownloadingFiles: true,
                      allowChangingUnitStatus: true
                    }
                  }
                },
                sessionToken
              )
                .then((response) => {
                  const portalSettings = response?.data?.vmPortalSettings;
                  setSharedPortalSettings(portalSettings);
                })
                .catch(() => {});
            }
          });
      }
    }
  }, [vaultObjectId, AuthReducer, setSharedPortalSettings]);

  const queryParams = fetchSettingsFromURL();
  if (queryParams?.image360) {
    return (
      <Suspense
        fallback={
          <Loader>
            <PromptoLoader />
          </Loader>
        }
      >
        <Image360Viewer imageUrlEncoded={queryParams.image360} />
      </Suspense>
    );
  }

  return (
    <ToastStore>
      <ProjectStore>
        <Provider store={store}>
          {/* ConnectedRouter will use the
              store from Provider automatically */}
          <ConnectedRouter history={history}>
            <IconContext.Provider
              value={{
                style: { verticalAlign: 'middle', maxHeight: 20, maxWidth: 20 }
              }}
            >
              <Switch>
                <Route
                  path={langRegex}
                  render={({
                    match: {
                      url,
                      params: { lang }
                    }
                  }) => {
                    if (strings.getLanguage() !== lang) {
                      strings.setLanguage(lang || defaultLocale);
                    }

                    // build a regex to see if the url is currently the top level
                    // by checking if the url end with the route.
                    // if that's the case, the sidebar must go wide
                    const isWideRegex = new RegExp(
                      `/(${Object.keys(topRoutes)
                        .map((key) => topRoutes[key])
                        .join('|')})$`
                    );

                    const isSidebarWide =
                      window.location.pathname
                        .replace(/\/$/, '')
                        .match(isWideRegex) !== null;

                    return (
                      <ThemeProvider theme={{ ...theme, isSidebarWide }}>
                        <Suspense
                          fallback={
                            <StyledDashboard>
                              {AuthReducer.authenticated && (
                                <AdminSidebar history={history} />
                              )}
                              <Loader>
                                <PromptoLoader />
                              </Loader>
                            </StyledDashboard>
                          }
                        >
                          <Switch>
                            {/** LOGIN/PASSWORD Routes */}
                            <Route
                              path={`${url}/login`}
                              render={(props) => <LoginView {...props} />}
                            />
                            <Route
                              path={`${url}/login-sso`}
                              render={(props) => <LoginViewSSO {...props} />}
                            />
                            <Route
                              path={`${url}/signup`}
                              render={(props) => <SignUpView {...props} />}
                            />
                            <Route
                              path={`${url}/forgot-password`}
                              render={(props) => <ForgotPassword {...props} />}
                            />
                            <Route
                              path={`${url}/reset-password`}
                              render={(props) => <ResetPassword {...props} />}
                            />

                            {/** REGISTER/SUBSCRIBE/TRIAL Routes */}
                            <Route
                              path={`${url}/register`}
                              render={(props) => (
                                <RegisterUserForm {...props} />
                              )}
                            />
                            <Route
                              path={`${url}/load`}
                              render={
                                /* istanbul ignore next */
                                () => <span />
                              }
                            />
                            <Route
                              path={`${url}/image360/:image360Url`}
                              render={(props) => <Image360Viewer {...props} />}
                            />
                            <Route
                              path={`${url}/product-led`}
                              render={(props) => (
                                <ProductLetRegisterForm {...props} />
                              )}
                            />
                            <Route
                              path={`${url}/email-verification`}
                              render={(props) => (
                                <EmailVerificationView {...props} />
                              )}
                            />
                            <Route
                              path={`${url}/collection/:objectId`}
                              render={(props) => <CollectionPage {...props} />}
                            />
                            {/** === PRIVATE ROUTES === */}
                            <PrivateRoute
                              path={`${url}/admin`}
                              component={(props) => (
                                <AdminDashboard history={history} {...props} />
                              )}
                            />
                            <PrivateRoute
                              path={`${url}/project/:objectId`}
                              component={(props) => (
                                <ProjectPage history={history} {...props} />
                              )}
                            />
                            <PrivateRoute
                              path={`${url}/projects`}
                              component={(props) =>
                                AuthReducer.pendingRelogin ? (
                                  <SwitchingVaultLoader>
                                    <PromptoLoader />
                                  </SwitchingVaultLoader>
                                ) : (
                                  <ProjectDashboard {...props} />
                                )
                              }
                            />
                            <PrivateRoute
                              path={`${url}/team`}
                              component={(props) => <UsersPage {...props} />}
                            />
                            {leadsEnabled && leadsAvailableForUser && (
                              <PrivateRoute
                                path={`${url}/leads`}
                                component={(props) => (
                                  <LeadsPage
                                    {...props}
                                    leadsAbleToArchive={leadsAbleToArchive}
                                    vmBoardColumnCreate={vmBoardColumnCreate}
                                    vmBoardColumnDelete={vmBoardColumnDelete}
                                    vmBoardColumnWrite={vmBoardColumnWrite}
                                    AuthReducer={AuthReducer}
                                    history={history}
                                  />
                                )}
                              />
                            )}

                            {contactsEnabled && (
                              <PrivateRoute
                                path={`${url}/contacts`}
                                component={(props) => (
                                  <ContactsPage
                                    {...props}
                                    AuthReducer={AuthReducer}
                                    history={history}
                                  />
                                )}
                              />
                            )}

                            <PrivateRoute
                              path={`${url}/vote`}
                              component={(props) => <VotePage {...props} />}
                            />

                            <PrivateRoute
                              path={`${url}/scenes`}
                              component={(props) => (
                                <SceneDashboard {...props} />
                              )}
                            />
                            <PrivateRoute
                              path={`${url}/sessions`}
                              component={(props) => (
                                <SessionDashboard {...props} />
                              )}
                            />

                            {/** APPLICATION ENTRY POINT */}
                            <Redirect
                              from={`${url}/download`}
                              to={{
                                pathname: `${url}/downloads`,
                                search: window.location.search
                              }}
                            />
                            <Redirect
                              from="/"
                              to={{
                                pathname: `/${strings.getLanguage()}/projects`,
                                search: window.location.search
                              }}
                            />
                            <Route
                              path="*"
                              render={
                                /* istanbul ignore next */
                                () => <h1>404 Not Found</h1>
                              }
                            />
                          </Switch>
                        </Suspense>
                      </ThemeProvider>
                    );
                  }}
                />
              </Switch>

              <DownloadPortalMediaAsZip />
            </IconContext.Provider>
          </ConnectedRouter>
        </Provider>
      </ProjectStore>
    </ToastStore>
  );
};

AppRouter.propTypes = {
  store: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired
};

export default withTheme(
  connect(mapStateToProps, mapDispatchToProps)(AppRouter)
);
