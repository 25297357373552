import { validActionCheck } from 'store/actionUtils';
import initialState from 'store/reducers/authReducer/defaultAuthState';
import {
  updateRenewTrackingCookie,
  createLoggedOutCookie,
  removeLoggedOutCookie,
  removeAuthCookie,
  createAuthCookie,
  createUserIdCookie,
  removeUserIdCookie
} from 'helpers/util';

import env from 'environment';
import Cookies from 'universal-cookie';

/**
 * Reducer to manage everything around authorization in the application
 */
const AuthReducer = (state = initialState, action) => {
  const cookies = new Cookies();
  if (validActionCheck(action)) {
    switch (action.type) {
      case 'AUTH_STORE_LOGIN_INFO': {
        return {
          ...state,
          ...action.payload
        };
      }

      case 'AUTH_LOGIN_REQUEST_PENDING': {
        return {
          ...state,
          authenticated: false,
          error: false,
          pending: true
        };
      }

      case 'AUTH_LOGIN_REQUEST_REJECTED': {
        return {
          ...state,
          ...action.payload,
          authenticated: false,
          error: true,
          pending: false
        };
      }

      case 'AUTH_RELOGIN_REQUEST_PENDING':
        return {
          ...state,
          pendingRelogin: true
        };

      case 'AUTH_RELOGIN_REQUEST_FULFILLED':
      case 'AUTH_LOGIN_REQUEST_FULFILLED': {
        const { user } = action.payload;

        removeLoggedOutCookie();
        updateRenewTrackingCookie(user.objectId, true);

        createAuthCookie(action.payload.sessionToken);
        createUserIdCookie(user.objectId);
        return {
          ...state,
          ...action.payload,
          authenticated: true,
          error: false,
          pending: false,
          pendingRelogin: false
        };
      }

      case 'AUTH_RESET':
      case 'AUTH_LOGOUT_REQUEST_PENDING':
      case 'VAULT_INFO_REQUEST_REJECTED': {
        cookies.remove(`AroundMedia/${env().env}/sast`, {
          domain: env().applicationDomain,
          path: '/'
        });
        removeAuthCookie(state.sessionToken);
        removeUserIdCookie();
        createLoggedOutCookie();
        return {
          ...initialState,
          error: false,
          pending: false
        };
      }

      case 'AUTH_UPDATE_REQUEST_FULFILLED': {
        return {
          ...state,
          ...action.payload.data
        };
      }
      // dictionaries related
      case 'GET_VAULT_DICTIONARIES_PENDING':
        return {
          ...state,
          loadingDictionaries: true
        };
      case 'AUTH_GET_VAULT_DICTIONARIES_SUCCESS': {
        return {
          ...state,
          ...action.payload,
          loadingDictionaries: false
        };
      }
      case 'SET_THIS_VAULT_PERMISSIONS':
        return {
          ...state,
          thisVaultPermissions: action.payload
        };
      case 'GET_VAULT_DICTIONARIES_REJECTED':
        return {
          ...state,
          loadingDictionaries: false
        };
      case 'START_VISITOR_CREATE_PROCESS':
        return {
          ...state,
          startCreateVisitorProcess: action.payload
        };
      case 'SET_VISITOR':
        return {
          ...state,
          visitorId: action.payload
        };
      case 'SET_VISITOR_SESSION':
        return {
          ...state,
          visitorSessionId: action.payload
        };

      default: {
        return state;
      }
    }
  }

  return state;
};

export default AuthReducer;
