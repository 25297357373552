import React, { useEffect, useState } from 'react';
import { string, func } from 'prop-types';

// Components
import { motion, AnimatePresence } from 'framer-motion';
// import IdleTimer from 'react-idle-timer';
import GalleryLoader from './GalleryLoader';

// Helpers
import localizer from 'localization/localizer';
// import { capitalize } from 'helpers/util';

//Styling
import styled from 'styled-components';

const TempLoader = styled(motion.div)`
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 6000;
  background: black;
`;

const PreviewWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 6000;
  background: grey;
  overflow: hidden;
`;

const SpaceFiller = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const IFrame = styled.iframe`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: none;
`;

// const ModalWrapper = styled(Modal)`
//   padding: 10px;
//   padding-top: 20px;
// `;

// const ModalTitle = styled.strong`
//   color: ${props => props.theme.primary400};
//   font-size: 24px;
//   margin-bottom: 15px;
// `;

// const ModalText = styled.p`
//   color: ${props => props.theme.primary100};
//   font-size: 14px;
//   line-height: 1.5;
//   margin-bottom: 10px;
// `;

// const ModalButtons = styled.div`
//   display: flex;
//   flex-flow: row;
//   justify-content: flex-end;
//   align-items: center;
// `;

// this came from the showcase and has this custom button here.
// it's not pretty but this is just a maintenance update
// so I'll leave it as is.
const CustomButton = styled.div`
  font-family: 'proxima-nova', sans-serif;
  align-items: center;
  backdrop-filter: blur(3px);
  border: none;
  background-color: rgba(144, 144, 144, 0.4);
  color: white;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  height: calc(60px + 1vmin);
  justify-content: center;
  outline: none;
  min-width: 80px;
  cursor: pointer;
  z-index: 15;
  margin-right: 3px;
  text-transform: uppercase;
  padding: 0 20px;
  position: absolute;
  bottom: 40px;
  left: 40px;
  z-index: 100;
  letter-spacing: 1.5px;
`;

const FullscreenIframePreview = ({ src, onExit, loaderGalleryUrl }) => {
  const [tempLoader, setTempLoader] = useState(null);
  const [progressbarValue, setProgressbarValue] = useState(0);
  const [showLoadingScreen, setShowLoadingScreen] = useState(true);

  // Hardcoded timer to show the loading screen a fixed amount, hiding the booting of the machine
  const loadingScreenDuration = 40000;

  // const idleTimerWarning = useRef();
  // const idleTimerExit = useRef();

  // const [showIdleWarning, setShowIdleWarning] = useState(false);

  useEffect(() => {
    let interval;
    if (loaderGalleryUrl) {
      setTimeout(() => {
        setShowLoadingScreen(false);
      }, loadingScreenDuration);
      interval = setInterval(() => {
        setProgressbarValue((curr) => curr + 100);
      }, 100);
      setTempLoader(
        <TempLoader
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          key="tempLoader"
        />
      );
      setTimeout(() => {
        setTempLoader(null);
      }, 3000);
    } else {
      if (interval) {
        clearInterval(interval);
      }
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [loaderGalleryUrl]);

  // let formattedTimeRemaining = '--';
  // if (idleTimerExit?.current) {
  //   const timeRemaining = idleTimerExit.current?.getRemainingTime();

  //   const seconds = Math.floor((timeRemaining / 1000) % 60);
  //   const minutes = Math.floor((timeRemaining / (1000 * 60)) % 60);

  //   if (minutes > 0) {
  //     formattedTimeRemaining = `${minutes} ${localizer.minutes}`;
  //   } else {
  //     formattedTimeRemaining = `${seconds} ${localizer.seconds}`;
  //   }
  // }

  return (
    <PreviewWrapper key="iframePreviewKey">
      {/* <IdleTimer
        ref={idleTimerWarning}
        element={document}
        onIdle={e => {
          setShowIdleWarning(true);
        }}
        debounce={250}
        timeout={600000} // 10 minutes
      /> */}

      {/* {showIdleWarning && (
        <ModalWrapper
          center
          open={showIdleWarning > 0}
          showCloseIcon={false}
          onClose={() => setShowIdleWarning(0)}
          closeOnOverlayClick={true}
          closeOnEsc={true}
        >
          <IdleTimer
            ref={idleTimerExit}
            element={document}
            events={[]}
            onIdle={e => {
              onExit();
            }}
            debounce={250}
            timeout={300000} // 5 minutes
          />
          <ModalTitle>{capitalize(localizer.areYouStillHere)}</ModalTitle>
          <ModalText>
            {`${capitalize(
              localizer.inactivityWarning
            )} ${formattedTimeRemaining}`}
          </ModalText>
          <Divider marginSize={25} />
          <ModalButtons>
            <Button onClickAction={onExit} label={capitalize(localizer.exit)} />
            <Button
              onClickAction={() => {
                setShowIdleWarning(false);
              }}
              type={'submit'}
              label={capitalize(localizer.iAmHere)}
            />
          </ModalButtons>
        </ModalWrapper>
      )} */}

      <SpaceFiller>
        <AnimatePresence>
          {tempLoader}
          {loaderGalleryUrl && showLoadingScreen && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ delay: 1 }}
              key="galleryLoader"
            >
              <GalleryLoader
                loaderGalleryUrl={loaderGalleryUrl}
                progressbarValue={progressbarValue}
                maxValue={loadingScreenDuration}
              />
            </motion.div>
          )}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ delay: 1 }}
            key="theIframe"
          >
            <IFrame title="iframe for preview" src={src}></IFrame>
          </motion.div>
          <CustomButton
            key="button"
            onClick={() => {
              onExit();
            }}
          >
            {localizer.back}
          </CustomButton>
        </AnimatePresence>
      </SpaceFiller>
    </PreviewWrapper>
  );
};

FullscreenIframePreview.propTypes = {
  src: string.isRequired,
  onExit: func.isRequired,
  loaderGalleryUrl: string
};

FullscreenIframePreview.defaultProps = {
  loaderGalleryUrl: null
};

export default FullscreenIframePreview;
