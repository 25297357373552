import React from 'react';
import { string, func } from 'prop-types';

import Button from 'components/button/Button';

// Styling
import styled from 'styled-components';

const Notification = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  background-color: ${({ theme }) => theme.accentAlt700};
`;
const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 5px;
  margin-right: -5px;
  margin-bottom: 5px;
`;
const Title = styled.p`
  color: ${({ theme }) => theme.whitePure};
  font-weight: 600;
  font-size: 0.875rem;
  margin-top: 10px;
  margin-left: 10px;
  padding-bottom: 5px;
`;
const Description = styled.p`
  font-size: 0.75rem;
  margin-left: 10px;
  margin-right: 10px;
  font-weight: 400;
  color: ${({ theme }) => theme.gray100};
`;
const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.gray100};
  padding: 0;
  font-weight: 600;
  &:hover {
    color: ${({ theme }) => theme.gray100};
  }
`;

const UpsellNotification = ({
  dataTestId,
  title,
  description,
  next,
  identifier,
  onNextClicked,
}) => {
  return (
    <Notification data-testid={dataTestId} key={identifier}>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <Actions>
        <StyledButton
          label={next.toUpperCase()}
          onClickAction={onNextClicked}
          type="link"
        />
      </Actions>
    </Notification>
  );
};

UpsellNotification.propTypes = {
  dataTestId: string,
  title: string,
  description: string,
  cancel: string,
  next: string,
  identifier: string,
  onNextClicked: func,
};

UpsellNotification.defaultProps = {
  title: '',
  description: '',
  cancel: '',
  next: '',
  identifier: '',
  onNextClicked: () => {},
};

export default UpsellNotification;
