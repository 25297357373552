import React, { useState, useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

// Components
import InputField from 'components/inputField/InputField';
import Button from 'components/button/Button';
import CustomCheckbox from 'components/form/CustomCheckbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RandomBgFormContainer from 'components/other/RandomBgFormContainer';
import PhoneInputField from 'components/form/PhoneInput';

// Helpers
import { motion, AnimatePresence } from 'framer-motion';
import localizer from 'localization/localizer';
import { capitalize } from 'helpers/util';
import env from 'environment';
import Cookies from 'universal-cookie';
import qs from 'query-string';
import { Vault } from '@prompto-api';
import { storeLoginInfo } from 'store/reducers/authReducer/AuthActions';

// App Language
import strings from 'localization/localizer';

// Styling
import styled from 'styled-components';
import { styledRespondTo } from 'styles/mixins';

import PromptoLogo from 'resources/prompto_logo.png';

const FormWrapper = styled.div`
  z-index: 10;
  padding: 130px 10px 10px 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.whitePure};
  overflow-y: auto;
`;

const Form = styled.div`
  width: 100%;
  min-height: 300px;
  max-height: calc(100% - 10px);
  color: ${({ theme }) => theme.primary900};
  h2 {
    font-size: 1.875rem;
    line-height: 1.33;
    font-weight: normal;
    margin: 0 0 10px;
  }

  h3 {
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.33;
    margin: 0 0 20px;
    ${styledRespondTo.lg`
      margin: 0 0 30px;
    `}
  }
`;

const StyledInputField = styled(InputField)`
  input {
    background-color: ${({ theme }) => theme.grayWhiteOff};
    border-color: ${({ theme }) => theme.gray300};
    font-size: 1rem;
    color: ${(props) => props.theme.primary200};
    padding: ${(props) =>
      props.withVisibilityToggler ? '8px 40px 8px 10px' : '8px 10px'};
    margin: 0;
    letter-spacing: ${({ wide }) => (wide ? 3 : 0)}px;
    height: 34px;
    line-height: normal;
    ${styledRespondTo.lg`
      height: 40px;
    `}
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  ${styledRespondTo.lg`
    margin-bottom: 20px;
  `}
`;

const InputLabel = styled.label`
  font-size: 0.75rem;
  color: ${(props) => props.theme.primary100};
  margin: 0 0 5px;
  flex-shrink: 0;
  position: relative;
  align-self: flex-start;
`;

const HDivider = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.gray300};
  margin-bottom: 12px;
  ${styledRespondTo.lg`
    margin-bottom: 20px;
  `}
`;

const TermsWrapper = styled.div`
  grid-area: terms;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 0 0 20px;
  ${styledRespondTo.lg`
    margin: 0 0 30px;
  `}
`;

const Terms = styled.div`
  font-size: 0.875rem;
  color: ${(props) => props.theme.primary300};
  margin-bottom: 5px;
  margin-left: 10px;
  a {
    color: ${(props) => props.theme.accentAlt300};
    text-decoration: none;
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.gray200 : theme.accentAlt500} !important;
  border: none !important;
  border-radius: 2px;
  font-size: 1rem;
  font-weight: 600;
  color: ${({ theme }) => theme.whitePure} !important;
  border: none;
  margin: 0;
  padding-top: 0;
  transition: all 180ms ease;
`;

const Error = styled(motion.div)`
  padding: 8px;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.errorColor};
  align-self: center;
  text-align: center;
`;

const SmallError = styled(Error)`
  padding-right: 15px;
  color: ${({ theme }) => theme.errorColor};
  align-self: flex-start;
  margin-top: 5px;
  font-size: 0.75rem;
`;

export const ErrorIcon = styled(FontAwesomeIcon)`
  margin-right: 7px;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const VisibilityToggler = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Logo = styled.img`
  position: absolute;
  top: -40px;
  left: 0;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 480px;
  ${styledRespondTo.sm`
    max-width: 480px;
    min-width: 480px;
  `}
`;

export const StyledLoader = styled(motion.div)``;

const StyledCustomCheckbox = styled(CustomCheckbox)``;

const LoginLink = styled.span`
  color: ${(props) => props.theme.accentAlt300};
  cursor: pointer;
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 8px;
`;

const fields = [
  {
    key: 'name'
  },
  {
    key: 'company'
  },
  {
    key: 'phoneNumber'
  },
  {
    key: 'divider'
  },
  {
    key: 'password',
    withVisibilityToggler: true,
    wide: true
  },
  {
    key: 'confirmPassword',
    withVisibilityToggler: true,
    wide: true
  },
  {
    key: 'divider'
  }
];

export const CustomInput = ({
  field,
  value,
  onChange,
  onBlur,
  handleSubmit,
  label,
  error
}) => {
  const { key, withVisibilityToggler, ...rest } = field;

  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    if (['password', 'confirmPassword'].includes(key)) {
      setHidden(true);
    }
  }, [key]);

  const ref = useRef();
  useEffect(() => {
    if (value) return ref.current?.focus();
  }, [hidden, value]);

  return (
    <InputGroup key={key}>
      <InputLabel>{label}</InputLabel>
      <InputWrapper>
        <StyledInputField
          ref={ref}
          type={hidden ? 'password' : 'text'}
          fieldName={key}
          onFieldChange={onChange}
          onBlur={onBlur}
          value={value}
          withVisibilityToggler={withVisibilityToggler}
          maxLength={256}
          handleSubmit={handleSubmit}
          {...rest}
        />
        {withVisibilityToggler && (
          <VisibilityToggler onClick={() => setHidden((prev) => !prev)}>
            <FontAwesomeIcon
              icon={['far', hidden ? 'eye-slash' : 'eye']}
              size={'1x'}
            />
          </VisibilityToggler>
        )}
      </InputWrapper>
      <AnimatePresence>
        {error && (
          <SmallError
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
          >
            Password is incorrect
          </SmallError>
        )}
      </AnimatePresence>
    </InputGroup>
  );
};

const ProductLedRegisterForm = ({
  AuthReducer,
  login,
  storeLogin,
  authRedirect,
  loginRedirect
}) => {
  const [processing, setProcessing] = useState(false);
  const [createUserError, setCreateUserError] = useState('');

  const [info, setInfo] = useState({});
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [isButtonActive, setIsButtonActive] = useState(false);

  const [phoneCountryCode, setPhoneCountryCode] = useState();

  const [passwordIsConfirmed, setPasswordIsConfirmed] = useState(true);

  const L = localizer.productLed;

  // Effects
  // Redirect to the projects page when user is authenticated
  useEffect(() => {
    const { authenticated, user } = AuthReducer;
    if (authenticated) {
      const { language } = user;
      strings.setLanguage(language || 'en');
      authRedirect();
    }
  }, [AuthReducer, authRedirect]);

  // Handlers
  const trimValue = (fieldKey) =>
    setTimeout(() => {
      setInfo((prev) => ({
        ...prev,
        [fieldKey]: prev[fieldKey]?.trim() ?? ''
      }));
    }, 150);

  const handleError = (error, email) => {
    setProcessing(false);
    let message = '';
    const { code, message: msg } = error.response.data;

    if ([4000, 3003].includes(code)) {
      message = localizer.formatString(
        L.vaultExists,
        <b>{email}</b>,
        <LoginLink onClick={() => loginRedirect(encodeURIComponent(email))}>
          {' '}
          {localizer.loginPage}{' '}
        </LoginLink>
      );
    } else if (code === 3002) {
      message = localizer.formatString(L.userExists, <b>{info.name}</b>);
    } else if (code === 2000) {
      message = L.missingLastName;
    } else {
      message = msg;
    }

    setCreateUserError(message);
  };

  const validateForm = useCallback(() => {
    setIsButtonActive(false);

    const isPasswordConfirmed =
      info.password && info.password === info.confirmPassword;
    const allInfoProvided = [
      'name',
      'company',
      'password',
      'confirmPassword'
    ].every((x) => !!info[x]?.trim());

    setPasswordIsConfirmed(isPasswordConfirmed);

    if (!agreedToTerms || processing) {
      setIsButtonActive(false);
      return;
    }

    setIsButtonActive(isPasswordConfirmed && allInfoProvided);
  }, [info, agreedToTerms, processing]);

  useEffect(() => {
    validateForm();
  }, [agreedToTerms, validateForm]);

  const onSubmit = () => {
    const cookies = new Cookies();
    const hutk = cookies.get('hubspotutk');

    const [firstname, ...lastname] = info.name.split(' ');
    const { email, utm_source } = qs.parse(window.location.search.substring(1));

    const shouldSavePhoneNumber =
      info.phoneNumber && info.phoneNumber !== phoneCountryCode?.dialCode;

    const payload = {
      fields: [
        {
          objectTypeId: '0-1',
          name: 'firstname',
          value: firstname
        },
        {
          objectTypeId: '0-1',
          name: 'lastname',
          value: lastname.join(' ')
        },
        {
          objectTypeId: '0-1',
          name: 'email',
          value: email
        },
        {
          objectTypeId: '0-1',
          name: 'company',
          value: info.company
        },
        {
          objectTypeId: '0-1',
          name: 'utm_source',
          value: utm_source
        }
      ],
      context: {
        hutk,
        pageName: 'Prompto Portal Product-led Registration'
      }
    };

    if (shouldSavePhoneNumber) {
      payload.fields.push({
        objectTypeId: '0-1',
        name: 'mobilephone',
        value: info?.phoneNumber ?? ''
      });
    }

    const {
      productLedRegistrationHubspotPortalId,
      productLedRegistrationHubspotFormId
    } = env();

    // create a user
    setProcessing(true);
    setCreateUserError('');

    const createVaultParams = {
      firstName: firstname,
      lastName: lastname.join(' ').trim(),
      companyName: info.company,
      email,
      password: info.password,
      utmSource: utm_source
    };

    if (shouldSavePhoneNumber) createVaultParams.phoneNumber = info.phoneNumber;

    Vault.createFromProductLed(createVaultParams)
      .then((response) => {
        if (response.data?.userLoginResponse) {
          Vault.submitHubspotProductLedRegistration(
            productLedRegistrationHubspotPortalId,
            productLedRegistrationHubspotFormId,
            payload
          ).catch(() => {});

          setProcessing(false);
          login(response.data.userLoginResponse);
          storeLogin(
            response.data.userLoginResponse?.user?.username,
            info.password
          );
        }
      })
      .catch((err) => handleError({ ...err }, email));
  };

  // Content
  const termsText = (
    <Terms>
      {localizer.iHaveReadAndAgreedTo}
      <a href={env().termsOfService} rel="noopener noreferrer" target="_blank">
        {capitalize(localizer.termsOfService)}
      </a>
      {` ${localizer.and} `}
      <a href={env().privacyPolicy} rel="noopener noreferrer" target="_blank">
        {capitalize(localizer.privacyPolicy)}
      </a>
      {` ${localizer.readAndAgreed}`}
    </Terms>
  );

  return (
    <RandomBgFormContainer>
      <FormWrapper>
        <Content>
          <Logo src={PromptoLogo} alt="prompto-logo" />
          <Form>
            <h2>{L.welcomeToPromptoApp}</h2>
            <h3>{L.activationInstruction}</h3>

            {fields.map((field, idx) => {
              const { key } = field;
              if (key === 'divider') return <HDivider key={`divider-${idx}`} />;

              if (key === 'phoneNumber') {
                return (
                  <InputGroup key={key}>
                    <InputLabel>{capitalize(localizer.phone)}</InputLabel>
                    <InputWrapper>
                      <PhoneInputField
                        width="100%"
                        value={info.phoneNumber ?? ''}
                        onChange={(phone, country) => {
                          setPhoneCountryCode(country);
                          setInfo((prev) => ({
                            ...prev,
                            phoneNumber: phone
                          }));
                        }}
                        countryCodeEditable={false}
                      />
                    </InputWrapper>
                  </InputGroup>
                );
              }

              return (
                <CustomInput
                  key={key}
                  field={field}
                  value={info[key]}
                  label={L[key]}
                  onChange={(fieldName, content) => {
                    if (createUserError) setCreateUserError('');
                    setPasswordIsConfirmed(true);
                    setIsButtonActive(false);
                    const value = ['password', 'confirmPassword'].includes(key)
                      ? content.trim()
                      : content;
                    setInfo((prev) => ({
                      ...prev,
                      [fieldName]: value
                    }));
                  }}
                  onBlur={() => {
                    trimValue(key);
                    validateForm();
                  }}
                  error={
                    key === 'confirmPassword' &&
                    info.confirmPassword &&
                    !passwordIsConfirmed
                  }
                />
              );
            })}
            <TermsWrapper>
              <StyledCustomCheckbox
                isChecked={false}
                onChecked={() => {
                  setAgreedToTerms(true);
                }}
                onUnchecked={() => {
                  setAgreedToTerms(false);
                }}
              />
              {termsText}
            </TermsWrapper>

            <AnimatePresence>
              {createUserError && (
                <Error
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -20 }}
                >
                  <ErrorIcon icon={['far', 'exclamation-triangle']} size="1x" />
                  {L.errorOccured}
                  <br />
                  {createUserError}
                </Error>
              )}
            </AnimatePresence>
            <StyledButton
              onClickAction={onSubmit}
              type="submit"
              status={isButtonActive ? 'default' : 'disabled'}
              disabled={!isButtonActive}
            >
              {processing && <Icon icon={['far', 'spinner']} size="sm" pulse />}
              {L.letsStart}
            </StyledButton>
          </Form>
        </Content>
      </FormWrapper>
    </RandomBgFormContainer>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  AuthReducer: state.store.AuthReducer
});
/* istanbul ignore next */
const mapDispatchToProps = (dispatch) => ({
  login: (payload) =>
    dispatch({ type: 'AUTH_LOGIN_REQUEST_FULFILLED', payload }),
  storeLogin: (username, password) =>
    dispatch(storeLoginInfo(username, password)),
  authRedirect: () => dispatch(push(`/${strings.getLanguage()}/projects`)),
  loginRedirect: (encodedEmail) =>
    dispatch(push(`/${strings.getLanguage()}/login?email=${encodedEmail}`))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductLedRegisterForm);
