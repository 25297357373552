const unitConfig = {
  badgeColor: '#28b3aa',
  badgeIcon: ['far', 'building']
};

const mediaConfig = {
  badgeColor: '#5068c2',
  badgeIcon: ['far', 'photo-video']
};

export const actionSource = {
  Unit: unitConfig,
  unitStatusUpdate: unitConfig,
  unitPriceUpdate: unitConfig,
  ContentItem: mediaConfig,
  unitFloorplanUpdate: mediaConfig
};
