import * as React from 'react';

function Svg360Custom(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M3.574 9.8a.703.703 0 01-.247.962c-1.238.735-1.92 1.595-1.92 2.422 0 .976.952 1.994 2.613 2.793 1.373.66 3.116 1.128 5.04 1.366l.447.05-.548-.548a.703.703 0 01.907-1.068l.088.074 1.875 1.875a.704.704 0 01.074.907l-.074.088-1.875 1.875a.702.702 0 01-.995 0 .704.704 0 01-.074-.908l.074-.087.774-.774c-2.406-.212-4.615-.762-6.322-1.583-2.2-1.058-3.411-2.5-3.411-4.06 0-.95.453-2.353 2.61-3.631a.703.703 0 01.964.246zm18.31.068C23.267 10.82 24 11.967 24 13.184c0 1.382-.923 2.65-2.67 3.668-1.573.917-3.694 1.565-6.133 1.876a.703.703 0 01-.178-1.395c2.222-.283 4.211-.885 5.602-1.696 1.272-.74 1.973-1.612 1.973-2.453 0-.927-.82-1.685-1.508-2.158a.703.703 0 01.797-1.158zM6.543 7.225c1.132 0 1.987.41 1.987 1.612 0 .65-.24 1.256-.686 1.46.525.197.908.588.908 1.31v.17c0 1.46-1.006 2.012-2.253 2.012-1.532 0-2.12-.935-2.12-1.683 0-.401.17-.508.66-.508.57 0 .712.125.712.463 0 .419.392.517.792.517.606 0 .82-.223.82-.802v-.17c0-.596-.365-.712-.855-.712-.303 0-.401-.267-.401-.534s.098-.534.4-.534c.34 0 .696-.045.696-.766 0-.517-.294-.642-.66-.642-.436 0-.659.107-.659.455 0 .302-.133.507-.65.507-.641 0-.721-.133-.721-.56 0-.695.499-1.595 2.03-1.595zm5.513 0c1.506 0 2.102.935 2.102 1.674 0 .428-.205.561-.65.561-.383 0-.721-.098-.721-.507 0-.339-.356-.517-.775-.517-.526 0-.837.276-.837.89v.802c.285-.311.686-.392 1.113-.392 1.015 0 1.87.446 1.87 1.87v.081c0 1.532-.953 2.102-2.182 2.102s-2.19-.57-2.19-2.102v-2.36c0-1.532.988-2.102 2.27-2.102zm5.362 0c1.229 0 2.19.57 2.19 2.102v2.36c0 1.532-.961 2.102-2.19 2.102-1.23 0-2.182-.57-2.182-2.102v-2.36c0-1.532.953-2.102 2.182-2.102zm-5.45 3.58c-.482 0-.793.232-.793.82v.16c0 .615.303.882.801.882.5 0 .793-.267.793-.882v-.08c0-.65-.294-.9-.802-.9zm5.45-2.369c-.499 0-.793.276-.793.89v2.361c0 .615.294.89.793.89.499 0 .801-.275.801-.89v-2.36c0-.615-.302-.89-.801-.89zM21.298 3c1.163 0 2.11.946 2.11 2.11a2.113 2.113 0 01-2.11 2.109 2.112 2.112 0 01-2.11-2.11c0-1.163.946-2.109 2.11-2.109zm0 1.406a.704.704 0 10.001 1.408.704.704 0 00-.001-1.408z"
      />
    </svg>
  );
}

export default Svg360Custom;
