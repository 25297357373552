import React, { useState, useEffect } from 'react';
import { number, shape } from 'prop-types';

// Components
import Svg360Icon from 'resources/icons/360Icon';

// Helpers
import { getAlbumByCode } from 'helpers/api/calls360';
import qs from 'query-string';
import urlJoin from 'url-join';
import env from 'environment';
import { isMobileOnly } from 'react-device-detect';

// Styling
import styled from 'styled-components';

const PlaceholderWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  color: white;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PlaceholderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledSvg360Icon = styled(Svg360Icon)`
  z-index: 1;
  fill: ${({ theme }) => theme.whitePure}!important;
  & > path {
    fill: ${({ theme }) => theme.whitePure}!important;
  }
  ${isMobileOnly &&
  `
    transform: scale(0.75);
  `}
`;

const CoverImage = styled.img`
  object-fit: cover;
  object-position: center;
  ${({ blurNeeded, isMobileOnly }) => {
    let styles = ``;

    if (blurNeeded) {
      styles += `
    filter: blur(20px);
    transform: scale(1.3);
    `;
    }
    if (isMobileOnly) {
      styles += `
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      filter: blur(3px);
      transform: scale(1);
    `;
    }

    return styles;
  }}
`;

const ImageOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageOverlayBackground = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: black;
  opacity: ${isMobileOnly ? '0.2' : '0.3'};
`;

const RATIO = 1.33; // standard 4x3 ratio

const Album360Preview = ({
  data,
  size = 500,
  isVerticalLayout
}) => {
  const [coverImage, setCoverImage] = useState();
  const [blurNeeded, setBlurNeeded] = useState(false);

  // calculate sizes
  let height = size;
  let width = size * RATIO;

  if (isVerticalLayout) {
    width = size;
    height = width / RATIO;
  }

  // Fetch tour album
  useEffect(() => {
    if (data?.contentUri) {
      const code = qs.parse(data.contentUri.split('?')[1], {
        parseBooleans: true,
        parseNumbers: true
      }).id;

      const transform = 'h200';

      if (data?.thumbnailUri) {
        setCoverImage(
          urlJoin(env().base360ImageUrl, transform, data.thumbnailUri)
        );
      } else {
        getAlbumByCode(code).then(({ data }) => {
          setBlurNeeded(true);
          setCoverImage(`data:image/jpeg;base64, ${data.image}`);
        });
      }
    }
  }, [data]);

  // Placeholder
  const style = {};

  if (isMobileOnly) {
    style.height = '100%';
    style.width = '100%';
  } else {
    style.height = `${size}px`;
    style.width = '100%';
    style.objectFit = 'contain';
  }

  const placeholder = (
    <PlaceholderContainer>
      <CoverImage
        blurNeeded={blurNeeded}
        isMobileOnly={isMobileOnly}
        src={coverImage}
        draggable={false}
        alt="360 tour"
        height={height}
        width={width}
      />
      <ImageOverlay>
        <ImageOverlayBackground />
        <StyledSvg360Icon />
      </ImageOverlay>
    </PlaceholderContainer>
  );

  return <PlaceholderWrapper>{placeholder}</PlaceholderWrapper>;
};

Album360Preview.propTypes = {
  data: shape({}).isRequired,
  pageWidth: number,
  pageHeight: number
};

Album360Preview.defaultProps = {
  pageWidth: null,
  pageHeight: null
};

export default Album360Preview;
