import React, { useState, useRef } from 'react';
import { string, array, arrayOf, func, shape, bool } from 'prop-types';

import Toolbar from 'components/toolbar/Toolbar';
import SearchField from 'components/form/searchField/SearchField';
import Divider from 'components/divider/Divider';
import SortBy from 'components/lists/SortBy';
import PromptoLoader from 'components/loader/PromptoLoader';
import localizer from 'localization/localizer';

import styled, { css, withTheme } from 'styled-components';
import { capitalize } from 'helpers/util';

const SearchProgressFeedback = styled(PromptoLoader)`
  width: auto;
  height: auto;
  flex-grow: 0;
  margin: 0 8px;

  visibility: ${(props) => (props.isHidden ? 'hidden' : 'visible')};
`;

const ToolbarWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const SortWrapper = styled.div`
  ${({ styles }) => styles}
`;

const toolbarWrapperStyles = (isFullWidth, givenStyles) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row wrap;
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.gray300};
  background-color: white;
  padding: 10px 20px;
  margin-bottom: 10px;
  min-height: 70px;
  width: ${isFullWidth ? 100 : 98}%;
  ${givenStyles}
`;

/**
 * Generic tool bar to show on top of any list
 * Through props, you can decide of "actions" that are added to the toolbar. Basically components that will be rendered in the toolbar.
 * By default, it includes:
 *  Search field to search the items in the list
 *  Sort options to sort the list based on some criteria
 * @param {Object} props
 * @param {Object} [props.searchOptions]
 * @param {boolean} [props.searchOptions.showSearchProgressOnFieldChanged = false]
 */
const ListToolbar = ({
  sortFieldPrefix,
  sortOptions,
  initialSortOption,
  initialAscending,
  actionFieldsLeft,
  actionFieldsRight,
  onChangeSearchField,
  searchOptions,
  onChangeSortField,
  onChangeSortOrderField,
  dataTestId,
  shouldReverseLeftFields,
  noDividers,
  sortFieldRight,
  fullWidth,
  sortById = 'toolbarSorting',
  styles,
  theme,
  withSortField = true,
  disableSearchBar,
  disableSorting
}) => {
  const searchCompletedTimer = useRef(null);
  const [isSearchInProgress, setIsSearchInProgress] = useState(false);

  const [sortValue, setSortValue] = useState(
    initialSortOption
      ? initialSortOption
      : sortOptions && sortOptions.length > 1
      ? sortOptions[0]
      : null
  );

  const [isAscending, setIsAscending] = useState(initialAscending);

  const onChangeSearchFieldDelayed = (...args) => {
    if (searchCompletedTimer.current) {
      clearTimeout(searchCompletedTimer.current);
    } else {
      setIsSearchInProgress(true);
    }
    searchCompletedTimer.current = setTimeout(() => {
      setIsSearchInProgress(false);
      onChangeSearchField(...args);
      searchCompletedTimer.current = null;
    }, 500);
  };

  // Searchfield
  let searchField = (
    <SearchField
      key={'searchField'}
      fieldName={`${dataTestId}_searchField`}
      onFieldChange={(...args) => {
        if (searchOptions.showSearchProgressOnFieldChanged) {
          onChangeSearchFieldDelayed(...args);
        } else {
          onChangeSearchField(...args);
        }
      }}
      childKey={'searchField'}
      dataTestId={`${dataTestId}_searchField`}
      hasDebounce
      placeholder={capitalize(localizer.search)}
      styles={`
        min-width: 180px;
        max-width: 260px;
        border: 1px solid #f1f1f4;
        ${styles.searchField}
      `}
      disabled={disableSearchBar}
    />
  );

  let searchInProgressFeedback = null;
  if (searchOptions.showSearchProgressOnFieldChanged) {
    searchInProgressFeedback = (
      <SearchProgressFeedback
        key="searchInProgressFeedback"
        width={16}
        height={16}
        isHidden={!isSearchInProgress}
      />
    );
  }

  // Divider
  let divider = (
    <Divider
      key={'divider'}
      thickness={1}
      marginSize={20}
      isVertical={true}
      color={theme.gray300}
      styles={{
        height: 30
      }}
    />
  );

  // Sortfield
  const onSortFieldChanged = (value) => {
    setSortValue(value);
    onChangeSortField(value);
  };

  const onSortOrderFieldChanged = (value) => {
    setIsAscending(value);
    onChangeSortOrderField(value);
  };

  let sortField = withSortField ? (
    <SortWrapper
      key="sortfields"
      className={styles.selectFieldWrapper}
      styles={styles.selectField}
    >
      <SortBy
        key="sort"
        prefix={sortFieldPrefix}
        sortOptions={sortOptions}
        selectedSortOption={sortValue}
        ascending={isAscending}
        onSortChanged={onSortFieldChanged}
        onSortOrderChanged={onSortOrderFieldChanged}
        id={sortById}
        disabled={disableSorting}
      />
    </SortWrapper>
  ) : null;

  let leftFields = [
    searchField,
    searchInProgressFeedback,
    noDividers ? undefined : divider,
    sortFieldRight ? undefined : sortField,
    ...actionFieldsLeft
  ];

  let rightFields = [
    sortFieldRight ? sortField : undefined,
    ...actionFieldsRight
  ];

  if (shouldReverseLeftFields) {
    leftFields = leftFields.reverse();
  }

  return (
    <ToolbarWrapper data-testid={dataTestId}>
      <Toolbar
        actionFieldsLeft={leftFields}
        actionFieldsRight={rightFields}
        styles={{
          wrapper: toolbarWrapperStyles(fullWidth, styles?.wrapper),
          rightFields: styles?.rightFields,
          leftFields: styles?.leftFields
        }}
      />
    </ToolbarWrapper>
  );
};

ListToolbar.propTypes = {
  /** Add a prefix to the selectfield value */
  sortFieldPrefix: string,
  /** the options of the sortField */
  sortOptions: arrayOf(
    shape({
      // Label that will be shown when the user chooses what to sort on
      label: string.isRequired,
      // The name (in code) representing the field to sort on. This should be the exact name of the field in the object that you will sort.
      value: string.isRequired
    })
  ),
  /** the initial sortOption to be selected. This should be one of the element of the sortOptions array. */
  initialSortOption: shape({
    label: string.isRequired,
    value: string.isRequired
  }),
  /** the initial ascending mode to be selected */
  initialAscending: bool,
  /** Actionfields starting from the left */
  actionFieldsLeft: array,
  /** Actionfields starting from the right */
  actionFieldsRight: array,
  /**
   * Called when the search field is changed
   * @callback
   * @param {string} fieldName the name of the field searched (useless, but kept to avoid breaking all callbacks)
   * @param {string} searchText the text in the search field
   */
  onChangeSearchField: func.isRequired,
  searchOptions: shape({
    /**
     * If true: before sending the "onChangeSearchField" callback, a "search in progress" indicator will be displayed next to the search bar for a fixed duration
     *          this is just to display to the user that something is happening. This can be useful in cases where changing the search field might cause other changes on the page
     * If false (default): onChangeSearchField is called immediately when the search field changes.
     */
    showSearchProgressOnFieldChanged: bool
  }),
  /**
   * Function that triggers on sortfield change
   * @param {{label: string, value: string}} sortOptionPicked
   */
  onChangeSortField: func.isRequired,
  /**
   * Function that triggers on ascendingField change
   * @param {boolean} isAscending
   */
  onChangeSortOrderField: func.isRequired,
  /** Data test id for automated testing */
  dataTestId: string,
  /** Styled-components styles */
  styles: shape({
    wrapper: string,
    rightFields: string,
    leftFields: string
  })
};

ListToolbar.defaultProps = {
  sortFieldPrefix: '',
  sortOptions: [],
  initialSortOption: null,
  initialAscending: true,
  actionFieldsLeft: [],
  actionFieldsRight: [],
  dataTestId: '',
  searchOptions: {
    showSearchProgressOnFieldChanged: false
  },
  fullWidth: false,
  styles: {},
  disableSearchBar: false,
  disableSorting: false
};

export default withTheme(ListToolbar);
