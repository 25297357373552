import { useEffect, useState } from 'react';

import { Vault } from '@prompto-api';
import { getUsersFromRoleList } from 'helpers/util';

const useGetUserRole = (vaultId, AuthReducer, userId) => {
  const [usersWithAccessToVault, setUsersWithAccessToVault] = useState([
    AuthReducer?.user
  ]);
  const [userDictionary, setUserDictionary] = useState();
  const [activeUserRole, setActiveUserRole] = useState();
  const [checkedForCachedRole, setCheckedForCachedRole] = useState(false);

  useEffect(() => {
    if (checkedForCachedRole) return;
    const cachedRole = localStorage.getItem(`prompto_role_${vaultId}`);
    if (cachedRole) {
      setActiveUserRole(cachedRole);
    }
    setCheckedForCachedRole(true);
  }, [checkedForCachedRole, vaultId]);

  // Get all users that have access to this vault.
  useEffect(() => {
    if (!checkedForCachedRole) return;
    Vault.getAllUsersThatCanAccess(vaultId, AuthReducer?.sessionToken)
      .then(({ data: { userDictionary } }) => {
        setUsersWithAccessToVault((currentUsers) => [
          ...currentUsers,
          ...getUsersFromRoleList(userDictionary)
        ]);
        setUserDictionary(userDictionary);
      })
      .catch(() => {});
  }, [vaultId, AuthReducer, checkedForCachedRole]);

  // Get current user role
  useEffect(() => {
    const userObjectId = userId || AuthReducer?.user?.objectId;
    if (userDictionary && userObjectId) {
      let userRole = 'not-defined';
      Object.entries(userDictionary).forEach(([role, users]) => {
        const userHasThisRole = users.find(
          (user) => user?.objectId === userObjectId
        );
        if (userHasThisRole) {
          userRole = role;
        }
      });
      setActiveUserRole(userRole);
      localStorage.setItem(`prompto_role_${vaultId}`, userRole);
    }
  }, [userDictionary, AuthReducer, userId, vaultId]);

  return { activeUserRole, userDictionary, usersWithAccessToVault };
};

export default useGetUserRole;
