import React, { useReducer, createContext, useContext } from 'react';
export const ToastStoreContext = createContext();

const ToastStore = props => {

  const [ToastState, ToastStateDispatch] = useReducer(
    (state, action) => {
      switch (action?.type) {
        case 'addToast':
          return [
            ...state,
            action.payload
          ];
        case 'removeToast':
          return state.filter(toast => toast.id !== action.payload);
        default:
          return state;
      }
    },
    []
  );

  return (
    <ToastStoreContext.Provider
      value={{ ToastState, ToastStateDispatch }}
    >
      {props.children}
    </ToastStoreContext.Provider>
  );
};

export const useToastState = () => useContext(ToastStoreContext);

export default ToastStore;
