import React, { lazy, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router';
import { func, shape, string, oneOfType, element } from 'prop-types';

// Components
import InactiveAccountModal from 'components/modals/InactiveAccountModal';
import InactiveUserModal from 'components/modals/InactiveUserModal';
import AdminSidebar from 'components/views/sidebar/AdminSidebar';
import PromptoLoader from 'components/loader/PromptoLoader';
import VaultAcceptInvitationModal from 'components/views/sidebar/vaultMenu/VaultAcceptInvitationModal';

// Helpers
import { featureIsAvailable, checkUserHasVault } from 'helpers/util';
import {
  userLogoutAction,
  userLoginAction
} from 'store/reducers/authReducer/AuthActions';
import VaultInfoCall from 'store/Vault/VaultInfo';
import { handleGetVaultListResult } from 'store/reducers/vaultListReducer/VaultListActions';

import { getVaultList } from 'store/reducers/vaultListReducer/VaultListApi';
import strings from 'localization/localizer';
import { vaultInfoAction } from 'store/reducers/vaultListReducer/VaultActions';
import history from 'store/history';

// Styling
import styled from 'styled-components';
import { styledRespondTo } from 'styles/mixins';

const Dashboard = styled.div`
  height: 100%;
  width: 100%;
  justify-items: center;
  align-items: stretch;
  display: flex;
  right: 0;
  left: 0;
  margin-left: 0;
  flex-direction: column;

  ${styledRespondTo.sm`
  margin-top: 0;
  flex-direction: row;
`}
`;

const ContentPage = lazy(() => import('components/views/ContentPage'));

const PrivateRouteContainer = ({
  component: Component,
  featureConditionName,
  AuthReducer,
  VaultReducers,
  VaultListReducer,
  SubscriptionReducers,
  handleGetVaultList,
  vaultInfo,
  reLogin,
  subscriptionPlansCall,
  ...props
}) => {
  const { authenticated, includedFeatureDictionary, user, sessionToken } =
    AuthReducer;

  const { vault, done, pending } = VaultReducers;

  // Check if the user is linked to a vault
  const userHasVault = checkUserHasVault(user);
  const userIsUnlinked = !userHasVault && authenticated;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (sessionToken && userIsUnlinked && !isLoading) {
      setIsLoading(true);
      getVaultList(sessionToken, handleGetVaultList);
    }
  }, [sessionToken, userIsUnlinked, isLoading, handleGetVaultList]);

  useEffect(() => {
    // We only need the invitationList fetched to continue, doesn't matter if it's empty or not
    if (VaultListReducer?.invitationList) {
      setIsLoading(false);
    }
  }, [VaultListReducer]);

  if (isLoading) {
    return (
      <Dashboard>
        <PromptoLoader />
      </Dashboard>
    );
  }

  return (
    <Route
      {...props}
      render={() => {
        // Check if the user has an inactive account;
        let userHasInactiveAccount = false;
        if (SubscriptionReducers) {
          const { currentSubscription } = SubscriptionReducers;
          userHasInactiveAccount =
            currentSubscription?.subscriptionStatus === 'cancelled';
        }

        // User has a vault, get vault data
        if (userHasVault) {
          if (!done && !pending) {
            VaultInfoCall(AuthReducer, vaultInfo);
          }
        }

        // Inactive account or user
        const isOnBillingPage =
          window.location.pathname.endsWith('/admin/billing');

        // Show the portal with only a sidebar and pass a modal to show to the user
        const portalWithOnlySidebar = (modalToShow) => (
          <Dashboard>
            <AdminSidebar />
            <ContentPage>{modalToShow}</ContentPage>
          </Dashboard>
        );

        if (userIsUnlinked) {
          // User is currently not linked to a vault, let's check if there are any invitations
          if (VaultListReducer?.invitationList?.length > 0) {
            // There is at least one invitation, let's show an invitation modal
            return portalWithOnlySidebar(
              <VaultAcceptInvitationModal
                invitation={VaultListReducer.invitationList[0]}
                sessionToken={sessionToken}
                showDecideLaterButton={false}
                successMessageTimeout={0}
                onClose={() => {}}
                onRemoveInvitation={() => {
                  history.push('/');
                }}
                onAcceptedInvitation={() => {
                  reLogin(AuthReducer.usernameOrEmail, AuthReducer.password);
                }}
              />
            );
          } else {
            // No invitations, let's show "no longer linked" modal
            return portalWithOnlySidebar(<InactiveUserModal />);
          }
        } else if (!isOnBillingPage && userHasInactiveAccount) {
          return portalWithOnlySidebar(<InactiveAccountModal />);
        } else {
          const FinalRoute = (
            <Dashboard>
              <AdminSidebar history={history} />
              <ContentPage>
                {!done ? <PromptoLoader /> : <Component {...props} />}
              </ContentPage>
            </Dashboard>
          );

          // If a feature condition exists, we need a vault to check it.
          if (featureConditionName) {
            let featureAvailable = false;
            if (
              SubscriptionReducers?.currentSubscription?.includedFeatureList
            ) {
              featureAvailable =
                SubscriptionReducers?.currentSubscription?.includedFeatureList?.includes(
                  featureConditionName
                );
            } else {
              featureAvailable = featureIsAvailable(
                includedFeatureDictionary,
                vault,
                featureConditionName
              );
            }

            if (authenticated && featureAvailable) {
              return FinalRoute;
            }
          } else {
            if (authenticated) {
              return FinalRoute;
            }
          }

          return (
            <Redirect
              to={{
                pathname: `/${strings.getLanguage()}/login`,
                search: window.location.search,
                state: { from: props.location }
              }}
            />
          );
        }
      }}
    />
  );
};

// PropTypes
PrivateRouteContainer.propTypes = {
  AuthReducer: shape({}).isRequired,
  VaultReducers: shape({}),
  SubscriptionReducers: shape({}),
  component: oneOfType([func, element]),
  location: shape({}).isRequired,
  featureConditionName: string
};

PrivateRouteContainer.defaultProps = {
  VaultReducers: {},
  SubscriptionReducers: {},
  featureConditionName: null,
  component: null
};
/* istanbul ignore next */
const mapStateToProps = (state) => ({
  AuthReducer: state.store.AuthReducer,
  VaultReducers: state.store.VaultReducers,
  VaultListReducer: state.store.VaultListReducer,
  SubscriptionReducers: state.store.SubscriptionReducers
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch) => {
  const onError = (sessionToken) => dispatch(userLogoutAction(sessionToken));

  return {
    vaultInfo: (AuthReducer, vaultObjectId, sessionToken) =>
      dispatch(vaultInfoAction(AuthReducer, vaultObjectId, sessionToken)),
    reLogin: (username, password) =>
      dispatch(userLoginAction(username, password, 'RELOGIN')),
    handleGetVaultList: (apiResult) =>
      dispatch(handleGetVaultListResult(apiResult))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRouteContainer);
