import React from 'react';

const PlaceholderEmptyPage = (props) => (
  <svg width={400} height={400} viewBox="0 0 195.1 184.5" xmlSpace="preserve">
    <g>
      <g style={{ opacity: 0.4 }}>
        <circle style={{ fill: '#EBEBEF' }} cx="110.2" cy="90" r="84.9" />
        <circle style={{ fill: '#EBEBEF' }} cx="45.2" cy="130.2" r="40.2" />
        <circle style={{ fill: '#EBEBEF' }} cx="137.9" cy="144.3" r="40.2" />
        <circle style={{ fill: '#EBEBEF' }} cx="125.9" cy="63" r="63" />
        <circle style={{ fill: '#EBEBEF' }} cx="63" cy="72.1" r="63" />
      </g>
      <g>
        <circle style={{ fill: '#EBEBEF' }} cx="85.9" cy="94.3" r="78.3" />
        <circle style={{ fill: '#EBEBEF' }} cx="145.9" cy="57.2" r="37" />
        <circle style={{ fill: '#EBEBEF' }} cx="60.4" cy="44.2" r="37" />
        <circle style={{ fill: '#EBEBEF' }} cx="71.4" cy="119.2" r="58" />
        <circle style={{ fill: '#EBEBEF' }} cx="129.5" cy="110.8" r="58" />
      </g>
      <g>
        <path
          style={{ fill: '#C8AD55' }}
          d="M75.2,151.3l-3.8,0.8l0.8,3.8l-1.2,0.2l-0.8-3.8l-3.8,0.8l-0.2-1.2l3.8-0.8l-0.8-3.8l1.2-0.2
			l0.8,3.8l3.8-0.8L75.2,151.3z"
        />
      </g>
      <g style={{ opacity: 0.5 }}>
        <g>
          <line
            style={{
              fill: 'none',
              stroke: '#9F9CAA',
              strokeWidth: 3,
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeMiterlimit: 10
            }}
            x1="130.1"
            y1="68.5"
            x2="142.6"
            y2="68.5"
          />

          <line
            style={{
              fill: 'none',
              stroke: '#9F9CAA',
              strokeWidth: 3,
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeMiterlimit: 10
            }}
            x1="127.1"
            y1="61.4"
            x2="136"
            y2="52.6"
          />

          <line
            style={{
              fill: 'none',
              stroke: '#9F9CAA',
              strokeWidth: 3,
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeMiterlimit: 10
            }}
            x1="120.1"
            y1="58.5"
            x2="120.1"
            y2="46"
          />
        </g>
      </g>
      <g style={{ opacity: 0.5 }}>
        <path
          style={{ fill: '#706A81' }}
          d="M95.8,40.2l-3.3-1.6L91,41.8l-1-0.5l1.6-3.3l-3.3-1.6l0.5-1l3.3,1.6l1.6-3.3l1,0.5l-1.6,3.3
			l3.3,1.6L95.8,40.2z"
        />
      </g>
      <line
        style={{ fill: 'none', stroke: '#C8AD55', strokeMiterlimit: 10 }}
        x1="53.1"
        y1="46.4"
        x2="58.2"
        y2="53.2"
      />

      <line
        style={{
          fill: 'none',
          stroke: '#9F9CAA',
          strokeWidth: 3,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: 10,
          opacity: 0.5
        }}
        x1="135.3"
        y1="140.9"
        x2="140.4"
        y2="147.6"
      />

      <line
        style={{
          fill: 'none',
          stroke: '#9F9CAA',
          strokeWidth: 3,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: 10,
          opacity: 0.5
        }}
        x1="146.7"
        y1="90"
        x2="151.6"
        y2="92.1"
      />

      <circle
        style={{
          fill: 'none',
          stroke: '#9F9CAA',
          strokeWidth: 3,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: 10,
          opacity: 0.5
        }}
        cx="47.7"
        cy="97.2"
        r="2.5"
      />
      <circle
        style={{ fill: 'none', stroke: '#C8AD55', strokeMiterlimit: 10 }}
        cx="146.6"
        cy="116.3"
        r="2.5"
      />
      <g>
        <path
          style={{ fill: '#F6F7F9' }}
          d="M120.4,130.3H73.9c-1.7,0-3.2-1.4-3.2-3.2V65.4c0-1.7,1.4-3.2,3.2-3.2h30.7
			c1.7,0,18.7,16.9,18.7,18.7l0.3,46.2C123.6,128.9,122.2,130.3,120.4,130.3z"
        />
        <g>
          <path
            style={{
              fill: 'none',
              stroke: '#9F9CAA',
              strokeWidth: 3,
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeMiterlimit: 10
            }}
            d="
				M104.6,62.2h-28c-3.9,0-7,3.1-7,7v56c0,3.9,3.1,7,7,7h42c3.9,0,7-3.1,7-7v-42L104.6,62.2z"
          />

          <polyline
            style={{
              fill: 'none',
              stroke: '#9F9CAA',
              strokeWidth: 3,
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeMiterlimit: 10
            }}
            points="
				104.6,62.2 104.6,83.2 125.6,83.2 			"
          />

          <polyline
            style={{
              fill: 'none',
              stroke: '#9F9CAA',
              strokeWidth: 3,
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeMiterlimit: 10
            }}
            points="
				90.6,86.7 87.1,86.7 83.6,86.7 			"
          />
        </g>
        <g>
          <line
            style={{
              fill: 'none',
              stroke: '#9F9CAA',
              strokeWidth: 3,
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeMiterlimit: 10
            }}
            x1="92.8"
            y1="115.1"
            x2="101.5"
            y2="115.1"
          />
          <circle style={{ fill: '#9F9CAA' }} cx="105.9" cy="105.9" r="2.2" />
          <circle style={{ fill: '#9F9CAA' }} cx="88.4" cy="105.9" r="2.2" />
        </g>
      </g>
    </g>
  </svg>
);

export default PlaceholderEmptyPage;
