import { css, keyframes } from 'styled-components';

export const tablet = 'tablet';
export const desktop = 'desktop';
export const desktopHd = 'desktopHd';

export const breakPointsMin = {
  mobile: null,
  tablet: 631,
  desktop: 1025,
  desktopHd: 1601
};

export const breakPointsMax = {
  mobile: 630,
  tablet: 1024,
  desktop: 1600,
  desktopHd: null
};

export const respondTo = (mediaTypes) => {
  let minBreakPoint = breakPointsMin[mediaTypes[0]];
  let maxBreakPoint = breakPointsMax[mediaTypes[0]];

  // Iterate through all arguments and get the smallest min and the largest max breakpoint
  mediaTypes.forEach((mediaType) => {
    const mediaTypeMinBreakPoint = breakPointsMin[mediaType];
    if (!mediaTypeMinBreakPoint || minBreakPoint > mediaTypeMinBreakPoint) {
      minBreakPoint = mediaTypeMinBreakPoint;
    }
    const mediaTypeMaxBreakPoint = breakPointsMax[mediaType];
    if (!mediaTypeMaxBreakPoint || maxBreakPoint < mediaTypeMaxBreakPoint) {
      maxBreakPoint = mediaTypeMaxBreakPoint;
    }
  });

  let query = `@media (min-width: 0px)`;
  if (minBreakPoint == null && maxBreakPoint) {
    query = `@media (max-width: ${maxBreakPoint}px)`;
  } else if (minBreakPoint && maxBreakPoint == null) {
    query = `@media (min-width: ${minBreakPoint}px)`;
  } else if (minBreakPoint && maxBreakPoint) {
    query = `@media (min-width: ${minBreakPoint}px) and (max-width: ${maxBreakPoint}px)`;
  }

  return query;
};

export const breakpoints = {
  sm: '630px', // mobile
  md: '1024px', // tablet
  lg: '1200px',
  extra_lg: '1600px' // desktop
};

export const styledRespondTo = Object.keys(breakpoints).reduce(
  (accumulator, label) => {
    accumulator[label] = (...args) => css`
      @media (min-width: ${breakpoints[label]}) {
        ${css(...args)};
      }
    `;
    return accumulator;
  },
  {}
);

export const doubleTransition = (trans, trans2) => css`
  -webkit-transition: ${trans}, ${trans2};
  -moz-transition: ${trans}, ${trans2};
  -ms-transition: ${trans}, ${trans2};
  -o-transition: ${trans}, ${trans2};
  transition: ${trans}, ${trans2};
`;

const slidingFrontSunbeam = keyframes`
    0% { left: -80% }
    100% { left: 120% }
`;

export const skeletonAnimation = (gradient, afterStyle) => css`
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 80%;
    background-image: linear-gradient(
      ${gradient ??
      `90deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.25) 30%,
      rgba(255, 255, 255, 0.37) 54%,
      rgba(255, 255, 255, 0.25) 70%,
      rgba(255, 255, 255, 0)`}
    );
    transform: skew(-8deg);
    animation: ${slidingFrontSunbeam} 2s infinite ease-out;
    ${afterStyle}
  }
`;
