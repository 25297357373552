import React from 'react';

import { number, string } from 'prop-types';
import Progressbar from 'components/progressbar/Progressbar';
import localizer from 'localization/localizer';

// Styling
import styled from 'styled-components';

const LoaderWrapper = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 15;
`;
const ProgressbarWrapper = styled.div`
  position: fixed;
  bottom: 50px;
  right: 300px;
  left: 300px;
`;
const IFrame = styled.iframe`
  height: 100%;
  width: 100%;
  border: none;
`;

const GalleryLoader = ({ progressbarValue, maxValue, loaderGalleryUrl }) => {
  if (!loaderGalleryUrl) {
    return null;
  }

  return (
    <LoaderWrapper key="loadWrapper">
      <ProgressbarWrapper>
        <Progressbar
          currentValue={progressbarValue}
          maxValue={maxValue}
          stylesObject={{
            filler: { background: 'white' },
            label: { color: 'white', fontSize: '26px', paddingBottom: '30px' },
            progressbar: { width: '100%', color: 'white' }
          }}
          labelText={localizer.launchingStream}
        />
      </ProgressbarWrapper>
      <IFrame id="loaderFrame" src={loaderGalleryUrl} title="loadingScreen" />
    </LoaderWrapper>
  );
};

GalleryLoader.propTypes = {
  progressbarValue: number,
  loaderGalleryUrl: string,
  maxValue: number
};

GalleryLoader.defaultProps = {
  progressbarValue: 0,
  loaderGalleryUrl: '',
  maxValue: 10
};

export default GalleryLoader;
