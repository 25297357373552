import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

// Styling
import { withTheme } from 'styled-components';

export const renderHiddenHorizontalThumb = ({ style, ...props }) => (
  <div
    style={{ ...style, overflowX: 'hidden', visibility: 'hidden' }}
    {...props}
  />
);

const StyledScrollbarWrapper = ({ children, theme, ...props }) => {
  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      borderRadius: 2,
      backgroundColor: theme.gray200,
      width: 4
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  return (
    <Scrollbars autoHeight renderThumbVertical={renderThumb} {...props}>
      {children}
    </Scrollbars>
  );
};

export default withTheme(StyledScrollbarWrapper);
