import initialState from 'store/reducers/vaultListReducer/defaultVaultState';
import { validActionCheck } from 'store/actionUtils';
import { createVaultCookie, removeVaultCookie } from 'helpers/util';
import numeral from 'numeral';

const VaultReducers = (state = initialState, action) => {
  if (validActionCheck(action)) {
    switch (action.type) {
      case 'AUTH_RELOGIN_REQUEST_FULFILLED': {
        return {
          ...state,
          done: false
        };
      }

      case 'VAULT_INFO_REQUEST': {
        return {
          ...state
        };
      }

      case 'VAULT_INFO_REQUEST_PENDING': {
        return {
          ...state,
          done: false,
          pending: true
        };
      }

      case 'VAULT_INFO_REQUEST_REJECTED': {
        return {
          ...state,
          ...action.payload,
          done: true,
          error: true,
          pending: false
        };
      }

      case 'VAULT_INFO_REQUEST_FULFILLED': {
        const { data } = action?.payload;
        if (data) {
          const { vault } = data;
          createVaultCookie(vault.objectId);
          if (
            vault.settings?.defaultNumberFormat &&
            vault.settings?.defaultNumberFormat !== 'auto'
          ) {
            numeral.locale(vault.settings?.defaultNumberFormat);
          }
        }

        return {
          ...state,
          ...action.payload.data,
          done: true,
          pending: false
        };
      }

      case 'SET_VAULT_INITIAL_INFO':
        return {
          ...state,
          vault: {
            ...action.payload
          }
        };

      case 'AUTH_RESET':
      case 'AUTH_LOGOUT_REQUEST_PENDING': {
        removeVaultCookie();
        return {
          ...initialState
        };
      }
      case 'VAULT_UPDATE_REQUEST_FULFILLED': {
        return {
          ...state,
          ...action.payload.data
        };
      }

      case 'VAULT_UPDATE_SUBSCRIPTION_REQUEST_FULFILLED': {
        return {
          ...state,
          ...action.payload.data
        };
      }

      case 'ADD_INVITATION': {
        return {
          ...state,
          invitationList: [...state.invitationList, action.payload]
        };
      }

      case 'REMOVE_INVITATION': {
        let updatedInvitationList = state.invitationList;
        if (updatedInvitationList) {
          updatedInvitationList = updatedInvitationList.filter(
            (user) => user.uuid !== action.payload.uuid
          );
        }
        return {
          ...state,
          invitationList: updatedInvitationList
        };
      }

      case 'REMOVE_USER': {
        let updatedUserList = state.userList;
        if (updatedUserList) {
          updatedUserList = updatedUserList.filter(
            (user) => user.objectId !== action.payload.objectId
          );
        }
        return {
          ...state,
          userList: updatedUserList
        };
      }

      case 'UPDATE_VAULT_INFO': {
        return {
          ...state,
          vault: {
            ...state.vault,
            ...action.payload
          }
        };
      }

      default: {
        return state;
      }
    }
  }

  return state;
};

export default VaultReducers;
