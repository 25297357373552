// icons
import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faLevelDown as faLevelDownLight,
  faLongArrowLeft as faLongArrowLeftLight,
  faMapMarkerAltSlash,
  faMapMarkerEdit,
  faTvAlt,
  faPen,
  faPlusCircle,
  faEyeDropper,
  faEye as faEyeLight,
  faEyeSlash as faEyeSlashLight,
  faTrash as faTrashLight,
  faUnlink as faUnlinkLight,
  faLink as faLinkLight,
  faExternalLink as faExternalLinkLight,
  faCloudDownload as faCloudDownloadLight,
  faCheck as faCheckLight,
  faCopy as faCopyLight,
  faBuilding as faBuildingLight,
  faBars,
  faSpinner as faSpinnerLight,
  faEnvelopeOpenText,
  faEdit,
  faPaperclip,
  faCommentsAlt
} from '@fortawesome/pro-light-svg-icons';

import {
  faAddressBook,
  faAlignCenter,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faAnalytics,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faArrows,
  faArrowDown,
  faArrowFromLeft,
  faArrowLeft,
  faArrowsV,
  faArrowToRight,
  faArrowUp,
  faAward,
  faBabyCarriage,
  faBan,
  faBasketballBall,
  faBath,
  faBatteryBolt,
  faBed,
  faBell,
  faBicycle,
  faBorderRight,
  faBurgerSoda,
  faBus,
  faBrowser,
  faCalendarDay,
  faChartLine,
  faCheck,
  faCheckDouble,
  faClone,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCloudDownload,
  faCloudUpload,
  faCog,
  faCompass,
  faCompress,
  faCopy,
  faCreditCard,
  faCube,
  faCubes,
  faEllipsisH,
  faEnvelope,
  faExchange,
  faExchangeAlt,
  faExclamationCircle,
  faExclamationTriangle,
  faExpand,
  faExternalLink,
  faEye,
  faEyeSlash,
  faFile,
  faFileDownload,
  faFileExport,
  faFileImport,
  faFileSignature,
  faFilter,
  faFlask,
  faFolderOpen,
  faFolderPlus,
  faForward,
  faHandPaper,
  faHistory,
  faImages,
  faInfoCircle,
  faLevelDown,
  faLevelUp,
  faLongArrowDown,
  faLongArrowLeft,
  faLongArrowRight,
  faLongArrowUp,
  faMap,
  faMedkit,
  faMountains,
  faPaperPlane,
  faPen as faPenRegular,
  faPencil,
  faPhotoVideo,
  faPlayCircle,
  faPlusSquare,
  faPresentation,
  faRepeat,
  faRulerCombined,
  faShare,
  faShareAlt,
  faShoppingCart,
  faSignature,
  faSignOutAlt,
  faSortAlphaDown,
  faSortAlphaUp,
  faSpinner,
  faSync,
  faSyncAlt,
  faTag,
  faTimes,
  faTimesCircle,
  faThumbtack,
  faTrash,
  faUndo,
  faUnlink,
  faUser,
  faUserEdit,
  faUsers,
  faUserClock,
  faUserLock,
  faVectorSquare,
  faVideo,
  faHouse,
  faHouseDay,
  faHomeAlt,
  faHomeLg,
  faGarageOpen,
  faWarehouseAlt,
  faBuilding,
  faCity,
  faGopuram,
  faHotel,
  faLandmarkAlt,
  faStore,
  faFarm,
  faSchool,
  faFlag,
  faImage,
  faWindowFrame,
  faAirConditioner,
  faHouseSignal,
  faBellOn,
  faBlindsRaised,
  faBooks,
  faCoffeePot,
  faDungeon,
  faFireplace,
  faGarageCar,
  faLightbulbOn,
  faMailbox,
  faPaw,
  faDumbbell,
  faSpa,
  faTrees,
  faCctv,
  faDryer,
  faPlusCircle as faPlusCircleRegular,
  faMinusCircle,
  faFireExtinguisher,
  faMapSigns,
  faMapMarkerAlt as faMapMarkerAltRegular,
  faBinoculars as faBinocularsRegular,
  faConstruction,
  faGrin,
  faWheelchair,
  faHospital,
  faChurch,
  faMosque,
  faArchway,
  faUniversity,
  faIndustryAlt,
  faCampground,
  faRouteHighway,
  faGraduationCap,
  faMoneyBillWave,
  faWallet,
  faCroissant,
  faCocktail,
  faCoffeeTogo,
  faBeer,
  faUtensilsAlt,
  faAppleCrate,
  faStoreAlt,
  faBagsShopping,
  faWasher,
  faGasPump,
  faChargingStation,
  faCarGarage,
  faCarMechanic,
  faSubway,
  faHelicopter,
  faAnchor,
  faCaravan,
  faTram,
  faFlowerDaffodil,
  faUmbrellaBeach,
  faIslandTropical,
  faWater,
  faSwimmingPool,
  faSunCloud,
  faHiking,
  faDogLeashed,
  faFutbol,
  faRacquet,
  faGolfClub,
  faFilm,
  faClinicMedical,
  faSort,
  faSortAmountUp,
  faSortNumericUp,
  faSortShapesUp,
  faFileAlt,
  faMicrophoneStand,
  faTags,
  faMinus,
  faFolderTimes,
  faPlus as faPlusRegular,
  faUsdCircle,
  faFolderTree,
  faExpandAlt,
  faMagic,
  faCommentAltCheck,
  faCommentAltTimes,
  faGift,
  faLanguage,
  faUnderline,
  faItalic,
  faBold,
  faH1,
  faH2,
  faLink,
  faInboxIn,
  faColumns,
  faThList,
  faArrowToBottom,
  faPrint,
  faCommentsAlt as faCommentsAltRegular,
  faCheckCircle as faCheckCircleRegular,
  faClock,
  faMapMarkerSlash,
  faCouch,
  faBedAlt,
  faOven,
  faShower,
  faChairOffice,
  faTreeAlt,
  faSeedling,
  faTelescope,
  faSortCircleUp,
  faSortCircleDown,
  faFaucet,
  faStroopwafel,
  faSolarPanel,
  faTv,
  faRulerVertical,
  faRulerHorizontal,
  faDollyFlatbed,
  faForklift,
  faPalletAlt,
  faConveyorBelt,
  faCalendarAlt,
  faFileContract,
  faInventory,
  faFire,
  faPlug,
  faBolt,
  faWifi,
  faDumpster,
  faTruck,
  faWeightHanging,
  faParking,
  faCarSide,
  faWrench,
  faHammer,
  faUtensils,
  faPaintRoller,
  faWindTurbine,
  faRouter,
  faPlaneAlt,
  faCogs,
  faEdit as faEditRegular,
  faPhonePlus as faPhonePlusRegular
} from '@fortawesome/pro-regular-svg-icons';

import {
  faCcMastercard,
  faCcVisa,
  faWhatsapp
} from '@fortawesome/free-brands-svg-icons';

import {
  faArrowUp as faArrowUpSolid,
  faBinoculars,
  faCaretDown,
  faCaretUp,
  faCaretRight,
  faCheckCircle,
  faCheck as faCheckSolid,
  faExclamationCircle as faExclamationCircleSolid,
  faEye as faEyeSolid,
  faFlag as faFlagSolid,
  faImage as faImageSolid,
  faInfoCircle as faInfoCircleSolid,
  faLink as faLinkSolid,
  faLock,
  faMale,
  faMapMarkerAlt,
  faMapPin,
  faMoon,
  faPlus,
  faQuestionCircle,
  faShoePrints,
  faStreetView,
  faSun,
  faTimesCircle as faTimesCircleSolid,
  faThLarge,
  faWalking,
  faHome as faHomeSolid,
  faBuilding as faBuildingSolid,
  faList,
  faPaperPlane as faPaperPlaneSolid,
  faUser as faUserSolid,
  faChevronDown as faChevronDownSolid
} from '@fortawesome/free-solid-svg-icons';

import { faFlagAlt as faFlagAltSolid } from '@fortawesome/pro-solid-svg-icons';

export default function registerFaIcons() {
  library.add(
    faEditRegular,
    faPhonePlusRegular,
    faWhatsapp,
    faAddressBook,
    faUserSolid,
    faAlignCenter,
    faAlignJustify,
    faAlignLeft,
    faAlignRight,
    faAnalytics,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faArrows,
    faArrowDown,
    faArrowFromLeft,
    faArrowLeft,
    faArrowsV,
    faArrowToRight,
    faArrowUp,
    faArrowUpSolid,
    faAward,
    faBabyCarriage,
    faBan,
    faBasketballBall,
    faBath,
    faBatteryBolt,
    faBed,
    faBell,
    faBicycle,
    faBinoculars,
    faBorderRight,
    faBuilding,
    faBuildingLight,
    faBurgerSoda,
    faBus,
    faBrowser,
    faCalendarDay,
    faCaretDown,
    faCaretUp,
    faCaretRight,
    faCcMastercard,
    faCcVisa,
    faChartLine,
    faCheck,
    faCheckDouble,
    faCheckCircle,
    faCheckCircleRegular,
    faCheckLight,
    faCheckSolid,
    faClone,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCloudDownload,
    faCloudDownloadLight,
    faCloudUpload,
    faCog,
    faCompass,
    faCompress,
    faCopy,
    faCopyLight,
    faCreditCard,
    faCube,
    faCubes,
    faEllipsisH,
    faEnvelope,
    faEnvelopeOpenText,
    faExchange,
    faExchangeAlt,
    faExclamationCircle,
    faExclamationCircleSolid,
    faExclamationTriangle,
    faExpand,
    faExternalLink,
    faExternalLinkLight,
    faEye,
    faEyeLight,
    faEyeDropper,
    faEyeSlash,
    faEyeSlashLight,
    faEyeSolid,
    faFile,
    faFileDownload,
    faFileExport,
    faFileImport,
    faFileSignature,
    faFilter,
    faFlagAltSolid,
    faFlagSolid,
    faFlask,
    faFolderOpen,
    faFolderPlus,
    faForward,
    faHandPaper,
    faHistory,
    faImages,
    faImageSolid,
    faInfoCircle,
    faInfoCircleSolid,
    faLanguage,
    faLevelDown,
    faLevelDownLight,
    faLevelUp,
    faLinkSolid,
    faLinkLight,
    faLock,
    faLongArrowLeftLight,
    faLongArrowDown,
    faLongArrowLeft,
    faLongArrowRight,
    faLongArrowUp,
    faMale,
    faMap,
    faMapMarkerAlt,
    faMapMarkerAltSlash,
    faMapMarkerEdit,
    faMapPin,
    faMedkit,
    faMicrophoneStand,
    faMoon,
    faMountains,
    faPaperPlane,
    faPen,
    faPencil,
    faPenRegular,
    faPhotoVideo,
    faPlayCircle,
    faPlus,
    faPlusCircle,
    faPlusSquare,
    faPresentation,
    faQuestionCircle,
    faRepeat,
    faRulerCombined,
    faShare,
    faShareAlt,
    faShoePrints,
    faShoppingCart,
    faSignature,
    faSignOutAlt,
    faSortAlphaDown,
    faSortAlphaUp,
    faSpinner,
    faStreetView,
    faSun,
    faSync,
    faSyncAlt,
    faTag,
    faThLarge,
    faTimes,
    faTimesCircle,
    faTimesCircleSolid,
    faThumbtack,
    faTrash,
    faTrashLight,
    faTvAlt,
    faUndo,
    faUnlink,
    faUnlinkLight,
    faUser,
    faUserEdit,
    faUsers,
    faUserClock,
    faUserLock,
    faVectorSquare,
    faVideo,
    faWalking,
    faHouse,
    faHouseDay,
    faHomeAlt,
    faHomeLg,
    faGarageOpen,
    faWarehouseAlt,
    faCity,
    faGopuram,
    faHotel,
    faLandmarkAlt,
    faStore,
    faFarm,
    faSchool,
    faFlag,
    faImage,
    faWindowFrame,
    faAirConditioner,
    faHouseSignal,
    faBellOn,
    faBlindsRaised,
    faBooks,
    faCoffeePot,
    faDungeon,
    faFireplace,
    faGarageCar,
    faLightbulbOn,
    faMailbox,
    faPaw,
    faDumbbell,
    faSpa,
    faTrees,
    faCctv,
    faDryer,
    faHomeSolid,
    faBuildingSolid,
    faPlusCircleRegular,
    faMinusCircle,
    faFireExtinguisher,
    faMapSigns,
    faMapMarkerAltRegular,
    faBinocularsRegular,
    faConstruction,
    faGrin,
    faWheelchair,
    faHospital,
    faChurch,
    faMosque,
    faArchway,
    faUniversity,
    faIndustryAlt,
    faCampground,
    faRouteHighway,
    faGraduationCap,
    faMoneyBillWave,
    faWallet,
    faCroissant,
    faCocktail,
    faCoffeeTogo,
    faBeer,
    faUtensilsAlt,
    faAppleCrate,
    faStoreAlt,
    faBagsShopping,
    faWasher,
    faGasPump,
    faChargingStation,
    faCarGarage,
    faCarMechanic,
    faSubway,
    faHelicopter,
    faAnchor,
    faCaravan,
    faTram,
    faFlowerDaffodil,
    faUmbrellaBeach,
    faIslandTropical,
    faWater,
    faSwimmingPool,
    faSunCloud,
    faHiking,
    faDogLeashed,
    faFutbol,
    faRacquet,
    faGolfClub,
    faVideo,
    faFilm,
    faClinicMedical,
    faSort,
    faSortAmountUp,
    faSortNumericUp,
    faSortShapesUp,
    faFileAlt,
    faTags,
    faMinus,
    faFolderTimes,
    faPlusRegular,
    faUsdCircle,
    faFolderTree,
    faList,
    faExpandAlt,
    faBars,
    faSpinnerLight,
    faMagic,
    faCommentAltCheck,
    faCommentAltTimes,
    faGift,
    faUnderline,
    faItalic,
    faBold,
    faH1,
    faH2,
    faLink,
    faInboxIn,
    faEdit,
    faColumns,
    faThList,
    faArrowToBottom,
    faPrint,
    faPaperclip,
    faPaperPlaneSolid,
    faCommentsAlt,
    faCommentsAltRegular,
    faChevronDownSolid,
    faClock,
    faMapMarkerSlash,
    faCouch,
    faBedAlt,
    faOven,
    faShower,
    faChairOffice,
    faTreeAlt,
    faSeedling,
    faTelescope,
    faSortCircleUp,
    faSortCircleDown,
    faFaucet,
    faStroopwafel,
    faSolarPanel,
    faTv,
    faRulerVertical,
    faRulerHorizontal,
    faDollyFlatbed,
    faForklift,
    faPalletAlt,
    faConveyorBelt,
    faCalendarAlt,
    faFileContract,
    faFire,
    faPlug,
    faBolt,
    faWifi,
    faDumpster,
    faTruck,
    faWeightHanging,
    faParking,
    faCarSide,
    faWrench,
    faHammer,
    faUtensils,
    faPaintRoller,
    faWindTurbine,
    faRouter,
    faPlaneAlt,
    faInventory,
    faCogs
  );
}
