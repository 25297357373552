import React, { useState, useEffect, useCallback } from 'react';
import { string, array, func, bool, shape, number } from 'prop-types';

import { MdSearch, MdHighlightOff } from 'react-icons/md';
import ContentLoader from 'react-content-loader';
import Autosuggest from 'react-autosuggest';
import ClampLines from 'react-clamp-lines';

import Image from 'components/image/Image';

import styled from 'styled-components';

const Card = styled.div`
  display: flex;
  flex-flow: row;
  height: 72px;
  padding: 5px;
  border-radius: 3px;
  text-transform: none;
  border: 1px solid transparent;
  margin-bottom: 10px;
  cursor: default;
  background: ${({ theme }) => theme.whiteOff};
  color: ${({ theme }) => theme.primary200};

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.mainTextColor1} !important;
    border: 1px solid #eaedf0;
  }
`;

const CardText = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding-left: 10px;
  font-size: 1rem;
  line-height: 1.4rem;
  overflow: hidden;
`;

const EmptyCard = styled.div`
  height: 72px;
  padding: 5px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  text-transform: none;
  border: 1px solid transparent;
  margin-bottom: 10px;
  background: ${({ theme }) => theme.whiteOff};
  color: ${({ theme }) => theme.primary200};
`;

const EmptyCardText = styled.div`
  font-size: 1rem;
  line-height: 1.4rem;
`;

const CardListSection = styled.li`
  color: ${({ theme }) => theme.primary200};
  display: flex;
  flex-flow: row;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 600;
  line-height: 1.4rem;
  padding-left: 10px;
`;

const CardListWrapper = styled.div`
  width: 100%;
  max-height: 420px;
  position: absolute;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.whitePure};
  padding: 10px;
  padding-bottom: 0;
  border-radius: 0 0 3px 3px;
  border: 1px solid #eaedf0;
  z-index: 100;
`;

const ClearButtonWrapper = styled.div`
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.primary900};
  margin-left: -20px;
`;

const ClearButton = styled(MdHighlightOff)`
  width: 20px;
  height: 20px;
  ${({ theme }) => theme.gray400};

  &:hover {
    color: ${({ theme }) => theme.primary900} !important;
  }
`;

const SearchIcon = styled.div`
  background: ${({ theme, selected }) =>
    selected ? primary900 : theme.gray400};
  margin: 5px;
  min-width: 30px;
  width: 30px;
  min-height: 30px;
  height: 30px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.whitePure};
`;

const Typeahead = styled.div`
  display: flex;
  flex-flow: row;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 42px;
  background: ${({ theme }) => theme.gray100};
  text-transform: none;
  border: 1px solid transparent;
  border-radius: 3px;
  outline: none;
  overflow: auto;
  transition: 0.2s border-color ease-in;

  &:hover,
  &:focus {
    border: 1px solid #eaedf0;
  }
`;

const Input = styled.input`
  flex-grow: 1;
  margin-left: -40px;
  border: none;
  width: 100%;
  text-transform: none;
  color: ${({ theme }) => theme.mainTextColor1};
  font-size: 1rem;
  line-height: 40px;
  background: transparent;
  padding-left: 45px;
  padding-right: 35px;
`;

const CardList = styled.ul``;

const TypeaheadFieldWrapper = styled.div``;

const TypeaheadContainer = styled.div`
  position: relative;
`;

const TypeaheadFieldWithLabel = styled.div`
  padding-top: 10px;
`;

const Warning = styled.span`
  display: block;
  margin-top: 4px;
  float: none;
  clear: both;
  color: ${({ theme, error }) =>
    error ? theme.errorColor : theme.descriptionActions};
  font-size: 0.75rem;
  font-weight: 300;
  line-height: normal;
  position: absolute !important;
`;

const SquareLoader = () => (
  <ContentLoader
    height={60}
    width={60}
    preserveAspectRatio="none"
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
    style={{
      minWidth: '60px !important',
      width: '60px !important',
      height: '60px !important'
    }}
  >
    <rect x="0" y="0" rx="5" ry="5" width="60" height="60" />
  </ContentLoader>
);

const TextLoader = () => (
  <ContentLoader
    height={60}
    width={305}
    preserveAspectRatio="none"
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
    style={{
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
      paddingLeft: 10,
      fontSize: '1rem',
      lineHeight: '1.4rem',
      overflow: 'hidden'
    }}
  >
    <rect x="0" y="17" rx="5" ry="5" width="300" height="12" />
    <rect x="0" y="34" rx="5" ry="5" width="200" height="12" />
  </ContentLoader>
);

const InputLoaderSquare = () => (
  <ContentLoader
    height={40}
    width={40}
    preserveAspectRatio="none"
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
    style={{
      minWidth: 42,
      width: 42,
      height: 40,
      marginRight: 5
    }}
  >
    <rect x="5" y="5" rx="4" ry="4" width="30" height="30" />
  </ContentLoader>
);

const InputLoaderText = () => (
  <ContentLoader
    height={40}
    width={245}
    preserveAspectRatio="none"
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
    style={{
      width: '100%',
      height: 40
    }}
  >
    <rect x="0" y="13" rx="4" ry="4" width="240" height="14" />
  </ContentLoader>
);

const TypeaheadField = ({
  fieldName,
  label,
  statusCode,
  statusMessage,
  selectedOption,
  allOptions,
  limitShown,
  onOptionSelected,
  onOptionDeselected,
  onScroll,
  onSceneInputChanged,
  onFieldChange,
  onSceneFocussed,
  showMostRecentOptions,
  mostRecentOptions,
  mostRecentHeaderText,
  noResultsMessage,
  placeholder,
  filterValue,
  displayValue,
  imageValue,
  sortValue,
  sortAscending,
  clearButtonEnabled,
  isLoadingOptions,
  isLoadingComponent,
  className,
  dataTestId
}) => {
  const [value, setValue] = useState('');
  const [selectedSuggestion, setSelectedSuggestion] = useState(selectedOption);
  const [isLoadingAllOptions, setIsLoadingAllOptions] = useState(false);
  const [searchedSuggestions, setSearchedSuggestions] = useState([]);
  const [mostRecentSuggestions, setMostRecentSuggestions] = useState([]);

  const [showSuggestions, setShowSuggestions] = useState(true);

  const getSuggestionFilterValue = useCallback(
    (suggestion) =>
      suggestion.isEmpty || suggestion.isLoading
        ? ''
        : `${suggestion[filterValue]}`,
    [filterValue]
  );

  // Suggestion filtering
  const getSuggestions = useCallback(
    (value) => {
      const escapedValue = value.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

      if (escapedValue === '') {
        return mostRecentOptions;
      }

      const regex = new RegExp('\\b' + escapedValue, 'i');
      let filteredOptions = allOptions.filter((option) =>
        regex.test(getSuggestionFilterValue(option))
      );

      // Sort by sortingValue ascending or descending
      filteredOptions = filteredOptions.sort((a, b) => {
        if (a[sortValue] > b[sortValue]) {
          if (sortAscending) {
            return 1;
          }
          return -1;
        } else if (a[sortValue] < b[sortValue]) {
          if (sortAscending) {
            return -1;
          }
          return 1;
        } else {
          return 0;
        }
      });

      // Add loading suggestion at the bottom
      if (isLoadingAllOptions) {
        filteredOptions.push({
          isLoading: true
        });
      }

      // If empty add empty message
      if (filteredOptions.length === 0) {
        filteredOptions.push({
          isEmpty: true
        });
      }

      return filteredOptions;
    },
    [
      allOptions,
      getSuggestionFilterValue,
      isLoadingAllOptions,
      mostRecentOptions,
      sortAscending,
      sortValue
    ]
  );

  useEffect(() => {
    if (selectedOption) {
      setSelectedSuggestion(selectedOption);
      setValue(selectedOption[displayValue]);
    }
  }, [isLoadingComponent, displayValue, selectedOption]);

  useEffect(() => {
    setSearchedSuggestions(getSuggestions(value));
  }, [allOptions, value, getSuggestions]);

  useEffect(() => {
    setIsLoadingAllOptions(isLoadingOptions);
  }, [isLoadingOptions]);

  useEffect(() => {
    setMostRecentSuggestions(mostRecentOptions);
  }, [mostRecentOptions]);

  // on Events
  const onChange = (event, { newValue }) => {
    if (value !== newValue) {
      if (selectedSuggestion) {
        onOptionDeselected();
      }

      if (event.nativeEvent.type !== 'click') {
        onSceneInputChanged(value);
      }

      onFieldChange(fieldName, newValue);

      setSelectedSuggestion(null);
      setValue(newValue);
      setShowSuggestions(true);
    }
  };

  const onFocussed = () => {
    onSceneFocussed && onSceneFocussed();
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSearchedSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSearchedSuggestions([]);
  };

  const onSuggestionSelected = (event, { suggestion }) => {
    if (suggestion.isLoading || suggestion.isEmpty) {
      return;
    }

    setSelectedSuggestion(suggestion);
    setValue(suggestion[displayValue]);
    onOptionSelected(suggestion);

    setShowSuggestions(false);
  };

  const onClearInput = (event) => {
    setSelectedSuggestion(null);
    setValue('');
    onFieldChange(fieldName, '');
    onOptionDeselected();
    setShowSuggestions(true);
  };

  // Render sub-components
  const renderInputComponent = (inputProps) =>
    InputComponent(
      clearButtonEnabled,
      onClearInput,
      className,
      isLoadingComponent,
      dataTestId,
      inputProps
    );

  const renderSuggestionsContainer = ({ containerProps, children }) =>
    SuggestionsContainer(
      value,
      mostRecentHeaderText,
      containerProps,
      children,
      showSuggestions,
      onScroll
    );

  const renderSuggestion = (suggestion) =>
    Suggestion(imageValue, displayValue, suggestion, noResultsMessage);

  let warning;
  if (statusCode >= 300 && statusMessage && statusMessage !== '') {
    warning = (
      <Warning
        error={statusCode >= 300 && statusMessage && statusMessage !== ''}
      >
        {statusMessage}
      </Warning>
    );
  }

  // Get correct suggestions
  let suggestions = searchedSuggestions;
  if (showMostRecentOptions && mostRecentSuggestions && value === '') {
    suggestions = mostRecentSuggestions;
  }

  // Slice suggestions based on limitShown
  if (limitShown > 0 && suggestions.length > limitShown) {
    suggestions = suggestions.slice(0, limitShown);
  }

  const autoSuggest = () => (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      onSuggestionSelected={onSuggestionSelected}
      getSuggestionValue={getSuggestionFilterValue}
      renderInputComponent={renderInputComponent}
      renderSuggestionsContainer={renderSuggestionsContainer}
      renderSuggestion={renderSuggestion}
      inputProps={{
        id: fieldName,
        placeholder,
        value,
        onChange
      }}
      shouldRenderSuggestions={() => !(value === '' && !showMostRecentOptions)}
    />
  );

  let content = () => autoSuggest();
  if (label) {
    content = () => (
      <>
        <label>{label}</label>
        <TypeaheadFieldWithLabel>{autoSuggest()}</TypeaheadFieldWithLabel>
      </>
    );
  }

  return (
    <TypeaheadContainer onFocus={onFocussed}>
      {content()}
      {warning}
    </TypeaheadContainer>
  );
};

TypeaheadField.propTypes = {
  /** fieldName, this is returned in the onFieldChange so you can keep them appart */
  fieldName: string.isRequired,
  /** the initial option that will be set as selected */
  selectedOption: shape({}),
  /** the options that will be filtered on text input */
  allOptions: array.isRequired,
  /** the number of options shown */
  limitShown: number,
  /** function that triggers when an option has been selected. Can be passed an 'option' parameter */
  onOptionSelected: func,
  /** function that triggers when an option is no longer selected */
  onOptionDeselected: func,
  /** function that triggers when suggestionlist is being scrolled */
  onScroll: func,
  /** this function gets called when the field input changes by typing - not selecting */
  onSceneInputChanged: func,
  /**
   * this function gets called when the field changes, with the following arguments;
   *
   * @param {string} fieldName - returns the name of this input field
   * @param {string} content - returns content this input field
   */
  onFieldChange: func.isRequired,
  /** bool to determine wether or not the most recent options should show when the input is empty but focussed */
  showMostRecentOptions: bool,
  /** the options shown when the text input is empty and focussed*/
  mostRecentOptions: array,
  /** the text displayed above the mostRecentOptions */
  mostRecentHeaderText: string,
  /** the text displayed when no results are found for the input */
  noResultsMessage: string,
  /** value of the placeholder text */
  placeholder: string,
  /** field of the option objects to filter on */
  filterValue: string.isRequired,
  /** field of the option objects to show */
  displayValue: string.isRequired,
  /** field of the options objects to use as image link */
  imageValue: string.isRequired,
  /** field to sort on */
  sortValue: string.isRequired,
  /** field to sort on */
  sortAscending: bool,
  /** enables a button which clear all text */
  clearButtonEnabled: bool,
  /** if true, shows a loading suggestion */
  isLoadingOptions: bool,
  /** if true, shows a loader as input */
  isLoadingComponent: bool,
  /** classname that can be used to style the component */
  className: string,
  /** data-testid for testing */
  dataTestId: string
};

TypeaheadField.defaultProps = {
  selectedOption: null,
  limitShown: 0,
  onOptionSelected: () => {},
  onOptionDeselected: () => {},
  onScroll: () => {},
  onSceneInputChanged: () => {},
  onSceneFocussed: () => {},
  showMostRecentOptions: false,
  mostRecentOptions: [],
  mostRecentHeaderText: 'Most recent',
  noResultsMessage: 'No results found.',
  placeholder: 'Search...',
  sortAscending: true,
  clearButtonEnabled: true,
  isLoadingOptions: false,
  isLoadingComponent: false,
  className: ''
};

export default TypeaheadField;

const InputComponent = (
  clearButtonEnabled,
  onClearInput,
  className,
  isLoadingComponent,
  dataTestId,
  inputProps
) => {
  if (isLoadingComponent) {
    return (
      <TypeaheadFieldWrapper className={className}>
        <Typeahead>
          <InputLoaderSquare />
          <InputLoaderText />
        </Typeahead>
      </TypeaheadFieldWrapper>
    );
  }

  let iconElement = (
    <SearchIcon>
      <MdSearch />
    </SearchIcon>
  );
  let clearButtonElement = null;
  if (clearButtonEnabled) {
    clearButtonElement = (
      <ClearButtonWrapper>
        <ClearButton onClick={onClearInput} />
      </ClearButtonWrapper>
    );
  }
  return (
    <TypeaheadFieldWrapper className={className}>
      <Typeahead>
        {iconElement}
        <Input {...inputProps} data-testid={dataTestId} />
        {clearButtonElement}
      </Typeahead>
    </TypeaheadFieldWrapper>
  );
};

const SuggestionsContainer = (
  value,
  mostRecentHeaderText,
  containerProps,
  children,
  showSuggestions,
  onScroll
) => {
  if (showSuggestions) {
    let recentlyUpdatedScenes = null;
    if (value === '') {
      recentlyUpdatedScenes = (
        <CardListSection>{mostRecentHeaderText}</CardListSection>
      );
    }

    if (children) {
      return (
        <CardListWrapper
          onScroll={(event) => {
            event.persist();
            onScroll(event);
          }}
        >
          <CardList {...containerProps}>
            {recentlyUpdatedScenes}
            {children}
          </CardList>
        </CardListWrapper>
      );
    }
  }
  return <div />;
};

const Suggestion = (imageValue, displayValue, suggestion, noResultsMessage) => {
  if (suggestion.isLoading) {
    return (
      <Card>
        <SquareLoader />
        <TextLoader />
      </Card>
    );
  } else if (suggestion.isEmpty) {
    return (
      <EmptyCard>
        <EmptyCardText>{noResultsMessage}</EmptyCardText>
      </EmptyCard>
    );
  }

  return (
    <Card>
      <Image
        imageUri={suggestion[imageValue]}
        styles={`
          min-width: 60px !important;
          width: 60px!important;
          height: 60px !important;
        `}
      />
      <CardText>
        <ClampLines
          text={suggestion[displayValue]}
          lines={1}
          ellipsis="..."
          buttons={false}
        />
      </CardText>
    </Card>
  );
};
