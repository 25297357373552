import LocalizedStrings from 'react-localization';

import numeral from 'numeral';
import 'numeral/locales/nl-nl';
import 'numeral/locales/nl-be';
import 'numeral/locales/fr';
import 'numeral/locales/de';

import enStrings from './en/en-US-strings.json';
import enErrors from './en/en-US-errors.json';

import nlStrings from './nl/nl-NL-strings.json';
import nlErrors from './nl/nl-NL-errors.json';

import deStrings from './de/de-DE-strings.json';
import deErrors from './de/de-DE-errors.json';

import frStrings from './fr/fr-FR-strings.json';
import frErrors from './fr/fr-FR-errors.json';

import ptStrings from './pt/pt-PT-strings.json';
import ptErrors from './pt/pt-PT-errors.json';

// Merge two json files into a single strings file
const languagesFiles = {
  en: { ...enStrings, ...enErrors },
  nl: { ...nlStrings, ...nlErrors },
  de: { ...deStrings, ...deErrors },
  fr: { ...frStrings, ...frErrors },
  pt: { ...ptStrings, ...ptErrors }
};

const supportedInterfaceLanguages = [
  {
    language: 'English',
    ietf: 'en-US',
    iso2: 'en',
    file: languagesFiles.en,
    numeralLocale: 'en'
  },
  {
    language: 'Nederlands',
    ietf: 'nl-NL',
    iso2: 'nl',
    file: languagesFiles.nl,
    numeralLocale: 'nl-nl'
  },
  {
    language: 'Nederlands (België)',
    ietf: 'nl-BE',
    iso2: 'nl',
    file: languagesFiles.nl,
    numeralLocale: 'nl-be'
  },
  {
    language: 'Deutsch',
    ietf: 'de-DE',
    iso2: 'de',
    file: languagesFiles.de,
    numeralLocale: 'de'
  },
  {
    language: 'Français',
    ietf: 'fr-FR',
    iso2: 'fr',
    file: languagesFiles.fr,
    numeralLocale: 'fr'
  },
  {
    language: 'Português',
    ietf: 'pt-PT',
    iso2: 'pt',
    file: languagesFiles.pt,
    numeralLocale: 'pt'
  }
];

const supportedlocalizableLanguages = [
  ...supportedInterfaceLanguages,
  {
    language: '中国人',
    ietf: 'cn-CN',
    iso2: 'cn',
    numeralLocale: 'cn'
  }
];

export const strings = new LocalizedStrings(languagesFiles);
export const getSupportedInterfaceLanguages = () => supportedInterfaceLanguages;
export const getSupportedLocalizableLanguages = () =>
  supportedlocalizableLanguages;
export const getSupportedLanguageCodes = () =>
  supportedInterfaceLanguages.map((language) => language.iso2);

export const getSupportedLanguageIETFTags = () =>
  supportedInterfaceLanguages.map((language) => language.ietf);

const regexString = getSupportedLanguageCodes().reduce(
  (accumulator, currentValue, currentIndex) => {
    let _accumulator = accumulator;
    // This version is the version for full BCP support:
    // en-US, en-GB, nl-NL, etc can be tested with this
    _accumulator += `${currentIndex !== 0 ? '|' : ''}${currentValue}`;
    return _accumulator;
  },
  ''
);

export const langRegex = `/:lang(${regexString})?`;
export const defaultLocale = strings.getInterfaceLanguage() || 'en';
export const defaultNumeralLocale = (() => {
  // numeral wants 'en' for the default, instead of the other locales like 'nl-nl' ...
  if (!defaultLocale || defaultLocale.substring(0, 2) === 'en') {
    return 'en';
  } else {
    if (defaultLocale === 'nl') {
      // numeral package can't handle "nl", it has to be "nl-nl" or "nl-be"
      return 'nl-be';
    } else {
      return defaultLocale;
    }
  }
})();
numeral.defaultFormat('0,0.00');

/**
 * Get localized value or return default one
 * @param {{ textMap: Object }} localizedValues
 * @param {string} defaultValue value to display if target language translation is missing, 'en' - by default
 * @returns {string} returns localized value or default value
 */
export const getValueFromLocalized = (localizedValues, defaultValue = '') => {
  const currentAppLanguage = strings.getLanguage();
  const localizedValue =
    localizedValues?.textMap?.[currentAppLanguage] ||
    localizedValues?.textMap?.['en'];
  return localizedValue || defaultValue;
};

export default strings;
