import { validActionCheck } from 'store/actionUtils';

const initialState = {
  currentSubscription: {
    fetching: false,
    fetched: false,
    error: null
  },
  subscriptionPlans: {
    fetching: false,
    fetched: false,
    error: null
  }
};

const SubscriptionReducer = (state = initialState, action) => {
  if (validActionCheck(action)) {
    switch (action.type) {
      case 'CHECK_SUBSCRIPTION_REQUEST_PENDING': {
        return {
          ...state,
          currentSubscription: {
            ...state.currentSubscription,
            fetching: true,
            fetched: false,
            error: null
          }
        };
      }
      case 'CHECK_SUBSCRIPTION_REQUEST_REJECTED': {
        return {
          ...state,
          currentSubscription: {
            ...state.currentSubscription,
            fetching: false,
            fetched: true,
            error: action.payload
          }
        };
      }
      case 'CHECK_SUBSCRIPTION_REQUEST_FULFILLED': {
        if (action.payload) {
          return {
            ...state,
            currentSubscription: {
              ...state.currentSubscription,
              ...action.payload.data,
              fetching: false,
              fetched: true,
              error: null
            }
          };
        } else {
          return { ...state };
        }
      }

      case 'SUBSCRIPTION_PLANS_REQUEST_PENDING': {
        return {
          ...state,
          subscriptionPlans: {
            ...state.subscriptionPlans,
            fetching: true,
            fetched: false,
            error: null
          }
        };
      }
      case 'SUBSCRIPTION_PLANS_REQUEST_REJECTED': {
        return {
          ...state,
          subscriptionPlans: {
            ...state.subscriptionPlans,
            fetching: false,
            fetched: true,
            error: action.payload
          }
        };
      }
      case 'SUBSCRIPTION_PLANS_REQUEST_FULFILLED': {
        return {
          ...state,
          subscriptionPlans: {
            ...state.subscriptionPlans,
            ...action.payload,
            fetching: false,
            fetched: true,
            error: null
          }
        };
      }
      case 'AUTH_RESET':
      case 'AUTH_LOGOUT_REQUEST_PENDING': {
        return {
          ...initialState
        };
      }
      default: {
        return state;
      }
    }
  }

  return state;
};

export default SubscriptionReducer;
