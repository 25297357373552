import React, { memo } from 'react';

// Components
import Tippy from '@tippyjs/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Helpers
import localizer from 'localization/localizer';
import { capitalize } from 'helpers/util';
import { connect } from 'react-redux';
import { useGetUserRole } from 'helpers/customHooks/index';

// Styling
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  &::after {
    display: block;
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
    background-image: linear-gradient(
      90deg,
      transparent 40%,
      ${({ theme }) => theme.whitePure}
    );
    transition: all 180ms ease;
    ${({ styles }) => styles}
  }
`;

const UpgradeButton = styled.button`
  position: absolute;
  z-index: 2;
  right: 10px;
  top: calc(50% - 4px);
  transform: translateY(-50%);
  height: 30px;
  min-width: 70px;
  padding: 0 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme, dark }) =>
    dark ? '#2E4B4B' : theme.discountBgColor};
  font-size: 0.75rem;
  font-weight: 600;
  color: ${({ theme }) => theme.successColor};
  border: none;
  border-radius: 3px;
  cursor: pointer;

  ${({ type }) => {
    if (!type) return ``;
    if (type === 'small') {
      return css`
        height: 22px;
        min-width: 40px;
        top: calc(50% - 20px);
        right: -6px;
        font-size: 0.6875rem;
      `;
    }
  }}
  ${({ styles }) => styles}
`;

const TippyContent = styled.div`
  width: 250px;
  word-wrap: normal;
  word-break: normal;
`;

const UpdateShieldHOC = memo(
  ({
    children,
    tippyText,
    history,
    type,
    tippyPosition = 'right',
    styles,
    wrapperStyles,
    collapsed = false,
    dark = false,
    AuthReducer,
    VaultReducers,
    email
  }) => {
    const user = AuthReducer?.user;
    const vault = VaultReducers?.vault;

    const { activeUserRole } = useGetUserRole(vault?.objectId, AuthReducer);

    return (
      <Wrapper styles={wrapperStyles}>
        {children}
        <Tippy
          key="tooltip"
          theme="prompto"
          placement={tippyPosition}
          zIndex={999}
          arrow={true}
          content={<TippyContent>{tippyText}</TippyContent>}
        >
          <UpgradeButton
            type={type}
            onClick={() => {
              if (activeUserRole !== 'redOwner') return;
              if (email) {
                const link = document.createElement('a');
                link.href = `mailto:${email}?subject=Enable_Leeds_feature_request`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              } else {
                history.push(`/${localizer.getLanguage()}/admin/billing`, {
                  showPricingPlans: true,
                  origin: history.location.pathname
                });
              }
            }}
            styles={styles}
            dark={dark}
          >
            {collapsed ? (
              <FontAwesomeIcon icon={['fas', 'lock']} size="sm" />
            ) : (
              capitalize(localizer.upgrade)
            )}
          </UpgradeButton>
        </Tippy>
      </Wrapper>
    );
  }
);

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  AuthReducer: state.store.AuthReducer,
  VaultReducers: state.store.VaultReducers,
  AppReducer: state.store.AppReducer,
  router: state.router
});

export default connect(mapStateToProps)(UpdateShieldHOC);
