// These are all the PROJECT LIST related Actions

// Import API Calls here
import { Project } from '@prompto-api';

// Helpers
import to from 'await-to-js';
import isEqual from 'lodash.isequal';

// = ACTIONS =
export const getAllProjectsAction =
  (vaultId, sessionToken, isBackgroundCall = false) =>
  async (dispatch, getState) => {
    const isGettingProjectsInProgress =
      getState().store.ProjectListReducer?.pending;

    if (isGettingProjectsInProgress) return;

    if (!isBackgroundCall) {
      dispatch({ type: 'GET_PROJECT_LIST_PENDING' });
    }

    const [error, result] = await to(
      Project.getAll(
        vaultId,
        {
          includeUnitSlotUsageReportMap: true,
          includeContentCollection: true,
          includeNavigationCollection: true
        },
        sessionToken
      )
    );

    // if user is logged out by the time the getAllProjects promise resolved
    // we do not update app state with any data
    const isAuthenticated = getState().store.AuthReducer?.authenticated;

    if (!isAuthenticated) {
      dispatch({ type: 'RESET_PROJECT_LIST' });
      return;
    }

    if (error) {
      const currentVault = getState().store.VaultReducers?.vault;
      // show error message only if getAllProjects request was made
      // for current vault
      if (currentVault?.objectId === vaultId) {
        dispatch({ type: 'GET_PROJECT_LIST_REJECTED', payload: error });
        return;
      } else {
        dispatch({ type: 'GET_PROJECT_LIST_SETTLED' });
      }
    }

    const currentState = getState();
    const actualUnitSlotUsageReportMap =
      currentState.store.SlotsUsageReducer?.unitSlotUsageReportMap;

    if (
      !isEqual(
        actualUnitSlotUsageReportMap,
        result?.data?.unitSlotUsageReportMap
      )
    ) {
      dispatch({
        type: 'SET_SLOTS_USAGE_PER_PROJECT',
        payload: result?.data?.unitSlotUsageReportMap
      });
    }

    const projectList = result?.data?.projectSectionList;
    const currentVault = getState().store.VaultReducers?.vault;
    const currentUserId = getState().store.AuthReducer?.user?.objectId;

    // update project list only if projects relate to current vault
    // otherwise save the response only to local storage
    if (currentVault?.objectId === projectList?.[0]?.vault?.objectId) {
      dispatch({
        type: 'GET_PROJECT_LIST_FULFILLED',
        payload: { projects: projectList, isSyncWithBackend: true }
      });
    } else {
      dispatch({ type: 'GET_PROJECT_LIST_SETTLED' });
    }

    const preparedProjects = projectList.map((project) => {
      const proj = {};
      proj.objectId = project.objectId;
      proj.state = project.state;
      proj.thumbnailUri = project.thumbnailUri;
      proj.title = project.title;
      proj.localizedTitle = project.localizedTitle;
      proj.lastModifiedAt = project.lastModifiedAt;
      proj.createdAt = project.createdAt;
      proj.description = project.description;
      proj.localizedDescription = project.localizedDescription;

      return proj;
    });

    localStorage.setItem(
      `cachedPromptoProjects_${vaultId}_${currentUserId}`,
      JSON.stringify(preparedProjects)
    );
  };

export const checkForCachedProjectsAction =
  (vaultId, sessionToken) => async (dispatch, getState) => {
    const currentUserId = getState().store.AuthReducer?.user?.objectId;
    const cachedProjects = JSON.parse(
      localStorage.getItem(`cachedPromptoProjects_${vaultId}_${currentUserId}`)
    );

    // if there are cached projects, display them and get all projects in background
    if (cachedProjects) {
      dispatch({
        type: 'GET_PROJECT_LIST_FULFILLED',
        payload: { projects: cachedProjects, isSyncWithBackend: false }
      });
      dispatch(getAllProjectsAction(vaultId, sessionToken, true));
    } else {
      dispatch(getAllProjectsAction(vaultId, sessionToken));
    }
  };

export const deleteProjectAction = (projectId) => async (dispatch) => {
  dispatch({ type: 'DELETE_PROJECT', payload: projectId });
};

export const createProjectAction =
  (project, vaultId, sessionToken) => async (dispatch) => {
    dispatch({ type: 'CREATE_PROJECT', payload: project });
    const cachedProjects =
      JSON.parse(localStorage.getItem(`cachedPromptoProjects_${vaultId}`)) ??
      [];

    const proj = {};
    proj.objectId = project.objectId;
    proj.state = project.state;
    proj.thumbnailUri = project.thumbnailUri;
    proj.title = project.title;
    proj.localizedTitle = project.localizedTitle;
    proj.lastModifiedAt = project.lastModifiedAt;
    proj.createdAt = project.createdAt;
    proj.description = project.description;
    proj.localizedDescription = project.localizedDescription;

    localStorage.setItem(
      `cachedPromptoProjects_${vaultId}`,
      JSON.stringify([proj, ...cachedProjects])
    );
  };

export const updateProjectAction =
  (project, vaultId, sessionToken) => async (dispatch) => {
    dispatch({ type: 'UPDATE_PROJECT', payload: project });
    dispatch(getAllProjectsAction(vaultId, sessionToken, true));
  };
