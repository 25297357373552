import React from 'react';
import path from 'path';
import env from 'environment';

// Components
import SelectField from 'components/selectField/SelectField';
import TypeaheadField from 'components/typeaheadField/TypeaheadField';
import InputField from 'components/inputField/InputField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MdDelete } from 'react-icons/md';
import Placeholder from './Placeholder';

// Helpers
import { Vault } from '@prompto-api';
import currencySymbolMap from 'currency-symbol-map';
import postcodeValidator from 'postcode-validator';
import localizer from 'localization/localizer';
import emailValidator from 'email-validator';
import { support } from 'config/emails.json';
import Cookies from 'universal-cookie';
import vatValidator from 'jsvat';
import urlJoin from 'url-join';
import qs from 'query-string';
import to from 'await-to-js';
import axios from 'axios';
import uploadSettings from 'config/ShowcaseUploadSettings.js';
import add from 'date-fns/add';
import getTime from 'date-fns/getTime';
import { useFileUploadWithProgress } from 'helpers/customHooks';
import { version } from '../../../../../package.json';

//import axios from 'axios';
import {
  isIOS,
  getUA,
  isMobileSafari,
  isAndroid,
  isWinPhone
} from 'react-device-detect';

// Styles
import classnames from 'classnames';
import styles from './util.module.css';

/**
 * The formFields object as it should be passed down
 * @typedef {object} FormField
 * @property {string} id - the id of formfield. Pick an identifier like 'firstname' or 'password'.
 * * use 'gap' to leave a cell
 * * use 'usernameFeedback' to use the userTaken variable
 * * use 'break' to make a line break
 * @property {string} type - a possible className to pass down.
 * @property {string} className - a possible className to pass down.
 * @property {string} statusCode - current validation code.
 * @property {string} statusMessage - current validation error mssage.
 * @property {boolean} disabled - whether the inputfield is readonly.
 * @property {boolean} isRequired - whether the inputfield is required.
 * @property {string|*} value - The current value of the textfield.
 * @property {*} [options] - The possible options for the react-select options
 */

export const buildRouteProps = (url, routeArray) =>
  routeArray.map(({ key, render, ...rest }) => {
    /* istanbul ignore next */
    const _render =
      render || (() => <Placeholder name={key} width={100} height={100} />);
    return {
      path: path.join(url, key),
      render: _render,
      key,
      ...rest
    };
  });

export const parseUserList = (
  userList,
  invitationList,
  currentUser,
  actionDeleteUserCallback,
  actionDeleteInvitationCallback,
  actionResendInvitationCallback,
  resendInvitation
) => {
  let users = userList.map((user, i) => {
    const parsedUser = [
      {
        key: 'email',
        type: 'string',
        value: user.email
      },
      {
        key: 'index',
        type: 'string',
        value: i
      },
      {
        key: 'firstName',
        type: 'string',
        value: user.firstName || '-'
      },
      {
        key: 'lastName',
        type: 'string',
        value: user.lastName || '-'
      },
      {
        key: 'phoneNumber',
        type: 'string',
        value: user.phoneNumber
      },
      {
        key: 'status',
        type: 'string',
        value: capitalize(localizer.active)
      },
      {
        key: 'id',
        type: 'string',
        value: user.objectId
      }
    ];
    // Only let the user delete if there is more than one user
    // you can't delete the last user.
    if (actionDeleteUserCallback && userList && userList.length > 1) {
      // Only let the user delete if it's another user.
      // you can't delete yourself as a user
      if (currentUser.objectId !== user.objectId) {
        parsedUser.push({
          key: 'action',
          type: 'action',
          value: { label: 'delete' },
          buttonSettings: {
            className: styles.actionButton,
            status: 'custom',
            type: 'link-error',
            label: 'delete',
            onClickAction: () => {
              /* istanbul ignore next */
              actionDeleteUserCallback(user);
            },
            IconComponent: MdDelete
          }
        });
      }
    }
    return parsedUser;
  });

  let invitations = invitationList.map((invitation, i) => {
    const parsedInvitations = [
      {
        key: 'email',
        type: 'string',
        value: invitation.email
      },
      {
        key: 'index',
        type: 'string',
        value: i
      },
      {
        key: 'firstName',
        type: 'string',
        value: invitation.firstName || '-'
      },
      {
        key: 'lastName',
        type: 'string',
        value: invitation.lastName || '-'
      },
      {
        key: 'phoneNumber',
        type: 'string',
        value: invitation.phoneNumber
      },
      {
        key: 'status',
        type: 'string',
        value:
          resendInvitation &&
          resendInvitation.objectId === invitation.objectId ? (
            <>
              <p key={'status'}>
                <FontAwesomeIcon icon={['far', 'spinner']} size="sm" pulse />{' '}
                {localizer.sending}
              </p>
            </>
          ) : (
            <>
              <p key={'status'}>{capitalize(localizer.invited)}</p>
              <p
                key={'resend'}
                className={styles.resend}
                onClick={() => {
                  actionResendInvitationCallback(invitation);
                }}
              >
                ({localizer.resendInvite})
              </p>
            </>
          )
      },
      {
        key: 'id',
        type: 'string',
        value: invitation.objectId ? invitation.objectId : 'undefined'
      }
    ];

    if (actionDeleteInvitationCallback) {
      parsedInvitations.push({
        key: 'action',
        type: 'action',
        value: { label: 'delete' },
        buttonSettings: {
          className: styles.actionButton,
          status: 'custom',
          type: 'link-error',
          label: 'delete',
          onClickAction: () => {
            /* istanbul ignore next */
            actionDeleteInvitationCallback(invitation);
          },
          IconComponent: MdDelete
        }
      });
    }

    return parsedInvitations;
  });

  return users.concat(invitations);
};

// Get the version defined in package.json, since this is the centralized point
export const getPackageVersion = () => {
  return version;
};

// capitalize the first letter of a string
export const capitalize = (string) =>
  string && `${string.charAt(0).toUpperCase()}${string.slice(1)}`;

// lowercase the first letter of a string
export const lowercase = (string) =>
  string && `${string.charAt(0).toLowerCase()}${string.slice(1)}`;

/**
 * Generates Form InputFields
 *
 * @param {FormField[]} formFields - Array of {@link FormField} objects.
 * @param {Function} onFieldChange - Callback to call when the field changes
 * @param {Function} onSubmit - Callback to call when the field submit is triggered
 * @param {Boolean} [userTaken] - optional value in case a username check was performed
 * @param {string} [formUUID] - optional value if a UUID was used for the form
 */
export const generateFormFields = (
  formFields,
  onFieldChange,
  onSubmit,
  onBlur,
  userTaken,
  uuid
) =>
  formFields.map((field, index) => {
    const {
      id,
      key,
      type,
      value,
      label,
      options,
      disabled,
      className,
      statusCode,
      statusMessage,
      ...restProps
    } = field;

    const fieldProps = {
      key,
      value,
      disabled,
      statusCode,
      fieldName: id,
      statusMessage,
      hasDebounce: true,
      handleSubmit: onSubmit,
      onBlur: onBlur,
      label: label || localizer[stripUUIDFromID(id, uuid)],
      onFieldChange: onFieldChange,
      ...restProps
    };

    // Exceptions
    if (id.match(/(password)/g)) {
      fieldProps.type = 'password';
    }

    if (className) {
      fieldProps.className = className;
    }
    switch (type) {
      case 'usernameFeedback':
        return (
          <p className={classnames(styles.formGap, className)} key={id}>
            {userTaken &&
              `${localizer.userExists} ${support} ${localizer.or} ${
                localizer.goTo
              } ${env().supportBaseLink}`}
          </p>
        );
      case 'break':
        return (
          <div className={classnames(styles.formBreak, className)} key={id} />
        );
      case 'selectField':
        fieldProps.options = options;
        fieldProps.onChange = (selected) => {
          /* istanbul ignore next */
          onFieldChange(id, selected.value);
        };

        const selectedOption = options.filter(
          (option) => option.value === value
        )[0];
        fieldProps.value = selectedOption;
        fieldProps.selectedValue = selectedOption;

        return <SelectField {...fieldProps} />;
      case 'inputField':
        return <InputField {...fieldProps} />;
      case 'typeaheadField':
        return <TypeaheadField {...fieldProps} />;
      case 'gap':
      default:
        return (
          <br className={classnames(styles.formGap, className)} key={id} />
        );
    }
  });

/**
 *
 * @param {FormField} field - field to be validated.
 * @param {FormField[]} formFields - All of the form's fields, needed to compare passwords for instance.
 */
export const validateField = async (field, formFields, sessionToken, UUID) => {
  const { value, id, isRequired, isDirty } = field;
  const validatedField = { ...field, statusMessage: '', statusCode: 200 };

  let strippedId = stripUUIDFromID(id, UUID);

  if (strippedId === 'password') {
    const passwordRepeatField = formFields.filter(
      (formField) => formField.id === 'passwordRepeat'
    )[0];
    if (passwordRepeatField) {
      if (
        value !== passwordRepeatField.value &&
        passwordRepeatField.value.length > 0
      ) {
        validatedField.statusMessage = localizer.passwordDontMatch;
        validatedField.statusCode = 300;
      }
    }
  }

  if (strippedId === 'email') {
    if (value.length > 0 && !emailValidator.validate(value)) {
      validatedField.statusMessage = localizer.shouldBeValidEmail;
      validatedField.statusCode = 300;
    }
  }

  if (strippedId === 'zip' && value) {
    const country = getCountry(formFields, UUID);
    let countryCode = country.value.code || 'BE';

    let zipIsValid = false;

    // Zippopotamus doesn't support the following countries so the
    // zippopotamus check is skipped and replaced with the zipcodevalidator check
    const ZippopotamusNotSupportedCountries = [
      'IE', // Ireland
      'EE', // Estonia
      'RO', // Romania
      'CY', // Cyprus
      'MT', // Malta
      'LV', // Latvia
      'GR' // Greece
    ];

    // In order to make validation easier for the user, we remove spaces in the zipcodes normally.
    // However for the following countries, zippopotamus requires the space in their zipcodes.
    const ZippopotamusCountryCodesWithSpaces = [
      'CZ', // Czech Republic
      'SK' // Slovakia
    ];

    // Check API first, if API call fails, fall back to validator
    try {
      if (ZippopotamusNotSupportedCountries.includes(countryCode)) {
        throw new Error(
          'Country not supported by API. Reverting to regex check'
        );
      }

      let zipCode = value;
      if (!ZippopotamusCountryCodesWithSpaces.includes(countryCode)) {
        zipCode = value.replace(/\s/g, '');
      }

      const requestUri = urlJoin(env().zippopotamusAPI, countryCode, zipCode);
      const result = await fetch(requestUri);
      const data = await result.json();
      zipIsValid = !(
        Object.entries(data).length === 0 && data.constructor === Object
      );
    } catch (err) {
      // PostcodeValidator has inconsistency with Great Britain...
      if (countryCode === 'GB') {
        countryCode = 'UK';
      }

      zipIsValid = postcodeValidator.validate(value, countryCode);
    }

    if (value.length > 0 && !zipIsValid) {
      validatedField.statusMessage = localizer.shouldBeValidZipcode;
      validatedField.statusCode = 300;
    }
  }

  if (strippedId === 'vat') {
    if (sessionToken && value !== '') {
      const processedValue = value.replace(/[\s?.]/g, '');
      validatedField.value = processedValue;

      const country = getCountry(formFields, UUID);
      const countryVatPrefix = country.value.vatPrefix || 'BE';

      // Check isValid from Government API
      const { valid, serviceAvailable } = await checkVatIsValidSDK(
        processedValue,
        countryVatPrefix,
        sessionToken
      );

      let isValid = valid;
      // Service not available -> fall back to vatValidator
      if (!serviceAvailable) {
        const result = vatValidator.checkVAT(
          `${countryVatPrefix}${processedValue}`
        );

        isValid = result.isValid;
      }

      if (!isValid) {
        validatedField.statusMessage = localizer.shouldBeValidVat;
        validatedField.statusCode = 300;
      }
    }
  }

  if (value === '' && isRequired) {
    if (isDirty) {
      validatedField.statusMessage = localizer.shouldNotBeEmpty;
      validatedField.statusCode = 300;
    } else {
      validatedField.statusCode = 201;
    }
  }

  return validatedField;
};

/**
 * Searches the formFields for a field which contains country info
 * and returns it
 * @param {FormField[]} formFields
 */
export const getCountry = (formFields, UUID) =>
  formFields.filter(
    (formField) => stripUUIDFromID(formField.id, UUID) === 'country'
  )[0];

/**
 * Validates an array of fields to see if the form is done
 * every field is checked, as soon as one has a status code
 * that differs from 200, the form returns as invalid.
 * If the field is required and empty, it will also return as invalid.
 *
 * @param {FormField[]} fields
 * @returns {boolean}
 */
export const formIsValid = (fields) =>
  fields.filter((field) => field.statusCode !== 200).length === 0;

/**
 * Transpiles the form fields array into an object structure
 * @param {FormField[]} fields
 * @returns {Object} transpiled object with de id as key
 * @param {string} formUUID - unique identifier for this form
 */
export const transpileFormFieldsToJson = (fields, formUUID) =>
  fields.reduce((result, field) => {
    let id = field.id;
    if (formUUID) {
      id = stripUUIDFromID(id, formUUID);
    }
    if (field.id.match(/(gap|break)\w+/g)) {
      return result;
    }
    result[id] = field.value;
    if (field.type === 'selectField') {
      result[id] = field.value || field.defaultValue.value;
    }
    return result;
  }, {});

/**
 * Generate the default state for a config of fields
 * @param {*} fields - array of field configs
 * @param {string} formUUID - unique identifier for this form
 */
export const generateDefaultStateForFields = (fields, formUUID) =>
  fields.map((field) => {
    let id = field.id;
    if (formUUID) {
      id = `${field.id}_${formUUID}`;
    }
    return {
      ...field,
      id,
      key: id,
      childKey: id,
      statusCode: field.isRequired ? 201 : 200,
      statusMessage: '',
      value: field.value || '',
      isDirty: false
    };
  });

export const makeFieldDirtyIfChanged = (
  formFields,
  processedValue,
  changedFieldId
) => {
  const requiredFieldIDs = ['title'];
  const checkedFields = formFields.map((originalField) => {
    if (originalField.id === changedFieldId) {
      if (
        originalField.value !== processedValue ||
        (requiredFieldIDs.includes(changedFieldId) && !processedValue)
      ) {
        // as soon as a field is touched, make it dirty
        originalField.isDirty = true;
      }
      originalField.value = processedValue;
    }

    return originalField;
  });

  return checkedFields;
};

export const UpdateAndValidateFields = async (
  formFields,
  processedValue,
  changedFieldId,
  sessionToken,
  UUID
) => {
  // Save the new data to the existing array
  const updatedFormFields = makeFieldDirtyIfChanged(
    formFields,
    processedValue,
    changedFieldId
  );

  // validate the fields with the new data
  const resultPromises = updatedFormFields.map(
    async (updatedField) =>
      await validateField(updatedField, updatedFormFields, sessionToken, UUID)
  );

  const result = await Promise.all(resultPromises);
  return result;
};

/* istanbul ignore next */
export const readAsBinaryString = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onabort = reject;
    reader.onerror = reject;

    reader.readAsBinaryString(file);
  });

/* istanbul ignore next */
export const readAsDataUrl = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onabort = reject;
    reader.onerror = reject;

    reader.readAsDataURL(file);
  });

export const checkUserHasVault = (user) =>
  user && user.vaultList && user.vaultList.length > 0;

export const featureIsAvailable = (
  includedFeatureDictionary,
  vault,
  featureName
) => {
  if (!vault || !includedFeatureDictionary) {
    return;
  }

  const featureList = includedFeatureDictionary[vault.objectId];
  if (!featureList) {
    return;
  }

  return featureList.filter((feature) => feature === featureName).length > 0;
};

/**
 * Checks if the specified vault operations are allowed
 * @param {array} allowedVaultOperationDictionary - the array of all allowed vault operations
 * @param {string} vaultID - the vault for which we want to check the operations
 * @param {string[]} operationsToCheck - check if these operations are allowed or not
 * @returns {boolean[]} array with the same length as 'operationsToCheck' where each element is a 'true' or 'false'.
 *                      If 'true', it means the corresponding operation in 'operationsToCheck' is allowed, if 'false', it means it is not
 */
export const vaultOperationIsAllowed = (
  allowedVaultOperationDictionary,
  vaultId,
  operationsToCheck
) => {
  if (!allowedVaultOperationDictionary) {
    return [];
  }
  const allowedVaultOperations = allowedVaultOperationDictionary[vaultId];
  if (!allowedVaultOperations) {
    return [];
  }

  const resultArray = operationsToCheck.map((operationName) =>
    allowedVaultOperations.includes(operationName)
  );
  return resultArray;
};

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
/* istanbul ignore next */
export const debounce = function (func, wait, immediate) {
  let timeout;
  /* istanbul ignore next */
  return function () {
    let context = this,
      args = arguments;
    let later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

/**
 * extract the extension from the filename
 * @param {File} file
 * @returns {String} fileExtension - returns filename if no extension is found
 */
export const getFileExtension = (file) =>
  file.name.split('.').pop().toLowerCase();

/**
 * Contruct the asset URI with the correct Bucket for the env
 * @param {string} res - the uri resource to load.
 * @returns {string|null} - the built string or null
 * if the base uri is undefined
 */
export const buildAssetURI = (res) => res && urlJoin(env().baseImageUrl, res);

/**
 * Contruct the asset URI with the correct Bucket for the env
 * @param {string} options - documentation imageserver options: https://bitbucket.org/aroundmediagroup/virtual-media-image-server/src/master/
 * @param {string} res - the uri resource to load.
 * @returns {string|null} - the built string or null
 * if the base uri is undefined
 */
export const buildAssetURIWithOptions = (options, res) =>
  options && res && urlJoin(env().baseImageUrl, options, res).split('?')[0];

/**
 * Parses the date from a timestamp into DD/MM/YYYY
 * @param {string|number} timestamp
 * @returns {string} the parsed timestamp in DD/MM/YYYY
 */
export const parseDateToDDMMYYYY = (timestamp) => {
  const d = new Date(timestamp);
  // We need to add one to the months since they are 0 based
  // all other values in the Date object are 1 based
  // thanks to JDK 1.0's java.util.Date class
  return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
};

const month = [
  localizer.monthList.january,
  localizer.monthList.february,
  localizer.monthList.march,
  localizer.monthList.april,
  localizer.monthList.may,
  localizer.monthList.june,
  localizer.monthList.july,
  localizer.monthList.august,
  localizer.monthList.september,
  localizer.monthList.october,
  localizer.monthList.november,
  localizer.monthList.december
];

export const parseDate = (date) => {
  const d = new Date(date);
  const hours = d.getHours() < 10 ? `0${d.getHours()}` : d.getHours();
  const minutes = d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes();

  return `${hours}:${minutes}, ${d
    .getDate()
    .toString()
    .padStart(2, '0')} ${month[d.getMonth()]
    .toString()
    .substring(0, 3)} ${d.getFullYear()}`;
};

/**
 * Convert date to format: 12:34 01.02.2021
 * @param {Date} date
 */
export const dateToHHMM_DDMMYY = (date) => {
  const minutes = date.getMinutes();
  const hours = date.getHours();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const formattedMinutes = (minutes < 10 ? '0' : '') + minutes;
  const formattedHours = (hours < 10 ? '0' : '') + hours;
  const formattedDay = (day < 10 ? '0' : '') + day;
  const formattedMonth = (month < 10 ? '0' : '') + month;

  return `${formattedHours}:${formattedMinutes} ${formattedDay}.${formattedMonth}.${year}`;
};

/**
 * this turns a string of bytes into a readable number
 * Useful for translating a number for a UI.
 * @param {*} num
 * @returns {Array[Number, String]} - returns array with the new value and
 * the unit prefix
 */
export const parseBytesToReadable = (num) => {
  let prefixStep = 1024;
  let unit,
    units = ['TB', 'GB', 'MB', 'KB', 'Bytes'];
  let value = num;
  for (
    unit = units.pop();
    units.length && value >= prefixStep;
    unit = units.pop()
  ) {
    value /= prefixStep;
  }
  return [parseFloat(value, 10).toFixed(2), unit];
};
/**
 * Upload an image to the bucket
 *
 * @param {*} file - file to upload
 * @param {*} vaultObjectId - vault object Id to save to
 * @param {*} sessionToken - Session token of the current user
 */
export const uploadImageToBucket = async (
  file,
  target,
  vaultObjectId,
  sessionToken,
  uploadToken
) => {
  let tokenToUse;
  if (uploadToken) {
    tokenToUse = uploadToken;
  } else {
    const result = await Vault.getUploadAccessToken(
      vaultObjectId,
      sessionToken
    );
    tokenToUse = result.data.token;
  }

  const [progress, isUploading, error, result] = useFileUploadWithProgress(
    file,
    env().googleStorageBucketId,
    target,
    tokenToUse,
    vaultObjectId,
    {}
  );

  return result;
};

/**
 * Validates whether the file is not too large,
 * and the right kind.
 * @param {File} file - File to validate
 * @param {Number} maxSize
 * @param {String[]} extensionList
 * @returns {Boolean} - isFileValid
 */
export const validateFile = (file, maxSize, extensionList) =>
  file.size <= maxSize && extensionList.includes(getFileExtension(file));

/**
 * Pulls the credentials out of the objects into a single object
 * @param {{sessionToken: string}} [AuthReducer] - AuthenticationData
 * @param {{vault: {objectId: string}}} [VaultReducers] - VaultData
 * @returns {{sessionToken: string | undefined, vaultId: string | undefined}} with fields `sessionToken` and `vaultId`
 */
export const credentials = (AuthReducer, VaultReducers) => ({
  sessionToken: (AuthReducer && AuthReducer.sessionToken) || undefined,
  sessionObjectId: (AuthReducer && AuthReducer.sessionObjectId) || undefined,
  vaultId:
    (VaultReducers && VaultReducers.vault && VaultReducers.vault.objectId) ||
    undefined
});

/**
 * Same as the normal credentials function but
 * this one destructures the props
 */
export const credentialsFromProps = ({ AuthReducer, VaultReducers }) =>
  credentials(AuthReducer, VaultReducers);

const variables = {
  breakSmall: 629,
  breakLarge: 1024,
  cardSizeS: 300,
  cardSizeM: 410,
  cardSizeL: 500
};

/**
 * @returns {Int} paginationAmount
 */
export const calcPagination = (defaultAmount) => {
  const h = window.innerHeight;
  const w = window.innerWidth;
  const small = parseInt(variables.breakSmall, 10);
  const large = parseInt(variables.breakLarge, 10);
  const calculate = (base, cardHeight) =>
    base * Math.round(h / parseInt(cardHeight, 10));

  let paginationAmount = calculate(4, variables.cardSizeS);
  if (large > w && w >= small) {
    paginationAmount = calculate(6, variables.cardSizeM);
  } else if (w >= large) {
    paginationAmount = calculate(8, variables.cardSizeL);
  }

  /**
   * Make sure we don't return 0
   * when window dimension is 0,0
   */
  return paginationAmount || defaultAmount || 8;
};

export const buildUrl = (paths) => {
  let url;

  // Check if the machine is a windows
  const winMachine = navigator.userAgent.toString().match(/win32/g);
  if (winMachine) {
    url = paths.join('\\');
  } else {
    url = paths.join('/');
  }

  return url;
};

/**
 * Gets the renewalDate from the recurring estimate
 * @param {object} recurringEstimate - recurring estimate data
 */
export const calcNextRenewalDate = (recurringEstimate) => {
  let renewalDate = -1;
  if (recurringEstimate) {
    const invoiceEstimates = recurringEstimate?.invoiceEstimates;
    if (invoiceEstimates && invoiceEstimates.length > 0) {
      const latestInvoice = invoiceEstimates[invoiceEstimates.length - 1];
      const { lineItems } = latestInvoice;
      if (lineItems) {
        lineItems.forEach((item) => {
          if (parseInt(item.dateFrom) > renewalDate) {
            renewalDate = item.dateFrom;
          }
        });
      }
    }
  }

  return renewalDate;
};

/**
 * builds a text showing the limit for something
 * e.g. 1 of 2 scenes used
 * @param {string} singleText - {count} of 1 things used
 * @param {string} pluralText - {count} of {max} things used
 * @param {string} unlimitedText - {count} of unlimited things used
 * @param {number} count - amount used
 * @param {number} max - max amount
 */
export const buildLimit = (
  singleText,
  pluralText,
  unlimitedText,
  count,
  max
) => {
  if (max > 1) {
    return localizer.formatString(pluralText, count, max);
  }
  if (max < 0) {
    return localizer.formatString(unlimitedText, count);
  }
  return localizer.formatString(singleText, count);
};

/**
 * Strips the uuid from an ID to get just the id
 * @param {string} id - id to check
 * @param {*} UUID - indentifier to strip away
 */
export const stripUUIDFromID = (id, UUID) => {
  if (!UUID) {
    return id;
  }
  const parts = id.split('_');
  parts.pop();
  return parts.join('_');
};

/**
 * Check if the user is currently in the highest plan
 * @param {Array} plans - Fetched subscriptionPlans to check against
 * @param {*} currentSubscription - The current plan of the user
 */
export const checkIsOnHighestPlan = (plans, currentSubscription) => {
  if (!currentSubscription) {
    return false;
  }

  if (plans && plans.length > 0) {
    return currentSubscription.objectId === plans[plans.length - 1].objectId;
  }

  return true;
};

/**
 * Process the feedback based on a validity predicate and the current state
 * @param {boolean} validPredicate bool that tells whether it should be valid or not
 * @param {boolean} state.error whether the component has an error
 * @param {boolean} state.processing whether the component is currently processing
 * @param {boolean} state.success whether the component is in sucessful state
 * @param {string} [errorMessage] - optional error message to show in case of an error
 *
 * @returns {Array} array containing `feedback`, `feedbackClassArray` and `buttonState`
 */
export const processFormFeedback = (
  validPredicate,
  error,
  processing,
  success,
  errorMessage
) => {
  let feedback = null;
  const feedbackClassArray = [styles.feedback];

  let buttonState = validPredicate ? 'default' : 'disabled';
  if (processing) {
    buttonState = 'processing';
  }
  if (error) {
    buttonState = 'error';
    feedback = errorMessage || localizer.genericError;
    feedbackClassArray.push(styles.feedbackError);
  }
  if (success) {
    buttonState = 'success';
    feedbackClassArray.push(styles.feedbackSuccess);
  }
  return [feedback, feedbackClassArray, buttonState];
};

export const checkVatIsValidSDK = async (vat, vatPrefix, sessionToken) =>
  await Vault.checkVat(vatPrefix, vat, sessionToken);

export const getPricesFromRecurringEstimate = (recurringEstimate) => {
  const { invoiceEstimate, nextInvoiceEstimate, difference } =
    recurringEstimate;

  let result = {};

  // InvoiceEstimate can be null if the current subscriptionplan is free
  if (nextInvoiceEstimate && difference) {
    let currentPriceWithoutVAT = 0;
    if (invoiceEstimate) {
      currentPriceWithoutVAT = invoiceEstimate.subTotal / 100;
    }
    currentPriceWithoutVAT = currentPriceWithoutVAT.toFixed(2);

    const addons = difference.lineItems;
    const totalPriceWithVAT = (nextInvoiceEstimate.total / 100).toFixed(2);

    const totalPriceWithoutVAT = (nextInvoiceEstimate.subTotal / 100).toFixed(
      2
    );

    let totalPriceVAT = 0;
    if (nextInvoiceEstimate.taxes.length > 0) {
      totalPriceVAT = (nextInvoiceEstimate.taxes[0].amount / 100).toFixed(2);
    }

    result = {
      currentPriceWithoutVAT,
      addons,
      totalPriceWithVAT,
      totalPriceVAT,
      totalPriceWithoutVAT
    };
  }

  return result;
};

/**
 * Input a number from chargebee to get a pretty price
 * @param {Number} amount - price without comma to be converted
 * @param {string} currencyCode - code to convert to symbol: 'EUR', 'USD', etc
 */
export const makePriceFromCents = (amount, currencyCode) => {
  if (!currencyCode || !currencySymbolMap(currencyCode)) {
    return `${(amount / 100).toFixed(2)}`;
  }
  return `${currencySymbolMap(currencyCode)} ${(amount / 100).toFixed(2)}`;
};

/**
 * Input a number from the backend
 * @param {Number} amount - price without comma to be converted
 * @param {string} currencyCode - code to convert to symbol: 'EUR', 'USD', etc
 */
export const makePrice = (amount, currencyCode) => {
  if (!currencyCode || !currencySymbolMap(currencyCode)) {
    return `${amount.toFixed(2)}`;
  }
  return `${currencySymbolMap(currencyCode)} ${amount.toFixed(2)}`;
};

/**
 * adds a renew being tracked.
 * @param {String} userObjectId - ObjectId of the user to reset
 * @param {Boolean} reset - whether the tracked renews need to reset
 */
export const updateRenewTrackingCookie = (userObjectId, reset) => {
  const cookies = new Cookies();
  const renewTrackCookie = `prompto/renew/${userObjectId}`;

  let renews = reset ? 0 : Number(cookies.get(renewTrackCookie)) + 1 || 0;
  cookies.set(renewTrackCookie, renews, {
    path: '/',
    expiry: new Date(Date.now() + 12096e5)
  });
};

/**
 * Gets the amount of renews for this user
 * @param {String} userObjectId
 * @returns {Number} amount this account has renewed
 */
export const getRenewTrackingCookie = (userObjectId) => {
  const cookies = new Cookies();
  const renewTrackCookie = `prompto/renew/${userObjectId}`;
  return Number(cookies.get(renewTrackCookie)) || 0;
};

/**
 * add a new logged out cookie
 */
export const createLoggedOutCookie = () => {
  const cookies = new Cookies();
  cookies.set(env().loggedOutCookieName, true, {
    path: '/',
    expiry: new Date(Date.now() + 12096e5)
  });
};

/**
 * remove the loggedOut cookie
 */
export const removeLoggedOutCookie = () => {
  const cookies = new Cookies();
  cookies.remove(env().loggedOutCookieName, { path: '/' });
};

/**
 * Create a cookie with the correct settings for us
 * @param {string} cookieName
 * @param {string} cookieValue
 * @param {number} [expiry] an optional expiry date, standard set to 10 years.
 */
export const createCookie = (cookieName, cookieValue, expiry) => {
  const cookies = new Cookies();
  const cookieData = {
    path: '/',
    // expiry set at 10 years
    expiry: new Date(new Date().setFullYear(new Date().getFullYear() + 10)),
    sameSite: 'Lax'
  };
  if (!isLocalhost) {
    cookieData.domain =
      `.${getDomainName(window.location.hostname)}` || env().applicationDomain;
  }
  cookies.set(cookieName, cookieValue, cookieData);
};

/**
 * Remove a cookie correctly
 * @param {string} cookieName
 */
const removeCookie = (cookieName) => {
  const cookies = new Cookies();
  const cookieData = {
    path: '/'
  };

  // we also have to add the domain here, else the cookie is not removed
  if (!isLocalhost) {
    cookieData.domain =
      `.${getDomainName(window.location.hostname)}` || env().applicationDomain;
  }

  cookies.remove(cookieName, cookieData);
};

/**
 * create an auth cookie storing the session token
 *
 * @param {string} sessionToken
 */
export const createAuthCookie = (sessionToken) => {
  createCookie(`prompto/${env().env}/ust`, sessionToken);
};

/**
 * remove the session token cookie
 */
export const removeAuthCookie = () => {
  removeCookie(`prompto/${env().env}/ust`);
};

/**
 *
 * @param {string} vaultId
 */
export const createVaultCookie = (vaultId) => {
  createCookie(`prompto/${env().env}/vid`, vaultId);
};

/**
 * remove the session token cookie
 */
export const removeVaultCookie = () => {
  removeCookie(`prompto/${env().env}/vid`);
};

/**
 * remove the user ID cookie
 */
export const removeUserIdCookie = () => {
  removeCookie(`prompto/${env().env}/uid`);
};

export const createUserIdCookie = (userId) => {
  createCookie(`prompto/${env().env}/uid`, userId);
};

/**
 * Runs a querystring search on the url params, to fetch the data,
 * destructure it as you need params
 */
export const fetchSettingsFromURL = () =>
  qs.parse(window.location.search, {
    parseBooleans: true,
    parseNumbers: true
  });

/**
 * Downloads the file from the given url and gives it the correct name
 * @param {array} files
 * @param {string=} vaultId
 * @param {object=} user
 * @param {function=} onDownloaded
 */
export const downloadFilesFromUrl = (
  files,
  vaultId,
  user,
  onDownloaded = () => {}
) => {
  const downloadedFilesIds = [];
  let blobSize = 0;
  let loadedFilesCount = 0;
  files.forEach((file, index) => {
    const { url, title, objectId } = file;
    downloadedFilesIds.push(objectId);
    if (url) {
      axios({
        url,
        method: 'GET',
        responseType: 'blob'
      }).then((result) => {
        const blob = new Blob([result.data]);
        blobSize += blob.size;
        loadedFilesCount++;

        if (loadedFilesCount === files.length) {
          onDownloaded(downloadedFilesIds, blobSize);
        }

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', title ?? '');
        document.body.appendChild(link);
        setTimeout(() => {
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        }, index * 2500);
      });
    }
  });
};

/**
 * Transform the given address to a readable string address
 * @param {address} address
 */
export const makeAddressString = (address) => {
  if (!address) {
    return '';
  }

  // go over the keys in this list to build the string with them
  // if they exist
  return ['addressLine1', 'city', 'country', 'zipCode'].reduce(
    (currAddress, current) => {
      if (address[current]) {
        currAddress += ` ${address[current]}`;
      }
      return currAddress;
    },
    ''
  );
};

/**
 * Add or edit params to the search string
 * @param {Array<Object>} arr - array of params to add
 */
export const addQueryParams = (arr) => {
  const parsed = qs.parse(window.location.search);

  arr.forEach((param) => {
    const key = Object.keys(param)[0];
    parsed[key] = param[key];
  });

  window.history.replaceState(
    {},
    '',
    `${window.location.pathname}?${qs.stringify(parsed)}`
  );
};

/**
 * remove params to the search string
 * @param {Array<Object>} arr - array of params to add
 */
export const removeQueryParams = (arr) => {
  const parsed = qs.parse(window.location.search);

  arr.forEach((param) => {
    const key = Object.keys(param)[0];
    parsed[key] = undefined;
  });

  window.history.replaceState(
    {},
    '',
    `${window.location.pathname}?${qs.stringify(parsed)}`
  );
};

/**
 * Ping prompto with a url change with params
 * @param {array} params - must be {key: value} objects
 */
export const pingPrompto = (params) => {
  addQueryParams(params);
  setTimeout(() => {
    removeQueryParams(params);
  }, 50);
};

export const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export const getDomainName = (hostName) =>
  hostName.substring(
    hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1
  );

/**
 * Create a URL to load the mediagallery in an iframe
 * @param {string} sceneObjectId
 * @param {string} sceneCatalogueId
 * @param {string} unitObjectId
 * @param {string} unitVaultObjectId
 * @param {string} language
 */
export const buildGalleryUrl = (
  sceneObjectId,
  sceneCatalogueId,
  unitObjectId,
  unitVaultObjectId,
  lang,
  env,
  sessionToken
) => `https://mediagallery.prompto.com/?${qs.stringify({
  sceneObjectId,
  sceneCatalogueId,
  unitObjectId,
  unitVaultObjectId,
  lang,
  env,
  sessionToken
})}
`;

export const isSafariRunningIosInDesktopMode = () =>
  isIOS && //running iOS
  isMobileSafari && //running Safari
  !getUA.includes('iPad') && //when running in 'desktop' mode the user agent does not contain 'ipad' or 'iphone' but 'Macintosh' instead
  !getUA.includes('iPhone');

/**
 * Get if is running on tablet or mobile
 */
export const isTabletOrMobileDevice = () =>
  isIOS || //running iOS
  isAndroid ||
  isWinPhone;

/**
 * Read a file as DataURL
 * @param {File} file
 * @returns {Promise} Promise that resolves with the DataURL
 * @
 */
export const readFileAsDataURL = function (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;

    reader.readAsDataURL(file);
  });
};

/**
 * Creates an image element from a dataURL
 * @param {String} dataURL
 * @returns {Promise} Promise that resolves with the image element
 * @
 */
export const createImageFromDataURL = function (dataURL) {
  return new Promise((resolve, reject) => {
    const image = new Image();

    image.onload = function () {
      resolve(this);
    };
    image.onerror = reject;

    image.src = dataURL;
  });
};

/**
 * Get the 5 digit Id from a Uri
 */
export const getIdFromUri = (uri) => {
  let code = '';
  const regex = /id=.{5}/;
  const id = regex.exec(uri);

  if (id !== null) {
    code = id.toString().substring(3);
  }

  return code;
};

/**
 * Sorts alphabetically
 * @param {string} textA
 * @param {string} textB
 */
export const nameSort = (textA, textB, ascending) => {
  if (!textA && !textB) {
    return 0;
  } else if (!textA) {
    return 1;
  } else if (!textB) {
    return -1;
  }

  if (textA < textB) {
    return ascending ? -1 : 1;
  } else if (textA > textB) {
    return ascending ? 1 : -1;
  }
  return 0;
};

/**
 * Sorts by date
 * @param {string} dateA
 * @param {string} dateB
 */
export const dateSort = (dateA, dateB, ascending) => {
  if (dateA === 0) {
    return -1;
  } else if (dateB === 0) {
    return 1;
  }
  return ascending ? dateB - dateA : dateA - dateB;
};

/**
 * Creates a `${name} is required` message that is localized
 * @param {string} name
 */
export const createIsRequiredMessage = (name) =>
  `${capitalize(name)} ${localizer.isRequired}.`;

/**
 * Creates a `${name} is invalid` message that is localized
 * @param {string} name
 */
export const createInvalidMessage = (name) =>
  `${capitalize(name)} ${localizer.invalid}.`;

/**
 * Creates a `enter ${name}` message that is localized
 * @param {string} name
 */
export const createPlaceholderMessage = (name) =>
  capitalize(localizer.formatString(localizer.enter, name));

/**
 * returns a list of users with their roles from a roleList
 * @param {array} list
 */
export const getUsersFromRoleList = (list) => {
  let newUserList = [];

  for (const role in list) {
    const users = list[role];
    const usersToAdd = users.map((user) => ({
      ...user,
      role,
      status:
        user.type === 'User'
          ? capitalize(localizer.active)
          : capitalize(localizer.invited)
    }));

    newUserList = [...newUserList, ...usersToAdd];
  }

  return newUserList;
};

export const isObjectEmpty = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

/**
 * Get if provided url is valid
 * @param {String} url The width of the container in pixels
 */
export const isValidUrl = (url) => {
  // eslint-disable-next-line max-len
  const regexp =
    /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  return regexp.test(url);
};

/**
 * Compute cloudinary ID
 * @param {array} uri - uri
 * @param {array} bucketId - bucketId
 */
export const computeCloudinaryId = (uri, bucketId) => {
  const splitString = bucketId.concat('/o/');
  const tokens = uri.split(splitString);
  if (!tokens || tokens.length < 2) {
    return null;
  }

  const relativePath = tokens[1].split('?')[0];
  const fileExtension = path.extname(relativePath);
  const cloudinaryId = relativePath.replace(fileExtension, '');

  return cloudinaryId;
};

/**
 * Compute cloudinary Thumbnail
 */
export const computeCloudinaryThumbnail = (
  uri,
  bucketId,
  baseUrl,
  transform
) => {
  if (uri && bucketId) {
    const cloudinaryId = computeCloudinaryId(uri, bucketId);
    const macMachine = navigator.userAgent
      .toString()
      .toLowerCase()
      .match(/mac/g);

    const extension = macMachine ? 'jpeg' : 'webp';
    let finalImageUri = `${baseUrl}`;
    if (transform) {
      finalImageUri += `/${transform}`;
    }
    finalImageUri += `/${cloudinaryId}.${extension}`;

    return finalImageUri;
  }
};

/**
 * It builds a complete cloudinary transform url
 *
 * @param {string} uri the data uri stored on prompto backend
 * @param {string} bucketId the google bucket id
 * @param {string} baseUrl the base cloudinary image or video url
 * @param {string} transform optional cloudinary transformation
 * @param {string} extension file extension
 */
export const computeTransformUri = (
  uri,
  bucketId,
  baseUrl,
  transform,
  extension
) => {
  if (uri && bucketId) {
    const cloudinaryId = computeCloudinaryId(uri, bucketId);
    let finalTransformUri = `${baseUrl}`;
    if (transform) {
      finalTransformUri += `/${transform}`;
    }
    finalTransformUri += `/${cloudinaryId}.${extension}`;
    return finalTransformUri;
  }
};

/**
 * It checks based on provided params if a specific cloudinary transformation is available
 *
 * @param {function} setTransformAvailable call back function
 * @param {string} uri the date uri stored on prompto backend
 * @param {string} bucketId the google bucket id
 * @param {string} baseUrl the base cloudinary image or video url
 * @param {string} transform optional cloudinary transformation
 * @param {string} extension file extension
 */
export const isCloudinaryTransformationAvailable = async (
  setTransformAvailable,
  uri,
  bucketId,
  baseUrl,
  transform,
  extension
) => {
  const checkUrl = computeTransformUri(
    uri,
    bucketId,
    baseUrl,
    transform,
    extension
  );
  const [err, result] = await to(axios({ method: 'head', url: checkUrl }));

  if (err) {
    setTransformAvailable(false);
    return;
  }

  const { status } = result;
  if (status === 200) {
    setTransformAvailable(true);
  } else {
    setTransformAvailable(false);
  }
};

/**
 * Determine the type of a file
 * @param {string} filename
 */
export const determineItemType = (filename) => {
  let type = 'undefined';

  uploadSettings.categories.forEach((category) => {
    category.contentItemTypes.forEach((contentItemType) => {
      if (
        contentItemType.extensions.includes(
          path.extname(filename).toLowerCase()
        )
      ) {
        type = contentItemType.name;
      }
    });
  });

  return type;
};

/**
 * Get all the images from the given files
 * @param {[]} files
 */
export const getImages = async (files) => {
  const imageFiles = files.filter(
    (file) => determineItemType(file.name) === 'image'
  );

  const promises = imageFiles.map(async (imageFile) => {
    const [readFileErr, imageAsDataURL] = await to(
      readFileAsDataURL(imageFile)
    );
    if (readFileErr) {
      throw readFileErr;
    }

    const [imageErr, image] = await to(createImageFromDataURL(imageAsDataURL));
    if (imageErr) {
      throw imageErr;
    }

    return { imageFile, width: image.width, height: image.height };
  });

  const [imagesErr, images] = await to(Promise.all(promises));
  if (imagesErr) {
    throw imagesErr;
  }

  return images;
};

/**
 * Prepare an array of external experiences for unit attachments
 * @param {{ externalExperienceUrl: string, vmContentCollection: Object }} unit
 */
export const getExternalExperiences = (unit) => {
  // check the externalExperienceUrl property on sake of backward compatibility
  let externalExperiences = [];
  if (unit?.externalExperienceUrl) {
    externalExperiences = [
      {
        contentItemType: 'externalExperienceUrl',
        contentUri: unit.externalExperienceUrl
      }
    ];
  }
  const urlContentItems = unit?.vmContentCollection?.vmContentItemList?.filter(
    (item) =>
      item.contentItemType === 'url' && item.contentItemState !== 'archived'
  );
  if (urlContentItems?.length > 0) {
    externalExperiences = [...urlContentItems];
  }
  return externalExperiences.filter(Boolean);
};

/**
 * Convert date to format: 01.02.2021
 * @param {Date} date
 */
export const dateToDDMMYY = (date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const formattedDay = (day < 10 ? '0' : '') + day;
  const formattedMonth = (month < 10 ? '0' : '') + month;

  return `${formattedDay}.${formattedMonth}.${year}`;
};

/**
 * Calculate share item expiration date based on the showcase configuration
 * @param {string | undefined} shareCodeDefaultExpirationTime
 * @returns {number} expiration date timestamp or 0 if share item should never expire
 */

export const getShareItemExpirationDate = (shareCodeDefaultExpirationTime) => {
  const getDate = (config) => getTime(add(Date.now(), config));
  switch (shareCodeDefaultExpirationTime) {
    case 'oneWeek':
      return getDate({ weeks: 1 });
    case 'twoWeeks':
      return getDate({ weeks: 2 });
    case 'oneMonth':
      return getDate({ months: 1 });
    case 'never':
    default:
      return 0;
  }
};

/**
 * Validate textMap to make sure it has valid value for at least one language
 * @param {Object} textMap
 * @returns {Boolean} whether the textMap is valid or not
 */
export const validateTextMap = (textMap) => {
  if (!textMap) return false;

  return Object.values(textMap).some((value) => {
    const trimmed = value.trim();
    return !!trimmed;
  });
};

/**
 * Display localized value
 * @param {Object} textMap
 * @returns {String} localized value
 */
export const displayLocalizedValue = (textMap) => {
  if (!textMap) return '';
  const lang = localizer.getLanguage();

  // 1. try to display a value in app language
  const value = textMap[lang];
  if (value) return value;

  // 2. fallback to value in other languages following the order [en, nl, fr, de]
  const fallbackLang = ['en', 'nl', 'fr', 'de'].find((lang) => textMap[lang]);
  return fallbackLang ? textMap[fallbackLang] : '';
};

/**
 * Convert timestamp to a DD/MM/YYYY format date
 * @param {Number} timestamp
 */
export const getDDMMYYYDate = (timestamp, separator = '/') => {
  const date = new Date(timestamp);
  return `${date.getDate()}${separator}${
    date.getMonth() + 1
  }${separator}${date.getFullYear()}`;
};

/**
 * Generate new folder name based on default name and existing folder names
 * @param {string} defaultName
 * @param {Array} oldFolders
 * @param {string=} newName
 * @param {Object=} updatingFolder
 */
export const generateFolderName = (
  defaultName,
  oldFolders = [],
  newName,
  updatingFolder = {}
) => {
  let newFolderName = newName || defaultName;
  let appliedToExisting = false;
  oldFolders.forEach((fldr) => {
    const currentName = fldr.name.trim();
    // if name for the same order - it is not need to be changed
    if (updatingFolder.uuid === fldr.uuid) appliedToExisting = true;
    // but if any other folder has same name - that means that new name should be applied
    if (currentName === newFolderName && updatingFolder.uuid !== fldr.uuid)
      appliedToExisting = false;
    if (currentName === newFolderName && !appliedToExisting) {
      const lastPartOfName = +currentName.split(defaultName)[1].trim();
      // if last part consist of defaultName + number in the end - increase number
      if (typeof lastPartOfName === 'number' && !isNaN(lastPartOfName)) {
        const newFolderNumber = lastPartOfName + 1;
        newFolderName = defaultName + ' ' + newFolderNumber;
        // if last part consist of defaultName + string in the end - add + 1 to whole name
      } else if (isNaN(lastPartOfName)) {
        newFolderName = newFolderName + ' 1';
      }
    }
  });
  const isAlreadyExist = oldFolders.some(
    (oldFolder) => oldFolder.name === newFolderName
  );
  // recursively find default name with next available number in the end
  if (isAlreadyExist && !appliedToExisting)
    return generateFolderName(defaultName, oldFolders, newFolderName);
  return newFolderName;
};

/**
 * Return the list of heights that are generated for each image by Cloudinary
 */
export const getBackendGeneratedHeights = () => {
  return [200, 250, 300, 400, 800, 1080, 1600, 2160];
};

const defaultSortingOrder = [
  'tour360',
  'floorplan',
  'url',
  'image360',
  'image',
  'video',
  'document',
  'image360Linked',
  'imageLinked',
  'videoLinked',
  'documentLinked'
];

export const sortBySortingOrders = (
  rawUnitContent,
  customOrder,
  defaultOrder = defaultSortingOrder
) => {
  if (customOrder && customOrder.length > 0) {
    const sortedItems = [];
    customOrder.forEach((itemObjectId) => {
      const contentItem = rawUnitContent.find(
        (item) => item.objectId === itemObjectId
      );
      if (contentItem) {
        sortedItems.push(contentItem);
      }
    });
    return sortedItems;
  } else if (defaultOrder) {
    const sortedItemsObj = defaultOrder.reduce(
      (sorted, curr) => ({ ...sorted, [curr]: [] }),
      {}
    );
    rawUnitContent.forEach((item) => {
      let category;
      switch (item.contentItemType) {
        case 'image360':
          category = item.isLinked ? 'image360Linked' : 'image360';
          break;
        case 'image':
          category = item.isLinked ? 'imageLinked' : 'image';
          break;
        case 'video':
          category = item.isLinked ? 'videoLinked' : 'video';
          break;
        case 'document':
          category = item.isLinked ? 'documentLinked' : 'document';
          break;
        default:
          category = item.contentItemType;
      }
      sortedItemsObj[category].push(item);
    });
    const sortedItems = Object.values(sortedItemsObj).reduce(
      (res, curr) => res.concat(...curr),
      []
    );
    return sortedItems;
  } else {
    return rawUnitContent;
  }
};

/**
 * Get the color from the theme that matches the unit state
 * @param {Object} theme theme object created by using ThemeProvider of StyledComponent
 * @param {String} unitState 'state' field of the 'vmUnit' object. The values are: AVAILABLE, IN_OPTION, SOLD and ARCHIVED.
 *                            You can see the documentation here: https://devapiv2.vr-tual.media/asciidoc/index.html#_vmunit_model
 * @param {{ unitColors: Object }} showcaseConfig
 * @returns return the color as a string or magenta if the state is not one of the expected values
 */
export const getColorForUnitState = (theme, unitState, showcaseConfig) => {
  const colorOnError = theme.primary300;

  switch (unitState) {
    case 'AVAILABLE':
      return (
        showcaseConfig.unitColors?.unitAvailableColor ??
        theme.unitState.available
      );

    case 'IN_OPTION':
      return (
        showcaseConfig.unitColors?.unitOptionColor ?? theme.unitState.option
      );

    case 'SOLD':
      return showcaseConfig.unitColors?.unitSoldColor ?? theme.unitState.sold;

    default:
      return colorOnError;
  }
};

/**
 * Converts a given duration in milliseconds to a readable version
 * @param {number} duration in milliseconds
 * @returns Formatted string in format 0d 0h 0m 0s
 */
export const convertMilliSecondsToReadableVersion = (duration) => {
  var day, hour, minute, seconds;
  seconds = Math.floor(duration / 1000);
  minute = Math.floor(seconds / 60);
  seconds = seconds % 60;
  hour = Math.floor(minute / 60);
  minute = minute % 60;
  day = Math.floor(hour / 24);
  hour = hour % 24;

  // Return value including days
  if (day > 0) {
    return day + 'd ' + hour + 'h ' + minute + 'm ' + seconds + 's';
  }

  // Return value including hours
  if (hour > 0) {
    return hour + 'h ' + minute + 'm ' + seconds + 's';
  }

  return minute + 'm ' + seconds + 's';
};

/**
 * Return the options needed to display a graph, we store some basic configuration here.
 * You can visit https://www.chartjs.org/docs/latest/general/options.html for documentation
 * @param {number} maxYValue The maximum value on the y-axis for the graph we want to show
 * @returns
 */
export const getBasicChartOptions = (maxYValue) => {
  const align = 'end';

  const options = {
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false,
        anchor: align,
        offset: 10,
        opacity: 1,
        color: 'black'
      },
      legend: {
        display: false
      },
      title: {
        display: false
      }
    },
    scales: {
      yAxis: {
        min: 0,
        max: maxYValue,
        grid: {
          z: 1
        }
      },
      xAxis: {
        stacked: true,
        grid: {
          z: 1
        }
      }
    }
  };

  return options;
};

/**
 * Get the specified field in the given order
 * @param {object} entity The entity to get the field from
 * @param {string} field The field we want
 */
export const getFieldValue = (entity, field) => {
  switch (field) {
    default:
    case 'shareCode':
    case 'archived':
    case 'totalPromptoSessions':
    case 'uniqueVisits':
    case 'averageTime':
    case 'totalTimeSpent':
    case 'lastActivity':
    case 'country':
    case 'city':
      return entity[field];
    case 'firstName':
    case 'email':
      return entity.customer[field];
  }
};

/**
 * Sort the items based on the sortfield in ascending or descending order
 * @param {array} entities The entities to be sorted
 * @param {string} sortField The field we want to sort on
 * @param {boolean} isAscending If we need to sort in an ascending or descending order
 * @param {boolean} toNumber if needed make sorting particular by numbers
 */
export const sortEntities = (entities, sortField, isAscending, toNumber) => {
  return entities?.sort((entityA, entityB) => {
    let a = getFieldValue(entityA, sortField);
    let b = getFieldValue(entityB, sortField);

    if (toNumber) {
      a = +a;
      b = +b;
    }

    if (typeof a === 'string') {
      const compareResult = a.localeCompare(b);

      return isAscending ? compareResult : -1 * compareResult;
    }

    if (a < b) {
      return isAscending ? -1 : 1;
    }
    if (a > b) {
      return isAscending ? 1 : -1;
    }

    return isAscending;
  });
};

export const hexToRGBA = (h, a) => {
  let r = 0,
    g = 0,
    b = 0;

  // 3 digits
  if (h.length === 4) {
    r = '0x' + h[1] + h[1];
    g = '0x' + h[2] + h[2];
    b = '0x' + h[3] + h[3];

    // 6 digits
  } else if (h.length === 7) {
    r = '0x' + h[1] + h[2];
    g = '0x' + h[3] + h[4];
    b = '0x' + h[5] + h[6];
  }

  return { r: +r, g: +g, b: +b, a: a ?? 1 };
};

export const stringifyRGBA = (rgba) => {
  const { r, g, b, a } = rgba;
  return `rgba(${r},${g}, ${b}, ${a})`;
};

// calculate file extension
// https://github.com/goranmoomin/extname/blob/master/index.js
const CHAR_FORWARD_SLASH = 47; /* / */
const CHAR_DOT = 46; /* . */

export const extname = (path) => {
  if (typeof path !== 'string') {
    throw new TypeError(
      `The "path" argument must be of type string. Received type ${typeof path}`
    );
  }

  let startDot = -1;
  let startPart = 0;
  let end = -1;
  let matchedSlash = true;
  // Track the state of characters (if any) we see before our first dot and
  // after any path separator we find
  let preDotState = 0;
  for (let i = path.length - 1; i >= 0; --i) {
    let code = path.charCodeAt(i);
    if (code === CHAR_FORWARD_SLASH) {
      // If we reached a path separator that was not part of a set of path
      // separators at the end of the string, stop now
      if (!matchedSlash) {
        startPart = i + 1;
        break;
      }
      continue;
    }
    if (end === -1) {
      // We saw the first non-path separator, mark this as the end of our
      // extension
      matchedSlash = false;
      end = i + 1;
    }
    if (code === CHAR_DOT) {
      // If this is our first dot, mark it as the start of our extension
      if (startDot === -1) {
        startDot = i;
      } else if (preDotState !== 1) {
        preDotState = 1;
      }
    } else if (startDot !== -1) {
      // We saw a non-dot and non-path separator before our dot, so we should
      // have a good chance at having a non-empty extension
      preDotState = -1;
    }
  }

  if (
    startDot === -1 ||
    end === -1 ||
    // We saw a non-dot character immediately before the dot
    preDotState === 0 ||
    // The (right-most) trimmed path component is exactly '..'
    (preDotState === 1 && startDot === end - 1 && startDot === startPart + 1)
  ) {
    return '';
  }
  return path.slice(startDot, end);
};

/*
 * get user data from ipapi.co api
 * */
export const getUserData = async () => {
  const response = await fetch(
    `https://ipapi.co/json/?key=${import.meta.env.VITE_IPAPI_SECRET_KEY}`
  );
  return await response.json();
};

/*
 * filter out files if size exceeds upload settings limits
 * */
export const filterOutFilesBySize = (files) => {
  return files.filter((file) => {
    const fileExtension = extname(file.name);
    let contentItemType = {};
    uploadSettings.categories?.forEach((category) => {
      category.contentItemTypes.forEach((itemType) => {
        if (itemType.extensions.includes(fileExtension)) {
          contentItemType = itemType;
        }
      });
    });
    return file.size <= contentItemType.limits.maxSize;
  });
};

export const abbreviateNumber = (price) => {
  const abbreviations = {
    B: 1000000000, // billion
    M: 1000000, // million
    K: 1000 // thousand
  };

  function abbreviate(price, range, letter) {
    const main = Math.floor(price / range);
    const reminder = (price % range) / range;

    return `${(main + +reminder).toFixed(2)}${letter}`;
  }

  if (price > abbreviations.B) {
    return abbreviate(price, abbreviations.B, 'B');
  } else if (price > abbreviations.M) {
    return abbreviate(price, abbreviations.M, 'M');
  } else if (price > abbreviations.K) {
    return abbreviate(price, abbreviations.K, 'K');
  } else {
    return price;
  }
};

export const getAddress = (geocoderData) => {
  if (!geocoderData) {
    return;
  }
  const addressComps = geocoderData.address_components;
  if (!addressComps) {
    return;
  }

  const city = addressComps.filter((x) => x.types.includes('locality'))[0];

  const province = addressComps.filter((x) =>
    x.types.some((type) => type.includes('administrative_area_level_2'))
  )[0];

  const region = addressComps.filter((x) =>
    x.types.some((type) => type.includes('administrative_area_level_1'))
  )[0];

  const country = addressComps.filter((x) => x.types.includes('country'))[0];

  let street = addressComps.filter((x) => x.types.includes('route'))[0];
  if (!street) {
    street = addressComps.filter(
      (x) =>
        x.types.includes('plus_code') ||
        x.types.includes('establishment') ||
        x.types.includes('point_of_interest') ||
        x.types.includes('tourist_attraction')
    )[0];
  }
  const streetNumber = addressComps.filter((x) =>
    x.types.includes('street_number')
  )[0];

  const zipCode = addressComps.filter((x) =>
    x.types.includes('postal_code')
  )[0];

  const location = geocoderData.geometry.location;
  const latitude = location.lat();
  const longitude = location.lng();

  let addressLine1 = `${street?.long_name || ''}`;
  if (streetNumber) {
    addressLine1 += ` ${streetNumber?.long_name || ''}`;
  }

  if (!addressLine1 || !city || !(province || region) || !country) {
    return;
  }

  const address = {
    addressLine1,
    addressLine2: '',
    city: `${city?.long_name || ''}`,
    zipCode: `${zipCode?.long_name || ''}`,
    country: `${country?.long_name || ''}`,
    latitude,
    longitude,
    province: province
      ? `${province?.long_name || ''}`
      : `${region?.long_name || ''}`,
    region: `${region?.long_name || ''}`,
    validated: true
  };

  return address;
};

// Remove query params related to Project Page internal state
export const resetProjectPageRelatedQueryParams = () => {
  const parsed = qs.parse(window.location.search);
  const filteredSearch = {};
  Object.entries(parsed).forEach(([key, value]) => {
    if (!['pps', 'pus'].includes(key)) {
      filteredSearch[key] = value;
    }
  });

  return qs.stringify(filteredSearch);
};
