import to from 'await-to-js';

import { Notification } from '@prompto-api';

// 1. Once a user gets authenticated we start making calls
//    to get notifications count.
// 2. The calls are being repeated every X seconds (currently it's 10 seconds).
// 3. If the call fails we don't wait next X seconds,
//    but repeat the call immediately.
// 4. If the call fails too many times, we stop doing calls
//    and show an error icon.

const MAX_RETRIES = 5;
const INTERVAL = 30 * 1000;
let getNotificationsInterval;

export const getNotificationsCount = (call) => (dispatch, getState) => {
  let retryCount = 0;

  const getCount = () => {
    const currentState = getState();
    const sessionToken = currentState.store?.AuthReducer?.sessionToken;
    const userId = currentState.store?.AuthReducer?.user?.objectId;

    let lastTimeOpenedNotifications =
      currentState.store?.NotificationsReducer?.lastTimeOpened;

    if (!lastTimeOpenedNotifications) {
      Notification.getLastTimeOpenedTimestamp(userId, sessionToken)
        .then((result) => {
          const timestamp = result.data?.lastTimeOpenedNotifications;
          lastTimeOpenedNotifications = timestamp;
          dispatch(updateLastTimeOpenedNotifications(timestamp));
        })
        .catch(() => {});
    }

    const params = {
      userId,
      limit: 1
    };

    if (lastTimeOpenedNotifications) {
      params.createdAtGreaterOrEqual = lastTimeOpenedNotifications;
    }

    return call(sessionToken, params)
      .then(async (result) => {
        retryCount = 0;
        const count = result.data?.totalCount ?? 0;

        // If feed is closed, update the count
        // else:
        //   1. get the latest notifications and add them to the list
        //   2. update lastTimeOpenedNotifications value

        const feedIsOpened =
          currentState.store?.NotificationsReducer?.isActivityFeedOpened;
        if (feedIsOpened && count > 0) {
          dispatch(Notification.getAll(sessionToken, { limit: count }, 'new'));
          const [, result] = await to(
            Notification.updateLastTimeOpenedTimestamp(userId, sessionToken)
          );
          const newTimestamp = result.data.lastTimeOpenedNotifications;
          dispatch(updateLastTimeOpenedNotifications(newTimestamp));
        } else {
          dispatch({ type: 'SET_NOTIFICATIONS_COUNT', payload: count });
        }
      })
      .catch(() => {
        ++retryCount;
        if (retryCount > MAX_RETRIES) {
          dispatch({
            type: 'SET_NOTIFICATIONS_COUNT_LOADING_STATE',
            payload: 'error'
          });
          clearInterval(getNotificationsInterval);
        }
      });
  };

  if (getNotificationsInterval) return;

  getNotificationsInterval = setInterval(() => {
    const isAuth = getState().store?.AuthReducer?.authenticated;
    if (isAuth) {
      getCount();
    }
  }, INTERVAL);
};

export const updateLastTimeOpenedNotifications = (timestamp) => (dispatch) => {
  localStorage.setItem('lastTimeOpenedPromptoNotifications', timestamp);
  dispatch({ type: 'SET_LAST_TIME_OPENED', payload: timestamp });
};

/**
 * Get Prompto notifications
 * @param {String} sessionToken
 * @param {Object} queryParams
 * @param {'new' | 'old'} type: if 'new' we add notifications to the beginning of the list, if 'old' - to the end
 */
export const getAllNotifications =
  (sessionToken, queryParams = {}, type = 'new') =>
  async (dispatch, getState) => {
    const currentState = getState();
    const user = currentState.store?.AuthReducer?.user;
    const endWasReached =
      !!currentState.store?.NotificationsReducer?.endReached;

    if (endWasReached && type !== 'new') return;

    const params = {
      userId: user?.objectId,
      offset: 0,
      limit: 20,
      orderBy: 'createdAt',
      descendantOrder: true,
      ...queryParams
    };

    dispatch({ type: 'FETCH_NOTIFICATIONS_PENDING' });

    const [error, response] = await to(
      Notification.getAll(sessionToken, params)
    );
    if (error) {
      dispatch({ type: 'FETCH_NOTIFICATIONS_FAIL' });
      return;
    }
    const notifications = response?.data?.notificationList ?? [];
    const endReached = response?.data?.totalCount < params.offset;
    dispatch({
      type: 'FETCH_NOTIFICATIONS_SUCCESS',
      payload: {
        notifications,
        endReached,
        type,
        totalCount: response?.data?.totalCount ?? 0
      }
    });
  };
