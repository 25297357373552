import { VL_GET_VAULTS, VL_ACCEPT_INVITATION } from './VaultListActions';

const VaultListReducer = (state = {}, action) => {
  switch (action.type) {
    case VL_GET_VAULTS: {
      return {
        ...state,
        vaultList: action.apiResult.vaultList,
        invitationList: action.apiResult.invitationList
      };
    }
    case VL_ACCEPT_INVITATION: {
      //find the id
      let indexInvitation = -1;
      let tempList = state.invitationList;
      for (var i = 0; i < tempList.length; i++) {
        let inv = tempList[i];
        if (inv.invitation.uuid === action.apiResult.invitationUUID) {
          indexInvitation = i;
          break;
        }
      }
      if (indexInvitation === -1) {
        //ignore the payload
        return { ...state };
      }

      let newVault = {
        objectId: action.apiResult.vaultId,
        name: action.apiResult.vaultName
      };

      return {
        ...state,
        invitationList: [
          ...state.invitationList
            .slice(0, indexInvitation)
            .concat(state.invitationList.slice(indexInvitation + 1))
        ],
        vaultList: [...state.vaultList.concat([newVault])]
      };
    }

    case 'AUTH_LOGOUT_REQUEST_PENDING': {
      return {};
    }

    default: {
      return state;
    }
  }
};

export default VaultListReducer;
