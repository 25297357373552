import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

// helpers
import {
  createAuthCookie,
  removeAuthCookie,
  createUserIdCookie,
  removeUserIdCookie
} from './app/helpers/util.js';

// Environments
import registerSentry from './app/registerSentry';
import registerServiceWorker from './app/registerServiceWorker';
import queryString from 'query-string';

// Styling
import { ThemeProvider } from 'styled-components';
import 'tippy.js/dist/tippy.css';
import './app/styles/main.css';

// App routing
import AppRouter from './app/AppRouter';

// Setup store and history
import storeBuilder from './app/store/store';
import {
  useDeeplinksAction,
  setAssimpVersionFilter,
  setUe4VersionFilter,
  useMipimAction
} from './app/store/reducers/appReducer/AppActions.js';
import history from './app/store/history';
import { saveState } from './app/store/localstore';
import registerFaIcons from 'registerFaIcons.js';

const theme = {
  language: 'en',
  showcaseWhite: '#ffffff',
  showcaseBlack: '#000000',
  primaryColor: '#3384f3',
  secondaryColor: '#100a2b',
  hightLightColor: '#00e8cb',
  accentMenuDefault: '#635cf7',
  accentMenuDark: '#6f67b7',
  primary100: '#9f9caa',
  primary200: '#706a81',
  primary300: '#574f6b',
  primary400: '#403957',
  primary700: '#1c1636',
  primary900: '#11082d',
  beigeBg10: '#f5f5f2',
  beigeBg20: '#f6f8fc',
  gray100: '#f3f2f4',
  gray200: '#edecf0',
  gray300: '#e7e6ea',
  gray350: '#c7cad1',
  gray400: '#c3c2ca',
  gray450: '#abb0ba',
  gray600: '#737b8c',
  accentAlt25: '#efeefe',
  accentAlt50: '#efeef7',
  accentAlt100: '#adaaf8',
  accentAlt300: '#635cf7',
  accentAlt400: '#6f67b7',
  accentAlt500: '#584dab',
  accentAlt700: '#4236a1',
  whitePure: '#ffffff',
  whiteOff: '#f7f8fa',
  accent: '#d6b959',
  accentLight: '#ebdcac',
  successColor: '#4ca760',
  successLight: '#a6d3b0',
  successMedium: '#75BA85',
  warning: '#d16d3a',
  warningLight: '#e6b095',
  warningMedium: '#DB9572',
  errorColor: '#be4a49',
  errorLight: '#dfa5a4',
  placeholder: '#eeaeae',
  sidebarGradientBright: '#2b185c',
  grayWhiteOff: '#f9f7fa',
  checkboxLabelColor: '#6e7a87',
  checkboxBgColor: '#6f67b7',
  borderDarkerColor: '#dbdee1',
  checkboxBorderColor: '#6f67b7',
  checkboxUncheckedBorderColor: '#e7e6ea',
  defaultSpotColor: 'rgba(175,175,175,1)',
  fadeWhite: 'rgba(255, 255, 255, 0.5)',
  fadeWhite08: 'rgba(255, 255, 255, 0.8)',
  reddish: '#be4a49',
  projectDescription: '#8897ae',
  red: '#ff0000',
  descriptionTitle: '#6f7e95',
  descriptionActions: '#a1b0c7',
  descriptionActionsRGBA025: 'rgb(161, 176, 199, 0.25)',
  grayWhiteOffDragged: '#f3f1f4',
  baseBg: '#f2f5f8',
  discountBgColor: '#d1ead3',
  textDisabled: 'rgba(36, 51, 74, 0.5)',
  defaultShowcaseColors: {
    primary: '#403957',
    secondary: '#b6a36c'
  },
  projectState: {
    draft: '#9f9caa',
    upcoming: '#d6b959',
    completed: '#4ca760',
    ongoing: '#209cea'
  },
  unitState: {
    default: '#26C6DA',
    available: '#4CA760',
    sold: '#9F9CAA',
    option: '#D16D3A'
  },
  mainTextColor1: '#24334a',
  mainTextColor2: '#a1b0c7',
  isSidebarWide: false,
  orange: 'f79500',
  fonts: {
    DMSans: "'DM Sans', sans-serif"
  }
};

//Process URLQueries
const strings = queryString.parse(window.location.search);

storeBuilder(strings.sessionToken).then((store) => {
  store.subscribe(() => {
    saveState({
      store: {
        AuthReducer: store.getState().store.AuthReducer
      }
    });
  });
  if (store.getState().store.AuthReducer.authenticated) {
    createAuthCookie(store.getState().store.AuthReducer.sessionToken);
    createUserIdCookie(store.getState().store.AuthReducer.user.objectId);
  } else {
    removeAuthCookie();
    removeUserIdCookie();
  }

  store.dispatch(
    useDeeplinksAction(typeof strings.useDeeplinks !== 'undefined')
  );
  store.dispatch(useMipimAction(typeof strings.useMipim !== 'undefined'));
  if (typeof strings.assimpVersionFilter !== 'undefined') {
    store.dispatch(setAssimpVersionFilter(strings.assimpVersionFilter));
  }
  if (typeof strings.ue4VersionFilter !== 'undefined') {
    store.dispatch(setUe4VersionFilter(strings.ue4VersionFilter));
  }

  registerFaIcons();
  registerSentry();

  // Render the main app, and changing this comment to trigger pipeline 2
  ReactDOM.render(
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <AppRouter store={store} history={history} />
      </ThemeProvider>
    </BrowserRouter>,
    document.getElementById('root')
  );

  // Serves the app offline instead of default chrome "offline" message
  registerServiceWorker();
  /* eslint-enable react/jsx-filename-extension */
});

/* eslint-enable react/jsx-filename-extension */
